import { TABLE_DEFAULT_PAGE_SIZE } from 'constant';
import { useState } from 'react';
import { SelectChangeEvent } from 'x-material-ui';

export function usePagination(handleChange?: (limit: number, page: number) => void) {
  const [limit, setLimit] = useState(TABLE_DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(0);
  function handlePageChange(newPage: number) {
    setPage(newPage);
    handleChange?.(limit, newPage);
  }
  function handleRowsPerPageChange(e: SelectChangeEvent<number>) {
    if (e) {
      const newLimit = Number(e.target.value);
      setPage(0);
      setLimit(newLimit);
      handleChange?.(newLimit, 0);
    }
  }
  return { limit, setLimit, page, setPage, handlePageChange, handleRowsPerPageChange };
}