import { LocaleT } from './interface';
import { trans } from './locale';
import { Cluster } from 'model';

export function getNormalStatus(identity: number, t: LocaleT = trans) {
  let status = 'active';
  let text = t('正常');
  if (identity === 2) {
    status = 'error';
    text = t('异常');
  }
  return {
    status,
    text,
  };
}

export function getConformStatus(identity: number, t: LocaleT = trans) {
  let status = 'active';
  let text = t('符合');
  if (identity === 2) {
    status = 'inconformity';
    text = t('不符合');
  }
  return {
    status,
    text,
  };
}

export function getNormalDangerStatus(identity: number, t: LocaleT = trans) {
  let status = 'active';
  let text = t('正常');
  if (identity === 2) {
    status = 'error';
    text = t('危险');
  }
  return {
    status,
    text,
  };
}

export function getServiceWarrantyStatus(cluster: Cluster) {
  let status = 'active';
  let text = '正常';
  if (new Date(cluster.warrantyEnd).getTime() < Date.now()) {
    status = 'expired';
    text = '已过期'
  }
  return {
    status,
    text,
  };
}

export function getUnknownNormalDangerNaStatus(identity: number, t: LocaleT = trans) {
  let status = 'not-support';
  let text = t('未知');
  if (identity === 1) {
    status = 'active';
    text = t('正常');
  } else if (identity === 2) {
    status = 'error';
    text = t('危险');
  } else if (identity === 3) {
    status = 'not-support';
    text = t('不支持');
  }
  return {
    status,
    text,
  };
}