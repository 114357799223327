import { LicenseDigital, LicenseDigitalLimit, LicenseDigitalService, LicenseDigitalVersion } from 'model';
import { getProductFeatureDisplay, getProductName } from 'common/src/license';
import { LICENSE_LIMITS } from 'constant';
import { InfoFieldProps } from 'interfaces/props';
import t from 'locale';
import React from 'react';
import styles from './index.module.scss';
import Collapse from '../Collapse';
import DetailBasicInfo from '../DetailBasicInfo';

type DigitalLicenseV4InfoProps = {
  license: LicenseDigital;
  warrantyDateConditionFields: InfoFieldProps[];
  additionalFields: InfoFieldProps[];
  renderService: (service: LicenseDigitalService, index: number, version: LicenseDigitalVersion) => React.ReactNode
}

const DigitalLicenseV4Info = (props: DigitalLicenseV4InfoProps) => {
  const { license, warrantyDateConditionFields, additionalFields, renderService } = props;
  function renderFeature(feature: string, className?: string) {
    return <div key={feature} className={className}>{getProductFeatureDisplay(feature)}</div>
  }
  function getLimitFields(license: LicenseDigital) {
    const fields: InfoFieldProps[] = [];
    const limits = license.limits;
    const limitKeys = Object.keys(limits) as (keyof LicenseDigitalLimit)[];
    limitKeys.map(limit => {
      fields.push({
        label: LICENSE_LIMITS[limit],
        value: limits[limit],
      });
    });
    return fields;
  }
  function getProductFields(license: LicenseDigital) {
    const fields: InfoFieldProps[] = [];
    fields.push({
      label: t('软件产品'),
      value: getProductName(license.productInfo),
    });
    fields.push({
      label: t('特性'),
      value: <>{
        (license.features || []).map(feature => renderFeature(feature))
      }</>
    });
    return fields;
  }
  return (<div className={styles['info-content-v4']}>
    <div className={styles['title-text']}>{t('license 配置')}</div>
    <Collapse title={t('产品信息')} initialOpen>
      <DetailBasicInfo fields={getProductFields(license)} col={2} colWidth={6} className={styles['info-field-container']} />
    </Collapse>
    <Collapse title={t('限制')} initialOpen>
      <DetailBasicInfo fields={getLimitFields(license)} col={2} colWidth={6} className={styles['info-field-container']} />
    </Collapse>
    {
      license.scene !== 'poc' &&
      <Collapse title={t('服务')} initialOpen>
        <DetailBasicInfo
          fields={warrantyDateConditionFields}
          col={2}
          colWidth={6}
          className={styles['info-field-container']}
        />
        {(license.services && license.services.filter(s => s.name !== '0') || []).map((s, i) => renderService(s, i, license.version))}
      </Collapse>
    }
    <Collapse title={t('其他')} initialOpen>
      <DetailBasicInfo fields={additionalFields}
        col={2}
        colWidth={6}
        className={styles['info-field-container']}
      />
    </Collapse>
  </div>)
}

export default DigitalLicenseV4Info;