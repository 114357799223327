import { Contract } from "model";
import t from 'locale';
import ScmShipment from './ScmShipment'
import DeliveryRecord from './DeliveryRecord'
import styles from './index.module.scss';
const Delivery = (props: { contract: Contract }) => {
  return <div className={styles.content}>
    <div>
      <div className={styles['title-text']}>{t('硬件发货信息')}</div>
      <ScmShipment contractNo={props.contract.no} />
    </div>
    <div className={styles['delivery-content']}>
      <div className={styles['title-text']}>{t('软件交付信息')}</div>
      <DeliveryRecord contractId={props.contract.id} />
    </div>
  </div>

}
export default Delivery;