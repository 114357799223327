import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import store from 'store';
import lodash from 'lodash';
const LOCALE = 'LOCALE';
const DEFAULT_LOCALE = 'zh-CN';
let currentLocale = store.get(LOCALE);
let resource = {};

try {
  resource = require(`./${currentLocale}.json`);
} catch (e) {
  console.warn(`no ${currentLocale} i18n resources`);
}

if (!/en|zh/.test(currentLocale)) {
  const tempNavigator: any = navigator;
  currentLocale =
    tempNavigator.language ||
    tempNavigator.browserLanguage ||
    tempNavigator.userLanguage ||
    DEFAULT_LOCALE;
}

export function getLocale() {
  return store.get(LOCALE) || DEFAULT_LOCALE;
}

export function setLocale(slug: string, localeData: any) {
  lodash.set(localeData, ['', 'localeSlug'], slug);
  store.set(LOCALE, slug);
}

const currentLocaleShort = currentLocale.split('-')[0];

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  appendNamespaceToCIMode: true,
  saveMissing: true,

  // have a common namespace used around the full app
  lng: currentLocaleShort,
  ns: ['translations'],
  defaultNS: 'translations',
  resources: {
    [currentLocaleShort]: { translations: resource },
  },

  debug: process.env.NODE_ENV !== 'production',
  nsSeparator: false,
  keySeparator: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format(value, format) {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },
});

export default i18n;
