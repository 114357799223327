export function downloadFromStream(stream: any, name: string) {
  const url = window.URL.createObjectURL(new Blob([stream]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    name,
  );
  link.click();
}
