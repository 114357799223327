import { useState } from 'react';

export function useCheckboxColumn<T extends { checked?: boolean; id?: number | string; code?: string }>(data: T[], setData: React.Dispatch<React.SetStateAction<T[]>>) {
  const [checkAll, setCheckAll] = useState(false);
  const [checkedData, setCheckedData] = useState<T[]>([]);
  function equal(a: T, b: T) {
    if ('id' in a) {
      return a.id === b.id;
    }
    return a.code === b.code;
  }
  function handleCheckItem(checked: boolean, record: T) {
    record.checked = checked;
    setData([...data]);
    if (checked) {
      setCheckedData([...checkedData, record]);
    } else {
      setCheckedData(checkedData.filter(d => !equal(d, record)));
    }
    if (!checked && checkAll) {
      setCheckAll(false);
    }
    if (data.filter(d => !d.checked).length === 0) {
      setCheckAll(true);
    }
  }
  function handleCheckAll(checked: boolean) {
    data.forEach(d => d.checked = checked);
    setData([...data]);
    setCheckAll(checked);
    if (checked) {
      setCheckedData([...data]);
    } else {
      setCheckedData([]);
    }
  }
  return { checkedData, checkAll, handleCheckItem, handleCheckAll, setCheckedData, setCheckAll };
}