import React, { useState } from 'react';
import {
  Table,
  roleNameColumn,
  userGroupTypeColumn,
  permissionIdColumn,
  permissionDescColumn,
} from 'components';
import t from 'locale';
import { RoleInfo as RoleInfoProps } from 'interfaces/props';
import { Button, Tab, Tabs } from 'x-material-ui';
import styles from './index.module.scss';
import moment from 'moment';
import { DATE_FORMAT } from 'common';
import DetailHeaderInfo from '../DetailHeaderInfo';
import Pagination from '../Pagination';
import { usePagination } from '../../hooks';
import { getPermissionAndUserFromRole } from 'common/src/permission';

type PermissionManagementTab = 'roleManage' | 'permissionManage';

const RoleInfo = (props: RoleInfoProps) => {
  const { role, addUserToRole, addPermissionToRole } = props;
  const { permissions: rolePermissions, users } = getPermissionAndUserFromRole(role);
  const [tab, setTab] = useState<PermissionManagementTab>('roleManage');
  const { limit, page, setPage, handlePageChange, handleRowsPerPageChange } = usePagination(handlePagerChange);

  function handlePagerChange(limit: number, page: number) {
    setPage(page);
  }
  const handleTabChange = (e: React.ChangeEvent<{}>, value: PermissionManagementTab) => {
    setTab(value);
  };
  return (
    <div className={styles['role-info']}>
      <div className={styles.header}>
        <DetailHeaderInfo
          title={role.name}
          fields={[{ label: t('备注'), text: role.desc }, { label: t('添加时间'), text: moment(role.createdAt).format(DATE_FORMAT) }]}
          col={2}
          colWidth={6}
        />
      </div>
      <Tabs
        value={tab}
        onChange={handleTabChange}
      // size="small"
      >
        <Tab
          value="roleManage"
          label={t('用户（组）管理')}
        />
        <Tab
          value="permissionManage"
          label={t('权限管理')}
        />
      </Tabs>
      {tab === 'roleManage' && (
        <div className={styles.content}>
          <div className={styles.next}>
            <Button 
            color="primary" 
            className={styles.next} 
            onClick={() => addUserToRole(role)}
            classes={{root:styles['add-button']}}>
              {t('添加')}
            </Button>
          </div>
          <Table
            data={users && users.slice(page * limit, page * limit + limit)}
            columns={[roleNameColumn, userGroupTypeColumn]}
            loading={false}
          />
          {!!(users && users.length) && (
            <Pagination
              count={users.length}
              rowsPerPage={limit}
              page={page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          )}
        </div>
      )}
      {tab === 'permissionManage' && (
        <div className={styles.content}>
          <div className={styles.next}>
            <Button 
            color="primary" 
            onClick={() => addPermissionToRole(role)}
            classes={{root:styles['add-button']}}>
              {t('添加')}
            </Button>
          </div>
          <div className={styles.table}>
            <Table
              data={
                rolePermissions &&
                rolePermissions.slice(page * limit, page * limit + limit)
              }
              columns={[permissionIdColumn, roleNameColumn, permissionDescColumn]}
              loading={false}
            />
          </div>
          {!!(rolePermissions && rolePermissions.length) && (
            <Pagination
              count={rolePermissions.length}
              rowsPerPage={limit}
              page={page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              className={styles['table-pagination']}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default RoleInfo;
