import { TABLE_DEFAULT_PAGE_SIZE } from 'constant';
import { GlobalContext } from 'context';
import t from 'locale';
import { LicenseDigital, TableFilterSetting } from 'model';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cancelDigitalLicense, downloadDigitalLicense, getLicenses, recallDigitalLicense } from 'request';
import { downloadFromStream } from 'utils';
import DefaultTable from '../DefaultTable';
import {
  digitalLicenseActiveDateColumn,
  digitalLicensApplicantColumn,
  digitalLicensApplyDateColumn,
  digitalLicenseClusterFsIdColumn,
  digitalLicenseClusterNameColumn,
  digitalLicenseCustomerNameColumn,
  digitalLicenseExpiredDateColumn,
  digitalLicenseOperateColumn,
  digitalLicensePermitTypeColumn,
  digitalLicenseProductNameColumn,
  digitalLicenseSerialNoColumn,
  digitalLicenseStatusColumn,
  digitalLicenseVersionColumn,
} from '../Column';
import DetailsContainer from '../DetailsContainer';
import DigitalLicenseDetail from '../DigitalLicenseDetail';
import styles from './permit-record-list.module.scss';
import { Button, Dialog, DialogContent, DialogTitle, MenuItem, Select, SelectChangeEvent, TextareaAutosize, TextButton } from 'x-material-ui';
import FormItemLine from '../FormItemLine';
import FormItemLabel from '../FormItemLabel';
import { PermitRecordListProps } from 'interfaces/props';
import { checkPermission } from 'common/src/permission';

type DialogType = 'cancel' | 'recall' | 'apply';
const PermitRecordList = (props: PermitRecordListProps) => {
  const history = useNavigate();
  const [licenses, setLicenses] = useState<LicenseDigital[]>([]);
  const [total, setTotal] = useState(0);
  const [version, setVersion] = useState('V4');
  const [loading, setLoading] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showLicenseDialog, setShowLicenseDialog] = useState(false);
  const [dialogType, setDialogType] = useState<DialogType>('cancel');
  const [reason, setReason] = useState('');
  const [currentLicense, setCurrentLicense] = useState<LicenseDigital>();
  const { token } = useContext(GlobalContext);
  const [setting, setSetting] = useState<TableFilterSetting>({
    limit: TABLE_DEFAULT_PAGE_SIZE,
    page: 0,
    search: ''
  });
  useEffect(() => {
    fetchData(setting.limit, setting.page, setting.search);
    if (props.defaultId) {
      setShowDetail(true);
    }
  }, []);
  async function fetchData(limit: number, page: number, search: string) {
    setSetting({
      limit,
      page,
      search,
    });
    setLoading(true);
    const response = await getLicenses(limit, page * limit, search);
    const result = response.data;
    setLicenses(result[0]);
    setTotal(result[1]);
    setLoading(false);
  }
  function handleSerialNoClick(license: LicenseDigital) {
    setCurrentLicense(license);
    setShowDetail(true);
  }
  const columns = [
    digitalLicenseSerialNoColumn(handleSerialNoClick),
    digitalLicensePermitTypeColumn,
    digitalLicenseProductNameColumn,
    digitalLicenseVersionColumn,
    digitalLicenseStatusColumn,
    digitalLicenseActiveDateColumn,
    digitalLicenseExpiredDateColumn,
    digitalLicenseClusterNameColumn,
    digitalLicenseClusterFsIdColumn,
    digitalLicenseCustomerNameColumn,
    digitalLicensApplyDateColumn,
    digitalLicensApplicantColumn,
    digitalLicenseOperateColumn(handleOperateClick)
  ];
  function handleOperateClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, data: LicenseDigital) {
    const target = e.target as any;
    if (target) {
      const className = target.className || '';
      if (className.indexOf('disabled') !== -1) {
        return;
      } else if (className.indexOf('edit') !== -1) {
        handleEditClick(data.masterId ? data.masterId : data.id, data.version);
      } else if (className.indexOf('recall') !== -1) {
        handleRecallClick(data);
      } else if (className.indexOf('cancel') !== -1) {
        handleCancelClick(data);
      } else if (className.indexOf('download') !== -1) {
        handleDownloadClick(data);
      }
    }
  }
  function handleEditClick(id: number, version: string) {
    history(`/license-form?id=${id}&version=${version}`);
  }
  async function handleDownloadClick(data: LicenseDigital) {
    const response = await downloadDigitalLicense(data.masterId ? data.masterId : data.id);
    data.download = true;
    setLicenses([...licenses]);
    downloadFromStream(response.data, `license-${Date.now()}.enc`);
  }
  function handleRecallClick(data: LicenseDigital) {
    setCurrentLicense(data);
    setDialogType('recall');
    setShowLicenseDialog(true);
  }
  function handleCancelClick(data: LicenseDigital) {
    setCurrentLicense(data);
    setDialogType('cancel');
    setShowLicenseDialog(true);
  }
  const toolbars: any[] = [];
  if (checkPermission('ApplyOEMLicense', token)) {
    toolbars.push({
      type: 'button',
      children: t('申请'),
      onClick: handleApplyClick
    });
  }
  function handleApplyClick() {
    setDialogType('apply');
    setShowLicenseDialog(true);
  }
  async function handleOkClick() {
    if (dialogType === 'apply') {
      history(`/license-form?version=${version}`);
    } else if (dialogType === 'cancel') {
      setOperationLoading(true);
      await cancelDigitalLicense(currentLicense?.masterId ? currentLicense?.masterId : currentLicense?.id || 0, reason);
      setOperationLoading(false);
      setShowLicenseDialog(false);
      fetchData(setting.limit, setting.page, setting.search);
    } else if (dialogType === 'recall') {
      setOperationLoading(true);
      await recallDigitalLicense(currentLicense?.masterId ? currentLicense?.masterId : currentLicense?.id || 0, reason);
      setOperationLoading(false);
      setShowLicenseDialog(false);
      fetchData(setting.limit, setting.page, setting.search);
    }
  }
  function handleCancelLicenseDialogClick() {
    setShowLicenseDialog(false);
    setOperationLoading(false);
  }
  async function handleVersionChange(event: SelectChangeEvent<string>, child: ReactNode) {
    const version = event.target.value as string;
    setVersion(version);
  }
  const confirmTitle = {
    cancel: t('作废'),
    recall: t('撤回'),
    apply: t('申请')
  };
  function renderVersion() {
    return <FormItemLine>
      <FormItemLabel label={t('产品版本')} required />
      <Select
        classes={{
          select: `${styles.select} ${styles['select-input']}`,
          icon: styles['select-icon'],
        }}
        variant="outlined"
        value={version}
        onChange={handleVersionChange}
      >
        {['V4', 'V5', 'V6'].map((menu, i) => {
          return (<MenuItem key={i} value={menu}>
            {menu}
          </MenuItem>)
        })}
      </Select>
    </FormItemLine>;
  }
  function renderOperation() {
    return <div>
      <div className={styles['tip-content']}>
        {t('确认{{operate}}该条电子 license 申请吗？', { operate: confirmTitle[dialogType] })}
      </div>
      <div className={styles['textarea-container']}>
        <TextareaAutosize
          className='textarea'
          maxRows={15}
          onChange={handleReasonChange}
          value={reason}
          placeholder={t('请输入{{operate}}原因', { operate: confirmTitle[dialogType] })}
        />
      </div>
    </div>;
  }
  function handleReasonChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setReason(e.target.value);
  }
  return (<div className={styles.container}>
    <DefaultTable
      loading={loading}
      data={licenses}
      columns={columns}
      toolbars={toolbars}
      right={1}
      name="permit"
      total={total}
      fetchData={fetchData}
    />
    <Dialog open={showLicenseDialog} >
      <div className={styles['modal-container']}>
        <DialogTitle id="scroll-dialog-title" hasClose onCloseClick={handleCancelLicenseDialogClick}>{t('{{action}} license', { action: confirmTitle[dialogType] })}</DialogTitle>
        <DialogContent >
          {dialogType === 'apply' ? renderVersion() : renderOperation()}
          <div className={styles.action}>
            <TextButton
              size="medium"
              className={styles.cancel}
              variant='text'
              color='primary'
              onClick={handleCancelLicenseDialogClick}
            >
              {t('取消')}
            </TextButton>
            <Button
              size='medium'
              variant='contained'
              loading={operationLoading}
              disabled={operationLoading}
              color={dialogType === 'cancel' ? 'secondary' : 'primary'}
              onClick={handleOkClick}
            >
              {confirmTitle[dialogType]}
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
    {
      showDetail && (<DetailsContainer open={showDetail} setOpen={setShowDetail} >
        <DigitalLicenseDetail type='oem' viewType="apply" id={currentLicense?.masterId ? currentLicense?.masterId : currentLicense?.id || props.defaultId} />
      </DetailsContainer>)
    }
  </div>);
}

export default PermitRecordList;