import { CLusterPermitProps } from 'interfaces/props';
import t from 'locale';
import { SdsV5BaseLicense, SdsV5ExtensionLicense, SdsV5ProductLicense } from 'model';
import Table from '../Table';
import {
  sdsLicenseExpireTime,
  sdsLicenseHddQuotaColumn,
  sdsLicenseHostNumQuotaColumn,
  sdsLicenseNameColumn,
  sdsLicenseNoColumn,
  sdsLicensePeriodColumn,
  sdsLicenseSignedTime,
  sdsLicenseSsdQuotaColumn,
  sdsLicenseStatusColumn,
  sdsLicenseV4ExpireTime,
  sdsLicenseV4SignedTime,
  sdsV4LicenseCapacityColumn,
  sdsV4LicenseCapacityPerHostColumn,
  sdsV4LicenseHostNumColumn,
  sdsV4LicenseNameColumn,
  sdsV4LicenseStatusColumn
} from '../Column';
import styles from './detail.module.scss';


const ClusterPermit = (props: CLusterPermitProps) => {
  const { xsosLicenses, v4Licenses } = props;
  function renderV4Below() {
    const columns = [
      sdsV4LicenseNameColumn,
      sdsV4LicenseCapacityColumn,
      sdsV4LicenseHostNumColumn,
      sdsV4LicenseCapacityPerHostColumn,
      sdsV4LicenseStatusColumn,
      sdsLicenseV4SignedTime,
      sdsLicenseV4ExpireTime,
    ];
    return (<div>
      <div className={styles['permit-container']}>
        <div>{t('产品许可激活记录')}</div>
        <Table loading={false} data={v4Licenses} columns={columns} />
      </div>
    </div>);
  }
  function renderV5Above() {
    let productLicense: SdsV5ProductLicense | undefined = undefined;
    const baseLicenses: SdsV5BaseLicense[] = [];
    const extensionLicenses: SdsV5ExtensionLicense[] = [];
    for (const l of xsosLicenses) {
      if (!productLicense && l.type === 'product') {
        productLicense = l as SdsV5ProductLicense;
      }
      if (l.type === 'base') {
        baseLicenses.push(l as SdsV5BaseLicense);
      } else if (l.type === 'extension') {
        extensionLicenses.push(l as SdsV5ExtensionLicense);
      }
    }
    const productColumn = [
      sdsLicenseNoColumn,
      sdsLicenseNameColumn,
      sdsLicenseStatusColumn,
      sdsLicensePeriodColumn,
      sdsLicenseSignedTime,
      sdsLicenseExpireTime,
    ];
    const baseColumn = [
      sdsLicenseNoColumn,
      sdsLicenseNameColumn,
      sdsLicenseStatusColumn,
      sdsLicenseHddQuotaColumn,
      sdsLicenseSsdQuotaColumn,
      sdsLicenseHostNumQuotaColumn,
      sdsLicensePeriodColumn,
      sdsLicenseSignedTime,
      sdsLicenseExpireTime,
    ];
    const extensionColumn = productColumn;
    return (<div>
      <div className={styles['permit-container']}>
        <div>{t('产品许可')}</div>
        <Table loading={false} data={[productLicense]} columns={productColumn} />
      </div>
      <div className={styles['permit-container']}>
        <div>{t('基础许可')}</div>
        <Table loading={false} data={baseLicenses} columns={baseColumn} />
      </div>
      <div className={styles['permit-container']}>
        <div>{t('扩展许可')}</div>
        <Table loading={false} data={extensionLicenses} columns={extensionColumn} />
      </div>
    </div>);
  }
  return <div>
    {!!v4Licenses.length && renderV4Below()}
    {!!xsosLicenses.length && renderV5Above()}
  </div>
}

export default ClusterPermit;