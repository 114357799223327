import styles from './index.module.scss';
import { PercentBarProps } from 'interfaces/props';

const PercentBar = (props: PercentBarProps) => {
  return (
    <div className={`${styles.container} ${props.className}`}>
      <div className={styles['total-bar']}>
        <div className={styles['percent-bar']} style={{ width: props.percent }} />
      </div>
      <div className={styles.details}>
        {props.showPercent && <div className={styles['percent-text']}>{props.percent}</div>}
        {props.showDetail && (
          <div className={styles['detail-text']}>
            {props.current}/{props.total}
          </div>
        )}
      </div>
    </div>
  );
};

export default PercentBar;
