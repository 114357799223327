import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { TOKEN_NAME } from 'constant';
import { getCookie } from 'utils';

let origin = window.location.origin;
if (!origin) {
  origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}
const instance = axios.create({
  baseURL: `${origin}/api/v2/`,
});
const withoutAuthInstance = axios.create({
  baseURL: `${origin}/api/v2/`,
});
const maybeErrorInstance = axios.create({
  baseURL: `${origin}/api/v2/`,
});
const utcOffset = -new Date().getTimezoneOffset() / 60;
function processRequest(config: AxiosRequestConfig) {
  if (config.method === 'get') {
    config.params = Object.assign({ r: Date.now() }, config.params);
  }
  const token = getCookie(TOKEN_NAME);
  if (config.headers) {
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    config.headers.TimezoneOffset = utcOffset;
  }
  return config;
}
function processRequestError(error: any) {
  return Promise.reject(error);
}
function processResponse(response: AxiosResponse<any>) {
  return response;
}
function processUnAuth(response: any) {
  if (response?.status === 401) {
    const currentPath = encodeURIComponent(window.location.pathname + window.location.search);
    window.location.href = `/login?redirect=${currentPath}`;
  }
}
function processResponseError(error: any) {
  const response = error.response;
  processUnAuth(response);
  return Promise.reject(response);
}
function processMaybeErrorResponseError(error: any) {
  const response = error.response;
  processUnAuth(response);
  return Promise.resolve(response);
}
instance.interceptors.request.use(processRequest, processRequestError);
maybeErrorInstance.interceptors.request.use(processRequest, processRequestError);

instance.interceptors.response.use(processResponse, processResponseError);
maybeErrorInstance.interceptors.response.use(processResponse, processMaybeErrorResponseError);

withoutAuthInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.resolve(error.response);
  },
);

export { instance, maybeErrorInstance, withoutAuthInstance };
