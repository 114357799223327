import {
  warrantyClusterNameColumn,
  warrantyDeliveryTypeColumn,
  warrantyClusterServeLevelColumn,
  warrantyClusterServePeriodColumn,
  warrantyClusterWarrantyStartColumn,
  warrantyClusterWarrantyEndColumn,
  warrantyStatusColumn,
  Table,
} from 'components';
import { Cluster } from 'model';
import { useEffect, useState } from 'react';
import { getWarrantyInfoByContractNo } from 'request';
import styles from './index.module.scss';
const WarrantyInfo = (props: { contractNo: string }) => {
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getHardwareProducts()
  }, []);
  async function getHardwareProducts() {
    setLoading(true);
    const response = await getWarrantyInfoByContractNo(props.contractNo);
    const result = response.data;
    setClusters(result);
    setLoading(false);
  }
  const columns = [
    warrantyClusterNameColumn,
    warrantyDeliveryTypeColumn,
    warrantyClusterServeLevelColumn,
    warrantyClusterServePeriodColumn,
    warrantyClusterWarrantyStartColumn,
    warrantyClusterWarrantyEndColumn,
    warrantyStatusColumn
  ];
 
  return (<div className={styles.content}>
    <Table data={clusters} columns={columns}  loading={loading} />
  </div>);
}
export default WarrantyInfo;