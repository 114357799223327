import React, { useState } from 'react';
import styles from './index.module.scss';
import { Trans } from 'react-i18next';
import { MenuItem, Select, TextButton } from 'x-material-ui';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { PaginationProps } from 'interfaces/props';
import classnames from 'classnames';

const Pagination = (props: PaginationProps) => {
  const {
    type = 'normal',
    count,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    rows = [10, 25, 50, 100],
    additionalButton,
  } = props;
  const [value, setValue] = useState(page + 1);
  let pagination = Array.from({ length: (count - 1) / rowsPerPage + 1 }, (x, i) => i);
  const paginationBefore = page > 1 ? 1 : 0;
  const paginationMiddle = [];
  if (page > 0) {
    paginationMiddle.push(pagination[page - 1]);
    paginationMiddle.push(pagination[page]);
  } else {
    paginationMiddle.push(0);
  }
  page + 1 < pagination.length && paginationMiddle.push(pagination[page + 1]);
  const paginationAfter = pagination.length >= 3 && page + 2 < pagination.length ? 1 : 0;
  const SelectComponents = (
    <Select
      defaultValue={rowsPerPage}
      className={styles.select}
      variant="outlined"
      onChange={onChangeRowsPerPage}
    >
      {rows.map(row => (
        <MenuItem key={'option' + row} value={row}>
          {row}
        </MenuItem>
      ))}
    </Select>
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    var value = e.target.value.trim();
    setValue(parseInt(value));
  };

  const handleSearchInputKeydown = (e: React.KeyboardEvent) => {
    e.persist();
    if (e.key === 'Enter') {
      let length = pagination.length;
      if (value < 0) {
        return onChangePage.call(null, 0);
      } else if (value <= length) {
        return onChangePage.call(null, value - 1);
      } else if (value > length) {
        return onChangePage.call(null, length - 1);
      }
    }
  };

  return (
    <div className={classnames(styles.layout, props.className, { [styles['additional-button']]: additionalButton })}>
      {additionalButton?.()}
      <div className={styles['pagination-options']}>
        {type === 'normal' ? (
          <div className={styles.total}>
            <Trans
              defaults="共 {{count}} 条，每页：<0></0>"
              values={{ count }}
              components={[SelectComponents]}
            />
          </div>
        ) : (
          SelectComponents
        )}
        <div className={styles.pagination}>
          {type === 'normal' ? (
            <React.Fragment>
              <TextButton
                onClick={onChangePage.bind(null, page - 1)}
                disabled={page - 1 >= 0 ? false : true}
              >
                <NavigateBeforeOutlinedIcon fontSize={'small'} />
              </TextButton>
              {paginationBefore ? (
                <React.Fragment>
                  <TextButton
                    onClick={onChangePage.bind(null, 0)}
                    className={page === 0 ? styles.active : ''}
                  >
                    {pagination[0] + 1}
                  </TextButton>
                  <MoreHorizOutlinedIcon fontSize={'small'} />
                </React.Fragment>
              ) : null}
              {paginationMiddle.map(v => (
                <TextButton
                  onClick={onChangePage.bind(null, v)}
                  className={page === v ? styles.active : ''}
                  key={`pagination-${v}`}
                  classes={{ root: styles['pagination-page'] }}
                >
                  {v + 1}
                </TextButton>
              ))}
              {paginationAfter ? (
                <React.Fragment>
                  <MoreHorizOutlinedIcon fontSize={'small'} />
                  <TextButton
                    onClick={onChangePage.bind(null, pagination.length - 1)}
                    className={page === pagination.length - 1 ? styles.active : ''}
                    classes={{ root: styles['pagination-page'] }}
                  >
                    {pagination[pagination.length - 1] + 1}
                  </TextButton>
                </React.Fragment>
              ) : null}
              <TextButton
                onClick={onChangePage.bind(null, page + 1)}
                disabled={page + 1 < pagination.length ? false : true}
              >
                <NavigateNextOutlinedIcon fontSize={'small'} />
              </TextButton>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <TextButton
                onClick={onChangePage.bind(null, page - 1)}
                disabled={page - 1 >= 0 ? false : true}
              >
                <NavigateBeforeOutlinedIcon fontSize={'small'} />
              </TextButton>
              <input
                value={page + 1}
                type="number"
                min={1}
                max={pagination.length}
                onChange={handleInputChange}
                onKeyDown={handleSearchInputKeydown}
              />
              <span> / </span>
              <span> {pagination.length} </span>
              <TextButton
                onClick={onChangePage.bind(null, page + 1)}
                disabled={page + 1 < pagination.length ? false : true}
              >
                <NavigateNextOutlinedIcon fontSize={'small'} />
              </TextButton>
            </React.Fragment>
          )}
          {type === 'normal' && (
            <span className={styles.goto}>
              <Trans
                defaults="前往 <0></0>页"
                components={[
                  <input
                    value={value}
                    type="number"
                    min={1}
                    max={pagination.length}
                    onChange={handleInputChange}
                    onKeyDown={handleSearchInputKeydown}
                  />
                ]}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default Pagination;
