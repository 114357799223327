import { getSkuFullName } from "common/src/license";
import { DATE_FORMAT } from "common";
import dayjs from 'dayjs';
import { DiditalLicenseFormModel, LicenseKeyVersionInfo, LicenseSku, ParseLicenseKeyResult, QuerySkuOption, Sku } from "model";
import t from 'locale';
import { querySkusFromLicense } from "request";
import { getExpireDate, getV5SkuQuota, getV5SkuSsdQuotaOmitCache } from "utils";
import { Tooltip } from "x-material-ui";
import { SkuV5TypeProps } from "./interface";

export function getDefaultSku(skuType: SkuV5TypeProps, license: DiditalLicenseFormModel) {
  const activeDate = dayjs(license.activeDate).format(DATE_FORMAT);
  const defaultSku: Partial<LicenseSku> = {
    type: skuType,
    period: license.period || 1000,
    periodUnit: license.periodUnit || 'year',
    signedDate: activeDate,
    expiredDate: getExpireDate(activeDate, license.period || 1000, license.periodUnit || 'year'),
  };
  return defaultSku;
}

export async function querySkuByName(option: QuerySkuOption) {
  const response = await querySkusFromLicense(option);
  return response.data;
}
export function skuDisplayItemWidthTooltipRender(s: Sku) {
  return <Tooltip title={getSkuFullName(s, t)} arrow placement="top">
    <span>{s.name || ''}</span>
  </Tooltip>;
};
export function getMd5FromKeyResult(r: ParseLicenseKeyResult) {
  if (typeof r === 'string') {
    return r;
  }
  return r.md5;
}
export function getKeyInfoFromKeyResult(r: ParseLicenseKeyResult): LicenseKeyVersionInfo {
  if (typeof r === 'string') {
    return {
      md5: r,
      keyAlgorithm: 'pkcs1v15',
    };
  }
  return r as LicenseKeyVersionInfo;
}
export function getSkuCapacityAndHostCount(licenseData: DiditalLicenseFormModel) {
  let capacityTbyte = 0;
  let hostCount = 0;
  if (licenseData.version === 'V4') {
    const limits = licenseData.limits;
    const max_host_num = limits?.max_host_num || 0;
    const max_capacity = limits?.max_capacity ? Number(limits.max_capacity || 0) : Number(limits?.max_capacity_per_host || 0) * Number(max_host_num);
    capacityTbyte = Number(max_capacity);
    hostCount = Number(max_host_num);
  } else {
    const skus = getLicenseSkusFromLicense(licenseData);
    skus.forEach(licenseSku => {
      const count = licenseSku.count || 1;
      const sku = licenseSku.sku
      const { hdd, host } = getV5SkuQuota(sku);
      const { ssd: ssdWithoutCache } = getV5SkuSsdQuotaOmitCache(sku);
      hostCount += host * count;
      capacityTbyte += hdd * count + ssdWithoutCache * count;
    });
  }
  return { hostCount, capacityTbyte }
}
export function getLicenseSkusFromLicense(license: DiditalLicenseFormModel) {
  const licenseData = [license, ...(license.licenseV6Cluster || [])];
  const skus: LicenseSku[] = [];
  licenseData.forEach(l => {
    skus.push(...(l.skus as LicenseSku[]))
  });
  return skus;
}