import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import {
  DiditalLicenseFormModel,
  LicenseDigitalClassify,
  LicenseDigitalVersion,
} from 'model';
import { toChineseNum } from 'common/src/number';
import { FormItemLabel, FormItemLine } from 'components';
import t from 'locale';
import lodash from 'lodash';
import React, { useEffect, useRef } from 'react';
import { getDetaultService, getExpectedUsageTime } from 'utils';
import { Checkbox, FormControlLabel, IconButton, TextField } from 'x-material-ui';
import ProductV6 from './product-v6';
import styles from './index.module.scss';
import { licenseV6ClusterProps, TextKey } from './interface';
import SkuV6 from './sku-v6';
import PartTitle from './part-title';
import { getKeyInfoFromKeyResult, getMd5FromKeyResult } from './utils';

const LicenseCluster = (props: licenseV6ClusterProps) => {
  const {
    dataClusterMd5,
    getExpireDate,
    license,
    objectServicePlatformMd5,
    renderInputSelectAdornment,
    renderActiveDate,
    supportedVersionOptions,
    type,
    update,
    version,
    oemSkusRef,
  } = props;
  const licenseRef = useRef<DiditalLicenseFormModel>();
  licenseRef.current = license;
  useEffect(() => {
    init();
  }, [dataClusterMd5, objectServicePlatformMd5]);
  async function init() {
    license.licenseV6Cluster = [];
    if (dataClusterMd5) {
      for (let index = 0; index < dataClusterMd5?.length; index++) {
        const clusterMd5 = dataClusterMd5[index];
        let clusterInfo, clusterName = '', customerName = '';
        const keyInfo = getKeyInfoFromKeyResult(clusterMd5);
        license.licenseV6Cluster?.push({
          id: -Math.random(),
          activeDate: new Date().toString(),
          expireDate: getExpireDate(new Date().toString(), license.period, license.periodUnit),
          contractType: 'new',
          clusterMd5: getMd5FromKeyResult(clusterMd5).split('-')[1],
          classify: 'XSOS-DATA',
          scene: license.scene,
          contractNo: '',
          supportVersions: [version],
          getMd5Type: license.getMd5Type,
          keyAlgorithm: keyInfo.keyAlgorithm,
          keyVaultAgentKey: keyInfo.keyVaultAgentKey,
          keyVaultKey: keyInfo.keyVaultKey,
          keyVersion: keyInfo.keyVersion,
          productInfo: {
            id: 0,
            model: '',
            desc: '',
            series: '',
          },
          services: [getDetaultService()],
          applyReason: '',
          period: license.period,
          periodUnit: license.periodUnit,
          type,
          productType: license.productType,
          skus: [],
          version,
          error: { licenseError: {} },
          clusterInfo: clusterInfo,
          clusterName: clusterName,
          customerName: customerName,
        });
      }
    }
    objectServicePlatformMd5?.forEach(element => {
      const keyInfo = getKeyInfoFromKeyResult(element);
      license.licenseV6Cluster?.push({
        id: -Math.random(),
        activeDate: new Date().toString(),
        expireDate: getExpireDate(new Date().toString(), license.period, license.periodUnit),
        contractType: 'new',
        clusterMd5: keyInfo.md5.split('-')[1],
        classify: 'XSOS-SERVE',
        scene: license.scene,
        contractNo: '',
        supportVersions: [version],
        getMd5Type: license.getMd5Type,
        productInfo: {
          id: 0,
          model: '',
          desc: '',
          series: '',
        },
        services: [getDetaultService()],
        applyReason: '',
        period: license.period,
        periodUnit: license.periodUnit,
        type,
        productType: license.productType,
        skus: [],
        version,
        error: { licenseError: {} }
      });
    });
    license.licenseV6Cluster = lodash.uniqBy(license.licenseV6Cluster, 'clusterMd5');
    const expectedUsageTime = await getExpectedUsageTime(license as DiditalLicenseFormModel);
    license.expectedUsageTime = expectedUsageTime;
    update(license);
  }
  function handleAddCluster(classify: LicenseDigitalClassify) {
    props.licenseV6Clusters?.push({
      id: -Math.random(),
      activeDate: new Date().toString(),
      expireDate: getExpireDate(new Date().toString(), license.period, license.periodUnit),
      contractType: 'new',
      scene: license.scene,
      contractNo: '',
      supportVersions: [version],
      getMd5Type: license.getMd5Type,
      productInfo: {
        id: 0,
        model: '',
        desc: '',
        series: '',
      },
      services: [getDetaultService()],
      applyReason: '',
      period: license.period,
      periodUnit: license.periodUnit,
      type,
      productType: license.productType,
      skus: [],
      version,
      classify,
      error: { licenseError: {} },
      clusterInfo: !license.enableMcm ? license.clusterInfo : undefined,
      clusterName: !license.enableMcm ? license.clusterName : undefined,
      customerName: !license.enableMcm ? license.customerName : undefined,
    });
    update(license);
  }
  function handleTextChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, clusterLicense: DiditalLicenseFormModel, key: TextKey) {
    clusterLicense[key] = e.target.value;
    update(license);
  }

  function handleSupportedVersionChange(version: LicenseDigitalVersion, clusterLicense: DiditalLicenseFormModel, checked: boolean) {
    const versions = clusterLicense.supportVersions || [];
    if (checked) {
      versions.push(version);
    } else {
      const index = versions.indexOf(version);
      versions.splice(index, 1);
    }
    clusterLicense.supportVersions = versions;
    update(license);
    clearError(clusterLicense, 'supportVersions');
  }
  function toggleOpen(license: DiditalLicenseFormModel) {
    license.fold = !license.fold;
    update({});
  }
  function clearError(license: DiditalLicenseFormModel, field: (keyof DiditalLicenseFormModel) | (keyof DiditalLicenseFormModel)[]) {
    license.error = { ...lodash.omit(license.error?.licenseError, field) };
    update({});
  }
  function handleClusterDeleteClick(license: DiditalLicenseFormModel, id: number | undefined, update: Function, clusterLicense: DiditalLicenseFormModel, i: number) {
    if (!license.licenseV6Cluster) {
      return;
    }
    const deleteMd5 = clusterLicense?.clusterMd5;
    if (clusterLicense.classify === 'XSOS-DATA' && deleteMd5) {
      license.dataClusterMd5 = license.dataClusterMd5?.filter(d => getMd5FromKeyResult(d).indexOf(deleteMd5) === -1);
    } else if (clusterLicense.classify === 'XSOS-SERVE' && deleteMd5) {
      license.objectServicePlatformMd5 = license.objectServicePlatformMd5?.filter(d => getMd5FromKeyResult(d).indexOf(deleteMd5) === -1);
    }
    for (let i = 0; i < license.licenseV6Cluster.length; i++) {
      if (license.licenseV6Cluster[i].id === id) {
        license.licenseV6Cluster.splice(i, 1);
        break;
      }
    }
    if (license.v5UpgradeClusterUuid && ([0, 1].includes(i))) {
      license.v5UpgradeClusterUuid = undefined;
    }
    update(license);
  }
  const hiddenProductV6Table = license.contractType === 'expand' && !license.enableMcm
  return <div className={styles['data-cluster-container']}>
    {
      props.licenseV6Clusters?.map((clusterLicense, i) => {
        return (<div key={clusterLicense.id} className={styles['data-object-container']}>
          <div className={styles['collapse-header']}>
            <span className={styles['collapse-title']}>{t('{{classify}}{{index}}', { classify: clusterLicense.classify === 'XSOS-DATA' ? '数据集群' : '对象服务平台', index: toChineseNum(i + 1) })}</span>
            <div className={styles['collapse-split-line']} />
            <div onClick={() => handleClusterDeleteClick(license, clusterLicense.id, update, clusterLicense, i)} className={styles.icon} >
              <DeleteOutlinedIcon fontSize='small' />
            </div>
            <IconButton classes={{ root: styles['fold-arrow'] }} onClick={() => toggleOpen(clusterLicense)}>
              {!clusterLicense.fold ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
            </IconButton>
          </div>
          {!clusterLicense.fold && <div>
            <FormItemLine>
              <FormItemLabel label={t('{{classify}} MD5', { classify: clusterLicense.classify === 'XSOS-DATA' || license.scene === 'test' ? '数据集群' : '对象服务平台' })} />
              {clusterLicense.clusterMd5}
            </FormItemLine>
            <FormItemLine>
              <FormItemLabel label={t('支持产品版本范围')} required />
              <div className={styles['support-versions']}>
                {supportedVersionOptions.map(v => {
                  const version = v as LicenseDigitalVersion;
                  return (<div key={v} className={styles['product-line']}>
                    <FormControlLabel
                      control={<Checkbox
                        checked={clusterLicense.supportVersions?.includes(version) || false}
                        color="primary"
                        onChange={(e, checked) => handleSupportedVersionChange(version, clusterLicense, checked)}
                        name={v}
                      />}
                      label={v}
                    />
                  </div>);
                })}
              </div>
            </FormItemLine>
            {!(clusterLicense.classify === 'XSOS-SERVE' && license.scene !== 'test') && <FormItemLine>
              <FormItemLabel label={t('集群名称')} />
              <FormItemLine>
                <TextField
                  variant="outlined"
                  onChange={e => handleTextChange(e, clusterLicense, 'clusterName')}
                  value={clusterLicense.clusterName}
                  classes={{ root: styles['text-input'] }}
                  placeholder={t('请输入集群名称')}
                />
              </FormItemLine>
            </FormItemLine>}
            {!hiddenProductV6Table && clusterLicense.classify === 'XSOS-DATA' && <ProductV6
              title={<PartTitle title={t('软件产品许可')} />}
              masterLicense={license}
              license={clusterLicense}
              error={clusterLicense.error?.licenseError ? clusterLicense.error?.licenseError : {}}
              update={update}
              getExpireDate={getExpireDate}
              renderInputSelectAdornment={renderInputSelectAdornment}
              renderActiveDate={renderActiveDate}
              type={type}
              version={version}
              oemSkusRef={oemSkusRef}
            />}
            <SkuV6
              title={<PartTitle title={t('基础许可')} />}
              type={type}
              skuType="base"
              license={clusterLicense}
              error={clusterLicense.error?.baseSkus}
              update={update}
              clearError={() => { clearError(clusterLicense, 'baseSkus') }}
              renderInputSelectAdornment={renderInputSelectAdornment}
              renderActiveDate={renderActiveDate}
              getExpireDate={getExpireDate}
              classify={clusterLicense.classify}
              oemSkusRef={oemSkusRef}
            />
            <SkuV6
              title={<PartTitle title={t('扩展许可')} />}
              type={type}
              skuType="extension"
              license={clusterLicense}
              error={clusterLicense.error?.extensionSkus}
              update={update}
              clearError={() => { clearError(clusterLicense, 'extensionSkus') }}
              renderInputSelectAdornment={renderInputSelectAdornment}
              renderActiveDate={renderActiveDate}
              getExpireDate={getExpireDate}
              classify={clusterLicense.classify}
              oemSkusRef={oemSkusRef}
            />
          </div>}
        </div>);
      })
    }
    <div className={styles['add-cluster-button']}>
      {(license.enableMcm || license.licenseV6Cluster?.length === 0) && <span onClick={() => handleAddCluster('XSOS-DATA')} className="link-without-decoration">+ {t('添加数据集群')}</span>}
      {license.enableMcm && <span onClick={() => handleAddCluster('XSOS-SERVE')} className="link-without-decoration">+ {t('添加对象服务平台')}</span>}
    </div>
  </div>
}

export default LicenseCluster;
