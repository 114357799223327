import classnames from 'classnames';
import { LicenseDigital, LicenseSku, Sku } from 'model';
import {
  FormItemLabel,
  FormItemLine,
  licenseProductSkuTableModelActiveDate,
  licenseProductSkuTableModelExpireDate,
  licenseProductSkuTableModelNameColumn,
  licenseProductSkuTableModelPeriodColumn,
  Table
} from 'components';
import { TableColumn } from 'interfaces/props';
import t from 'locale';
import lodash from 'lodash';
import {useEffect, useState } from 'react';
import { getLicensesByMd5 } from 'request';
import { skuDisplayItemRender, skuDisplayRender } from 'utils';
import { Dialog, DialogContent, DialogTitle, SearchSelect } from 'x-material-ui';
import styles from './index.module.scss';
import { ProductV6Props } from './interface';
import ProductPeriod from './product-period';
import { getDefaultSku} from './utils';


const ProductV6 = (props: ProductV6Props) => {
  const { license, update, type, error, oemSkusRef } = props;
  const [historyLicenses, setHistoryLicenses] = useState<LicenseDigital[]>([]);
  const [showHistoryModel, setShowHistoryModel] = useState<boolean>(false);
  const columns: TableColumn[] = [
    licenseProductSkuTableModelNameColumn,
    licenseProductSkuTableModelPeriodColumn,
    licenseProductSkuTableModelActiveDate,
    licenseProductSkuTableModelExpireDate,
  ];
  function handleSkuChange(sku: Sku) {
    let skus: Partial<LicenseSku>[] = [];
    if (license.contractType !== 'upgrade' && (['XE', 'XINFINI', 'XH', 'XP', 'XLRS', 'SDDC'].includes(license.productType || '') || type === 'oem')) {
      if (!!sku?.extensions?.find(e => !lodash.isEmpty(e.limits))) {
        skus = [{
          ...getDefaultSku('base', license),
          sku,
          code: sku.code,
          skuId: sku.id,
          id: -Math.random(),
        }];
      }
    }
    license.productSku = sku;
    license.productSkuCode = sku.code;
    license.productSkuId = sku.id;
    license.skus = skus;
    update({});
  }
  function handleCancelModelClick() {
    setShowHistoryModel(false);
  }
  async function handleHistoryModelClick() {
    if (license.clusterMd5) {
      const response = await getLicensesByMd5(license.clusterMd5, type, 'V6');
      const historyLicense = (response.data[0] || []).filter(l => !!l.productSku);
      if (historyLicense) {
        setHistoryLicenses(historyLicense);
      }
      setShowHistoryModel(true);
    }
  }

  async function queryV6ProductSkuByName(name: string) {
    const nonStandardSkus = [...oemSkusRef.current];
    let products = nonStandardSkus.filter(s => s.type === '产品' && s.category ==='XSOS');
    if (name) {
      products = products.filter(s => s.name.indexOf(name) !== -1);
    }
    return products;
  }
  function getProductV5Key() {
    let key = `${license.version}-${'noTest'}`
    return key;
  }
  return (<div>
    {props.title}
    <div >
      <FormItemLine>
        <FormItemLabel label={t('软件产品')} required={license.contractType === 'new'} />
        <SearchSelect
          searchCallback={queryV6ProductSkuByName}
          displayRender={skuDisplayRender}
          itemRender={skuDisplayItemRender}
          updateValue={handleSkuChange}
          value={license.productSku}
          placeholder={t('请选择产品')}
          className={styles['sku-select']}
          key={getProductV5Key()}
          error={error.productInfo?.error}
          helperText={error.productInfo?.text}
        />
        {!!license?.clusterMd5 && <span onClick={handleHistoryModelClick} className={classnames('link-without-decoration', styles['history-link-button'])} >
          {t('集群现有许可信息')}
        </span>}
      </FormItemLine>
      <ProductPeriod {...props} />
      <Dialog open={showHistoryModel} scroll="paper">
        <div className={styles['modal-container']}>
          <DialogTitle id="scroll-dialog-title" hasClose onCloseClick={handleCancelModelClick}>{t('集群现有许可信息')}</DialogTitle>
          <DialogContent dividers={true}>
            <Table columns={columns} data={historyLicenses} rowKey='code' loading={false} />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  </div>)
}

export default ProductV6;