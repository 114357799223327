import { BID_SCENES } from "constant";
import { ContractSubjectMatterTableModel } from "interfaces/models";
import lodash from 'lodash';
import { Contract } from "model";
import { BidSku } from "model/src/bid";

export function getContractTypes(contract: Contract) {
  const bidApply = contract.bidApply;
  if (bidApply) {
    const scenes = bidApply.bids.map(c => BID_SCENES[c.scene]);
    return lodash.uniq(scenes).join(';');
  }
}
export function getSubjectMatterDisplay(contract: Contract) {
  const matters: ContractSubjectMatterTableModel[] = lodash.cloneDeep(contract.subjectMatters || []);
  const bidSkus: BidSku[] = [];
  if (contract.bidApply) {
    contract.bidApply.bids?.forEach(bid => {
      bidSkus.push(...(bid.skus || []));
    });
  }
  const productDetails = (contract.productDetails || []).map(p => {
    if (p.bidSkuId) {
      p.bidSku = bidSkus.find(s => s.id === p.bidSkuId);
    }
    return p;
  });
  matters.forEach(m => {
    m.open = true;
    m.subs = productDetails.filter(d => d.matterId === m.id);
  });
  return matters;
}
