import { Buffer } from 'buffer';
import { checkPermission } from 'common/src/permission';
import { Loading, TopBar } from 'components';
import { CHECK_SESSION_INTERVAL, EXPIRE_NAME, TOKEN_NAME } from 'constant';
import { GlobalContext } from 'context';
import { AccountForm, LicenseForm } from 'forms';
import lodash from 'lodash';
import { AliOssConfig, Token, User } from 'model';
import { useContext, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { getAliOssConfig, getUser, getUserPermission, refreshToken } from 'request';
import { getCookie, getToken } from 'utils';
import Cluster from '../pages/cluster';
import Dashboard from '../pages/dashboard';
import Login from '../pages/login';
import Permissions from '../pages/permissions';
import Order from '../pages/order';
import SdsConfig from '../pages/sds-config';
import styles from './index.module.scss';

interface CheckTokenProps {
  token: Token;
  updateLastActionTime: () => void;
}
let lastActionTime = Date.now();
const BasicLayout = () => {
  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(false);
  const [aliOssConfig, setAliOssConfig] = useState<AliOssConfig>();
  const tokenRef = useRef<Token>();
  tokenRef.current = token;
  const userRef = useRef<User>();
  userRef.current = user;
  lastActionTime = Date.now();
  const location = useLocation();

  useEffect(() => {
    const checkSessionTimer = setInterval(checkSession, CHECK_SESSION_INTERVAL);
    checkSession();
    fetchToken();
    fetchUser();
    return () => {
      clearInterval(checkSessionTimer);
    }
  }, []);
  useEffect(() => {
    fetchUser();
    fetchAliOssConfig();
  }, [token.uuid]);

  const updateLastActionTime = lodash.throttle(() => {
    lastActionTime = Date.now();
  });
  async function checkSession() {
    const expires = getCookie(EXPIRE_NAME);
    const expireTimestamp = new Date(expires).getTime();
    const now = Date.now();
    const path = window.location.pathname;
    if (path !== '/login') {
      if (expireTimestamp - now < CHECK_SESSION_INTERVAL * 10) {
        const token = getCookie(TOKEN_NAME);
        const response = await refreshToken(token, lastActionTime);
        if (response.status === 200) {
          const result = response.data;
          document.cookie = `${TOKEN_NAME}=${result.uuid}; path=/; expires=${result.expires}`;
          document.cookie = `${EXPIRE_NAME}=${result.expires}; path=/; expires=${result.expires}`;
        }
      }
    }
  };
  async function fetchUser(id?: number) {
    const latestToken = tokenRef.current;
    const { userId } = latestToken || token;
    if (!isLoginPage()) {
      const response = await getUser(id || userId);
      if (response.status === 200) {
        const result = response.data;
        setUser(result);
      }
    }
    setLoading(false);
  }
  async function fetchToken() {
    if (!isLoginPage()) {
      const response = await getUserPermission();
      setToken(response.data);
    }
  }
  async function fetchAliOssConfig() {
    if (location.pathname !== '/login' && !aliOssConfig) {
      const response = await getAliOssConfig();
      if (response.status === 200) {
        const stringConfig = Buffer.from(response.data, 'base64').toString();
        setAliOssConfig(JSON.parse(stringConfig));
      }
    }
  }
  function updateToken(token: Token) {
    setToken(token);
  }
  function isLoginPage() {
    const path = window.location.pathname;
    if (path !== '/login') {
      return false;
    }
    return true;
  }
  async function updateUser(id: number) {
    await fetchUser(id);
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <GlobalContext.Provider value={{ token, updateToken: setToken, user, aliOssConfig }}>
      <Routes>
        <Route path='/login' element={<Login updateToken={updateToken} updateUser={updateUser} updateAliOssConfig={fetchAliOssConfig} />} />
        <Route path='*' element={<RequireAuth>
          <AuthedPage token={token} updateLastActionTime={updateLastActionTime} />
        </RequireAuth>} />
      </Routes>
    </GlobalContext.Provider >
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useContext(GlobalContext);
  const location = useLocation();
  if (!auth.token?.uuid) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

const AuthedPage = (props: CheckTokenProps) => {
  return <div
    className={styles.layout}
    id="layout"
    onClick={props.updateLastActionTime}
    onKeyDown={props.updateLastActionTime}
    onScroll={props.updateLastActionTime}
  >
    <TopBar token={props.token} />
    <div className={styles['body-layout']}>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/cluster" element={<Cluster />} />
        <Route path='/account-form' element={<AccountForm />} />
        {checkPermission('ViewContract', props.token) && <Route path='/order' element={<Order />} />}
        {checkPermission('ApplyOEMLicense', props.token) && <Route path='/license-form' element={<LicenseForm />} />}
        {checkPermission(['ViewConfigTool', 'ManageConfigTool'], props.token) && <Route path="/sds-config" element={<SdsConfig />} />}
        {checkPermission('ManagePermission', props.token) && <Route path='/permission' element={<Permissions />} />}
      </Routes>
    </div>
  </div >
}

export default BasicLayout;
