import { FormFooter as FormFooterProps } from 'interfaces/props';
import styles from './index.module.scss';
import t from 'locale';
import { TextButton, Button } from 'x-material-ui';

const FormFooter = (props: FormFooterProps) => {
  return (<div className={styles.container}>
    <span className={styles['button-container']}>
      <TextButton
        size="medium"
        className={styles.cancel}
        onClick={props.handleCancelClick}
      >
        {t('取消')}
      </TextButton>
      <Button
        size="medium"
        color="primary"
        variant="contained"
        disabled={props.disabled}
        loading={props.loading}
        onClick={props.handleOkClick}
      >
        {props.okText || t('添加')}
      </Button>
    </span>
  </div>);
}

export default FormFooter;