import { AxiosResponse } from 'axios';
import { TABLE_DEFAULT_PAGE_SIZE } from 'constant';
import { QuerySkuOption, Sku } from 'model';
import { instance } from './instance';

export async function getSkus(limit: number = TABLE_DEFAULT_PAGE_SIZE, offset: number = 0, search: string = '', filter: Array<any> = [], associate: string = '', opportunityId: string | number = '', showAllStatusSku = false, expandSkuHostConfig = false, oemId: string | number = ''): Promise<AxiosResponse<{ count: number; rows: Sku[] }>> {
  return instance.get(`/sku/list?offset=${offset}&limit=${limit}&search=${search}&filter=${JSON.stringify(filter)}&associate=${associate}&opportunityId=${opportunityId}&showAllStatusSku=${showAllStatusSku}&expandSkuHostConfig=${expandSkuHostConfig}&oemId=${oemId}`);
}

export async function querySkusFromLicense(option: QuerySkuOption): Promise<AxiosResponse<Sku[]>> {
  return instance.get(`/sku/query-sku-from-license`, { params: option });
}