import { PollingDetailProps } from 'interfaces/props';
import lodash from 'lodash';
import { LicenseSummary, Polling, PollingReport, SdsLicense } from 'model';
import { useEffect, useState } from 'react';
import t from 'locale';
import InfoField from '../InfoField';
import { DATE_FORMAT, NA, UNAVAILABLE_SYMBOL } from 'common';
import styles from './polling-detail.module.scss';
import { convertBytes, convertBytes1000 } from 'common/src/capacity';
import { getPercent } from 'common/src/number';
import PercentBar from '../PercentBar';
import { Grid, Status } from 'x-material-ui';
import { LICENSE_STATUS } from 'constant';
import dayjs from 'dayjs';
import Collapse from '../Collapse';
import { getConformStatus, getNormalDangerStatus, getNormalStatus, getServiceWarrantyStatus, getUnknownNormalDangerNaStatus } from 'common/src/polling';
import GroupByUsage from '../GroupByUsage';
import { getPollingDetail } from 'request';

interface GroupByUsageData {
  title: string;
  data: GroupByUsageDataDetail[];
  field?: string;
  model?: string;
}
interface GroupByUsageDataDetail {
  title: string;
  status: string;
  value: number;
  isError?: boolean;
  gteSize?: number;
  ltSize?: number;
}
const protocols: { [key: string]: string } = {
  RBD: 'RBD',
  iSCSI: 'iSCSI',
  FC: 'FC',
  SCSI: 'SCSI',
  Smb: 'SMB/CIFS',
  Ftp: 'FTP',
  FileNfs: 'NFS(文件)',
  S3: 'S3',
  ObjectNfs: 'NFS(对象)',
};
const PollingDetail = (props: PollingDetailProps) => {
  const [polling, setPolling] = useState<Polling>();
  useEffect(() => {
    fetchPolling();
  }, [props.pollingId]);
  async function fetchPolling() {
    if (props.pollingId) {
      const response = await getPollingDetail(props.pollingId);
      setPolling(response.data);
    }
  }
  if (!polling) {
    return <div>{t('暂无数据')}</div>;
  }

  function getNormalDangerNonStatus(identity: number) {
    let status = 'active';
    let text = t('正常');
    if (identity === 2) {
      status = 'error';
      text = t('危险');
    } else if (identity === 3) {
      status = 'not-support';
      text = t('无索引池');
    }
    return {
      status,
      text,
    };
  }


  function renderGroupByUsage(title: string, resource: GroupByUsageData) {
    return (
      <InfoField
        key={title}
        label={title}
        value={<GroupByUsage healthyTitle={resource.title} data={resource.data} toolTip={resource?.field === 'ssd_life_left' ? t('该数字为 osd 和缓存盘数据总和') : ''} />}
      />
    );
  }
  function getHostResources(report: PollingReport) {
    return [
      {
        title: t('系统盘使用'),
        resource: {
          title: t('当前所有服务器的系统盘使用均处于正常范围 (<50%)'),
          field: 'actual_size_usage_host_root_disk',
          model: 'host',
          data: [
            {
              title: '<50%',
              value: report.host_system_disk_usage_lt50_count,
              status: 'active',
              ltSize: 50,
            },
            {
              title: '50% ~ 75%',
              value: report.host_system_disk_usage_gte50_lt75_count,
              status: 'warning',
              isError: true,
              gteSize: 50,
              ltSize: 70
            },
            {
              title: '>=75%',
              value: report.host_system_disk_usage_gte75_count,
              status: 'error',
              isError: true,
              gteSize: 75
            },
          ],
        },
      },
      {
        title: t('系统盘 IO 负载'),
        resource: {
          title: t('当前所有服务器的系统盘 IO 负载均处于正常范围 (＜50%)'),
          field: 'io_util_host_root_disk',
          model: 'host',
          data: [
            {
              title: '<50%',
              value: report.host_system_disk_io_load_lt50_count,
              status: 'active',
              ltSize: 50,
            },
            {
              title: '50% ~ 75%',
              value: report.host_system_disk_io_load_gte50_lt75_count,
              status: 'warning',
              isError: true,
              gteSize: 50,
              ltSize: 70
            },
            {
              title: '>=75%',
              value: report.host_system_disk_io_load_gte75_count,
              status: 'error',
              isError: true,
              gteSize: 75
            },
          ],
        },
      },
      {
        title: t('CPU 负载'),
        resource: {
          title: t('当前所有服务器的 CPU 负载均处于正常范围 (<60%)'),
          field: 'cpu_util',
          model: 'host',
          data: [
            {
              title: '<60%',
              value: report.host_cpu_load_lt60_count,
              status: 'active',
              ltSize: 60,
            },
            {
              title: '60% ~ 80%',
              value: report.host_cpu_load_gte60_lt80_count,
              status: 'warning',
              isError: true,
              gteSize: 60,
              ltSize: 80
            },
            {
              title: '80% ~ 90%',
              value: report.host_cpu_load_gte80_lt90_count,
              status: 'error',
              isError: true,
              gteSize: 80,
              ltSize: 90
            },
            {
              title: '>=90%',
              value: report.host_cpu_load_gte90_count,
              status: 'urgent',
              isError: true,
              gteSize: 90,
            },
          ],
        },
      },
      {
        title: t('内存负载'),
        resource: {
          title: t('当前所有服务器的内存负载均处于正常范围 (＜80%)'),
          field: 'mem_usage_percent',
          model: 'host',
          data: [
            {
              title: '<80%',
              value: report.host_memory_load_lt80_count,
              status: 'active',
              ltSize: 80,
            },
            {
              title: '80% ~ 90%',
              value: report.host_memory_load_gte80_lt90_count,
              status: 'warning',
              isError: true,
              gteSize: 80,
              ltSize: 90
            },
            {
              title: '>=90%',
              value: report.host_memory_load_gte90_count,
              status: 'error',
              isError: true,
              gteSize: 90
            },
          ],
        },
      },
    ];
  }
  function getOsdResources(report: PollingReport) {
    return [
      {
        title: t('缓存盘 IO 负载'),
        resource: {
          title: t('当前所有缓存盘 IO 负载均处于正常范围 (<50%)'),
          field: 'io_util_cache',
          model: 'cache',
          data: [
            {
              title: '<50%',
              value: report.disk_cache_io_load_lt50_count,
              status: 'active',
              ltSize: 50
            },
            {
              title: '50% ~ 70%',
              value: report.disk_cache_io_load_gte50_lt70_count,
              status: 'warning',
              isError: true,
              gteSize: 50,
              ltSize: 70
            },
            {
              title: '70% ~ 80%',
              value: report.disk_cache_io_load_gte70_lt_80count,
              status: 'error',
              isError: true,
              gteSize: 70,
              ltSize: 80
            },
            {
              title: '>=80%',
              value: report.disk_cache_io_load_gte80_count,
              status: 'urgent',
              isError: true,
              gteSize: 80
            },
          ],
        },
      },
      {
        title: t('数据盘 IO 负载'),
        resource: {
          title: t('当前所有数据盘 IO 负载均处于正常范围 (＜50%)'),
          field: 'io_util_data',
          model: 'osd',
          data: [
            {
              title: '<50%',
              value: report.osd_data_io_load_lt50_count,
              status: 'active',
              ltSize: 50
            },
            {
              title: '50% ~ 70%',
              value: report.osd_data_io_load_gte50_lt70_count,
              status: 'warning',
              isError: true,
              gteSize: 50,
              ltSize: 70
            },
            {
              title: '70% ~ 80%',
              value: report.osd_data_io_load_gte70_lt_80count,
              status: 'error',
              isError: true,
              gteSize: 70,
              ltSize: 80
            },
            {
              title: '>=80%',
              value: report.osd_data_io_load_gte80_count,
              status: 'error',
              isError: true,
              gteSize: 80
            },
          ],
        },
      },
      {
        title: t('SSD 写入寿命'),
        resource: {
          title: t('当前所有 SSD 写入寿命均处于正常范围 (>=60%)'),
          field: 'ssd_life_left',
          model: 'cache',
          data: [
            {
              title: '>=60%',
              value: report.osd_ssd_write_life_gte60_count,
              status: 'active',
              gteSize: 60
            },
            {
              title: '60% ~ 30%',
              value: report.osd_ssd_write_life_gte30_lt60_count,
              status: 'warning',
              isError: true,
              gteSzie: 30,
              ltSize: 60
            },
            {
              title: '<30%',
              value: report.osd_ssd_write_life_lt30_count,
              status: 'error',
              isError: true,
              ltSize: 30
            },
          ],
        },
      },
      {
        title: t('硬盘容量使用'),
        resource: {
          title: t('当前所有硬盘容量使用均处于正常范围 (＜50%)'),
          field: 'actual_size_usage_osd',
          model: 'osd',
          data: [
            {
              title: '<50%',
              value: report.osd_capacity_usage_lt50_count,
              status: 'active',
              key: 'osd_capacity_usage_lt50_count',
              ltSize: 50
            },
            {
              title: '50% ~ 70%',
              value: report.osd_capacity_usage_gte50_lt70_count,
              status: 'warning',
              isError: true,
              key: 'osd_capacity_usage_gte50_lt70_count',
              gteSize: 50,
              ltSize: 70
            },
            {
              title: '70% ~ 85%',
              value: report.osd_capacity_usage_gte70_lt85_count,
              status: 'warning',
              isError: true,
              key: 'osd_capacity_usage_gte70_lt85_count',
              gteSize: 70,
              ltSize: 85
            },
            {
              title: '>=85%',
              value: report.osd_capacity_usage_gte85_count,
              status: 'error',
              isError: true,
              key: 'osd_capacity_usage_gte85_count',
              gteSize: 85,
            },
          ],
        },
      },
    ];
  }

  function renderLicense(license: SdsLicense, summary: LicenseSummary) {
    let shortId = NA;
    if (license.uuid) {
      shortId = license.uuid.slice(0, 8).toUpperCase();
    }
    const fields = [
      {
        label: t('序列号'),
        value: shortId,
      },
      {
        label: t('产品名称'),
        value: license?.version === 'v3' ? lodash.get(summary, 'product_info.series') : lodash.get(license, 'product_info.series', license.product),
      },
      {
        label: t('客户名称'),
        value: license.customer,
      },
    ];
    if (license.version) {
      let limit;
      let used_quota;
      if (license.version === 'v3') {
        limit = summary?.limits || {};
        used_quota = summary?.used_quota || {};
      } else {
        limit = license.limits || {};
        used_quota = license;
      }
      if (limit.max_capacity) {
        let max;
        let total;
        let used;
        let [capacity, unit] = limit.max_capacity.split(' ');
        unit = unit.replace('i', '');
        if (summary?.capacity_base === 1000) {
          max = convertBytes1000(parseFloat(capacity), {
            targetUnit: 'B',
            baseUnit: unit,
          }) as number;
          total = convertBytes1000(max, { baseUnit: 'B', needUnit: true }) as any;
          used = convertBytes1000(used_quota.used_capacity || 0, { baseUnit: 'B', needUnit: true }) as any;
        } else {
          max = convertBytes(parseFloat(capacity), {
            targetUnit: 'B',
            baseUnit: unit,
          }) as number;
          total = convertBytes(max, { baseUnit: 'B', needUnit: true }) as any;
          used = convertBytes(used_quota.used_capacity || 0, { baseUnit: 'B', needUnit: true }) as any;
        }
        fields.push({
          label: t('购买容量'),
          value: (
            <PercentBar
              percent={getPercent(max, used_quota.used_capacity || 0) + ''}
              showDetail
              showPercent
              current={`${used.value} ${used.unit}`}
              total={`${total.value} ${total.unit}`}
            />
          ),
        });
      }
      if (limit.max_host_num) {
        fields.push({
          label: t('服务器数(台)'),
          value: (
            <PercentBar
              percent={getPercent(limit.max_host_num, used_quota.used_host_num || 0) + ''}
              showDetail
              showPercent
              current={used_quota.used_host_num}
              total={limit.max_host_num}
            />
          ),
        });
      }
      if (limit.max_capacity_per_host) {
        fields.push({
          label: t('单节点容量'),
          value: limit.max_capacity_per_host,
        });
      }
    } else {
      if (license.capacity) {
        const used = convertBytes(license.used_capacity, { baseUnit: 'B', needUnit: true }) as any;
        const total = convertBytes(license.capacity, { baseUnit: 'B', needUnit: true }) as any;
        fields.push({
          label: t('购买容量'),
          value: (
            <PercentBar
              percent={getPercent(license.capacity, license.used_capacity) + ''}
              showPercent
              showDetail
              current={`${used.value} ${used.unit}`}
              total={`${total.value} ${total.unit}`}
            />
          ),
        });
      }
      if (license.host_num < 99999) {
        fields.push({
          label: t('服务器数(台)'),
          value: (
            <PercentBar
              percent={getPercent(license.host_num, license.used_host_num) + ''}
              showDetail
              showPercent
              current={license.used_host_num}
              total={license.host_num}
            />
          ),
        });
      }
    }
    fields.push(
      {
        label: t('授权状态'),
        value: <Status text={LICENSE_STATUS[license.status]} status={license.status} />,
      },
      {
        label: t('激活日期'),
        value: dayjs(license.signed_time).format(DATE_FORMAT),
      },
      {
        label: t('截止日期'),
        value: dayjs(license.expired_time).format(DATE_FORMAT),
      }
    );
    const layouts = lodash.chunk(fields, 3);
    return (
      <div>
        {layouts.map((line, i) => {
          return (
            <Grid
              className={i === 0 ? styles['license-card-first-line'] : ''}
              key={`row-${i}`}
              container
              item
              xs={12}
            >
              {line.map((col, i) => {
                return (
                  <Grid item xs={4} key={`col-${i}`}>
                    <InfoField label={col.label} value={col.value} />
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </div>
    );
  }
  function titleDisplay(title: string, count: number) {
    return (
      <div >
        {title}
        ({count})
      </div>
    )
  }
  function renderReport(report?: PollingReport) {
    if (!report) {
      return <div>{t('暂无数据')}</div>;
    }
    const hostResources = getHostResources(report);
    const osdResources = getOsdResources(report);
    const cluster = polling?.cluster;
    return (
      <div className={styles['report-container']}>
        <div className={styles['title-text']}>{t('报告详情')}</div>
        <Collapse title={titleDisplay(t('服务器'), report.host_count)} initialOpen>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <InfoField
                label={t('状态')}
                value={<Status {...getNormalStatus(report.host_status, t)} />}
              />
            </Grid>
            <Grid item xs={4}>
              <InfoField
                label={t('网络状态')}
                value={<Status {...getNormalStatus(report.host_network_status, t)} />}
              />
            </Grid>
            <Grid item xs={4}>
              <InfoField
                label={t('操作系统版本')}
                value={<Status {...getConformStatus(report.host_os_version, t)} />}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <InfoField
                label={t('系统 OS 的 RAID 模式')}
                value={<Status {...getUnknownNormalDangerNaStatus(report.host_os_raid_mode, t)} />}
              />
            </Grid>
          </Grid>
          {hostResources.map((r) => renderGroupByUsage(r.title, r.resource))}
        </Collapse>
        <Collapse title={titleDisplay(t('存储池'), report.pool_count)} initialOpen>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <InfoField
                label={t('状态')}
                value={<Status {...getNormalStatus(report.pool_status, t)} />}
              />
            </Grid>
            <Grid item xs={4}>
              <InfoField
                label={t('容量使用')}
                value={<Status {...getNormalDangerStatus(report.pool_capacity_usage, t)} />}
              />
            </Grid>
            <Grid item xs={4}>
              <InfoField
                label={t('索引池 omap 分区使用率')}
                value={<Status {...getNormalDangerNonStatus(report.pool_index_pool_omap_usage)} />}
              />
            </Grid>
          </Grid>
        </Collapse>
        <Collapse title={titleDisplay(t('硬盘'), report.osd_count)} initialOpen>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <InfoField
                label={t('状态')}
                value={<Status {...getNormalStatus(report.osd_status, t)} />}
              />
            </Grid>
          </Grid>
          {osdResources.map((r) => renderGroupByUsage(r.title, r.resource))}
        </Collapse>
        <Collapse title={t('集群服务')} initialOpen>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <InfoField
                label={t('告警信息')}
                value={<Status {...getNormalStatus(report.service_alert, t)} />}
              />
            </Grid>
            <Grid item xs={4}>
              <InfoField
                label={t('ceph 运行状态')}
                value={<Status {...getNormalStatus(report.service_ceph_status, t)} />}
              />
            </Grid>
            <Grid item xs={4}>
              <InfoField
                label={t('es 运行状态')}
                value={<Status {...getConformStatus(report.service_es_status, t)} />}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <InfoField
                label={t('etcd 运行状态')}
                value={<Status {...getNormalStatus(report.service_etcd_status, t)} />}
              />
            </Grid>
            <Grid item xs={4}>
              <InfoField
                label={t('快照及备份任务')}
                value={<Status {...getNormalDangerStatus(report.service_snapshot_status, t)} />}
              />
            </Grid>
          </Grid>
        </Collapse>
        <Collapse title={t('信息收集')} initialOpen>
          <InfoField
            label={t('存储协议')}
            value={report.storage_protocol
              .split(',')
              .map((p) => protocols[p])
              .join(',')}
          />
        </Collapse>
        <Collapse title={t('产品许可')} initialOpen>
          {renderLicense(report.license, report.summary)}
        </Collapse>
        <Collapse title={t('服务许可')} initialOpen>
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <InfoField
                label={t('服务级别')}
                value={cluster?.warrantyServiceLevel}
              />
            </Grid>
            <Grid item xs={4}>
              <InfoField
                label={t('服务期限')}
                value={cluster?.warrantyServicePeriod}
              />
            </Grid>
            <Grid item xs={4}>
              <InfoField
                label={t('激活状态')}
                value={cluster?.warrantyEnd ? <Status {...getServiceWarrantyStatus(cluster)} /> : UNAVAILABLE_SYMBOL}
              />
            </Grid>
          </Grid>
        </Collapse>
      </div>
    );
  }
  function renderDetail(data: Polling) {
    const report = data.report;
    return (
      <div className={styles['detail-container']}>
        {renderReport(report)}
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles['brief-container']}>
        <div className={styles['title-container']}>
          <span className={styles['title-text']}>
            {t('{{name}}巡检报告', { name: polling.cluster?.name })}
          </span>
        </div>
      </div>
      {renderDetail(polling)}
    </div>
  );
}

export default PollingDetail;