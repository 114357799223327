import { DetailPerformForm, DictOption } from './interface';
import { max, min } from 'lodash';
import t from 'locale';

export function detailPerformance(config: DictOption, formConfig: DetailPerformForm, dataDict: DictOption) {
  return detailPerformCalculate(config, formConfig, dataDict);
}

function detailPerformCalculate(config: DictOption, formConfig: DetailPerformForm, dataDict: DictOption) {
  //return a[4][15]
  let retArray = new Array();
  const performDetail = dataDict.performDetail;
  const dictInfo = getPerformDatilInfo(config, dataDict);
  const dataPool = config.pool === 'data';
  if (dataPool) {
    // 无cache数据池 用缓存层来计算
    dictInfo.cacheInfo = dictInfo.dataInfo;
    config.cacheOsdNum = config.dataOsdNum;
  }
  const { cpuRatio } = performDatilRatio(formConfig, performDetail);
  const cpuVal = dictInfo.cpuInfo.value;
  const cacheVal = dictInfo.cacheInfo.value;
  const cacheDiskRead = cacheVal.randreadO ? cacheVal.randreadO : cacheVal.randread;
  const dataVal = dictInfo.dataInfo.value;

  const cpuIops = config.cpuNum * cpuVal.cores * cpuVal.baseFrequency * config.nodeNum * cpuRatio;
  const cpuIopsHit = cpuIops * performDetail.cpuIopsGhz.hit;
  const cpuIopsNotHit = cpuIops * performDetail.cpuIopsGhz.notHit;
  const networkBand = calculateNetworkBand(config, dataDict);
  const cacheWriteIops = min([cacheDiskRead, performDetail.diskMaxIops.write]) * config.cacheOsdNum * config.nodeNum;
  const cacheReadIops = min([cacheDiskRead, performDetail.diskMaxIops.read]) * config.cacheOsdNum * config.nodeNum;
  const cacheWriteBand = cacheVal.seqwrite * config.cacheOsdNum * config.nodeNum;
  const cacheReadBand = cacheVal.seqread * config.cacheOsdNum * config.nodeNum;
  const dataReadBand = dataVal.seqread * config.dataOsdNum * config.nodeNum;
  //RBD命中
  const performRbdHit = performDetail.performRbdHit;
  const randWriteIops = ceilNumber((min([cacheWriteIops, cpuIopsHit]) as number) / config.cacheReplicated, 1000);
  const randReadIops = min([randWriteIops * performRbdHit.randwriteTurnRandread, cacheReadIops]) as number;
  const writeBand = min([networkBand / (config.cacheReplicated - 1), cacheWriteBand / config.cacheReplicated]) as number;
  const readBand = min([networkBand, cacheReadBand]) as number;
  retArray[0] = [
    //4k/8k/16k
    randWriteIops,
    randReadIops,
    0,
    randWriteIops * performRbdHit.writeSmallIO,
    randReadIops * performRbdHit.readSmallIO,
    0,
    //64k
    ceilNumber(writeBand * performRbdHit.write64k, 1000),
    ceilNumber(readBand * performRbdHit.read64k, 1000),
    0,
    //128k
    ceilNumber(writeBand * performRbdHit.write128k, 100),
    ceilNumber(readBand * performRbdHit.read128k, 100),
    0,
    //1M
    ceilNumber(writeBand * performRbdHit.write1m, 100),
    ceilNumber(readBand * performRbdHit.read1m, 100),
    0,
  ];
  //RBD不全命中
  const performRbdNotHit = performDetail.performRbdNotHit;
  const randWriteIopsRbdNotHit = randWriteIops * performRbdNotHit.randwrite;
  const randReadIopsRbdNotHit = Math.round(cpuIopsNotHit * performRbdNotHit.randread);
  const dataReadBandRbdNotHit = dataReadBand / 3;
  retArray[1] = [
    //4k/8k/16k
    randWriteIopsRbdNotHit,
    randReadIopsRbdNotHit,
    0,
    randWriteIopsRbdNotHit * performRbdNotHit.writeSmallIO,
    randReadIopsRbdNotHit * performRbdNotHit.readSmallIO,
    0,
    //64k
    retArray[0][6] * performRbdNotHit.write64k,
    min([retArray[0][7] * performRbdNotHit.read64k, dataReadBandRbdNotHit]),
    0,
    //128k
    retArray[0][9] * performRbdNotHit.write128k,
    min([retArray[0][10] * performRbdNotHit.read128k, dataReadBandRbdNotHit]),
    0,
    //1M
    retArray[0][12] * performRbdNotHit.write1m,
    min([retArray[0][13] * performRbdNotHit.read1m, dataReadBandRbdNotHit]),
    0,
  ];
  //iSCSI命中
  const performISCSIHit = performDetail.performISCSIHit;
  const randwriteIH = retArray[0][0] * performISCSIHit.randwrite;
  const randreadIH = retArray[0][1] * performISCSIHit.randread;
  const iscsiWriteBand = min([
    networkBand / (max([config.cacheReplicated - 1, 2]) as number),
    cacheWriteBand / config.cacheReplicated
  ]) as number;
  const iscsiReadBand = min([networkBand / 2, cacheReadBand]) as number;
  retArray[2] = [
    //4k/8k/16k
    randwriteIH,
    randreadIH,
    0,
    randwriteIH * performISCSIHit.writeSmallIO,
    randreadIH * performISCSIHit.readSmallIO,
    0,
    //64k
    ceilNumber(iscsiWriteBand * performISCSIHit.write64k, 100),
    ceilNumber(iscsiReadBand * performISCSIHit.read64k, 100),
    0,
    //128k
    ceilNumber(iscsiWriteBand * performISCSIHit.write128k, 100),
    ceilNumber(iscsiReadBand * performISCSIHit.read128k, 100),
    0,
    //1M
    ceilNumber(iscsiWriteBand * performISCSIHit.write1m, 100),
    ceilNumber(iscsiReadBand * performISCSIHit.read1m, 100),
    0,
  ];
  //iSCSI不全命中
  const performISCSINotHit = performDetail.performISCSINotHit;
  retArray[3] = [
    //4k/8k/16k
    retArray[1][0] * performISCSINotHit.randwrite,
    retArray[1][1] * performISCSINotHit.randread,
    0,
    retArray[1][3] * performISCSINotHit.writeSmallIO,
    retArray[1][4] * performISCSINotHit.readSmallIO,
    0,
    //64k
    retArray[1][6] * performISCSINotHit.write64k,
    retArray[1][7] * performISCSINotHit.read64k,
    0,
    //128k
    retArray[1][9] * performISCSINotHit.write128k,
    retArray[1][10] * performISCSINotHit.read128k,
    0,
    //1M
    retArray[1][12] * performISCSINotHit.write1m,
    retArray[1][13] * performISCSINotHit.read1m,
    0,
  ];
  for (let i = 0; i < 4; i++) {
    for (let j = 2; j < 15; j += 3) {
      const w = retArray[i][j - 2];
      const r = retArray[i][j - 1];
      //rw=write*read/ (write*rpct + read*(1-rpct))
      retArray[i][j] = w * r / (w * formConfig.rdpct + r * (1 - formConfig.rdpct));
      let asign = 1000;
      if ((i <= 1 && j > 8) || (i == 2)) {
        asign = 100;
      }
      retArray[i][j] = ceilNumber(retArray[i][j], asign);
    }
  }
  retArray[2][2] = retArray[0][2] * performISCSIHit.randrw;
  retArray[3][2] = retArray[1][2] * performISCSINotHit.randrw;
  retArray[3][5] = retArray[1][5] * performISCSINotHit.rwSmallIO;

  const io16kFlag = formConfig.io === '16K';
  const netTurnIops = networkBand * 1024 / 16;
  if (formConfig.showType === 'iops') {
    for (let i = 0; i < retArray.length; i++) {
      if (io16kFlag) {
        for (let j = 0; j < 6; j++) {
          retArray[i][j] = min([netTurnIops, retArray[i][j]]) as number;
        }
      }
      for (let j = 6; j < retArray[i].length; j++) {
        //64k 128k 1m
        if (j < 9) {
          retArray[i][j] = ceilNumber(retArray[i][j] * 1024 / 64, 1000);
        } else if (j < 12) {
          retArray[i][j] = ceilNumber(retArray[i][j] * 1024 / 128, 100);
        } else {
          retArray[i][j] = ceilNumber(retArray[i][j] * 1024 / 1024, 100);
        }
      }
    }
  } else {
    for (let i = 0; i < retArray.length; i++) {
      if (io16kFlag) {
        for (let j = 0; j < 6; j++) {
          retArray[i][j] = ceilNumber(min([retArray[i][j] * 16 / 1024, networkBand]) as number, 10);
        }
      } else {
        for (let j = 0; j < 6; j++) {
          retArray[i][j] = '';
        }
      }
    }
  }

  for (let i = 0; i < retArray.length; i++) {
    for (let j = 0; j < retArray[i].length; j++) {
      if (retArray[i][j]) {
        retArray[i][j] = retArray[i][j].toFixed(0);
      }
    }
  }
  return retArray;
}

export function performDatilRatio(formConfig: DictOption, performDetail: DictOption) {
  const ioRatio = performDetail.ioRatio[formConfig.io];
  const cpuRatio = ioRatio * performDetail.latencyRatio[formConfig.latency];
  return { ioRatio, cpuRatio };
}

export function getPerformDatilInfo(config: DictOption, dataDict: DictOption) {
  const cpuInfo = findItemById(config.cpu, dataDict.cpus);
  const netInfo = findItemById(config.netCard, dataDict.netCards);
  const netPerform = networkPerformance(config, dataDict) / config.nodeNum;
  const cacheInfo = findItemById(config.cacheOsd, dataDict.ssds);
  let dataInfo = findItemById(config.dataOsd, dataDict.hdds);
  if (!dataInfo.value) {
    dataInfo = findItemById(config.dataOsd, dataDict.ssds);
  }
  return { cpuInfo, netInfo, netPerform, cacheInfo, dataInfo };
}

export function basePerformance(config: DictOption, dataDict: DictOption) {
  const performRet = {
    cacheWriteIops: 0,
    cacheReadIops: 0,
    cacheWriteBand: 0,
    cacheReadBand: 0,
    dataWriteIops: 0,
    dataReadIops: 0,
    dataWriteBand: 0,
    dataReadBand: 0,
    nfs: { writeIops: 0, readIops: 0, writeBand: 0, readBand: 0, dataWriteBand: 0, dataReadBand: 0, writeOps: 0, readOps: 0 },
    smb: { writeIops: 0, readIops: 0, writeBand: 0, readBand: 0, dataWriteBand: 0, dataReadBand: 0, writeOps: 0, readOps: 0 },
  };
  const notDataPool = config.pool !== 'data';
  const xspeedPool = config.pool === 'XSpeed';
  const allSSD = config.version.endsWith('all_ssd');
  const nodeNum = config.nodeNum as number;
  const performanceDict = dataDict.performance;
  const maxDiskPerformDict = performanceDict.maxDiskPerformance;
  const maxHddDict = performanceDict.maxHddPerformance;
  const maxLossDict = performanceDict.maxLossRatio;
  const maxGfsPerformDict = performanceDict.maxGfsPerformance;
  let maxDiskPerform, maxLoss;
  if (allSSD) {
    maxDiskPerform = maxDiskPerformDict.allSSD;
    maxLoss = maxLossDict.allSSD;
  } else {
    maxDiskPerform = maxDiskPerformDict.general;
    maxLoss = maxLossDict.general;
  }
  const maxDiskWrite = maxDiskPerform.write;
  const maxDiskRead = maxDiskPerform.read;
  const lossWriteIops = maxLoss.writeIops;
  const lossReadIops = maxLoss.readIops;
  const lossWriteBand = maxLoss.writeBand;
  const lossReadBand = maxLoss.readBand;
  let dataOsd = findItemById(config.dataOsd, dataDict.hdds);
  if (!dataOsd.value) {
    dataOsd = findItemById(config.dataOsd, dataDict.ssds);
  }
  dataOsd = dataOsd.value;
  const dataOsdNum = config.dataOsdNum;
  const policyType = config.policyType;
  let writeBandRatio: number;
  if (policyType === 'replicated') {
    writeBandRatio = 1 / Number(config.replicated);
  } else {
    const erasure = config.erasure;
    const ec = erasure !== 'other' ? erasure : config.otherErasure;
    const kmb = covertEcKMB(ec);
    writeBandRatio = kmb.k / (kmb.k + kmb.m);
    if (kmb.b && kmb.b === 1) {
      const minNodeNum = (kmb.k + kmb.m) / 2;
      const nodeRatio = min([2, nodeNum / minNodeNum]) as number;
      writeBandRatio = writeBandRatio / 2 * nodeRatio;
    } else if (kmb.k === 2 && kmb.m === 1) {
      writeBandRatio /= 2;
    }
  }

  if (!allSSD) {
    let cacheOsd: DictOption = {};
    let cacheDiskNum = 0, cacheDiskWriteIops = 0;
    if (notDataPool) {
      cacheOsd = findItemById(config.cacheOsd, dataDict.ssds).value;
      cacheDiskNum = config.cacheOsdNum * nodeNum;
      cacheDiskWriteIops = min([cacheOsd.randwrite, maxDiskWrite]);
      const cacheDiskReadIops = min([cacheOsd.randread, maxDiskRead]);
      performRet.cacheReadIops = ceilNumber(cacheDiskReadIops * cacheDiskNum * lossReadIops, 1000);
      performRet.cacheReadBand = ceilNumber(cacheOsd.seqread * cacheDiskNum * lossReadBand, 10);
      if (config.pool === 'XSpeed') {
        const cacheReplicated = Number(config.cacheReplicated);
        performRet.cacheWriteIops = ceilNumber(cacheDiskWriteIops * cacheDiskNum / cacheReplicated * lossWriteIops, 1000);
        performRet.cacheWriteBand = ceilNumber(cacheOsd.seqwrite * cacheDiskNum / cacheReplicated * lossWriteBand, 10);
      } else {
        performRet.cacheWriteIops = ceilNumber(cacheDiskWriteIops * cacheDiskNum * writeBandRatio * lossWriteIops, 1000);
        performRet.cacheWriteBand = ceilNumber(cacheOsd.seqwrite * cacheDiskNum * writeBandRatio * lossWriteBand, 10);
      }
    }
  } else {
    const formConfig: DetailPerformForm = {
      io: '4K',
      latency: '3ms',
      rdpct: 0.1,
      showType: 'iops',
    };
    const allSsdPerformIops = detailPerformCalculate(config, formConfig, dataDict);
    performRet.cacheWriteIops = allSsdPerformIops[0][0];
    performRet.cacheReadIops = allSsdPerformIops[0][1];
    performRet.cacheWriteBand = allSsdPerformIops[0][12];
    performRet.cacheReadBand = allSsdPerformIops[0][13];
    if (!notDataPool){
      performRet.dataWriteIops = performRet.cacheWriteIops;
      performRet.dataReadIops = performRet.cacheReadIops;
      performRet.dataWriteBand = performRet.cacheWriteBand;
      performRet.dataReadBand = performRet.cacheReadBand;
    }
  }

  const dataDiskNum = dataOsdNum * nodeNum;
  if (notDataPool) {
    performRet.dataWriteBand = ceilNumber(dataOsd.seqwrite * dataDiskNum * writeBandRatio * maxHddDict.writeBand, 10);
    performRet.dataReadBand = ceilNumber(dataOsd.seqread * dataDiskNum * maxHddDict.readBand, 10);
    performRet.dataWriteBand = min([performRet.dataWriteBand, performRet.cacheWriteBand]) as number;
    performRet.dataReadBand = min([performRet.dataReadBand, performRet.cacheReadBand]) as number;
  } 
  if(!notDataPool && !allSSD){
    //无cache数据池，非全闪计算
    const diskWriteIops = min([dataOsd.randwrite, maxDiskWrite]);
    const diskReadIops = min([dataOsd.randread, maxDiskRead]);
    performRet.dataWriteIops = ceilNumber(diskWriteIops * dataDiskNum * writeBandRatio * lossWriteIops, 1000);
    performRet.dataReadIops = ceilNumber(diskReadIops * dataDiskNum * lossReadIops, 1000);
    performRet.dataWriteBand = ceilNumber(dataOsd.seqwrite * dataDiskNum * writeBandRatio * lossWriteBand, 10);
    performRet.dataReadBand = ceilNumber(dataOsd.seqread * dataDiskNum * lossReadBand, 10);
  }
  if (!xspeedPool && !allSSD) {
    //非XSpeed数据池，有HDD最大带宽180MB/s
    performRet.dataWriteBand = ceilNumber(maxHddDict.writeDataBand * dataDiskNum * writeBandRatio, 10);
    performRet.dataReadBand = maxHddDict.readDataBand * dataDiskNum;
  }
  if(!notDataPool){
    // 全闪无cache数据池，将data盘的数据赋值给cache，这里全部用cache来计算，方便xgfs计算
    performRet.cacheWriteIops = performRet.dataWriteIops;
    performRet.cacheReadIops = performRet.dataReadIops;
    performRet.cacheWriteBand = performRet.dataWriteBand;
    performRet.cacheReadBand = performRet.dataReadBand;
  }

  if (config.product === 'XGFS') {
    const gfsGatewayNum = config.gfsGatewayNum;
    if (config.version === 'v5_1') {
      const gatewayNfs = performanceDict.maxGfsGatewayPerform.nfs5_1;
      performRet.nfs = {
        writeIops: min([performRet.cacheWriteIops, maxGfsPerformDict.largeWriteIops, gatewayNfs.writeIops * gfsGatewayNum]),
        readIops: min([performRet.cacheReadIops, maxGfsPerformDict.largeReadIops, gatewayNfs.readIops * gfsGatewayNum]),
        writeBand: min([performRet.cacheWriteBand, gatewayNfs.writeBand * gfsGatewayNum]) as number,
        readBand: min([performRet.cacheReadBand, gatewayNfs.readBand * gfsGatewayNum]) as number,
        dataWriteBand: min([performRet.dataWriteBand, gatewayNfs.writeBand * gfsGatewayNum]) as number,
        dataReadBand: min([performRet.dataReadBand, gatewayNfs.readBand * gfsGatewayNum]) as number,
        writeOps: min([gatewayNfs.writeOps * gfsGatewayNum, gatewayNfs.writeMaxOps]),
        readOps: min([gatewayNfs.readOps * gfsGatewayNum, gatewayNfs.readMaxOps])
      };
    } else {
      const nsKey = config.version.replace('v', '').replace('_all_ssd', '');
      const gatewayNfs = performanceDict.maxGfsGatewayPerform['nfs' + nsKey];
      const gatewaySmb = performanceDict.maxGfsGatewayPerform['smb' + nsKey];
      performRet.nfs = {
        writeIops: min([performRet.cacheWriteIops, gatewayNfs.writeIops * gfsGatewayNum]) as number,
        readIops: min([performRet.cacheReadIops, gatewayNfs.readIops * gfsGatewayNum]) as number,
        writeBand: min([performRet.cacheWriteBand, gatewayNfs.writeBand * gfsGatewayNum]) as number,
        readBand: min([performRet.cacheReadBand, gatewayNfs.readBand * gfsGatewayNum]) as number,
        dataWriteBand: min([performRet.dataWriteBand, gatewayNfs.writeBand * gfsGatewayNum]) as number,
        dataReadBand: min([performRet.dataReadBand, gatewayNfs.readBand * gfsGatewayNum]) as number,
        writeOps: min([gatewayNfs.writeOps * gfsGatewayNum, gatewayNfs.writeMaxOps]),
        readOps: min([gatewayNfs.readOps * gfsGatewayNum, gatewayNfs.readMaxOps])
      };
      performRet.smb = {
        writeIops: min([performRet.cacheWriteIops, gatewaySmb.writeIops * gfsGatewayNum]) as number,
        readIops: min([performRet.cacheReadIops, gatewaySmb.readIops * gfsGatewayNum]) as number,
        writeBand: min([performRet.cacheWriteBand, gatewaySmb.writeBand * gfsGatewayNum]) as number,
        readBand: min([performRet.cacheReadBand, gatewaySmb.readBand * gfsGatewayNum]) as number,
        dataWriteBand: min([performRet.dataWriteBand, gatewaySmb.writeBand * gfsGatewayNum]) as number,
        dataReadBand: min([performRet.dataReadBand, gatewaySmb.readBand * gfsGatewayNum]) as number,
        writeOps: min([gatewaySmb.writeOps * gfsGatewayNum, gatewaySmb.writeMaxOps]),
        readOps: min([gatewaySmb.readOps * gfsGatewayNum, gatewaySmb.readMaxOps])
      };
    }
  }

  return performRet;
}

export function objectCpuLimit(config: DictOption, dataDict: DictOption) {
  let ret = '';
  const dataOsdNum = config.dataOsdNum;
  const cpuNum = config.cpuNum;
  const cpu = findItemById(config.cpu, dataDict.cpus).value;
  if (config.objectMetaType !== 'ssd' && dataOsdNum && cpuNum && cpu) {
    const cpuPerform = cpu.baseFrequency * cpu.cores * cpuNum * dataDict.performance.objectLossRatio.cpuRatio;
    if (dataOsdNum > cpuPerform) {
      ret = t('CPU性能不足，每个data OSD消耗1Ghz');
    }
  }
  return ret;
}

export function objectPerformance(config: DictOption, dataDict: DictOption) {
  const performRet: DictOption = {
    write4k: 0,
    read4k: 0,
    write32k: 0,
    read32k: 0,
    write4m: 0,
    read4m: 0,
  };
  const objectMetaType = config.objectMetaType;
  const objectCacheType = config.objectCacheType;
  const objectPerforDict = dataDict.performance.objectPerformance;
  const lossRatio = dataDict.performance.objectLossRatio;
  const dataOsdNum = config.dataOsdNum;
  const nodeNum = config.nodeNum;
  let performanceDict: DictOption;

  let globalRatio = 1;
  let bandRatio = 1;
  if (config.policyType === 'replicated') {
    bandRatio = lossRatio.bandRatio;
  } else if (config.erasure === '16+2:1') {
    globalRatio = lossRatio.globalRatio;
  }
  const dataCommonVal = dataOsdNum * nodeNum * globalRatio;
  if (objectMetaType === 'hdd') {
    if (objectCacheType === 'hdd') {
      performanceDict = objectPerforDict.hddMetaHddCache;
    } else {
      performanceDict = objectPerforDict.hddMetaSsdCache;
    }
    performRet.write4k = Math.round(performanceDict.writeOps4k * dataCommonVal);
    performRet.write32k = Math.round(performanceDict.writeOps32k * dataCommonVal);
  } else {
    const cacheOsdNum = config.cacheOsdNum;
    performanceDict = objectPerforDict.ssdMetaSsdCache;
    performRet.write4k = Math.round(performanceDict.writeOps4k * cacheOsdNum * nodeNum * globalRatio);
    performRet.write32k = Math.round(performanceDict.writeOps32k * cacheOsdNum * nodeNum * globalRatio);
  }
  performRet.read4k = Math.round(performanceDict.readOps4k * dataCommonVal);
  performRet.read32k = Math.round(performanceDict.readOps32k * dataCommonVal);

  performRet.write4m = Math.round(performanceDict.writeBand4m * dataCommonVal * bandRatio);
  performRet.read4m = Math.round(performanceDict.readBand4m * dataCommonVal * bandRatio);
  return performRet;
}

export function cpuPerformance(config: DictOption, dataDict: DictOption) {
  const performRet = {
    write: 0,
    read: 0,
  };
  const cpu = findItemById(config.cpu, dataDict.cpus).value;
  const cpuNum = config.cpuNum;
  const nodeNum = config.nodeNum;
  const cacheReplicated = config.cacheReplicated;
  const cpuPerformance = dataDict.performance.cpuPerformance;
  const writePerform = cpuPerformance.write;
  const readPerform = cpuPerformance.read;
  const allSSD = config.version.endsWith('all_ssd');
  const cpuTotalGhz = cpu.baseFrequency * cpu.cores * cpuNum;
  if (!allSSD) {
    const nodeWrite = min([cpuTotalGhz * writePerform.ratio * writePerform.iops, writePerform.nodeMax]);
    performRet.write = ceilNumber(nodeWrite * nodeNum / cacheReplicated, 100);
    const nodeRead = min([cpuTotalGhz * readPerform.ratio * readPerform.iops, readPerform.nodeMax]);
    performRet.read = ceilNumber(nodeRead * nodeNum, 100);
  } else {
    const performDetail = dataDict.performDetail;
    const ioRatio = performDetail.ioRatio['4K'];
    const cpuRatio = ioRatio * performDetail.latencyRatio['3ms'];
    performRet.write = ceilNumber(cpuTotalGhz * config.nodeNum * cpuRatio * performDetail.cpuIopsGhz.hit / config.cacheReplicated, 1000);
    performRet.read = performRet.write * performDetail.performRbdHit.randwriteTurnRandread;
  }
  return performRet;
}

export function networkPerformance(config: DictOption, dataDict: DictOption) {
  return calculateNetworkBand(config, dataDict);
}

function calculateNetworkBand(config: DictOption, dataDict: DictOption) {
  const networkRatio = dataDict.performance.networkRatio;
  const nodeNum = config.nodeNum;
  const netCard = config.netCard;
  const netCardNum = config.netCardNum;
  const bondMode = config.bondMode;
  const netCardBandGb = findItemById(netCard, dataDict.netCards).value;
  let nodeBand = netCardBandGb * 1000 / 8 * networkRatio.networkRatio;
  if (bondMode === 'mode1') {
    nodeBand *= netCardNum / 2;
  } else if (bondMode === 'mode4') {
    nodeBand *= netCardNum;
  }
  return Math.round(nodeBand * nodeNum);
}

export function calculateMemory(config: DictOption, dataDict: DictOption) {
  return calculateAllMemory(config, dataDict);
}

function calculateAllMemory(config: DictOption, dataDict: DictOption) {
  const memoryRet: DictOption = {
    cache: '',
    data: '',
    base: '',
    detailAddition: '',
    managerNode: '',
    notManagerNode: '',
    gfsMetaNode: '',
    gfsDataNode: '',
    eosRgw: '',
    recomManager: '',
    recomNotManager: '',
    recomMetaManager: '',
    recomMetaNotManager: '',
    recomDataManager: '',
    recomDataNotManager: '',
  };
  const pool = config.pool;
  const cacheOsdNum = config.cacheOsdNum;
  const dataOsdNum = config.dataOsdNum;
  const memoryDict = dataDict.memory;
  const osdMemoryDict = memoryDict.osdMemory;
  if (pool === 'data') {
    memoryRet.data = (osdMemoryDict.dataGiB * dataOsdNum).toFixed(1) + '（' + osdMemoryDict.dataGiB + '*' + dataOsdNum + '）';
  } else {
    const cacheOsd = findItemById(config.cacheOsd, dataDict.ssds);
    let [cacheVal, cacheUnit] = findCapacityValAndUnit(cacheOsd.value);
    cacheVal = convertCapacity(cacheVal, cacheUnit, 'GiB');
    const mscacheThreadDict = memoryDict.mscacheThreadNum;
    const mscacheThreadNum = config.version.endsWith('all_ssd') ? mscacheThreadDict.allSSD : mscacheThreadDict.general;
    if (pool === 'XSpeed') {
      const mscacheCapacity = cacheVal - config.omap - 3;
      const nvcacheMem = (mscacheCapacity / 64 - mscacheCapacity * 256 / 1024 / 1024) * 343;
      let mscacheThreadMem = 0;
      if (config.version.startsWith('v5_2')) {
        mscacheThreadMem = (mscacheThreadNum - 1) * (8 * cacheVal / 64 / 3 + 320);
      }
      const singleCache = (nvcacheMem + osdMemoryDict.xspeedCacheMiB + mscacheThreadMem) / 1024;
      memoryRet.cache = (singleCache * cacheOsdNum).toFixed(1) + '（' + singleCache.toFixed(1) + '*' + cacheOsdNum + '）';
      memoryRet.data = (osdMemoryDict.dataGiB * dataOsdNum).toFixed(1) + '（' + osdMemoryDict.dataGiB + '*' + dataOsdNum + '）';
    } else if (pool === 'LocalCache') {
      const singleCache = cacheVal / (dataOsdNum / cacheOsdNum);
      const mscacheCapacity = singleCache - 20 - 3;
      const nvcacheMem = (mscacheCapacity / 32 - mscacheCapacity * 512 / 1024 / 1024) * 343;
      let mscacheThreadMem = 0;
      if (config.version.startsWith('v5_2')) {
        mscacheThreadMem = (mscacheThreadNum - 1) * (8 * singleCache / 32 / 3 + 320);
      }
      const singleOsd = (nvcacheMem + osdMemoryDict.localCacheMiB + mscacheThreadMem) / 1024;
      memoryRet.data = (singleOsd * dataOsdNum).toFixed(1) + '（' + singleOsd.toFixed(1) + '*' + dataOsdNum + '）';
    }
  }

  const product = config.product;
  const baseMemoryDict = memoryDict.baseMemoryGiB;
  memoryRet.base = baseMemoryDict.xdc + baseMemoryDict.osOther;
  memoryRet.managerNode = baseMemoryDict.demon + baseMemoryDict.mon;
  memoryRet.notManagerNode = baseMemoryDict.demonAgent;
  const recomMemList = memoryDict.recommendMemoryGiB as Array<number>;
  const recomMemRatio = memoryDict.recommendMemoryRatio;

  let detailBaseMem = memoryRet.cache ? parseFloat(memoryRet.cache) : 0;
  detailBaseMem += parseFloat(memoryRet.data);
  detailBaseMem += memoryRet.base;
  let managerNodeMem = detailBaseMem + memoryRet.managerNode;
  let notManagerNodeMem = detailBaseMem + memoryRet.notManagerNode;
  if (product === 'XEBS') {
    memoryRet.managerMem = managerNodeMem.toFixed(1);
    memoryRet.notManagerMem = notManagerNodeMem.toFixed(1);
    memoryRet.recomManager = getRecommenMem(recomMemList, managerNodeMem * recomMemRatio);
    memoryRet.recomNotManager = getRecommenMem(recomMemList, notManagerNodeMem * recomMemRatio);
  } else if (product === 'XGFS') {
    memoryRet.gfsDataNode = baseMemoryDict.gfsGateway;
    memoryRet.dataManagerMem = managerNodeMem + baseMemoryDict.gfsGateway;
    memoryRet.dataNotManagerMem = notManagerNodeMem + baseMemoryDict.gfsGateway;
    memoryRet.recomDataManager = getRecommenMem(recomMemList, memoryRet.dataManagerMem * recomMemRatio);
    memoryRet.recomDataNotManager = getRecommenMem(recomMemList, memoryRet.dataNotManagerMem * recomMemRatio);

    memoryRet.gfsMetaNode = baseMemoryDict.gfsGateway + baseMemoryDict.xmds;
    memoryRet.metaManagerMem = managerNodeMem + baseMemoryDict.gfsGateway + baseMemoryDict.xmds;
    memoryRet.metaNotManagerMem = notManagerNodeMem + baseMemoryDict.gfsGateway + baseMemoryDict.xmds;
    memoryRet.recomMetaManager = getRecommenMem(recomMemList, memoryRet.metaManagerMem * recomMemRatio);
    memoryRet.recomMetaNotManager = getRecommenMem(recomMemList, memoryRet.metaNotManagerMem * recomMemRatio);
    memoryRet.dataManagerMem = memoryRet.dataManagerMem.toFixed(1);
    memoryRet.dataNotManagerMem = memoryRet.dataNotManagerMem.toFixed(1);
    memoryRet.metaManagerMem = memoryRet.metaManagerMem.toFixed(1);
    memoryRet.metaNotManagerMem = memoryRet.metaNotManagerMem.toFixed(1);
  } else if (product === 'XEOS') {
    memoryRet.eosRgw = baseMemoryDict.rgw;
    memoryRet.managerMem = managerNodeMem + baseMemoryDict.rgw;
    memoryRet.notManagerMem = notManagerNodeMem + baseMemoryDict.rgw;
    memoryRet.recomManager = getRecommenMem(recomMemList, memoryRet.managerMem * recomMemRatio);
    memoryRet.recomNotManager = getRecommenMem(recomMemList, memoryRet.notManagerMem * recomMemRatio);
    memoryRet.managerMem = memoryRet.managerMem.toFixed(1);
    memoryRet.notManagerMem = memoryRet.notManagerMem.toFixed(1);
  }

  return memoryRet;
}

function getRecommenMem(recomList: number[], memory: number) {
  let memRet = 0;
  for (let i = 0; i < recomList.length; i++) {
    const recomMem = recomList[i];
    if (memory <= recomMem) {
      memRet = recomMem;
      break;
    }
  }
  return memRet;
}

export function calculateXgfsFileNum(gfsMetaDiskId: number, dataDict: DictOption) {
  const gfsMetaDisk = findItemById(gfsMetaDiskId, dataDict.ssds)
  const [diskVal, diskUnit] = findCapacityValAndUnit(gfsMetaDisk.value);
  const diskValTiB = convertCapacity(diskVal, diskUnit, 'TiB');
  const fileNumMillion = ceilNumber(diskValTiB * dataDict.capacity.gfsFileNumRatio, 1).toFixed(0);
  return fileNumMillion;
}

export function calculateCapacity(config: DictOption, dataDict: DictOption) {
  let dataOsd = findItemById(config.dataOsd, dataDict.hdds);
  if (!dataOsd.value) {
    dataOsd = findItemById(config.dataOsd, dataDict.ssds);
  }
  let [dataVal, dataUnit] = findCapacityValAndUnit(dataOsd.value);
  dataVal = convertCapacity(dataVal, dataUnit, 'GiB');
  const totalGiB = dataVal * config.dataOsdNum * config.nodeNum;
  let actualTotalGiB = (dataVal - 20 - 3) * config.dataOsdNum * config.nodeNum;
  const policyType = config.policyType;
  if (policyType === 'erasure') {
    const erasure = config.erasure;
    const ec = erasure !== 'other' ? erasure : config.otherErasure;
    if (ec) {
      const kmb = covertEcKMB(ec);
      actualTotalGiB *= kmb.k / (kmb.k + kmb.m);
    }
  } else if (policyType === 'replicated') {
    actualTotalGiB /= config.replicated;
  }
  const pool = config.pool;
  const ratio = dataDict.capacity.poolCapacityRatio[pool];
  const actualTiB = actualTotalGiB * ratio;
  return {
    totalTiB: polishCapacityTiB(totalGiB),
    actualTotalTiB: polishCapacityTiB(actualTotalGiB),
    actualTiB: polishCapacityTiB(actualTiB),
    ratio: ratio
  };
}

function polishCapacityTiB(capacityGiB: number) {
  return (capacityGiB / 1024).toFixed(1);
}

export function checkNodeNum(config: DictOption) {
  let errorText = '';
  const nodeNum = config.nodeNum;
  if (nodeNum) {
    const policyType = config.policyType;
    if (policyType === 'erasure') {
      const erasure = config.erasure;
      const ec = erasure !== 'other' ? erasure : config.otherErasure;
      if (ec) {
        const kmb = covertEcKMB(ec);
        let minNodeNum = kmb.k + kmb.m;
        if (kmb.b && kmb.b === 1) {
          minNodeNum /= 2;
        }
        if (nodeNum < minNodeNum) {
          errorText = t(`小于最小节点数 ${minNodeNum}`);
        }
      }
    } else if (policyType === 'replicated') {
      if (config.replicated > nodeNum) {
        errorText = t(`小于最小节点数 ${config.replicated}`);
      }
    }
  }
  return errorText;
}

export function calculateOmap(config: DictOption, options: DictOption, capacityDict: DictOption) {
  let omap: number | string = '';
  let errorText: string = '';
  let tipText: string = '';
  if (config.dataOsd && config.dataOsdNum && config.cacheOsd && config.cacheOsdNum) {
    const cacheOsds = options.cacheOsds;
    const omapDict = capacityDict.omapConfig;

    let dataOsd = findItemById(config.dataOsd, options.dataOsds);
    if (!dataOsd.value) {
      dataOsd = findItemById(config.dataOsd, options.ssds);
    }
    let [dataVal, dataUnit] = findCapacityValAndUnit(dataOsd.value);
    if (dataUnit === 'GB') {
      dataVal /= 1000;
    }
    let dataCapacity = dataVal * config.dataOsdNum;
    if (config.policyType === 'replicated') {
      dataCapacity /= config.replicated;
    } else {
      let kmb;
      if (config.erasure === 'other') {
        if (!config.otherErasure) {
          return '';
        }
        kmb = covertEcKMB(config.otherErasure);
      } else {
        kmb = covertEcKMB(config.erasure);
      }
      dataCapacity *= kmb.k / (kmb.k + kmb.m);
    }

    const cacheOsd = findItemById(config.cacheOsd, cacheOsds);
    let [cacheVal, cacheUnit] = findCapacityValAndUnit(cacheOsd.value);
    let [omapRatio, minCacheSize] = getOmapRatio(cacheVal, cacheUnit, omapDict);
    // XSOS6.1 混闪场景omap都改成1
    if (config.version >= 'v6_1' && !config.version.endsWith('all_ssd')) {
      omapRatio = 1;
    }
    const volumeOmap = Math.round(dataCapacity * 4 * config.cacheReplicated / config.cacheOsdNum * omapRatio);

    const defalutGfsNum = 10;
    const cacheValGiB = convertCapacity(cacheVal, cacheUnit, 'GiB');
    if (config.product === 'XEBS') {
      const gfsOmap = Math.round(80 * defalutGfsNum / config.cacheOsdNum);
      if (volumeOmap < gfsOmap) {
        const maxVolumeOmap = Math.round(cacheValGiB - 3 - minCacheSize);
        if (maxVolumeOmap < volumeOmap) {
          omap = volumeOmap;
          errorText = t('缓存盘过小');
        } else if (maxVolumeOmap < gfsOmap) {
          omap = maxVolumeOmap;
        } else {
          omap = gfsOmap;
        }
      } else {
        if (cacheValGiB - 3 - volumeOmap < minCacheSize) {
          errorText = t('缓存盘过小');
        }
        omap = volumeOmap;
      }
    } else if (config.product === 'XGFS') {
      const gfsOmap = Math.round(80 * config.gfsFileNum / config.nodeNum / config.cacheOsdNum);
      const maxOmap = Math.max(volumeOmap, gfsOmap);
      if (config.gfsScene === 'largeIO') {
        omap = Math.round(maxOmap * capacityDict.gfsLargeIOOmapRatio);
        cacheVal /= (cacheUnit === 'GB' ? 1024 : 1);
        if (dataCapacity / (cacheVal * config.cacheOsdNum) > capacityDict.gfsLargeIOCapacityPercent) {
          errorText = t('请控制容量比在 SSD:HDD = 1:80 之内');
        }
      } else {
        omap = maxOmap;
      }
      if (cacheValGiB - 3 - omap < minCacheSize) {
        errorText = t('缓存盘过小');
      }
      if (!errorText) {
        //前面看缓存盘是否足够，现在omap计算值与产品同步(产品按照10亿文件数来计算的)
        const gfsOriginOmap = Math.round(80 * defalutGfsNum / config.cacheOsdNum);
        if (omap < gfsOriginOmap && cacheValGiB - 3 - gfsOriginOmap > minCacheSize) {
          omap = gfsOriginOmap;
        }
        if (gfsOmap > gfsOriginOmap && gfsOmap > volumeOmap) {
          tipText = t('文件数计算出来的omap大于产品上计算的，需要在产品上手动设置索引容量');
        }
      }
    }
    if (omap < 160) {
      omap = 160;
    } else if (omap > 4096) {
      errorText = t('单盘 omap 超过最大设置值 4096 GiB');
    }
  }
  return [omap, errorText, tipText];
}

function getOmapRatio(val: number, unit: string, omapDict: DictOption[]) {
  let ratio, minCacheSize;
  if (unit === 'TB') {
    val *= 1024;
  }
  for (let i = 0; i < omapDict.length; i++) {
    const one = omapDict[i];
    if (val <= one.totalSizeGB) {
      ratio = one.ratio;
      minCacheSize = one.minCacheSizeGiB;
      break;
    }
  }
  return [ratio, minCacheSize];
}
export function findItemByIdExp(id: number, dataList: DictOption[]){
  return findItemById(id, dataList);
}

function findItemById(id: number, dataList: DictOption[]) {
  let item: DictOption = {};
  for (let i = 0; i < dataList.length; i++) {
    const el = dataList[i];
    if (el.id === id) {
      item = el;
      break;
    }
  }
  return item;
}

export function findIndexByCode(code: string, dataList: DictOption[]) {
  let index = 0;
  for (let i = 0; i < dataList.length; i++) {
    const el = dataList[i];
    if (el.code === code) {
      index = i;
      break;
    }
  }
  return index;
}

function findCapacityValAndUnit(item: DictOption) {
  let val, unit;
  for (let key in item) {
    if (key.startsWith('capacity')) {
      val = item[key];
      unit = key.replace('capacity', '');
      break;
    }
  }
  return [val, unit];
}

function convertCapacity(originVal: number, originUnit: string, distUnit: string) {
  let distVal = 0;
  // convert KiB
  switch (originUnit) {
    case 'TiB':
      distVal = originVal * Math.pow(1024, 3);
      break;
    case 'GiB':
      distVal = originVal * Math.pow(1024, 2);
      break;
    case 'TB':
      distVal = originVal * Math.pow(1000, 4) / 1024;
      break;
    case 'GB':
      distVal = originVal * Math.pow(1000, 3) / 1024;
      break;
  }
  switch (distUnit) {
    case 'TiB':
      distVal /= Math.pow(1024, 3);
      break;
    case 'GiB':
      distVal /= Math.pow(1024, 2);
      break;
    case 'MiB':
      distVal /= 1024;
      break;
  }
  return distVal;
}

function covertEcKMB(str: string) {
  const cut1 = str.split(':');
  const b = cut1.length > 1 ? cut1[1] : '';
  const cut2 = str.split('+');
  return { k: parseInt(cut2[0]), m: parseInt(cut2[1]), b: parseInt(b) };
}

function ceilNumber(num: number, significance: number) {
  num /= significance;
  return Math.ceil(num) * significance;
}
