import t from 'locale';

export const permissionIdColumn = {
  title: t('权限ID'),
  dataKey: 'code',
};

export const permissionNameColumn = {
  title: t('权限名称'),
  dataKey: 'name',
};

export const permissionDescColumn = {
  title: t('权限描述'),
  dataKey: 'desc',
};