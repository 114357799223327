import { Permission, Role, User } from 'model';
import React, { useState } from 'react';
import { Loading, permissionIdColumn, permissionNameColumn, permissionDescColumn, roleDescColumn, roleNameColumn, Table, userNameColumn, userEmailColumn } from 'components';
import { getRolesByPermissionsCode, getUserByPermissionsCode, getUserPermissionsByMail, getUserRolesByMail } from 'request';
import styles from './index.module.scss';
import Toolbar from '../Toolbar';

const UserPermission = () => {
  const [mail, setMail] = useState('');
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  async function fetchUserPermissions() {
    setLoading(true);
    if (mail.includes('@')) {
      const allResults = await Promise.all([
        await getUserPermissionsByMail(mail),
        await getUserRolesByMail(mail),
      ]);
      setLoading(false);
      if (allResults[0].status === 200 && allResults[1].status === 200) {
        setPermissions(allResults[0].data);
        setRoles(allResults[1].data);
      } else {
        setPermissions([]);
        setRoles([]);
      }
    } else {
      const allResults = await Promise.all([
        await getUserByPermissionsCode(mail),
        await getRolesByPermissionsCode(mail),
      ]);
      setLoading(false);
      if (allResults[0].status === 200 && allResults[1].status === 200) {
        setUsers(allResults[0].data);
        setRoles(allResults[1].data);
      } else {
        setUsers([]);
        setRoles([]);
      }
    }
  };

  function handleSearchInputChange(e: React.ChangeEvent<{ value: string }>) {
    setMail(e.target.value);
  };
  function handleSearchClick() {
    fetchUserPermissions();
  }


  const toolbars: any[] = [];
  toolbars.push({
    type: 'search',
    children: mail,
    onChange: handleSearchInputChange,
    onSearch: handleSearchClick,
    tableName: 'user-permission'
  });

  return (
    <div className={styles.content}>
      <Toolbar
        className={styles.toolbar}
        items={toolbars}
      />
      {!loading ? (
        <React.Fragment>
          <div className={styles.table}>
            <Table
              data={roles}
              columns={[roleNameColumn, roleDescColumn]}
              loading={loading}
            />
            {mail.includes('@') ? <Table
              data={permissions}
              columns={[permissionIdColumn, permissionNameColumn, permissionDescColumn]}
              loading={loading}
            /> : <Table
              data={users}
              columns={[userNameColumn, userEmailColumn]}
              loading={loading}
            />}
          </div>
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default UserPermission;
