import { AxiosResponse } from 'axios';
import { GetMcmType, LicenseDigital, LicenseDigitalCreateReq, LicenseDigitalType, LicenseDigitalUpdateReq, LicenseDigitalVersion, Osp, ReceivingMailBox } from 'model';
import { instance, maybeErrorInstance } from './instance';
import { TABLE_DEFAULT_PAGE_SIZE } from 'constant';

export async function analyzeLicenseKey(key: string, version: string): Promise<AxiosResponse<string | string[]>> {
  return maybeErrorInstance.post(`/license/analyze-license-key`, { key, version });
}

export async function checkDigitalLicenseApplyUnfinished(md5: string, id?: number): Promise<AxiosResponse<{ code: string, message: string } | undefined>> {
  return maybeErrorInstance.get(`/license/check-apply-unfinished`, { params: { md5, id } });
}

export async function checkDigitalLicenseNewContractTypeExits(md5: string): Promise<AxiosResponse<{ code: string, message: string } | undefined>> {
  return maybeErrorInstance.get(`/license/check-new-contract-type-license-exits`, { params: { md5 } });
}

export async function getLastDownloadedLicenseByMd5(md5: string): Promise<AxiosResponse<LicenseDigital>> {
  return instance.get(`/license/get-last-downloaded-license-by-md5`, { params: { md5 } });
}

export async function getActiveLicensesByMd5(md5: string): Promise<AxiosResponse<LicenseDigital[]>> {
  return instance.get(`/license/get-active-license-by-md5`, { params: { md5 } });
}
export async function getCommonMails(mail: string): Promise<AxiosResponse<ReceivingMailBox[]>> {
  return instance.get(`/license/get-common-mails`, { params: { mail } });
}

export async function getOemLicensesByMd5(id: number, md5: string): Promise<AxiosResponse<[LicenseDigital[], number]>> {
  return instance.get(`/license/get-oem-license-by-md5`, { params: { id, md5 } });
}
export async function getLicensesByMd5(md5: string, type: LicenseDigitalType, version: LicenseDigitalVersion): Promise<AxiosResponse<[LicenseDigital[], number]>> {
  return instance.get(`/license/get-licenses-by-md5?md5=${md5}&type=${type}&version=${version}`);
}
export async function createDigitalLicense(param: LicenseDigitalCreateReq) {
  return instance.post(`/license/create`, param);
}

export async function updateDigitalLicense(param: LicenseDigitalUpdateReq) {
  return instance.put(`license/update/${param.id}`, param);
}
export async function updateV6DigitalLicense(param: LicenseDigitalUpdateReq, id: number) {
  return instance.put(`license/update/${id}`, param);
}
export async function getDigitalLicense(id: number, type: string): Promise<AxiosResponse<LicenseDigital>> {
  return instance.get(`license/${id}?type=${type}`);
}

export async function getLicenses(limit = TABLE_DEFAULT_PAGE_SIZE, offset = 0, search = ''): Promise<AxiosResponse<[LicenseDigital[], number]>> {
  return instance.get(`/license`, { params: { offset, limit, search } });
}
export async function downloadDigitalLicense(id: number) {
  return instance.put(`/license/download/${id}`, { responseType: 'blob' });
}
export async function cancelDigitalLicense(id: number, reason: string) {
  return instance.put(`/license/cancel/${id}`, { reason });
}

export async function recallDigitalLicense(id: number, reason: string) {
  return instance.put(`/license/recall/${id}`, { reason });
}
export async function getOspsByNameAndMcmId(name: string, type: GetMcmType, mcmId?: number): Promise<AxiosResponse<Osp[]>> {
  return instance.post(`/license/get-osps-by-name-and-mcm-id`, { name, type, mcmId });
}
export async function createCommonMails(payload: Partial<ReceivingMailBox>[]): Promise<AxiosResponse<ReceivingMailBox[]>> {
  return instance.post(`license/set-common-mail`, { payload });
}
