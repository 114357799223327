import { ContractDeliveryStatus, ContractStatus, HardwareWarrantyDateCondition, SoftwareWarrantyDateCondition } from '../../model/src/contract';
import { LocaleT } from './interface';
import { trans } from './locale';
export function getHardwareWarrantyDateConditions(key: HardwareWarrantyDateCondition, t: LocaleT = trans) {
  const HARDWARE_WARRANTY_DATE_CONDITIONS: { [key: string]: string; } = {
    productInstallDate: t('自一体机产品安装激活日起'),
    productAcceptanceCheck: t('自一体机产品验收后起'),
    productShipmentDate: t('自发货之日'),
    followTheWholeMachine: t('配件随整机质保'),
    none: t('无'),
    other: t('其他'),
  };
  return HARDWARE_WARRANTY_DATE_CONDITIONS[key];
}
export function getSoftwareWarrantyDateConditions(key: SoftwareWarrantyDateCondition, t: LocaleT = trans) {
  const SOFTWARE_WARRANTY_DATE_CONDITIONS: { [key: string]: string; } = {
    licenseActiveDate: t('自软件产品正式许可激活后次日起'),
    productInstallDate: t('自软件产品安装激活日起'),
    productAcceptanceCheck: t('自软件产品验收后起'),
    productShipmentDate: t('自发货之日'),
    none: t('无'),
    other: t('其他'),
  };
  return SOFTWARE_WARRANTY_DATE_CONDITIONS[key];
}
export function getContractStatus(key: ContractStatus, t: LocaleT = trans) {
  const CONTRACT_STATUS = {
    draft: { text: t('未提交'), status: 'waiting' },
    pending: { text: t('审批中'), status: 'pending' },
    waitingStamp: { text: t('待盖章'), status: 'pending' },
    stamped: { text: t('已盖章'), status: 'pending' },
    active: { text: t('已生效'), status: 'active' },
    abolished: { text: t('已作废'), status: 'canceled' },
    dismissed: { text: t('已解除'), status: 'canceled' },
    rejected: { text: t('被驳回'), status: 'rejected' },
    changingActiveContract: { text: t('变更中'), status: 'pending' },
    rejectedChangeActiveContract: { text: t('变更被驳回'), status: 'rejected' },
    toBeChangedContract: { text: t('待变更'), status: 'pending' },
    changing: { text: t('变更中'), status: 'pending' },
    changesToBeActive: { text: t('变更待生效'), status: 'pending' },
  };
  return CONTRACT_STATUS[key];
}
export function getContractDeliveryStatus(key: ContractDeliveryStatus, t: LocaleT = trans) {
  const status = {
    waiting: { text: t('未交付'), status: 'waiting' },
    pending: { text: t('交付中'), status: 'pending' },
    completed: { text: t('已完成'), status: 'active' },
  };
  return status[key];
}
export const SKU_CATEGORY_MAP = new Map([
  ['expand', '扩容'],
  ['renew', '续保'],
  ['serve', '服务'],
  ['train', '培训'],
  ['special', '特定 SKU'],
  ['hardware', '硬件配件'],
  ['X3000', 'X3000 硬件'],
  ['X3200', 'X3200 硬件'],
  ['X5000', 'X5000 硬件'],
  ['X5100', 'X5100 硬件'],
  ['X5200', 'X5200 硬件'],
  ['XE5000S', 'XE5000S 一体机'],
  ['XE5000V', 'XE5000V 一体机'],
  ['XE2100G2', 'XE2100G2 一体机'],
  ['XE3100G2', 'XE3100G2 一体机'],
  ['XE3200G3', 'XE3200G3 一体机'],
  ['XP备份一体机', 'XP 备份一体机'],
  ['XINFINI8000', 'XINFINI8000 一体机'],
  ['XH超融合一体机', 'XH 一体机高级版'],
  ['XECCP', 'XECCP 企业版'],
  ['外采产品', '外采产品'],
  ['XLRS光磁一体机', 'XLRS 光磁一体机'],
  ['xp', 'XP 备份一体机'],
  ['xdriveNetDiskIntegration', 'XDRIVE 云盘一体机'],
  ['hyperXIntegration', 'XH 超融合一体机'],
  ['cloudXeccpIntegration', 'XH 计算一体机']
]);