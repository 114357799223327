import t from 'locale';
import { Checkbox, TextButton } from 'x-material-ui';
import styles from './index.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
export const usernameColumn = {
  title: t('用户名称'),
  dataKey: 'name',
};

export const roleNameColumn = {
  title: t('角色名称'),
  dataKey: 'name',
};

export const roleDescColumn = {
  title: t('角色描述'),
  dataKey: 'desc',
};

export const checkBoxWithTextColumn = (onChange: (e: any, checked: boolean, record: any) => void, dataKey = "checkbox", title = "", template?: (record: any) => void) => ({
  title: title,
  dataKey: dataKey,
  template: (record: any) => {
    return (<div className={styles['check-box']}>
      <Checkbox
        checked={record.checked}
        color="primary"
        disabled={record.disabled}
        onChange={(e, checked) => onChange(e, checked, record)}
      />
      <>
        {template && template(record)}
      </>
    </div>);
  },
});

export const deleteColumn = (onClick: (record: any) => void) => ({
  title: t('操作'),
  dataKey: 'operate',
  template: (record: any) => {
    return (
      <TextButton
        color='primary'
        className={styles['delete-button']}
        startIcon={<DeleteIcon />}
        onClick={() => onClick(record)}
      >{t('移除')}</TextButton>
    );
  },
})