import { AxiosResponse } from 'axios';
import { ClusterDelivery, Cluster, Contract, ContractSubjectMatter } from 'model';
import { instance } from './instance';
import { TABLE_DEFAULT_PAGE_SIZE } from 'constant';

export async function getContractByNo(no: string): Promise<AxiosResponse<Contract>> {
  return instance.get(`/contract/get-by-no?no=${no}`);
}
export async function getContracts(limit = TABLE_DEFAULT_PAGE_SIZE, offset = 0, search = '', status = ''): Promise<AxiosResponse<[Contract[], number]>> {
  return instance.get(`/contract`, { params: { offset, limit, search, status } });
}
export async function getContractDetail(id: number | string, modelNames: string[]): Promise<AxiosResponse<Contract>> {
  return instance.get(`/contract/detail/${id}`, { params: { modelNames: modelNames.join(',') } });
}
export async function getDeliveriesFromCustomerByContractId(id: number | string): Promise<AxiosResponse<ClusterDelivery[]>> {
  return instance.get(`/contract/get-delivery/${id}`);
}
export async function getScmProductByContractNo(no: string): Promise<AxiosResponse<ContractSubjectMatter[]>> {
  return instance.get(`/contract/get-hardware-product-by-contract-no?contractNo=${no}`);
}
export async function getWarrantyInfoByContractNo(no: string): Promise<AxiosResponse<Cluster[]>> {
  return instance.get(`/contract/get-warranty-info-by-contract-no?contractNo=${no}`);
}
