import React from 'react';
import { Select, MenuItem, SelectChangeEvent } from 'x-material-ui';
import styles from './index.module.scss';
import { DropdownProps } from 'interfaces/props';

const Dropdown = (props: DropdownProps) => {
  const { name, menus } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const handleToggleOpen = (isOpen?: boolean) => {
    if (isOpen !== undefined) {
      return setOpen(isOpen);
    }
    setOpen(!open);
  };
  const handleChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    menus &&
      menus.forEach(menu => {
        if (menu.name === event.target.value) {
          menu.callback();
        }
      });
  };
  return (
    <div className={styles.dropdown}>
      <Select
        open={open}
        className={styles.select}
        variant="outlined"
        onClose={() => handleToggleOpen(false)}
        onOpen={() => handleToggleOpen()}
        onChange={handleChange}
        value={''}
        displayEmpty
      >
        <MenuItem value="" disabled classes={{ root: styles.title }}>
          {name}
        </MenuItem>
        {menus &&
          menus.map(menu => (
            <MenuItem disabled={menu.disabled} value={menu.name} key={menu.name}>
              <em>{menu.name}</em>
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

export default Dropdown;
