import classnames from 'classnames';
import { DetailHeaderInfoProps, DetailHeaderInfoDefaultProps } from 'interfaces/props';
import t from 'locale';
import lodash from 'lodash';
import { useState } from 'react';
import { Grid, Status } from 'x-material-ui';
import DetailsBasicField from '../DetailsBasicField';
import styles from './index.module.scss';

const DetailHeaderInfo = (props: DetailHeaderInfoProps) => {
  const layouts = lodash.chunk(props.fields, props.col);
  const [open, setOpen] = useState(true);
  function handleToggleOpen() {
    setOpen(!open);
  }
  return (<div className={classnames(styles.container, props.className)}>
    <div className={styles['title-container']}>
      <span className={styles['title-text']}>{props.title}</span>
      {props.status && props.statusText && <Status text={props.statusText} status={props.status} />}
      {props.additionalStatus?.map((status, i) => {
        if (!!status.color && !!status.text) {
          return (<span key={i} className={styles['status-items']}><Status text={status.text} status={status.color} /></span>)
        }
        return null;
      })}
      <span className={styles['right-area']}>
        {props.additionalHeaders?.map((h, i) => (<span className={styles['additional-header']} key={i}>{h}</span>))}
        <span className={classnames(styles['title-toggle-button'], 'link-without-decoration')} onClick={handleToggleOpen}>{open ? t('收起') : t('展开')}</span>
      </span>
    </div>
    {open && <div className={styles['basic-items']}>
      {layouts.map((line, i) => {
        return (<Grid key={`row-${i}`} className={styles.row} container item xs={12}>
          {line.map((col, i) => {
            return <Grid item xs={props.colWidth} key={`col-${i}`}>
              <DetailsBasicField
                label={col.label}
                text={col.text}
                render={col.render}
              />
            </Grid>
          })}
        </Grid>)
      })}
    </div>}
  </div >);
}
const defaultProps: DetailHeaderInfoDefaultProps = {
  col: 3,
  colWidth: 4,
}
DetailHeaderInfo.defaultProps = defaultProps;

export default DetailHeaderInfo;
