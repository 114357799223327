import { Contract } from "model";
import {
  contractSubjectMatterNameColumn,
  contractSubjectMatterUnitColumn,
  contractSubjectMatterCountColumn,
  contractSubjectMatterVersionColumn,
  contractSkuNameColumn,
  contractSkuSaleTypeColumn,
  contractSkuCountColumn,
  contractSkuAllDescColumn,
  Table,
} from 'components';
import { getSubjectMatterDisplay } from "utils";
import styles from './index.module.scss';

const SubjectMatter =(props: { contract: Contract })=>{
  const columns = [
    contractSubjectMatterNameColumn,
    contractSubjectMatterCountColumn,
    contractSubjectMatterUnitColumn,
    contractSubjectMatterVersionColumn,
  ];
  const productDetailColumns =[
    contractSkuNameColumn,
    contractSkuSaleTypeColumn,
    contractSkuCountColumn,
    contractSkuAllDescColumn,
  ];
  const subjectMatterDisplayData = getSubjectMatterDisplay(props.contract);
  return (<div className={styles.content}>
    <Table data={subjectMatterDisplayData} columns={columns} subTableCols={productDetailColumns} loading={false} />
  </div>);
}
export default SubjectMatter;