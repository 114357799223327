import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DATE_FORMAT, formatDateTime } from 'common';
import { getLicensePeriodUnit, pocFunctions, productFeatures } from 'common/src/license';
import { GlobalContext } from 'context';
import { LicenseFormErrorModel } from 'interfaces/props';
import t from 'locale';
import lodash from 'lodash';
import {
  DiditalLicenseFormModel,
  LicenseDigital,
  LicenseDigitalContractType,
  LicenseDigitalCreateReq,
  LicenseDigitalFeature,
  LicenseDigitalPeriodUnit,
  LicenseDigitalProductInfo,
  LicenseDigitalScene,
  LicenseDigitalUpdateReq,
  LicenseDigitalVersion,
  LicenseSku,
  PartnerOem,
  Sku,
  UploadFile
} from 'model';
import queryString from 'query-string';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createDigitalLicense, getDigitalLicense, getSkus, updateDigitalLicense, updateV6DigitalLicense } from 'request';
import { focusToError, getDetaultService, getExpectedUsageTime, getExpireDate } from 'utils';
import { MenuItem, Select, TextField } from 'x-material-ui';
import FormFooter from '../footer';
import Additional from './additional';
import Basic from './basic';
import styles from './index.module.scss';
import LicenseCluster from './license-cluster';
import Limit from './limit';
import PartTitle from './part-title';
import ProductV4 from './product-v4';
import ProductV5 from './product-v5';
import SkuV5 from './sku-v5';
import SkuV6 from './sku-v6';
import { getDefaultSku, getSkuCapacityAndHostCount } from './utils';
import { validate, validateV6Licenses } from './validation';

const periodUnits: LicenseDigitalPeriodUnit[] = ['second', 'day', 'month', 'year'];
const LicenseForm = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  let version: LicenseDigitalVersion = 'V5';
  if (['V4', 'V6'].includes(query.version as LicenseDigitalVersion)) {
    version = query.version as LicenseDigitalVersion;
  }
  const id = query.id ? parseInt(query.id as string, 10) : undefined;
  const action = id ? 'edit' : 'create';
  const history = useNavigate();
  const scenes: LicenseDigitalScene[] = ['poc'];
  const contractTypes: LicenseDigitalContractType[] = ['new', 'expand', 'upgrade'];
  const [error, setError] = useState<LicenseFormErrorModel>({});
  const [loading, setLoading] = useState(false);
  const [originalLicense, setOriginalLicense] = useState<LicenseDigital>();
  const [currentFeatures, setCurrentFeatures] = useState<LicenseDigitalFeature[]>(productFeatures);
  const [oemSkus, setOemSkus] = useState<Sku[]>([]);
  const { user } = useContext(GlobalContext);
  const [isNewContractLicenseExist, setIsNewContractLicenseExist] = useState<boolean>(false);
  const [license, setLicense] = useState<DiditalLicenseFormModel>({
    contractType: 'new',
    scene: scenes[0],
    contractNo: '',
    enableMcm: false,
    supportVersions: [version],
    getMd5Type: 'manual',
    productInfo: {
      id: 0,
      model: '',
      desc: '',
      series: '',
    },
    services: [getDetaultService()],
    applyReason: '',
    period: 1,
    expectedUsageTime: 0,
    periodUnit: 'month',
    type: 'oem',
    productType: 'SDS',
    skus: [],
    version,
    receivingMailBox: [{ id: 0, name: user?.name || '', type: 'inner', mail: user?.mail || '', isApplicant: true }]
  });
  const licenseRef = useRef<DiditalLicenseFormModel>();
  licenseRef.current = license;
  const oemSkusRef = useRef<Sku[]>([]);
  oemSkusRef.current = oemSkus;
  async function fetchOemSkus(oemId: number) {
    let version = 'v5';
    if (query.version === 'V6') {
      version = 'v6';
    }
    const response = await getSkus(1000, 0, '', [{ type: 'sku_category', value: 'oem' }, { type: 'oem_id', value: oemId + '' }, { type: 'version', value: version }, { type: 'in_used', value: 'true' }, { type: 'licenseUseScene', value: 'poc' },]);
    return response;
  }
  useEffect(() => {
    init();
  }, [user?.companyId]);
  async function init() {
    let oemSkus: Sku[] = [];
    if (version !== 'V4' && user?.companyId) {
      const response = await fetchOemSkus(user.companyId);
      oemSkus = response.data.rows;
      setOemSkus(oemSkus);
    }
    if (id) {
      const response = await getDigitalLicense(id, 'apply');
      const result = response.data;
      let keyFile: Partial<UploadFile> | undefined = undefined;
      if (result.licenseFile) {
        keyFile = {
          name: result.licenseFile.name,
          id: result.licenseFile.id,
        };
      }
      setLicense({
        ...result,
        contractType: result.isDelay ? 'new' : result.contractType,
        isDelay: result.isDelay,
        keyFile,
      });
      setCurrentFeatures(getFeatures(result.productInfo));
    }
    if (!id) {
      if (user && user.companyId) {
        const vendor = { name: '' } as PartnerOem;
        setLicense({
          ...licenseRef.current,
          vendor: vendor,
          vendorId: user.companyId,
          skus: version === 'V5' ? [...oemSkus.filter(sku => sku.type === '扩展').map(sku => {
            return {
              ...getDefaultSku('extension', license),
              sku,
              code: sku.code,
              skuId: sku.id,
              id: -Math.random(),
            }
          })] : [],
        });
      }
    }
  }
  function updateLicense(data: DiditalLicenseFormModel) {
    const expectedUsageTime = data.expectedUsageTime || licenseRef.current?.expectedUsageTime;
    setLicense({
      ...licenseRef.current,
      ...data,
      expectedUsageTime
    });
  }
  function clearError(field: (keyof DiditalLicenseFormModel) | (keyof DiditalLicenseFormModel)[]) {
    setError({
      ...lodash.omit(error, field),
    });
  }
  function getFeatures(productInfo?: LicenseDigitalProductInfo) {
    const series = productInfo?.series || '';
    const seriesFeatures = productFeatures.filter(feature => {
      if (feature.product_id) {
        if (pocFunctions.includes(feature.desc)) {
          return false;
        }
        return feature.product_id === productInfo?.id;
      }
      return feature.name.indexOf(series) === 0;
    });
    return seriesFeatures;
  }
  function handleToPages() {
    history(`/cluster?tab=permit`);
  }
  function handleCancelClick() {
    handleToPages();
  }
  function getHasError(error: LicenseFormErrorModel) {
    let hasError = false;
    for (const p in error) {
      if (error[p as keyof LicenseFormErrorModel]?.error) {
        hasError = true;
        break;
      }
    }
    return hasError;
  }
  function getLicenseParam(simplify = false) {
    const formValues = license as Required<DiditalLicenseFormModel>;
    let customerName = formValues.customerName || '';
    const { hostCount, capacityTbyte } = getSkuCapacityAndHostCount(license);
    const param: LicenseDigitalCreateReq = {
      activeDate: formatDateTime(formValues.activeDate),
      applyReason: formValues.applyReason,
      clusterAddress: null,
      clusterMd5: formValues.clusterMd5,
      clusterName: formValues.clusterName,
      contractNo: formValues.contractNo || null,
      contractType: formValues.contractType || 'new',
      customerContact: formValues.customerContact,
      customerName: customerName,
      mcmId: formValues.mcmId,
      ospId: formValues.ospId,
      isDelay: formValues.isDelay,
      pocVersion: formValues.pocVersion,
      expireDate: formValues.expireDate,
      enableMcm: formValues.enableMcm,
      features: formValues.features,
      getMd5Type: formValues.getMd5Type,
      keyFileId: formValues.getMd5Type === 'manual' ? formValues.keyFileId : null,
      keyAlgorithm: formValues.keyAlgorithm,
      keyVaultAgentKey: formValues.keyVaultAgentKey,
      keyVaultKey: formValues.keyVaultKey,
      keyVersion: formValues.keyVersion,
      licenseKey: formValues.getMd5Type === 'qr-code' ? formValues.licenseKey : null,
      limits: formValues.limits || {},
      opportunityId: null,
      period: formValues.period,
      periodUnit: formValues.periodUnit || null,
      productInfo: formValues.productInfo,
      productSkuCode: formValues.productSkuCode,
      productSkuId: formValues.productSkuId,
      scene: formValues.scene,
      services: formValues.services.filter(s => s.name !== '0'),
      supportVersions: formValues.supportVersions,
      type: 'oem',
      vendorId: formValues.vendorId,
      partnerId: formValues.partnerId,
      version,
      guestVersion: 'guestV2',
      guestMail: user?.mail || '',
      guestName: user?.name || '',
      productType: formValues.productType,
      skus: simplify ? (license.skus || []).map(sku => lodash.omit(sku, ['sku'])) as LicenseSku[] : license.skus as LicenseSku[],
      productSku: formValues.productSku,
      hostCount,
      capacityTbyte,
      receivingMailBox: [],
    }
    return param;
  }
  function getLicenseV6Param(simplify = false) {
    const licenseData = [];
    const formValues = license as Required<DiditalLicenseFormModel>;
    let customerName = formValues.customerName || '';
    const clusterNames = license.licenseV6Cluster?.filter(c => c.clusterName).map(s => s.clusterName) || [];
    const { hostCount, capacityTbyte } = getSkuCapacityAndHostCount(license);
    const globalLicense: LicenseDigitalUpdateReq = {
      activeDate: formatDateTime(formValues.activeDate),
      applyReason: formValues.applyReason,
      clusterAddress: null,
      clusterMd5: formValues.clusterMd5 || formValues.clusterInfo?.md5,
      clusterName: formValues.clusterName || formValues.clusterInfo?.name || clusterNames.join(','),
      contractNo: formValues.contractNo || null,
      contractType: formValues.contractType || 'new',
      customerContact: formValues.customerContact,
      customerName: customerName,
      enableMcm: formValues.enableMcm,
      isDelay: formValues.isDelay,
      expireDate: formValues.expireDate,
      features: formValues.features,
      getMd5Type: formValues.getMd5Type,
      keyFileId: formValues.getMd5Type === 'manual' ? formValues.keyFileId : null,
      keyAlgorithm: formValues.keyAlgorithm,
      keyVaultAgentKey: formValues.keyVaultAgentKey,
      keyVaultKey: formValues.keyVaultKey,
      keyVersion: formValues.keyVersion,
      licenseKey: formValues.getMd5Type === 'qr-code' ? formValues.licenseKey : null,
      limits: formValues.limits || {},
      mcmId: formValues.mcmId,
      opportunityId: null,
      ospId: formValues.ospId,
      period: formValues.period,
      periodUnit: formValues.periodUnit || null,
      productInfo: formValues.productInfo,
      productSkuCode: formValues.productSkuCode,
      productSkuId: formValues.productSkuId,
      scene: formValues.scene,
      services: formValues.services.filter(s => s.name !== '0'),
      supportVersions: lodash.uniq(formValues.supportVersions),
      type: 'oem',
      vendorId: formValues.vendorId,
      partnerId: formValues.partnerId,
      version,
      guestVersion: 'guestV2',
      guestMail: user?.mail || '',
      guestName: user?.name || '',
      productType: formValues.productType,
      skus: simplify ? (license.skus || []).map(sku => lodash.omit(sku, ['sku'])) as LicenseSku[] : license.skus as LicenseSku[],
      productSku: formValues.productSku,
      classify: 'XSOS-GLOBAL',
      pocVersion: license.pocVersion || '',
      delayCertificate: formValues.delayCertificate,
      hostCount,
      capacityTbyte,
      receivingMailBox: [],
    }
    if (license.licenseV6Cluster) {
      globalLicense.period = formValues.licenseV6Cluster[0]?.period || 1;
      globalLicense.periodUnit = formValues.licenseV6Cluster[0]?.periodUnit as LicenseDigitalPeriodUnit;
    }
    if (id) {
      globalLicense.id = formValues.id;
    }
    licenseData.push(globalLicense);
    if (version === 'V6') {
      license.licenseV6Cluster?.forEach(element => {
        const formValues = element as Required<DiditalLicenseFormModel>;
        const { hostCount, capacityTbyte } = getSkuCapacityAndHostCount(formValues);
        const param: LicenseDigitalUpdateReq = {
          activeDate: formatDateTime(formValues.activeDate),
          applyReason: formValues.applyReason,
          clusterAddress: null,
          clusterMd5: formValues.clusterMd5 || formValues.clusterInfo?.md5,
          clusterName: formValues.clusterName || formValues.clusterInfo?.name,
          contractNo: formValues.contractNo || null,
          contractType: formValues.contractType || 'new',
          customerContact: formValues.customerContact,
          customerName: customerName,
          isDelay: !!license.isDelay,
          enableMcm: formValues.enableMcm,
          expireDate: formValues.expireDate,
          features: formValues.features,
          getMd5Type: formValues.getMd5Type,
          keyFileId: formValues.getMd5Type === 'manual' ? formValues.keyFileId : null,
          keyAlgorithm: formValues.keyAlgorithm,
          keyVaultAgentKey: formValues.keyVaultAgentKey,
          keyVaultKey: formValues.keyVaultKey,
          keyVersion: formValues.keyVersion,
          licenseKey: formValues.getMd5Type === 'qr-code' ? formValues.licenseKey : null,
          limits: formValues.limits || {},
          mcmId: formValues.mcmId,
          opportunityId: null,
          ospId: formValues.ospId,
          period: formValues.period,
          periodUnit: formValues.periodUnit || null,
          productInfo: formValues.productInfo,
          productSkuCode: formValues.productSkuCode,
          productSkuId: formValues.productSkuId,
          scene: formValues.scene,
          services: formValues.services.filter(s => s.name !== '0'),
          supportVersions: lodash.uniq(formValues.supportVersions),
          type: 'oem',
          vendorId: formValues.vendorId || user?.companyId || null,
          partnerId: formValues.partnerId,
          version,
          guestVersion: 'guestV2',
          guestMail: user?.mail || '',
          guestName: user?.name || '',
          productType: formValues.productType,
          skus: simplify ? (formValues.skus || []).map(sku => lodash.omit(sku, ['sku'])) as LicenseSku[] : formValues.skus as LicenseSku[],
          productSku: formValues.productSku,
          classify: formValues.classify,
          masterId: license.id,
          pocVersion: license.pocVersion || '',
          v5UpgradeClusterUuid: license.v5UpgradeClusterUuid,
          delayCertificate: formValues.delayCertificate,
          md5Data: license.md5Data,
          hostCount,
          capacityTbyte,
          receivingMailBox: [],
        }
        if (id) {
          param.id = formValues.id;
        }
        licenseData.push(param);
      });
    }
    return licenseData;
  }
  async function handleOkClick() {
    let V6Error;
    let hasError = false;
    if (version === 'V6') {
      V6Error = await validateV6Licenses(license);
      if (V6Error?.newV6License.error?.licenseError) {
        hasError = V6Error.hasError;
        setError(V6Error.newV6License.error.licenseError);
        updateLicense({
          licenseV6Cluster: V6Error.newV6License.licenseV6Cluster,
        });
      }
    } else {
      const error = await validate(license);
      hasError = getHasError(error);
      setError(error);
    }
    if (hasError) {
      setLoading(false);
      focusToError();
    } else {
      setLoading(true);
      let param;
      if (version === 'V6') {
        param = getLicenseV6Param(true) as unknown as LicenseDigitalCreateReq;
      } else {
        param = getLicenseParam(true) as unknown as LicenseDigitalCreateReq;
      }
      if (action === 'create') {
        await createDigitalLicense(param);
      } else {
        if (id) {
          if (version != 'V6') {
            await updateDigitalLicense({ ...param, id });
          } else {
            await updateV6DigitalLicense(param as LicenseDigitalUpdateReq, id);
          }
        }
      }
      setLoading(false);
      handleToPages();
    }
  }
  function renderActiveDate(value: string | undefined, handleChange: (date: string | null) => void, disabled = false) {
    return (<div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label=""
          value={value || null}
          inputFormat={DATE_FORMAT}
          onChange={handleChange}
          disabled={disabled}
        />
      </LocalizationProvider>
    </div>);
  }
  function renderInputSelectAdornment(value: string | undefined, handleChange: (value: LicenseDigitalPeriodUnit) => void, disabled: boolean) {
    return (<div className={styles['period-select-end']}>
      <Select
        value={value}
        variant="outlined"
        classes={{
          icon: styles['select-icon'],
        }}
        onChange={e => handleChange(e.target.value as LicenseDigitalPeriodUnit)}
        disabled={disabled}
      >
        {periodUnits.map(unit => <MenuItem value={unit} key={unit}>{getLicensePeriodUnit(unit, t)}</MenuItem>)}
      </Select>
    </div>);
  }
  function renderV4() {
    return (<>
      <div className={styles['info-container']}>
        <div className={styles['basic-info']}>
          <Basic
            license={license}
            version={version}
            type="oem"
            contractTypes={contractTypes}
            update={updateLicense}
            clearError={clearError}
            updateError={setError}
            error={error}
            getFeatures={getFeatures}
            setOriginalLicense={setOriginalLicense}
            setCurrentFeatures={setCurrentFeatures}
            originalLicense={originalLicense}
            getExpireDate={getExpireDate}
            setOemSkus={setOemSkus}
            isNewContractLicenseExist={isNewContractLicenseExist}
            setIsNewContractLicenseExist={setIsNewContractLicenseExist}
          />
        </div>
        <div className={styles['product-info']}>
          <ProductV4
            license={license}
            update={updateLicense}
            setCurrentFeatures={setCurrentFeatures}
            clearError={clearError}
            getFeatures={getFeatures}
            error={error}
            originalLicense={originalLicense}
            currentFeatures={currentFeatures}
            getExpireDate={getExpireDate}
            renderInputSelectAdornment={renderInputSelectAdornment}
            renderActiveDate={renderActiveDate}
            version={version}
          />
        </div>
      </div>
      <div className={styles['limit-service-container']}>
        <div className={styles.limit}>
          <Limit
            license={license}
            originalLicense={originalLicense}
            update={updateLicense}
          />
        </div>
      </div>
      <div className={styles.others}>
        <Additional
          license={license}
          update={updateLicense}
          error={error}
        />
      </div>
    </>);
  }
  function renderV5() {
    return (<>
      <div className={styles['basic-info']}>
        <Basic
          license={license}
          type={'oem'}
          version={version}
          contractTypes={contractTypes}
          update={updateLicense}
          clearError={clearError}
          updateError={setError}
          error={error}
          getFeatures={getFeatures}
          setOriginalLicense={setOriginalLicense}
          setCurrentFeatures={setCurrentFeatures}
          originalLicense={originalLicense}
          getExpireDate={getExpireDate}
          setOemSkus={setOemSkus}
          isNewContractLicenseExist={isNewContractLicenseExist}
          setIsNewContractLicenseExist={setIsNewContractLicenseExist}
        />
        <ProductV5
          title={<PartTitle title={t('软件产品许可')} />}
          license={license}
          error={error}
          update={updateLicense}
          clearError={clearError}
          getExpireDate={getExpireDate}
          renderInputSelectAdornment={renderInputSelectAdornment}
          renderActiveDate={renderActiveDate}
          type={'oem'}
          version={version}
          oemSkusRef={oemSkusRef}
        />
        <SkuV5
          title={<PartTitle title={t('基础许可')} />}
          type={'oem'}
          skuType="base"
          license={license}
          error={error.baseSkus}
          update={updateLicense}
          clearError={() => { clearError('baseSkus') }}
          renderInputSelectAdornment={renderInputSelectAdornment}
          renderActiveDate={renderActiveDate}
          getExpireDate={getExpireDate}
          oemSkusRef={oemSkusRef}
        />
        <SkuV5
          title={<PartTitle title={t('扩展许可')} />}
          type={'oem'}
          skuType="extension"
          license={license}
          error={error.extensionSkus}
          update={updateLicense}
          clearError={() => { clearError('extensionSkus') }}
          renderInputSelectAdornment={renderInputSelectAdornment}
          renderActiveDate={renderActiveDate}
          getExpireDate={getExpireDate}
          oemSkusRef={oemSkusRef}
        />

        <Additional
          license={license}
          update={updateLicense}
          error={error}
        />
      </div>
    </>);
  }
  function renderV6() {
    return (<>
      <div className={styles['basic-info']}>
        <Basic
          license={license}
          type='oem'
          version={version}
          contractTypes={contractTypes}
          update={updateLicense}
          clearError={clearError}
          updateError={setError}
          error={error}
          getFeatures={getFeatures}
          setOriginalLicense={setOriginalLicense}
          setCurrentFeatures={setCurrentFeatures}
          originalLicense={originalLicense}
          getExpireDate={getExpireDate}
          setOemSkus={setOemSkus}
          isNewContractLicenseExist={isNewContractLicenseExist}
          setIsNewContractLicenseExist={setIsNewContractLicenseExist}
        />
        {license.enableMcm && <>
          <div className={styles['collapse-header']}>
            <span className={styles['overall-collapse-title']}>{t('全局License')}</span>
            <div className={styles['collapse-split-line']} />
          </div>
          <div className={styles['global-sku-container']}>
            <SkuV6
              title={t('基础许可')}
              classify={'XSOS-GLOBAL'}
              type='oem'
              skuType="base"
              license={license}
              error={error.baseSkus}
              update={updateLicense}
              clearError={() => { clearError('baseSkus') }}
              renderInputSelectAdornment={renderInputSelectAdornment}
              renderActiveDate={renderActiveDate}
              getExpireDate={getExpireDate}
              oemSkusRef={oemSkusRef}
            />
          </div>
        </>}
        <LicenseCluster
          dataClusterMd5={license.dataClusterMd5}
          objectServicePlatformMd5={license.objectServicePlatformMd5}
          licenseV6Clusters={license.licenseV6Cluster}
          scenes={scenes}
          license={license}
          type='oem'
          version={version}
          contractTypes={contractTypes}
          update={updateLicense}
          getExpireDate={getExpireDate}
          renderInputSelectAdornment={renderInputSelectAdornment}
          renderActiveDate={renderActiveDate}
          supportedVersionOptions={['V6']}
          error={error}
          oemSkusRef={oemSkusRef}
        />
        <div className={styles.others}>
          <Additional
            license={license}
            update={updateLicense}
            error={error}
          />
        </div>
      </div>
    </>);
  }
  return (<div className={styles.container}>
    <div className={styles['form-title']}>{t('申请许可')}</div>
    <div className={styles.content}>
      {version === 'V4' && renderV4()}
      {version === 'V5' && renderV5()}
      {version === 'V6' && renderV6()}
      <FormFooter handleCancelClick={handleCancelClick} handleOkClick={handleOkClick} disabled={loading} loading={loading} okText={t('申请')} />
    </div>
  </div>);
}

export default LicenseForm;