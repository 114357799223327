import styles from './index.module.scss';
import { RowCell } from './interface';
import t from 'locale';
import { Tooltip } from 'x-material-ui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export function showTip(title: string, colorError: boolean) {
  return (
    <>
      {title && <Tooltip title={title} placement="top" arrow>
        {<InfoOutlinedIcon className={styles.icon} fontSize='small' color={colorError ? 'error' : 'inherit'} />}
      </Tooltip>}
    </>
  );
}

export function getTable(title: string, rows: RowCell[], rowHeadNum: number = 1) {
  const headRow = rows.slice(0, rowHeadNum);
  const bodyRow = rows.slice(rowHeadNum, rows.length);
  let headList: JSX.Element[] = headRow.map((row) => (
    <tr>
      {row.rowHead?.map((cell, index) => (
        <th className={styles.cell} colSpan={row.cellSpan ? row.cellSpan[index] : 1} key={index} >{t(cell)}</th>
      ))}
    </tr>
  ));
  let bodyList: JSX.Element[] = bodyRow.map((row) => (
    <tr>
      {row.rowHead?.map((cell, index) => (
        <td className={styles.cell} rowSpan={row.cellRowSpan && row.cellRowSpan[index] ? row.cellRowSpan[index] : 1} key={index} >{t(cell)}</td>
      ))}
      {row.rowCell && row.rowCell.map((cell, index) => (
        <td className={styles['right-cell']} colSpan={row.cellSpan ? row.cellSpan[index] : 1} key={index} >{cell}</td>
      ))}
    </tr>
  ));
  return (
    <div className={styles['table-container']}>
      {!!title && <div className={styles['table-title']}>{t(title)}</div>}
      <table className={styles.table}>
        <thead>
          {headList}
        </thead>
        <tbody>
          {bodyList}
        </tbody>
      </table>
    </div>
  )
};
