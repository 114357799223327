import React, { useState } from 'react';
import styles from './index.module.scss';
import { Permission, UserPermission, Role, DetailsContainer } from 'components';
import t from 'locale';
import { Tab, Tabs } from 'x-material-ui';

type PermissionManagementTab = 'role' | 'permission' | 'userPermission';
const Permissions = () => {
  const [tab, setTab] = useState<PermissionManagementTab>('role');
  const [showRoleDrawer, setShowRoleDrawer] = useState<boolean>(false);
  const [drawerChildren, setDrawerChildren] = useState<React.ReactNode>(null);
  const handleTabChange = (e: React.ChangeEvent<{}>, value: PermissionManagementTab) => {
    setTab(value);
  }

  const handleDrawerOpen = (show: boolean, children?: React.ReactNode) => {
    setShowRoleDrawer(show);
    setDrawerChildren(children);
  }
  return (<div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.tab}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
        >
          <Tab value="role" label={t('角色管理')} />
          <Tab value="permission" label={t('权限管理')} />
          <Tab value="userPermission" label={t('权限查询')} />
        </Tabs>
      </div>
      <div className={styles['table-content']}>
        {tab === 'role' && <Role
          onOpenDrawer={handleDrawerOpen}
        />}
        {tab === 'permission' && <Permission />}
        {tab === 'userPermission' && <UserPermission />}
      </div>
    </div>
    {showRoleDrawer && (<DetailsContainer open={showRoleDrawer} setOpen={handleDrawerOpen}>
      {drawerChildren}
    </DetailsContainer>)}
  </div>);
};

export default Permissions;
