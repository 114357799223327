import { LicenseDigital, LicenseDigitalService, LicenseDigitalVersion } from 'model';
import { licenseSkuCountColumn, licenseSkuExpiredColumn, licenseSkuHddColumn, licenseSkuHostCountColumn, licenseSkuNameColumn, licenseSkuPeriodColumn, licenseSkuSignedColumn, licenseSkuSsdColumn, licenseSkuUuidColumn } from 'components';
import { InfoFieldProps, TableColumn } from 'interfaces/props';
import t from 'locale';
import React from 'react';
import { dateDisplay, licenseProductPeriodDisplay } from 'utils';
import styles from './index.module.scss';
import Collapse from '../Collapse';
import DetailBasicInfo from '../DetailBasicInfo';
import Table from '../Table';

type DigitalLicenseV5InfoProps = {
  license: LicenseDigital;
  warrantyDateConditionFields: InfoFieldProps[];
  additionalFields: InfoFieldProps[];
  renderService: (service: LicenseDigitalService, index: number, version: LicenseDigitalVersion) => React.ReactNode
}

const DigitalLicenseV5Info = (props: DigitalLicenseV5InfoProps) => {
  const { license, renderService, additionalFields, warrantyDateConditionFields } = props;
  function getColumns(type: string) {
    const columns: TableColumn[] = [
      licenseSkuUuidColumn,
      licenseSkuNameColumn,
    ];
    if (type === 'base') {
      columns.push(licenseSkuCountColumn);
      columns.push(licenseSkuHostCountColumn);
      columns.push(licenseSkuHddColumn);
      columns.push(licenseSkuSsdColumn);
    }
    columns.push(licenseSkuPeriodColumn);
    columns.push(licenseSkuSignedColumn);
    columns.push(licenseSkuExpiredColumn);
    return columns;
  }
  function getProductFields(license: LicenseDigital) {
    const fields: InfoFieldProps[] = [];
    const activeDate = license.productSku ? dateDisplay(license.activeDate) : '';
    const expireDate = license.productSku ? dateDisplay(license.expireDate) : '';
    const period = licenseProductPeriodDisplay(license);
    const productUuid = license.productSku ? license.productUuid : '';
    fields.push(
      {
        label: t('电子 license 序列号'),
        value: productUuid,
      },
      {
        label: t('软件产品'),
        value: license.productSku?.name || '',
      },
      {
        label: t('有效期'),
        value: period,
      },
      {
        label: t('签发时间'),
        value: activeDate,
      },
      {
        label: t('到期时间'),
        value: expireDate,
      },
    );
    return fields;
  }
  return (
    <div className={styles['info-content-v5']}>
      <div className={styles['title-text']}>{t('license 配置')}</div>
      <Collapse title={t('软件产品许可')} initialOpen>
        <DetailBasicInfo fields={getProductFields(license)} className={styles['info-field-container']} />
      </Collapse>
      <Collapse title={t('基础许可')} initialOpen>
        <div className={styles['table-content']}>
          <Table data={(license.skus || []).filter(i => i.type === 'base')} columns={getColumns('base')} loading={false} />
        </div>
      </Collapse>
      <Collapse title={t('扩展许可')} initialOpen>
        <div className={styles['table-content']}>
          <Table data={(license.skus || []).filter(i => i.type === 'extension')} columns={getColumns('extension')} loading={false} />
        </div>
      </Collapse>
      {
        license.scene !== 'poc' &&
        <Collapse title={t('服务')} initialOpen>
          <DetailBasicInfo
            fields={warrantyDateConditionFields}
            col={3}
            colWidth={6}
            className={styles['info-field-container']}
          />
          {(license.services.filter(s => s.name !== '0') || []).map((s, i) => renderService(s, i, license.version))}
        </Collapse>
      }
      <Collapse title={t('其他')} initialOpen>
        <DetailBasicInfo fields={additionalFields} className={styles['info-field-container']} />
      </Collapse>
    </div>
  )
}

export default DigitalLicenseV5Info;