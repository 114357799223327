import { AxiosResponse } from 'axios';
import { instance } from './instance';
import { Message } from 'model';

export async function getMessages(offset: number): Promise<AxiosResponse<[Message[], number, number]>> {
  return instance.get(`/message`, { params: { offset } });
}

export async function readAllMessages() {
  return instance.put(`/message/read-all`);
}
export async function readMessages(id: number) {
  return instance.put(`/message/read?id=${id}`);
}