import { GroupByUsageProps } from 'interfaces/props';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Tooltip } from 'x-material-ui';
import styles from './index.module.scss';
import classnames from 'classnames';

const GroupByUsage = (props: GroupByUsageProps) => {
  const hasError = props.data.find((d) => d.isError && d.value > 0);
  if (!hasError && props.healthyTitle) {
    return (
      <div className={`${styles.container} ${styles.healthy}`}>
        <CheckCircleOutlinedIcon className={styles['healthy-icon']} />
        <span>{props.healthyTitle}</span>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {props.data.map((d) => {
        return (
          <div
            key={d.title}
            className={classnames(styles.content, styles[d.status], {
              [styles.zero]: d.value === 0,
              [styles['content-clickable']]: !!props.handleDataClick,
            })}
          >
            <div className={styles['left-line']}></div>
            <div className={styles['right-content']}>
              <span className={styles['content-title']}>{d.title}</span>
              {props.toolTip ?
                <Tooltip title={props.toolTip} placement="top" arrow>
                  <span className={styles['content-value']} onClick={() => props.handleDataClick?.(d.gteSize, d.ltSize)}>{d.value}</span>
                </Tooltip> :
                <span className={styles['content-value']} onClick={() => props.handleDataClick?.(d.gteSize, d.ltSize)}>{d.value}</span>}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default GroupByUsage;
