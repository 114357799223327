import { AxiosResponse } from 'axios';
import { TABLE_DEFAULT_PAGE_SIZE, TOKEN_NAME } from 'constant';
import { LoginReq } from 'interfaces/models';
import { UserGroup } from 'interfaces/props';
import { Permission, Role, Token, User } from 'model';
import { getCookie } from 'utils';
import { instance, maybeErrorInstance } from './instance';

export async function login(param: LoginReq): Promise<AxiosResponse<Token>> {
  return maybeErrorInstance.post('/user/login', param);
}

export async function loginByToken(token: string): Promise<AxiosResponse<Token>> {
  return maybeErrorInstance.post('/user/login-by-token', { token });
}

export async function logout() {
  return instance.post(`/user/logout`);
}
export async function checkPassword(param: LoginReq) {
  return maybeErrorInstance.post(`/user/check-password`, param);
}
export async function sendEmail(mail: string, type: string) {
  return instance.post(`/user/send-email`, { mail, type });
}
export async function bindEmail(mail: string, mailCode: string) {
  return instance.post(`/user/bind-email`, { mail, mailCode });
}
export async function updateEmail(mail: string, mailCode: string, oldMail: string, oldMailCode: string) {
  return instance.post(`/user/update-email`, { mail, mailCode, oldMail, oldMailCode });
}
export async function updatePassword(password: string, newPassword: string) {
  return instance.post(`/user/update-password`, { password, newPassword });
}

export async function refreshToken(token: string, lastActionTime: number) {
  return instance.patch(`/token/${token}`, { lastActionTime });
}

export async function getUserPermission() {
  const token = getCookie(TOKEN_NAME) || 'e0225fe2-313c-4385-95e6-0857cd4f317f';
  return instance.get(`/token/${token}`);
}

export async function getUser(id: number): Promise<AxiosResponse<User>> {
  return instance.get(`/user/${id}`);
}
export async function getPermissions(limit: number = TABLE_DEFAULT_PAGE_SIZE, offset: number = 0, search: string = ''): Promise<AxiosResponse<[Permission[], number]>> {
  return instance.get(`/permission/list?limit=${limit}&offset=${offset}&search=${search}`);
}
export async function getRolesByPermissionsCode(code: string = ''): Promise<AxiosResponse<Role[]>> {
  return instance.get(`/role/get-roles-by-permissions-code?code=${code}`)
}
export async function getUserByPermissionsCode(code: string = ''): Promise<AxiosResponse<User[]>> {
  return instance.get(`user/get-users-by-permissions-code?code=${code}`)
}
export async function getUserPermissionsByMail(mail: string = ''): Promise<AxiosResponse<Permission[]>> {
  return instance.get(`/permission/get-user-permissions-by-mail?mail=${mail}`)
}
export async function getUserRolesByMail(mail: string = ''): Promise<AxiosResponse<Role[]>> {
  return instance.get(`/role/get-user-roles-by-mail?mail=${mail}`)
}
export async function queryUser(name: string): Promise<AxiosResponse<User[]>> {
  return instance.get(`user/query-user?name=${name}`);
}
export async function getRoles(limit: number = TABLE_DEFAULT_PAGE_SIZE, offset: number = 0, search: string = ''): Promise<AxiosResponse<[Role[], number]>> {
  return instance.get(`/role/list?limit=${limit}&offset=${offset}&search=${search}`)
}
export async function createRole(param: { name: string, desc: string }) {
  return instance.post('/role/create', param);
}

export async function updateRole(id: number, param: Partial<Role>) {
  return instance.put(`/role/update/${id}`, param);
}

export async function removeRole(id: number) {
  return instance.delete(`/role/remove/${id}`);
}

export async function updateRolePermission(roleId: number, permissions: number[]) {
  return instance.put(`/role/update-permission/${roleId}`, { permissions });
}

export async function addUserToRole(roleId: number, users: UserGroup[]) {
  return instance.post(`/role/add-user/${roleId}`, { users });
}

export async function removeUserFromRole(roleId: number, users: UserGroup[]) {
  return instance.post(`/role/remove-user/${roleId}`, { users });
}