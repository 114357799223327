import {
  basePerformance,
  calculateCapacity,
  calculateMemory,
  cpuPerformance,
  networkPerformance,
  objectPerformance,
} from './calculation';
import { getTable } from './common';
import styles from './index.module.scss';
import { ResultProps, DictOption, RowCell } from './interface';
import t from 'locale';
import { min } from 'lodash';
import moment from 'moment';

const PerformResult = (props: ResultProps) => {
  const configDict = props.configDict;
  const dataDict = props.dataDict;
  const product = configDict.product;
  const isInit = !props.configDict.product;
  const notDataPool = configDict.pool !== 'data';
  const xgfsFlag = product === 'XGFS';

  let capacityDict: DictOption = {};
  let memoryDict: DictOption = {};
  let networkTab;
  let performanceTab;
  let memoryRecomTable: RowCell[] = [];
  if (!isInit) {
    const networkBand = networkPerformance(configDict, dataDict);
    capacityDict = calculateCapacity(configDict, dataDict);
    memoryDict = calculateMemory(configDict, dataDict);
    if (configDict.product === 'XEOS') {
      const objectDict = objectPerformance(configDict, dataDict);
      performanceTab = getTable('对象存储性能', [
        { rowHead: ['', '4K', '32K', '4M(MB/s)'], cellSpan: [1, 2, 2, 2] },
        { rowHead: ['', '写OPS', '读OPS', '写OPS', '读OPS', '写带宽', '读带宽'] },
        {
          rowHead: ['集群性能'], rowCell: [
            objectDict.write4k, objectDict.read4k,
            objectDict.write32k, objectDict.read32k,
            min([networkBand, objectDict.write4m]), min([networkBand, objectDict.read4m]),
          ]
        }
      ], 2);
    } else {
      const cpuDict = cpuPerformance(configDict, dataDict);
      const baseDict = basePerformance(configDict, dataDict);
      let baseTile: string;
      let baseRows: RowCell[];
      if (xgfsFlag) {
        baseTile = `XGFS文件存储性能`;
        baseRows = [
          { rowHead: ['', '大文件4K/8K', '大文件1M带宽', '4K小文件OPS'], cellSpan: [1, 2, 2, 2] },
          { rowHead: ['', '写IOPS', '读IOPS', '写MB/s', '读MB/s', '写OPS', '读OPS'] },
        ];
      } else {
        baseTile = '块存储性能';
        baseRows = [
          { rowHead: ['', '4K', '1M(MB/s)'], cellSpan: [1, 2, 2] },
          { rowHead: ['', '写IOPS', '读IOPS', '写带宽', '读带宽'] },
        ];
      }
      if (notDataPool) {
        if (xgfsFlag) {
          const nfsData = baseDict.nfs;
          baseRows.push({
            rowHead: ['NFS'], rowCell: [
              min([cpuDict.write, nfsData.writeIops]), min([cpuDict.read, nfsData.readIops]),
              min([networkBand, nfsData.writeBand, nfsData.dataWriteBand]), min([networkBand, nfsData.readBand, nfsData.dataReadBand]),
              nfsData.writeOps, nfsData.readOps
            ]
          });
          if (configDict.version !== 'v5_1') {
            const smbData = baseDict.smb;
            baseRows.push({
              rowHead: ['SMB'], rowCell: [
                min([cpuDict.write, smbData.writeIops]), min([cpuDict.read, smbData.readIops]),
                min([networkBand, smbData.writeBand, smbData.dataWriteBand]), min([networkBand, smbData.readBand, smbData.dataReadBand]),
                smbData.writeOps, smbData.readOps
              ]
            });
          }
        } else {
          baseRows.push({
            rowHead: ['存储池'], rowCell: [
              min([cpuDict.write, baseDict.cacheWriteIops]), min([cpuDict.read, baseDict.cacheReadIops]),
              min([networkBand, baseDict.cacheWriteBand, baseDict.dataWriteBand]), min([networkBand, baseDict.cacheReadBand, baseDict.dataReadBand])
            ]
          });
        }
      } else {
        if (!xgfsFlag) {
          baseRows.push({
            rowHead: ['存储池'], rowCell: [
              min([cpuDict.write, baseDict.dataWriteIops]), min([cpuDict.read, baseDict.dataReadIops]),
              min([networkBand, baseDict.dataWriteBand]), min([networkBand, baseDict.dataReadBand])
            ]
          });
        } else {
          const nfsData = baseDict.nfs;
          baseRows.push({
            rowHead: ['NFS'], rowCell: [
              min([cpuDict.write, nfsData.writeIops]), min([cpuDict.read, nfsData.readIops]),
              min([networkBand, nfsData.writeBand]), min([networkBand, nfsData.readBand]),
              nfsData.writeOps, nfsData.readOps
            ]
          });
          if (configDict.version !== 'v5_1') {
            const smbData = baseDict.smb;
            baseRows.push({
              rowHead: ['SMB'], rowCell: [
                min([cpuDict.write, smbData.writeIops]), min([cpuDict.read, smbData.readIops]),
                min([networkBand, smbData.writeBand]), min([networkBand, smbData.readBand]),
                smbData.writeOps, smbData.readOps
              ]
            });
          }
        }
      }
      performanceTab = getTable(baseTile, baseRows, 2);
    }
    memoryRecomTable.push({ rowHead: ['', `管理节点`, `非管理节点`] });
    if (product === 'XEBS') {
      memoryRecomTable.push({
        rowHead: ['节点'], rowCell: [
          `${memoryDict.recomManager}（${memoryDict.managerMem}）`,
          `${memoryDict.recomNotManager}（${memoryDict.notManagerMem}）`,
        ]
      });
    } else if (product === 'XEOS') {
      memoryRecomTable.push({
        rowHead: [`节点`], rowCell: [
          `${memoryDict.recomManager}（${memoryDict.managerMem}）`,
          `${memoryDict.recomNotManager}（${memoryDict.notManagerMem}）`,
        ]
      });
    } else if (product === 'XGFS') {
      memoryRecomTable = memoryRecomTable.concat([
        {
          rowHead: [`元数据节点`], rowCell: [
            `${memoryDict.recomMetaManager}（${memoryDict.metaManagerMem}）`,
            `${memoryDict.recomMetaNotManager}（${memoryDict.metaNotManagerMem}）`,
          ]
        },
        {
          rowHead: [`数据节点`], rowCell: [
            `${memoryDict.recomDataManager}（${memoryDict.dataManagerMem}）`,
            `${memoryDict.recomDataNotManager}（${memoryDict.dataNotManagerMem}）`,
          ]
        }
      ]);
    }
  }
  const date = moment().format('HH:mm:ss');

  return (
    <div className={styles['right-content']}>
      <div className={styles['right-top']}>
        {t('配置方案输出（仅供参考）v{{date}}', { date })}
      </div>
      <div className={styles['output-container']} >
        {isInit && <div className={styles['output-content']}>
          <span className={styles.tip}>{t('暂无相关配置方案')}</span>
        </div>}

        {!isInit && <div className={styles['table-container']}>
          {getTable('容量预估（TiB）', [{ rowHead: ['裸容量', '有效容量', '可用容量(' + capacityDict.ratio + ')'] }
            , { rowCell: [capacityDict.totalTiB, capacityDict.actualTotalTiB, capacityDict.actualTiB] }])}
          {getTable('节点内存推荐（GB）', memoryRecomTable)}
          {networkTab}
          {performanceTab}
        </div>}
      </div>
    </div>
  );
}

export default PerformResult;
