import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import classNames from 'classnames';
import { Dropdown } from 'components';
import { TABLE_SEARCH_PLACEHOLDER } from 'constant';
import { ToolbarProps } from 'interfaces/props';
import t from 'locale';
import React from 'react';
import { Button, InputAdornment, TextField, Tooltip } from 'x-material-ui';
import styles from './index.module.scss';

const EnhancedTableToolbar = (props: ToolbarProps) => {
  const { items } = props;
  const handleSearchInputKeydown = (
    onSearch: ToolbarProps['items'][0]['onSearch'],
    e: React.KeyboardEvent
  ) => {
    if (e.key === 'Enter') {
      onSearch && onSearch();
    }
  };
  const handleSearchClick = (onSearch: ToolbarProps['items'][0]['onSearch']) => {
    onSearch && onSearch();
  };
  return (
    <div className={classNames(styles.toolbar, props.className || '')}>
      {items.map((item, i) => {
        switch (item.type) {
          case 'button':
            return (
              <div className={styles.fields} key={item.type + i}>
                <Button
                  onClick={item.onClick}
                  disabled={item.disabled}
                  size='medium'
                  classes={{ root: styles.button }}
                  variant='contained'
                >
                  {item.children}
                </Button>
              </div>
            );
          case 'dropdown':
            return <div className={styles.fields} key={item.type + i}>
              <Dropdown name={item.name || ''} menus={item.menus} />
            </div>;
          case 'search':
            let searchPlaceholder = '';
            if (item.tableName) {
              searchPlaceholder = TABLE_SEARCH_PLACEHOLDER[item.tableName] || '';
            }
            return (
              <div className={styles.fields} key={item.type + i}>
                <Tooltip arrow placement="top" title={searchPlaceholder || t('请输入内容')}>
                  <div>
                    <TextField
                      classes={{ root: styles.search }}
                      id="input-with-icon-textfield"
                      placeholder={searchPlaceholder || t('请输入内容')}
                      variant="outlined"
                      value={item.children}
                      onChange={item.onChange}
                      onKeyDown={handleSearchInputKeydown.bind(null, item.onSearch)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className={styles['search-icon-container']} position="start" onClick={handleSearchClick.bind(null, item.onSearch)}>
                            <SearchOutlinedIcon className={styles['search-icon']} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default EnhancedTableToolbar;
