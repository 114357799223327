import React, { useState } from 'react';
import { PartCollapseListProps, PartCollapseContentProps } from 'interfaces/props';
import styles from './index.module.scss';
import PartCollapse from '../PartCollapse';
import lodash from 'lodash';
import { Grid, Status } from 'x-material-ui';
import InfoField from '../InfoField';

const PartCollapseList = (props: PartCollapseListProps) => {
  return (<div className={styles.container}>{props.data.map(d => {
    return (<div key={d.id} className={styles.content}>
      <div className={styles['content-left']}>
        <div className={styles['left-line']} />
        <div className={styles['circle-outer']}><div className={styles['circle-inner']} /></div>
      </div>
      <div className={styles['content-right']}>
        <div className={styles.title}>
          <span className={styles['title-text']}>{d.title}</span>
          {d.status && <Status status={d.status} text={d.statusText || ''} />}
        </div>
        <Content {...d} />
      </div>
    </div>)
  })}</div>);
}

const Content = (props: PartCollapseContentProps) => {
  const [open, setOpen] = useState(true);
  function toggleOpen() {
    setOpen(!open);
  }
  const layouts = lodash.chunk(props.fields, 3);
  if (!open) {
    layouts.splice(1, layouts.length - 1);
  }
  if (!props.fields.length && !props.separateFields?.length) {
    return null;
  }
  return (<div className={styles.card}>
    <PartCollapse open={open} setOpen={toggleOpen}>
      {layouts.map((line, i) => {
        return (<Grid className={i === 0 ? styles['card-first-line'] : ''} key={`row-${i}`} container item xs={12}>
          {line.map((col, i) => {
            return <Grid item xs={4} key={`col-${i}`}>
              <InfoField
                label={col.label}
                value={col.value}
              />
            </Grid>
          })}
        </Grid>)
      })}
      {open && props.separateFields?.map((field, index) => (
        <div key={index}>
          <InfoField
            label={field.label}
            value={field.value}
          /></div>))}
    </PartCollapse>
  </div>);
}

export default PartCollapseList;