import { PercentConvertOptions } from './interface';

export function getPercent(total: number, current: number, options: PercentConvertOptions = {}) {
  let percent: string | number = options.defaultPercent || 0;
  if (total !== 0) {
    percent = (current * 100) / total;
    let abs = Math.abs(percent);
    if (abs === 0) {
    } else if (abs < 0.001) {
      abs = 0.001;
    } else if (abs < 1) {
      abs = parseFloat(abs.toFixed(3));
    } else {
      abs = parseFloat(abs.toFixed(2));
    }
    if (percent < 0) {
      percent = -abs;
    } else {
      percent = abs;
    }
  }
  if (!options.needNumber) {
    percent = percent + '%';
  }
  return percent;
}
export function toChineseNum(num: number) {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  let unit = ['', '十', '百', '千', '万'];
  let getWan = (temp: number, overWan?: boolean) => {
    let strArr = temp.toString().split('').reverse();
    let newNum = '';
    for (var i = 0; i < strArr.length; i++) {
      if (strArr.length === 1 && Number(strArr[i]) == 0) {
        newNum = '零';
      } else if (strArr.length <= 2 && changeNum[Number(strArr[i])] === '一' && unit[i] === '十') {
        newNum = (overWan ? changeNum[Number(strArr[i])] + unit[i] : unit[i]) + newNum;
      } else {
        newNum = changeNum[Number(strArr[i])] + (Number(strArr[i]) == 0 ? unit[0] : unit[i]) + newNum;
      }
    }
    return newNum;
  }
  let overWan = Math.floor(num / 10000);
  let noWan = (num % 10000).toString();
  const result = overWan ? getWan(overWan) + "万" + (noWan !== '0' ? (noWan.length <= 4 ? '零' : '') + getWan(Number(noWan), true) : '') : getWan(num);
  return result.length < 2 ? result : result.replace(/零(?=零)|零$|零(?=万)/g, '');
}