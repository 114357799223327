import { Contract, LicenseDigital, LicenseDigitalService, LicenseDigitalVersion } from 'model';
import { DATE_FORMAT } from 'common';
import { getHardwareWarrantyDateConditions, getSoftwareWarrantyDateConditions } from 'common/src/contract';
import {
  getDigitalLicenseScene,
  getDigitalLicenseStatus,
  getLicenseContractType,
  getLicensePeriodUnit,
  licensePeriodDisplay
} from 'common/src/license';
import { DetailsBasicFieldProps, DigitalLicenseDetailProps, InfoFieldProps } from 'interfaces/props';
import t from 'locale';
import lodash from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getContractByNo, getDigitalLicense } from 'request';
import { dateDisplay, getLicenseServiceNames } from 'utils';
import styles from './index.module.scss';
import DigitalLicenseV4Info from './licenseV4Info';
import DigitalLicenseV5Info from './licenseV5Info';
import DigitalLicenseV6Info from './licenseV6Info';
import DetailBasicInfo from '../DetailBasicInfo';
import DetailHeaderInfo from '../DetailHeaderInfo';
import Loading from '../Loading';

const DigitalLicenseDetail = (props: DigitalLicenseDetailProps) => {
  const [license, setLicense] = useState<LicenseDigital>();
  const [contract, setContract] = useState<Contract>();
  useEffect(() => {
    init();
  }, []);
  async function init() {
    if (props.id) {
      const response = await getDigitalLicense(props.id, props.viewType);
      setLicense(response.data);
      if (response.data.type === 'oem' && response.data.contractNo) {
        const contractResponse = await getContractByNo(response.data.contractNo);
        const result = contractResponse.data;
        setContract(result);
      }
    }
  }
  if (!license) {
    return <Loading />;
  }
  function getServiceDisplayInfo(service: LicenseDigitalService, version: LicenseDigitalVersion) {
    return {
      name: getLicenseServiceNames(version)[service.name],
      period: t('{{period}} {{unit}}', { period: service.period, unit: getLicensePeriodUnit(service.unit, t) }),
      activeDate: moment(service.activeDate).format(DATE_FORMAT),
      expireDate: moment(service.expireDate).format(DATE_FORMAT),
      uuid: service?.uuid,
    }
  }
  function getHeaderFields(license: LicenseDigital) {
    const applyDate = dateDisplay(license.applyDate);
    let activeDate = dateDisplay(license.activeDate);
    if (license.version === 'V5' && lodash.isEmpty(license.productSku)) {
      activeDate = '';
    }
    const period = licensePeriodDisplay(license, t);
    const fields: DetailsBasicFieldProps[] = [
      {
        label: t('申请时间'),
        text: applyDate,
      },
      {
        label: t('使用场景'),
        text: getDigitalLicenseScene(license.scene, t),
      },
      {
        label: t('合同类型'),
        text: getLicenseContractType(license.contractType, t),
      },
      {
        label: t('是否为延期'),
        text: license.isDelay ? t('是') : t('否'),
      },
      {
        label: t('集群名称'),
        text: license.clusterName || '',
      },
      {
        label: t('集群 ID'),
        text: license.cluster?.fsId || license.clusterMd5 || '',
      },
      {
        label: t('支持产品版本范围'),
        text: license.supportVersions.join(','),
      },
      {
        label: t('license 激活日期'),
        text: activeDate,
      },
      {
        label: t('产品有效期'),
        text: period,
      }
    ];
    const render: DetailsBasicFieldProps['render'][] = [];
    fields.forEach((field, i) => {
      field.render = render[i];
    });
    if (license.status === 'cancelled') {
      fields.push({
        label: t('作废原因'),
        text: license.cancelReason,
      });
    }
    return fields;
  }
  function getOpportunityFields(license: LicenseDigital) {
    const fields: InfoFieldProps[] = [];
    const customerName = license.customerName;

    fields.push({
      label: t('客户名称'),
      value: customerName,
    },
      {
        label: t('客户联系人'),
        value: license.customerContact,
      });

    return fields;
  }
  function renderService(service: LicenseDigitalService, index: number, version: LicenseDigitalVersion) {
    const fields: InfoFieldProps[] = [];
    const serviceDisplay = getServiceDisplayInfo(service, version);
    fields.push(
      {
        label: t('服务名称'),
        value: serviceDisplay.name,
      },
      {
        label: t('有效期'),
        value: serviceDisplay.period,
      },
      {
        label: t('签发时间'),
        value: serviceDisplay.activeDate,
      },
      {
        label: t('到期时间'),
        value: serviceDisplay.expireDate,
      },
    );
    if (license?.version === 'V5') {
      fields.unshift({
        label: t('电子 license 序列号'),
        value: serviceDisplay.uuid,
      });
    }
    return license?.version === 'V4' ? (
      <DetailBasicInfo
        fields={fields}
        col={2}
        colWidth={6}
        className={styles['info-field-container']}
        key={index}
      />) : (
      <DetailBasicInfo
        fields={fields}
        className={styles['info-field-container']}
        key={index}
      />);
  }
  const WarrantyDateConditionFields: InfoFieldProps[] = [
    {
      label: t('软件质保起算时间'),
      value: contract?.softwareWarrantyDateCondition !== 'other' ? <div>{contract?.softwareWarrantyDateCondition ? -getSoftwareWarrantyDateConditions(contract?.softwareWarrantyDateCondition, t) : ''}</div> :
        <div>{contract?.softwareWarrantyDateOtherCondition}</div>,
    },
    {
      label: t('硬件质保起算时间'),
      value: contract?.hardwareWarrantyDateCondition !== 'other' ? <div>{contract?.hardwareWarrantyDateCondition ? -getHardwareWarrantyDateConditions(contract.hardwareWarrantyDateCondition, t) : ''}</div> :
        <div>{contract?.hardwareWarrantyDateOtherCondition}</div>,
    }
  ];
  const additionalFields: InfoFieldProps[] = [
    {
      label: t('申请内容描述'),
      value: license?.applyReason || '',
    },
  ];
  return (<div className={styles.container}>
    <DetailHeaderInfo
      title={license.serialNo}
      fields={getHeaderFields(license)}
      status={getDigitalLicenseStatus(license.status, t).color}
      statusText={getDigitalLicenseStatus(license.status, t).text}
    />
    <div className={styles.content}>
      {license.version === 'V4' && <DigitalLicenseV4Info license={license} warrantyDateConditionFields={WarrantyDateConditionFields} additionalFields={additionalFields} renderService={renderService} />}
      {license.version === 'V5' && <DigitalLicenseV5Info license={license} warrantyDateConditionFields={WarrantyDateConditionFields} additionalFields={additionalFields} renderService={renderService} />}
      {license.version === 'V6' && <DigitalLicenseV6Info license={license} warrantyDateConditionFields={WarrantyDateConditionFields} additionalFields={additionalFields} renderService={renderService} opportunityFields={getOpportunityFields(license)} />}
    </div>
  </div>);
}

export default DigitalLicenseDetail;