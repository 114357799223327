import classnames from 'classnames';
import { DATE_FORMAT, NA, formatDateOnly, formatDateTime } from 'common';
import { convertBytes1000 } from 'common/src/capacity';
import { getDigitalLicenseStatus, getProductName, getSkuLimit } from 'common/src/license';
import dayjs, { QUnitType } from 'dayjs';
import { TableColumn } from 'interfaces/props';
import t from 'locale';
import lodash from 'lodash';
import { LicenseDigital, LicenseSku, SdsV5BaseLicense, SdsV5CommonLicense } from 'model';
import { dateDisplay, getSkuName, licenseProductPeriodDisplay } from 'utils';
import { Status } from 'x-material-ui';
import styles from './index.module.scss';

export const digitalLicenseSerialNoColumn: (onClick: (record: LicenseDigital) => void) => TableColumn<LicenseDigital> = onClick => ({
  title: t('电子 license 序列号'),
  dataKey: 'serialNo',
  template: record => {
    return <span className="link-without-decoration" onClick={() => onClick(record)}>{record?.serialNo || ''}</span>
  }
})

export const digitalLicensePermitTypeColumn: TableColumn<LicenseDigital> = {
  title: t('许可类型'),
  dataKey: 'permitType',
  template: record => {
    return record.scene === 'poc' ? 'POC' : t('交付');
  }
}
export const digitalLicenseProductNameColumn: TableColumn<LicenseDigital> = {
  title: t('产品名称'),
  dataKey: 'productInfo',
  template: record => {
    if (record.version === 'V4') {
      return getProductName(record.productInfo);
    } else
      return record.productSku?.name || '';
  }
}
export const digitalLicenseVersionColumn: TableColumn<LicenseDigital> = {
  title: t('版本'),
  dataKey: 'version',
}
export const digitalLicenseActiveDateColumn: TableColumn<LicenseDigital> = {
  title: t('签发时间'),
  dataKey: 'activeDate',
  template: record => {
    return formatDateOnly(record.activeDate);
  }
}
export const digitalLicenseExpiredDateColumn: TableColumn<LicenseDigital> = {
  title: t('到期时间'),
  dataKey: 'expiredDate',
  template: record => {
    if (record.scene === 'poc') {
      return formatDateOnly(record.expireDate);
    }
    return t('永久');
  }
}
export const digitalLicenseClusterNameColumn: TableColumn<LicenseDigital> = {
  title: t('集群名称'),
  dataKey: 'clusterName',
}
export const digitalLicenseClusterFsIdColumn: TableColumn<LicenseDigital> = {
  title: t('集群 ID'),
  dataKey: 'fsId',
  template: record => {
    return record.cluster?.fsId || record.clusterMd5 || '';
  }
}
export const digitalLicenseStatusColumn: TableColumn<LicenseDigital> = {
  title: t('状态'),
  dataKey: 'status',
  template: (record: LicenseDigital) => {
    return <span><Status status={getDigitalLicenseStatus(record.status, t).color} text={getDigitalLicenseStatus(record.status, t).text} /></span>
  }
}
export const digitalLicenseCustomerNameColumn: TableColumn<LicenseDigital> = {
  title: t('客户名称'),
  dataKey: 'customerName',
}
export const digitalLicensApplyDateColumn: TableColumn<LicenseDigital> = {
  title: t('申请时间'),
  dataKey: 'applyDate',
  template: record => {
    return formatDateOnly(record.applyDate);
  }
}
export const digitalLicensApplicantColumn: TableColumn<LicenseDigital> = {
  title: t('申请人'),
  dataKey: 'applicant',
  template: record => {
    if (record.source === 'xboc') {
      return 'XSKY';
    } else {
      return record.applicant;
    }
  }
}

export const digitalLicenseProductColumn: TableColumn<LicenseDigital> = {
  title: t('产品'),
  dataKey: 'productUuid',
  template: record => {
    if (record.version === 'V4') {
      return `${record?.productInfo?.series || ''}-${record?.features?.join(',')}`;
    } else {
      return record.productSku?.name;
    }
  }
}

export const licenseSkuCountColumn: TableColumn = {
  title: t('数量'),
  dataKey: 'count',
}

export const licenseSkuLimitColumn: TableColumn = {
  title: t('配额'),
  dataKey: 'limits',
  template: (record: LicenseSku) => {
    const limit = record.sku?.extensions?.map(e => getSkuLimit(e, t)) || [];
    return lodash.compact(limit).join('，');
  }
}
export const licenseSkuDisableSignedColumn: TableColumn = {
  title: t('签发时间'),
  dataKey: 'signedDate',
  template: (record: LicenseSku) => {
    return dateDisplay(record.signedDate);
  }
};
export const licenseSkuEditableCountColumn: (render: (record: LicenseSku) => React.ReactNode) => TableColumn = render => ({
  title: t('数量'),
  dataKey: 'count',
  template: render
});

export const licenseSkuEditableNameColumn: (render: (record: LicenseSku, i: number) => React.ReactNode) => TableColumn = render => ({
  title: t('SKU 名称'),
  dataKey: 'name',
  template: (record, i) => render(record, i)
});

export const licenseSkuEditablePeriodColumn: (render: (record: LicenseSku) => React.ReactNode) => TableColumn = render => ({
  title: t('有效期'),
  dataKey: 'period',
  template: render
});

export const licenseSkuExpiredColumn: TableColumn = {
  title: t('到期时间'),
  dataKey: 'expiredDate',
  template: (record: LicenseSku) => {
    const diffYear = dayjs(record.expiredDate).diff(record.signedDate, 'years');
    if (diffYear >= 1000) {
      return NA;
    }
    return record.expiredDate ? dayjs(record.expiredDate).format(DATE_FORMAT) : '';
  }
};

export const licenseSkuHddColumn: TableColumn = {
  title: t('HDD 容量配额'),
  dataKey: 'maxHddCapacityTb',
  template: (record: LicenseSku) => {
    let capacity = 0;
    record.sku?.extensions?.forEach(e => {
      if (e.type === 'limit') {
        capacity += e.limits.maxHddCapacityTb || 0;
      }
    });
    return capacity ? `${capacity} TB` : 0;
  }
};

export const licenseSkuSsdColumn: TableColumn = {
  title: t('SSD 容量配额'),
  dataKey: 'maxSsdCapacityTb',
  template: (record: LicenseSku) => {
    let capacity = 0;
    record.sku?.extensions?.forEach(e => {
      if (e.type === 'limit') {
        capacity += e.limits.maxSsdCapacityTb || 0;
      }
    });
    return capacity ? `${capacity} TB` : 0;
  }
};
export const licenseSkuHostCountColumn: TableColumn = {
  title: t('节点配额'),
  dataKey: 'maxHostNum',
  template: (record: LicenseSku) => {
    let hostNum = 0;
    record.sku?.extensions?.forEach(e => {
      if (e.type === 'limit') {
        hostNum += e.limits.maxHostNum || 0;
      }
    });
    return hostNum || NA;
  }
};

export const licenseSkuNameColumn = {
  title: t('产品'),
  dataKey: 'name',
  template: (record: LicenseSku) => {
    return record.sku ? getSkuName(record.sku) : '';
  }
};
export const licenseSkuOperateColumn: (render: (record: LicenseSku) => React.ReactNode) => TableColumn = render => ({
  title: t('操作'),
  dataKey: 'operate',
  template: render
});

export const licenseSkuPeriodColumn = {
  title: t('有效期'),
  dataKey: 'period',
  template: (record: LicenseSku) => {
    return getPeriodDisplay(record.signedDate, record.expiredDate);
  }
};

export const licenseSkuSignedColumn: TableColumn = {
  title: t('签发时间'),
  dataKey: 'signedDate',
  template: (record: LicenseSku) => {
    return record.signedDate ? dayjs(record.signedDate).format(DATE_FORMAT) : '';
  }
};

export const licenseSkuTableModelNameColumn: TableColumn = {
  title: t('SKU 名称'),
  dataKey: 'name',
  template: getSkuName
};


export const licenseProductSkuTableModelActiveDate: TableColumn<LicenseDigital> = {
  title: t('签发时间'),
  dataKey: 'activeDate',
  template: record => record.productSku ? dateDisplay(record.activeDate) : '',
}

export const licenseProductSkuTableModelExpireDate: TableColumn<LicenseDigital> = {
  title: t('到期时间'),
  dataKey: 'expireDate',
  template: record => record.productSku ? dateDisplay(record.expireDate) : '',
}

export const licenseProductSkuTableModelNameColumn: TableColumn<LicenseDigital> = {
  title: t('软件产品'),
  dataKey: 'productSku.name',
  template: record => record.productSku ? getSkuName(record.productSku) : '',
};

export const licenseProductSkuTableModelPeriodColumn: TableColumn<LicenseDigital> = {
  title: t('有效期'),
  dataKey: 'period',
  template: licenseProductPeriodDisplay,
}
export const sdsLicenseNoColumn: TableColumn<SdsV5CommonLicense> = {
  title: t('序列号'),
  dataKey: 'uuid'
}

export const sdsLicenseNameColumn: TableColumn<SdsV5CommonLicense> = {
  title: t('许可名称'),
  dataKey: 'name_cn'
}

function getStatusDisplay(expiredTime: string, active: boolean) {
  let status = '';
  let text = '';
  if (!active) {
    status = 'expired';
    text = t('已失效');
  } else if (dayjs(expiredTime).isBefore(dayjs())) {
    status = 'expired';
    text = t('已过期');
  } else if (dayjs(expiredTime).isBefore(dayjs().add(1, 'months'))) {
    status = 'expiring';
    text = t('即将过期');
  } else {
    status = 'active';
    text = t('生效中');
  }
  return <Status text={text} status={status} />;
}

export const sdsLicenseStatusColumn: TableColumn<SdsV5CommonLicense> = {
  title: t('许可状态'),
  dataKey: 'status',
  template: record => {
    return getStatusDisplay(record.expired_time, true);
  }
}

function getPeriodDisplay(signedTime: string, expiredTime: string) {
  const diffYear = dayjs(expiredTime).diff(signedTime, 'years');
  if (diffYear >= 1000) {
    return t('永久');
  }
  const units: { [key: string]: string } = {
    years: t('年'),
    months: t('个月'),
    days: t('天'),
    hours: t('小时'),
    minutes: t('分钟'),
    seconds: t('秒'),
  }
  for (const u in units) {
    const diff = dayjs(expiredTime).diff(signedTime, u as QUnitType);
    if (diff) {
      return t('{{diff}} {{unit}}', { diff, unit: units[u] });
    }
  }
}

export const sdsLicensePeriodColumn: TableColumn<SdsV5CommonLicense> = {
  title: t('使用期限'),
  dataKey: 'period',
  template: record => {
    return getPeriodDisplay(record.signed_time, record.expired_time);
  }
}

export const sdsLicenseSignedTime: TableColumn<SdsV5CommonLicense> = {
  title: t('签发时间'),
  dataKey: 'signed_time',
  template: record => {
    return formatDateTime(record.signed_time);
  }
}

export const sdsLicenseExpireTime: TableColumn<SdsV5CommonLicense> = {
  title: t('到期时间'),
  dataKey: 'expired_time',
  template: record => {
    const diffYear = dayjs(record.expired_time).diff(record.signed_time, 'years');
    if (diffYear >= 1000) {
      return NA;
    }
    return formatDateTime(record.expired_time);
  }
}

function analyzeCapacityQuota(capacity: string) {
  const [c, u] = capacity.split(' ');
  if (capacity && u) {
    const { value, unit } = convertBytes1000(parseInt(c, 10), { baseUnit: u, needUnit: true }) as any;
    return `${value} ${unit}`;
  }
}

export const sdsLicenseHddQuotaColumn: TableColumn<SdsV5BaseLicense> = {
  title: t('HDD 容量配额'),
  dataKey: 'max_hdd_capacity',
  template: record => {
    if (record.limits.max_hdd_capacity) {
      return analyzeCapacityQuota(record.limits.max_hdd_capacity);
    }
  }
}

export const sdsLicenseSsdQuotaColumn: TableColumn<SdsV5BaseLicense> = {
  title: t('SSD 容量配额'),
  dataKey: 'max_ssd_capacity',
  template: record => {
    if (record.limits.max_ssd_capacity) {
      return analyzeCapacityQuota(record.limits.max_ssd_capacity);
    }
  }
}

export const sdsLicenseHostNumQuotaColumn: TableColumn<SdsV5BaseLicense> = {
  title: t('节点配额'),
  dataKey: 'max_host_num',
  template: record => {
    return record.limits.max_host_num || NA;
  }
}

export const sdsV4LicenseNameColumn: TableColumn<LicenseDigital> = {
  title: t('产品名称'),
  dataKey: 'productInfo',
  template: record => {
    return record.productInfo?.series;
  }
}

export const sdsV4LicenseCapacityColumn: TableColumn<LicenseDigital> = {
  title: t('购买容量'),
  dataKey: 'limits.max_capacity',
  template: record => {
    if (record.limits?.max_capacity) {
      return `${record.limits?.max_capacity} TB`;
    }
    return t('无限制');
  }
}

export const sdsV4LicenseHostNumColumn: TableColumn<LicenseDigital> = {
  title: t('服务器数(台)'),
  dataKey: 'limits.max_capacity',
  template: record => {
    if (record.limits?.max_host_num) {
      return record.limits?.max_host_num;
    }
    return t('无限制');
  }
}

export const sdsV4LicenseCapacityPerHostColumn: TableColumn<LicenseDigital> = {
  title: t('单节点容量'),
  dataKey: 'limits.max_capacity_per_host',
  template: record => {
    if (record.limits?.max_capacity_per_host) {
      return `${record.limits?.max_capacity_per_host} TB`;
    }
    return t('无限制');
  }
}

export const sdsV4LicenseStatusColumn: TableColumn<LicenseDigital> = {
  title: t('状态'),
  dataKey: 'status',
  template: (record, i) => {
    return getStatusDisplay(record.expireDate, i === 0);
  }
}

export const sdsLicenseV4SignedTime: TableColumn<LicenseDigital> = {
  title: t('激活日期'),
  dataKey: 'activeDate',
  template: record => {
    return formatDateTime(record.activeDate);
  }
}

export const sdsLicenseV4ExpireTime: TableColumn<LicenseDigital> = {
  title: t('截止日期'),
  dataKey: 'expireDate',
  template: record => {
    const diffYear = dayjs(record.expireDate).diff(record.activeDate, 'years');
    if (diffYear >= 1000) {
      return NA;
    }
    return formatDateTime(record.expireDate);
  }
}

export const licenseSkuUuidColumn = {
  title: t('电子 license 序列号'),
  dataKey: 'uuid',
  template: (record: LicenseSku) => {
    return record.uuid || '';
  }
};
export const digitalLicenseOperateColumn = (onClick: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, data: LicenseDigital) => void) => ({
  title: t('操作'),
  dataKey: 'operate',
  template: (record: LicenseDigital) => {
    return (
      <div className={styles.operate}>
        <span className={classnames('link-without-decoration edit', { disabled: (record.status !== 'draft' && record.status !== 'rejected') })} onClick={e => onClick(e, record)}>{t('修改')}</span>
        <span className={classnames('link-without-decoration recall', { disabled: record.status !== 'pending' })} onClick={e => onClick(e, record)}>{t('撤回')}</span>
        <span className={classnames('link-without-decoration cancel', { disabled: record.download || record.status === 'cancelled' })} onClick={e => onClick(e, record)}>{t('作废')}</span>
        <span className={classnames('link-without-decoration download', { disabled: record.status !== 'approved' })} onClick={e => onClick(e, record)}>{t('下载')}</span>
      </div>
    )
  }
})
export const licenseGlobalSkuDataClusterColumn: TableColumn = {
  title: t('数据集群配额'),
  dataKey: 'maxDataClusterCapacityTb',
  template: (record: LicenseSku) => {
    let capacity = 0;
    record.sku?.extensions?.forEach(e => {
      if (e.type === 'limit') {
        capacity += e.limits.maxDataClusterNum || 0;
      }
    });
    return capacity ? `${capacity} 个` : 0;
  }
};

export const licenseGlobalSkuObjectServicePlatformColumn: TableColumn = {
  title: t('对象服务平台配额'),
  dataKey: 'maxObjectServicePlatformCapacityTb',
  template: (record: LicenseSku) => {
    let capacity = 0;
    record.sku?.extensions?.forEach(e => {
      if (e.type === 'limit') {
        capacity += e.limits.maxObjectServicePlatformNum || 0;
      }
    });
    return capacity ? `${capacity} 个` : 0;
  }
};
export const licenseMaxDataBackendColumn: TableColumn = {
  title: t('次级存储配额'),
  dataKey: 'maxDataBackendCapacityTiB',
  template: (record: LicenseSku) => {
    let capacity = 0;
    record.sku?.extensions?.forEach(e => {
      if (e.type === 'limit') {
        capacity += e.limits.maxDataBackend || 0;
      }
    });
    return capacity ? `${capacity} TiB` : 0;
  }
};

export const licenseMaxCapacityColumn: TableColumn = {
  title: t('容量配额'),
  dataKey: 'maxCapacityTiB',
  template: (record: LicenseSku) => {
    let capacity = 0;
    record.sku?.extensions?.forEach(e => {
      if (e.type === 'limit') {
        capacity += e.limits.maxCapacity || 0;
      }
    });
    return capacity ? `${capacity} TiB` : 0;
  }
};

export const licenseSddcMaxCpuNumColumn: TableColumn = {
  title: t('CPU授权数量配额'),
  dataKey: 'maxCpuNum',
  template: (record: LicenseSku) => {
    let capacity = 0;
    record.sku?.extensions?.forEach(e => {
      if (e.type === 'limit') {
        capacity += e.limits.maxCpuNum || 0;
      }
    });
    return capacity ? `${capacity} 个` : 0;
  }
};
export const licenseSkuEditableSignedColumn: (render: (record: LicenseSku) => React.ReactNode) => TableColumn = render => ({
  title: t('签发时间'),
  dataKey: 'signedDate',
  template: render
});