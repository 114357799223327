import {  FormItemLabelProps } from 'interfaces/props';
import styles from './index.module.scss';
import classnames from 'classnames';

const FormItemLabel = (props: FormItemLabelProps) => {
  return <div>
    <div
      className={classnames(styles.label, { required: props.required })}
      style={{ width: props.width }}
    >
      {props.label}
    </div>
  </div>
}

export default FormItemLabel;