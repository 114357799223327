import { LicenseDigital, LicenseDigitalContractType, LicenseDigitalFeature, LicenseDigitalPeriodUnit, LicenseDigitalProductInfo, LicenseDigitalScene, LicenseDigitalStatus, Sku, SkuExtension } from 'model';
import { LocaleT } from './interface';
import { trans } from './locale';

export function getLicenseContractType(status: LicenseDigitalContractType, t: LocaleT = trans) {
  const LICENSE_CONTRACT_TYPE: { [key in LicenseDigitalContractType]: string } = {
    new: t('首次下单'),
    expand: t('扩容'),
    renew: t('续保'),
    upgrade: t('升级'),
    delay: t('延期'),
  };
  return LICENSE_CONTRACT_TYPE[status];
}
export const productFeatures: LicenseDigitalFeature[] = [
  { name: "XCBS04CEHB09900", desc: "XCBS产品标配许可", is_standard: true, product_id: 1, product_info: "http://license.xsky.com/api/product-infos/1/" },
  { name: "XCBS04CEHB00100", desc: "XCBS 20TiB容量包", is_standard: false, product_id: 1, product_info: "http://license.xsky.com/api/product-infos/1/" },
  { name: "XCBS04CECB00200", desc: "XCBS 对象存储基本功能", is_standard: false, product_info: null },
  { name: "XEBS04CEHB09900", desc: "XEBS产品标配许可", is_standard: true, product_info: null },
  { name: "XEBS04CEHB00100", desc: "XEBS 20TiB容量包", is_standard: false, product_info: null },
  { name: "XSCALERE20R40HB000001YA", desc: "XSCALER EXPRESS 2000  软硬一体", is_standard: true, product_info: null },
  { name: "XEUS04CEHB09900", desc: "XEUS产品标配许可", is_standard: true, product_info: null },
  { name: "XEDP04CEHB09900", desc: "XEDP产品标配许可", is_standard: true, product_info: null },
  { name: "XEOS04CECB09900", desc: "XEOS产品标配许可", is_standard: true, product_info: null },
  { name: "XEUS04CEHB00100", desc: "XEUS 20TiB容量包", is_standard: false, product_info: null },
  { name: "XEDP04CEHB00100", desc: "XEDP 20TiB容量包", is_standard: false, product_info: null },
  { name: "XEOS04CECB00100", desc: "XEOS 20TiB容量包", is_standard: false, product_info: null },
  { name: "XEOS04CENB01400", desc: "XEOS 海量小文件1亿文件包", is_standard: false, product_info: null },
  { name: "XEBS04CEHB00400", desc: "FC 功能包", is_standard: false, product_info: null },
  { name: "XEUS04CEHB00400", desc: "FC 功能包", is_standard: false, product_info: null },
  { name: "XEDP04CEHB00400", desc: "FC 功能包", is_standard: false, product_info: null },
  { name: "XEDP04CEHB00300", desc: "NAS功能包", is_standard: false, product_info: null },
  { name: "XEBS04CEHB00500", desc: "VDI加速功能", is_standard: false, product_info: null },
  { name: "XEUS04CEHB00500", desc: "VDI加速功能", is_standard: false, product_info: null },
  { name: "XEDP04CEHB00500", desc: "VDI加速功能", is_standard: false, product_info: null },
  { name: "XCBS04CEHB00600", desc: "延展集群", is_standard: false, product_info: null },
  { name: "XEUS04CEHB00600", desc: "延展集群", is_standard: false, product_info: null },
  { name: "XEBS04CEHB00600", desc: "延展集群", is_standard: false, product_info: null },
  { name: "XEDP04CEHB00600", desc: "延展集群", is_standard: false, product_info: null },
  { name: "XEBS04CEHB00700", desc: "卷同步复制", is_standard: false, product_id: 2, product_info: "http://license.xsky.com/api/product-infos/2/" },
  { name: "XEUS04CEHB00700", desc: "卷同步复制", is_standard: false, product_id: 3, product_info: "http://license.xsky.com/api/product-infos/3/" },
  { name: "XEDP04CEHB00700", desc: "卷同步复制", is_standard: false, product_id: 4, product_info: "http://license.xsky.com/api/product-infos/4/" },
  { name: "XEBS04CEHB00800", desc: "卷异步复制", is_standard: false, product_id: 2, product_info: "http://license.xsky.com/api/product-infos/2/" },
  { name: "XEUS04CEHB00800", desc: "卷异步复制", is_standard: false, product_id: 3, product_info: "http://license.xsky.com/api/product-infos/3/" },
  { name: "XEDP04CEHB00800", desc: "卷异步复制", is_standard: false, product_id: 4, product_info: "http://license.xsky.com/api/product-infos/4/" },
  { name: "XEBS04CEHB00900", desc: "卷快照云备份", is_standard: false, product_id: 2, product_info: "http://license.xsky.com/api/product-infos/2/" },
  { name: "XEUS04CEHB00900", desc: "卷快照云备份", is_standard: false, product_id: 3, product_info: "http://license.xsky.com/api/product-infos/3/" },
  { name: "XEDP04CEHB00900", desc: "卷快照云备份", is_standard: false, product_id: 4, product_info: "http://license.xsky.com/api/product-infos/4/" },
  { name: "XEOS04CELB01200", desc: "对象属性查询", is_standard: false, product_id: 5, product_info: "http://license.xsky.com/api/product-infos/5/" },
  { name: "XEOS04CELB01300", desc: "对象存储多站点", is_standard: false, product_id: 5, product_info: "http://license.xsky.com/api/product-infos/5/" },
  { name: "XSCALERE20RGB00500", desc: "XSCALER EXPRESS 2000 FC功能包", is_standard: false, product_info: null },
  { name: "XSCALERE20RGB00600", desc: "VDI加速功能", is_standard: false, product_id: 6, product_info: "http://license.xsky.com/api/product-infos/6/" },
  { name: "XEOS04CEHB00600", desc: "延展集群", is_standard: false, product_id: 5, product_info: "http://license.xsky.com/api/product-infos/5/" },
  { name: "XCBS04CECB09900", desc: "XCBS产品容量标配许可 (180TiB/集群)", product_id: 7, is_standard: true, product_info: "http://license.xsky.com/api/product-infos/7/" },
  { name: "XCBS04CECB00100", desc: "XCBS 20TiB集群容量包", is_standard: false, product_id: 7, product_info: "http://license.xsky.com/api/product-infos/7/" },
  { name: "XEUS04CEHB00A00", desc: "RBD 协议包", is_standard: false, product_info: null },
  { name: "XEBS04CEHB00A00", desc: "RBD 协议包", is_standard: false, product_info: null },
  { name: "XEDP04CELB01200", desc: "对象属性查询", is_standard: false, product_info: null },
  { name: "XEDP04CELB01300", desc: "对象存储多站点", is_standard: false, product_info: null },
  { name: "XEDP04CENB01400", desc: "海量小文件1亿文件包", is_standard: false, product_info: null },
  { name: "XEOS04CELB01500", desc: "对象数据分层", is_standard: false, product_id: 5, product_info: "http://license.xsky.com/api/product-infos/5/" },
  { name: "XEDP04CELB01500", desc: "对象数据分层", is_standard: false, product_info: null },
  { name: "XGFS04CECB09900", desc: "XGFS产品标配许可  (纯软件许可)", is_standard: true, product_id: 8, product_info: "http://license.xsky.com/api/product-infos/8/" },
  { name: "XGFS04CECB00100", desc: "XGFS 20TB容量包", is_standard: false, product_id: 8, product_info: "http://license.xsky.com/api/product-infos/8/" },
  { name: "XEOS04CEHB00300", desc: "NAS 功能包", is_standard: false, product_id: 5, product_info: "http://license.xsky.com/api/product-infos/5/" },
  { name: "XEOS04CELB01600", desc: "对象数据重删", is_standard: false, product_id: 5, product_info: "http://license.xsky.com/api/product-infos/5/" },
  { name: "XEDP04CELB01600", desc: "对象数据重删", is_standard: false, product_id: 4, product_info: "http://license.xsky.com/api/product-infos/4/" },
  { name: "X04OELB02900", desc: "超融合版本功能包", is_standard: false, product_info: null },
  { name: "D1JB12D3C18", desc: "回源重定向功能", is_standard: false, product_id: 5, product_info: "http://license.xsky.com/api/product-infos/5/" },
  { name: "E1JB12D3C18", desc: "回源重定向功能", is_standard: false, product_id: 4, product_info: "http://license.xsky.com/api/product-infos/4/" },
];
export const pocFunctions = ['对象数据重删'];
export function getLicensePeriodUnit(key: LicenseDigitalPeriodUnit, t: LocaleT = trans) {
  const LICENSE_PERIOD_UNIT: { [key in LicenseDigitalPeriodUnit]: string } = {
    second: t('秒'),
    day: t('天'),
    month: t('月'),
    year: t('年'),
  };
  return LICENSE_PERIOD_UNIT[key];
}
export function getProductName(product: LicenseDigitalProductInfo) {
  return `${product.desc || `${product.series} ${product.model}`}`
}
export function getProductFeatureDisplay(featureName: string) {
  const feature = productFeatures.find(f => f.name === featureName)
  return feature?.desc;
}
export const productInfos: LicenseDigitalProductInfo[] = [
  {
    series: 'XCBS',
    desc: 'XCBS（节点）',
    model: '',
    id: 1,
  },
  {
    series: 'XEBS',
    desc: '',
    model: '',
    id: 2,
  },
  {
    series: 'XEUS',
    desc: '',
    model: '',
    id: 3,
  },
  {
    series: 'XEDP',
    desc: '',
    model: '',
    id: 4,
  },
  {
    series: 'XEOS',
    desc: '',
    model: '',
    id: 5,
  },
  {
    series: 'XSCALER',
    desc: '',
    model: 'EXPRESS 2000',
    id: 6,
  },
  {
    series: 'XCBS',
    desc: 'XCBS（集群）',
    model: '',
    id: 7,
  },
  {
    series: 'XGFS',
    desc: '',
    model: '',
    id: 8,
  },
];
export const SKU_CATEGORY_MAP = new Map([
  ['expand', '扩容'],
  ['renew', '续保'],
  ['serve', '服务'],
  ['train', '培训'],
  ['special', '特定 SKU'],
  ['hardware', '硬件配件'],
  ['X3000', 'X3000 硬件'],
  ['X5000', 'X5000 硬件'],
  ['X5100', 'X5100 硬件'],
  ['XE5000S', 'XE5000S 一体机'],
  ['XE5000V', 'XE5000V 一体机'],
  ['XE2100G2', 'XE2100G2 一体机'],
  ['XE3100G2', 'XE3100G2 一体机'],
  ['XP备份一体机', 'XP 备份一体机'],
  ['XINFINI8000', 'XINFINI8000 一体机'],
  ['XH超融合一体机', 'XH 超融合一体机'],
  ['外采产品', '外采产品'],
  ['XLRS光磁一体机', 'XLRS 光磁一体机'],
]);
export function getSkuFullName(sku: Sku, t: LocaleT = trans) {
  if (sku.type === '在线续保服务' && sku.version === 'v4') {
    return `${sku.full_name}${sku.warranty_period}${sku.warranty_unit === 1 ? t('年') : t('月')}${sku.warranty_level === 1 ? '5\*9' : '7\*24'}在线续保服务`;
  }
  return sku.full_name;
}
export const licenseV5BaseSkuFields = ['sku', 'count', 'period', 'signedDate'];
export const licenseV5ExtensionSkuFields = ['sku', 'period', 'signedDate'];
export function getDigitalLicenseStatus(status: LicenseDigitalStatus, t: LocaleT = trans) {
  const DIGITAL_LICENSE_STATUS = {
    draft: { text: t('待申请'), color: 'pending' },
    pending: { text: t('申请中'), color: 'pending' },
    approved: { text: t('已通过'), color: 'ready' },
    rejected: { text: t('被驳回'), color: 'rejected' },
    using: { text: t('使用中'), color: 'pending' },
    cancelled: { text: t('已作废'), color: 'canceled' },
  };
  return DIGITAL_LICENSE_STATUS[status];
}

export function getDigitalLicenseScene(key: LicenseDigitalScene, t: LocaleT = trans) {
  const DIGITAL_LICENSE_SCENE: { [key in LicenseDigitalScene]: string } = {
    permanent: t('正式'),
    temporary: t('临时'),
    subscription: t('订阅'),
    test: t('内部测试'),
    poc: t('POC'),
    testToProd: t('测试转生产'),
  }
  return DIGITAL_LICENSE_SCENE[key];
}
export function licensePeriodDisplay(license: LicenseDigital, t: LocaleT = trans) {
  if (license.scene === 'permanent' || license.scene === 'testToProd') {
    return t('永久');
  }
  return `${license.period} ${getLicensePeriodUnit(license.periodUnit || 'month', t)}`;
}
export const pocVersion = '6.0.000';
export function getSkuLimit(record: SkuExtension, t: LocaleT = trans) {
  const limits: string[] = [];
  if (record.limits?.maxHddCapacityTb) {
    limits.push(t('{{capacity}} TB HDD', { capacity: record.limits?.maxHddCapacityTb }));
  }
  if (record.limits?.maxSsdCapacityTb) {
    limits.push(t('{{capacity}} TB SSD', { capacity: record.limits?.maxSsdCapacityTb }));
  }
  if (record.limits?.maxHostNum) {
    limits.push(t('{{capacity}} 节点', { capacity: record.limits?.maxHostNum }));
  }
  if (record.limits?.maxDataClusterNum) {
    limits.push(t('{{capacity}} 个数据集群', { capacity: record.limits?.maxDataClusterNum }));
  }
  if (record.limits?.maxObjectServicePlatformNum) {
    limits.push(t('{{capacity}} 个对象服务平台', { capacity: record.limits?.maxObjectServicePlatformNum }));
  }
  if (record.limits?.maxDataBackend) {
    limits.push(t('{{capacity}} TiB 对象服务平台次级存储容量', { capacity: record.limits?.maxDataBackend }));
  }
  if (record.limits?.maxCapacity) {
    limits.push(t('{{capacity}} TiB 对象服务平台容量', { capacity: record.limits?.maxCapacity }));
  }
  if (record.limits?.maxCpuNum) {
    limits.push(t('{{capacity}} 个 CPU 授权', { capacity: record.limits?.maxCpuNum }));
  }
  return limits.join(';\n');
}