import React, { useState, useEffect } from 'react';
import {
  Toolbar,
  Button,
  Checkbox,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Grid,
} from 'x-material-ui';
import t from 'locale';
import { TransferList as TransferListProps, dataProps } from 'interfaces/props';
import styles from './index.module.scss';

function not(a: dataProps[], b: dataProps[]) {
  return a.filter(value => b.findIndex(v => v.id === value.id) === -1);
}

function intersection(a: dataProps[], b: dataProps[]) {
  return a.filter(value => b.findIndex(v => v.id === value.id) !== -1);
}

export default function TransferList(props: TransferListProps) {
  const { onSelected, data, leftFooter, rightFooter } = props;
  const [checked, setChecked] = useState<dataProps[]>([]);
  const [left, setLeft] = useState<dataProps[]>(data);
  const [right, setRight] = useState<dataProps[]>([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    onSelected(right);
  }, [right]);

  useEffect(() => {
    setLeft(data);
    setRight([]);
    setChecked([]);
  }, [data]);

  const handleToggle = (value: dataProps) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items: dataProps[]) => (
    <div className={styles.paper}>
      <List dense component="div" role="list">
        {items.length ? (
          items.map((value: dataProps) => {
            const labelId = `transfer-list-item-${value}-label`;
            return (
              <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.name} />
              </ListItem>
            );
          })
        ) : (
          <ListItem>
            <ListItemText primary={t('暂未选中')} />
          </ListItem>
        )}
        <ListItem />
      </List>
    </div>
  );

  const Header = (type: 'left' | 'right', checked: number, total?: number) => {
    return (
      <Toolbar classes={{ root: styles['toolbar-root'] }}>
        {type === 'left' && (
          <ListItemText primary={t('添加可选（{{checked}}/{{total}}）', { checked, total })} />
        )}
        {type === 'right' && <ListItemText primary={t('已选（{{checked}}）', { checked })} />}
      </Toolbar>
    );
  };

  return (
    <Grid container alignItems="center" className={styles.root}>
      <Grid item className={styles.content}>
        {Header('left', checked.length, left.length)}
        {customList(left.slice(props.perPage * props.page, props.perPage * (props.page + 1)))}
        {leftFooter && (
          <div className={styles['content-footer']}>{leftFooter(checked.length, left.length)}</div>
        )}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            classes={{ root: styles.button, disabled: styles.disabled }}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            classes={{ root: styles.button, disabled: styles.disabled }}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            classes={{ root: styles.button, disabled: styles.disabled }}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            classes={{ root: styles.button, disabled: styles.disabled }}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item className={styles.content}>
        {Header('right', right.length)}
        {customList(right)}
        {rightFooter && (
          <div className={styles['content-footer']}>{rightFooter(right.length, left.length)}</div>
        )}
      </Grid>
    </Grid>
  );
}
