import { LocaleT } from './interface';
import lodash from 'lodash';

export const trans: LocaleT = function (keys: string, ...args: any[]) {
  let str = keys;
  if (!!args.length) {
    const argsKeysStr = Object.keys(args[0]).map(i => '{{' + i + '}}').join('|');
    const keysArr = keys.match(new RegExp(argsKeysStr, 'g')) || [];
    if (!!keysArr.length) {
      let startIndex = 0;
      let endIndex = 0;
      str = '';
      for (let i = 0; i < keysArr.length; i++) {
        endIndex = i === keysArr.length - 1 ? keys.length : keys.indexOf(keysArr[i]) + keysArr[i].length;
        const tmpStr = keys.slice(startIndex, endIndex);
        const argKey = keysArr[i].slice(2, keysArr[i].length - 2);
        str += lodash.has(args[0], argKey) ? tmpStr.replace(keysArr[i], lodash.get(args[0], argKey, '')) : tmpStr;
        startIndex = endIndex;
      }
    }
  }
  return str;
}