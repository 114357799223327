import CampaignIcon from '@mui/icons-material/Campaign';
import classnames from 'classnames';
import { checkPermission } from 'common/src/permission';
import { FormItemLabel, FormItemLine } from 'components';
import { GlobalContext } from 'context';
import t from 'locale';
import { DashboardSummary } from 'model';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDashboardSummary } from 'request';
import { Button, Dialog, DialogContent, DialogTitle, MenuItem, Select, SelectChangeEvent, TextButton } from 'x-material-ui';
import styles from './index.module.scss';

interface Card {
  title: string;
  count: number;
  countTo: string;
  tips?: string;
  action?: string;
  actionTo?: string;
  details: CardDetail[];
}
interface CardDetail {
  title: string;
  key: string;
  count: number;
}
const Dashboard = () => {
  const history = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [version, setVersion] = useState('V4');
  const [data, setData] = useState<Card>();
  const { token } = useContext(GlobalContext);
  const [operationLoading, setOperationLoading] = useState(false);
  useEffect(() => {
    fetchSummary();
  }, []);
  const [summary, setSummary] = useState<DashboardSummary>();
  async function fetchSummary() {
    const response = await getDashboardSummary();
    setSummary(response.data);
  }
  const clusterSummary = summary?.cluster;
  const cluster: Card = {
    title: t('我的集群'),
    count: clusterSummary?.total!!,
    countTo: '/cluster',
    details: [
      { title: t('已过保'), key: 'warrantyExpired', count: clusterSummary?.warrantyExpired!! },
      { title: t('小于等于 30 天'), key: 'warrantyLte30', count: clusterSummary?.warrantyLte30!! },
      { title: t('31 至 90 天'), key: 'warranty31To90', count: clusterSummary?.warranty31To90!! },
      { title: t('大于等于 91 天'), key: 'warrantyGte91', count: clusterSummary?.warrantyGte91!! },
    ],
  };
  const permitSummary = summary?.permit;
  const permit: Card = {
    title: t('我的许可'),
    count: permitSummary?.total!!,
    countTo: '/cluster?tab=permit',
    tips: t('(使用中)'),
    action: checkPermission('ApplyOEMLicense', token) ? t('申请许可') : '',
    actionTo: 'license-form',
    details: [
      { title: t('使用中'), key: 'licenseActive', count: permitSummary?.licenseActive!! },
      { title: t('已失效'), key: 'licenseExpired', count: permitSummary?.licenseExpired!! },
    ],
  };
  const orderSummary = summary?.order;
  const order: Card = {
    title: t('我的订单'),
    count: orderSummary?.total!!,
    countTo: '/order',
    details: [
      { title: t('生效中'), key: 'active', count: orderSummary?.active!! },
      { title: t('已解除'), key: 'dismissed', count: orderSummary?.dismissed!! },
    ],
  };
  // const ticketSummary = summary?.ticket;
  // const ticket: Card = {
  //   title: t('我的工单'),
  //   count: ticketSummary?.total!!,
  //   countTo: '',
  //   action: t('创建工单'),
  //   actionTo: 'https://support.xsky.com/api/acct/authing-login?next_path=/tickets/create',
  //   details: [
  //     { title: t('进行中'), key: 'ticketPending', count: ticketSummary?.pending!! },
  //     { title: t('已完成'), key: 'ticketCompleted', count: ticketSummary?.completed!! },
  //   ],
  // };
  function handleDetailCountClick(key: string, countTo: string) {
    if (countTo === '/order') {
      history(`${countTo}?status=${key}`, { relative: 'path' });
    }
  }
  function handleCountClick(to: string) {
    if (to) {
      history(`${to}`, { relative: 'path' });
    }
  }
  function handleActionClick(data: Card) {
    if (data.actionTo === 'license-form') {
      setData(data);
      setShowDialog(true);
    } else if (data.action === t('创建工单') && data.actionTo) {
      window.open(data.actionTo);
    }
  }
  function handleOkClick() {
    history(`/${data?.actionTo}?version=${version}`);
  }
  function handleCancelDialogClick() {
    setShowDialog(false);
  }
  async function handleVersionChange(event: SelectChangeEvent<string>, child: ReactNode) {
    const version = event.target.value as string;
    setVersion(version);
  }
  function renderCard(card: Card) {
    let countClass = '';
    if (card.count!! > 0) {
      countClass = styles['card-count-active'];
    }
    return (<div className={styles['card-container']}>
      <div className={styles['card-title']}>
        <div>
          <span>{card.title}</span>
          {card.action && <span onClick={() => handleActionClick(card)} className={styles['card-action']}>{card.action}<span className={styles['card-action-icon']}>&gt;</span></span>}
        </div>
        <div className={styles['card-title-info']}>
          <span onClick={() => handleCountClick(card.countTo)} className={classnames(countClass, styles['card-title-count'])}>{card.count}</span>
          <span className={styles['card-title-unit']}>{t('个{{tips}}', { tips: card.tips })}</span>
        </div>
      </div>
      <div className={styles['card-separate-line']} />
      <div className={styles['card-detail']}>
        {card.details.map(d => {
          let countClass = '';
          if (d.count!! > 0) {
            countClass = styles['card-count-active'];
          }
          return <div key={d.key} className={styles['card-detail-item']}>
            <div className={styles['card-detail-title']}>{d.title}</div>
            <div className={classnames(countClass, styles['card-detail-count'])} onClick={() => handleDetailCountClick(d.key, card.countTo,)}>{d.count}</div>
          </div>
        })}
      </div>
    </div>);
  }
  let notification = t('暂无公告');
  const expiredClusterCount = clusterSummary?.warrantyExpired || 0;
  const expiringClusterCount = (clusterSummary?.warrantyLte30 || 0) + (clusterSummary?.warranty31To90 || 0);
  if (expiredClusterCount || expiringClusterCount) {
    if (expiredClusterCount && expiringClusterCount) {
      notification = t('您有 {{expired}} 个集群已过保，{{expiring}} 个集群即将过保，请及时续保', { expired: expiredClusterCount, expiring: expiringClusterCount });
    } else if (expiredClusterCount) {
      notification = t('您有 {{expired}} 个集群已过保，请及时续保', { expired: expiredClusterCount });
    } else {
      notification = t('您有 {{expiring}} 个集群即将过保，请及时续保', { expiring: expiringClusterCount });
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles['notification-container']}>
        <CampaignIcon className={styles['notification-icon']} />
        <span className={styles['notification-text']}>{notification}</span>
      </div>
      <div className={styles.content}>
        {checkPermission('ViewCluster', token) && renderCard(cluster)}
        {checkPermission('ViewOEMLicense', token) && renderCard(permit)}
        {checkPermission('ViewContract', token) && renderCard(order)}
        {/* {renderCard(ticket)} */}
      </div>
      <Dialog open={showDialog} >
        <div className={styles['modal-container']}>
          <DialogTitle id="scroll-dialog-title" hasClose onCloseClick={handleCancelDialogClick}>{t('{{action}} license', { action: t('申请') })}</DialogTitle>
          <DialogContent >
            <FormItemLine>
              <FormItemLabel label={t('产品版本')} required />
              <Select
                classes={{
                  select: `${styles.select} ${styles['select-input']}`,
                  icon: styles['select-icon'],
                }}
                variant="outlined"
                value={version}
                onChange={handleVersionChange}
              >
                {['V4', 'V5', 'V6'].map((menu, i) => {
                  return (<MenuItem key={i} value={menu}>
                    {menu}
                  </MenuItem>)
                })}
              </Select>
            </FormItemLine>
            <div className={styles.action}>
              <TextButton
                size='medium'
                className={styles.cancel}
                onClick={handleCancelDialogClick}
              >
                {t('取消')}
              </TextButton>
              <Button
                size="medium"
                loading={operationLoading}
                disabled={operationLoading}
                color='primary'
                variant='contained'
                onClick={handleOkClick}
              >
                {t('申请')}
              </Button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

export default Dashboard;