import t from 'locale';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'x-material-ui';
import DetailHeaderInfo from '../DetailHeaderInfo';
import Loading from '../Loading';
import { DetailsBasicFieldProps, OrderDetailProps } from 'interfaces/props';
import { Contract } from 'model';
import { dateDisplay, getContractTypes } from 'utils';
import { getContractDeliveryStatus, getContractStatus } from 'common/src/contract';
import styles from './index.module.scss';
import { getContractDetail } from 'request';
import SubjectMatter from './SubjectMatter';
import Delivery from './Delivery';
import WarrantyInfo from './WarrantyInfo';

type OrderDetailTabs = 'product' | 'delivery' | 'warranty';
const OrderDetail = (props: OrderDetailProps) => {
  const [tab, setTab] = useState<OrderDetailTabs>('product');
  const [contract, setContract] = useState<Contract>();
  useEffect(() => {
    if (props.contractId) {
      fetchContractInfo();
    }
  }, []);
  async function fetchContractInfo() {
    const modelNames: string[] = ['opportunity', 'submitter', 'orders', 'receives']
    const response = await getContractDetail(props.contractId, modelNames);
    setContract(response.data);
  }

  function handleTabChange(e: React.ChangeEvent<{}>, value: OrderDetailTabs) {
    setTab(value);
  }
  const tabs = [
    { value: 'product', text: t('产品信息') },
    { value: 'delivery', text: t('交付信息') },
    { value: 'warranty', text: t('质保信息') },
  ];
  function getHeaderFields(contract: Contract) {
    const signingDate = dateDisplay(contract.signingDate);
    const fields: DetailsBasicFieldProps[] = [
      {
        label: t('客户名称'),
        text: contract.opportunity?.customer?.name,
      },
      {
        label: t('甲方'),
        text: contract.partyAName,
      },
      {
        label: t('合同类型'),
        text: getContractTypes(contract),
      },
      {
        label: t('交付进度'),
        text: getContractDeliveryStatus(contract.deliveryStatus, t).text,
      },
      {
        label: t('签订时间'),
        text: signingDate,
      },
    ];
    return fields;
  }
  if (!contract) {
    return <Loading />
  }
  return <div className={styles.container}>
    <DetailHeaderInfo
      title={contract?.outerNo}
      fields={getHeaderFields(contract)}
      status={getContractStatus(contract.status, t).status}
      statusText={getContractStatus(contract.status, t).text}
      className={styles['basic-container']}
    />
    <Tabs
      value={tab}
      onChange={handleTabChange}
    >
      {tabs.map(tab => (<Tab key={tab.value} value={tab.value} label={tab.text} />))}
    </Tabs>
    <div className={styles['content-container']}>
      {tab === 'product' && <SubjectMatter contract={contract} />}
      {tab === 'delivery' && <Delivery contract={contract} />}
      {tab === 'warranty' && <WarrantyInfo contractNo={contract.no} />}
    </div>
  </div>
}
export default OrderDetail;