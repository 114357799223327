import { PartCollapseProps } from 'interfaces/props';
import styles from './index.module.scss';
import { IconButton } from 'x-material-ui';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

const PartCollapse = (props: PartCollapseProps) => {
  return <div className={styles.container}>
    {props.children}
    <IconButton classes={{ root: styles['collapse-button'] }} onClick={props.setOpen}>
      {props.open ? <KeyboardArrowUpOutlinedIcon className={styles.icon} /> : <KeyboardArrowDownOutlinedIcon className={styles.icon} />}
    </IconButton>
  </div>
}

export default PartCollapse;