import { createTheme } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'x-material-ui';
import './App.css';
import BasicLayout from './layouts/basic';
import {
  checkboxOverrides,
  formControlLabelOverrides,
  menuItemOverrides,
  outlinedInputOverrides,
  radioOverrides,
  tabOverrides,
  tabsOverrides,
  buttonOverrides,
} from './styles/theme';


const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: outlinedInputOverrides,
    },
    MuiRadio: {
      styleOverrides: radioOverrides,
    },
    MuiFormControlLabel: {
      styleOverrides: formControlLabelOverrides,
    },
    MuiCheckbox: {
      styleOverrides: checkboxOverrides,
    },
    MuiMenuItem: {
      styleOverrides: menuItemOverrides,
    },
    MuiTabs: {
      styleOverrides: tabsOverrides,
    },
    MuiTab: {
      styleOverrides: tabOverrides,
    },
    MuiButton: {
      styleOverrides: buttonOverrides,
    }
  }
});
function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <BasicLayout />
      </ThemeProvider>
    </Router>
  );
}

export default App;
