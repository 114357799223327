import { DATE_FORMAT } from 'common';
import dayjs from 'dayjs';
import { BidApprovalModel } from 'interfaces/models';
import { TableColumn } from 'interfaces/props';
import t from 'locale';

export const approvalApplyDateColumn: TableColumn<BidApprovalModel> = {
  title: t('申请时间'),
  dataKey: 'applyDate',
  template: record => {
    return dayjs(record.applyDate).format(DATE_FORMAT);
  }
}