import { DictOption } from './interface';
import t from "locale";

export function validate(config: DictOption) {
  const error = {
    nodeNum: '',
    cpu: '',
    cpuNum: '',
    netCardNum: '',
    gfsMetaDisk: '',
    gfsMetaDiskNum: '',
    gfsFileNum: '',
    otherErasure: '',
    dataOsd: '',
    dataOsdNum: '',
    cacheOsd: '',
    cacheOsdNum: '',
    omap: '',
  };
  const common = t('请输入');
  if (!config.nodeNum) {
    error.nodeNum = common;
  }
  if (!config.cpu) {
    error.cpu = common;
  }
  if (!config.cpuNum) {
    error.cpuNum = common;
  }
  if (!config.netCardNum) {
    error.netCardNum = common;
  }
  if (config.product === 'XGFS') {
    if (!config.gfsMetaDisk) {
      error.gfsMetaDisk = common;
    }
    if (!config.gfsMetaDiskNum) {
      error.gfsMetaDiskNum = common;
    }
    if (!config.gfsFileNum) {
      error.gfsFileNum = common;
    }
  }
  if (config.policyType === 'erasure' && config.erasure === 'other' && !config.otherErasure) {
    error.otherErasure = common;
  }
  if (!config.dataOsd) {
    error.dataOsd = common;
  }
  if (!config.dataOsdNum) {
    error.dataOsdNum = common;
  }
  const xspeedFlag = config.pool === 'XSpeed';
  if (xspeedFlag || config.pool === 'LocalCache') {
    if (!config.cacheOsd) {
      error.cacheOsd = common;
    }
    if (!config.cacheOsdNum) {
      error.cacheOsdNum = common;
    }
  }
  if (xspeedFlag && !config.omap) {
    error.omap = t('无索引容量值');
  }

  return error;
}
