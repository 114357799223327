import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';
import {
  checkBoxAllColumn,
  ErrorText,
  licenseSkuCountColumn,
  licenseSkuDisableSignedColumn,
  licenseSkuEditableCountColumn,
  licenseSkuEditableNameColumn,
  licenseSkuEditablePeriodColumn,
  licenseSkuExpiredColumn,
  licenseSkuHddColumn,
  licenseSkuHostCountColumn,
  licenseSkuNameColumn,
  licenseSkuOperateColumn,
  licenseSkuPeriodColumn,
  licenseSkuSignedColumn,
  licenseSkuSsdColumn,
  licenseSkuTableModelNameColumn,
  Table
} from 'components';
import { GlobalContext } from 'context';
import { TableColumn } from 'interfaces/props';
import t from 'locale';
import lodash from 'lodash';
import { LicenseDigital, LicenseDigitalPeriodUnit, LicenseSku, Sku } from 'model';
import React, { useContext, useState } from 'react';
import { getLicensesByMd5 } from 'request';
import { inputToPositiveInteger, skuDisplayRender } from 'utils';
import { Button, Dialog, DialogContent, DialogTitle, InputAdornment, SearchSelect, TextButton, TextField } from 'x-material-ui';
import { useCheckboxColumn } from '../../hooks';
import styles from './index.module.scss';
import { SkuV5Props } from './interface';
import { getDefaultSku, querySkuByName, skuDisplayItemWidthTooltipRender } from './utils';

const SkuV5 = (props: SkuV5Props) => {
  const { license, update, error, clearError, renderInputSelectAdornment, getExpireDate, type, skuType, oemSkusRef } = props;
  const nonStandardSkus = oemSkusRef.current;
  const skus = license.skus?.filter(s => s.type === skuType) || [];
  const [showBatchAddSkuModel, setShowBatchAddSkuModel] = useState(false);
  const [showHistoryModel, setShowHistoryModel] = useState<boolean>(false);
  const [historyLicenseSkus, setHistoryLicenseSkus] = useState<LicenseSku[]>([]);
  const [availableSkus, setAvailableSkus] = useState<Sku[]>([]);
  const { checkedData, checkAll, handleCheckItem, setCheckedData, handleCheckAll, setCheckAll } = useCheckboxColumn(availableSkus, setAvailableSkus);
  const [search, setSearch] = useState<string>('');
  const { user } = useContext(GlobalContext);

  function getTypeValue() {
    let typeValue = ''
    switch (skuType) {
      case 'base':
        typeValue = '基础';
        break;
      case 'extension':
        typeValue = '扩展';
        break;
    }
    return typeValue;
  }
  function handleSkuChange(sku: Sku, record: LicenseSku) {
    record.sku = sku;
    record.code = sku.code;
    record.skuId = sku.id;
    update({});
    clearError();
  }
  function handlePeriodChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, record: LicenseSku) {
    record.period = inputToPositiveInteger(e.target.value);
    if (record.signedDate) {
      record.expiredDate = getExpireDate(record.signedDate, record.period, record.periodUnit);
    }
    update({});
    clearError();
  }
  function handlePeriodUnitChange(value: LicenseDigitalPeriodUnit, record: LicenseSku) {
    record.periodUnit = value;
    if (record.signedDate && record.period) {
      record.expiredDate = getExpireDate(record.signedDate, record.period, record.periodUnit);
    }
    update({});
    clearError();
  }
  function handleRemoveClick(record: LicenseSku) {
    const index = license.skus?.findIndex(s => s.id === record.id);
    if (index !== undefined) {
      license.skus?.splice(index, 1);
    }
    update({});
    clearError();
  }
  function handleCountChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, record: LicenseSku) {
    record.count = inputToPositiveInteger(e.target.value);
    update({});
    clearError();
  }
  function handleAddClick() {
    license.skus?.push({ ...getDefaultSku(skuType, license), id: -Math.random() });
    update({});
    clearError();
  }
  function renderCount(record: LicenseSku) {
    return (<TextField
      variant="outlined"
      className={styles['sku-table-count-input']}
      value={record.count || ''}
      onChange={e => handleCountChange(e, record)}
      type="number"
    />);
  }
  async function queryV5SkuByName(name: string = '', limit: number = 50) {
    let skus: Sku[] = [];
    let nonSkus = nonStandardSkus;
    nonSkus = nonSkus.filter(s => s.type === getTypeValue());
    if (name) {
      nonSkus = nonSkus.filter(s => s.name.indexOf(name) !== -1);
    }
    nonSkus = nonSkus.filter(s => s.category === 'XSOS');
    if (skuType === 'extension') {
      skus = await querySkuByName({
        version: 'v5',
        type: '扩展',
        search: name,
        limit,
        category: 'XSOS',
        oemId: license.vendorId || user?.companyId || undefined,
        scene: 'poc',
      });
      skus = skus.filter(sku => {
        return !(sku.name.indexOf('仅用于POC测试') !== -1 || sku.name.indexOf('天合翔宇') !== -1);
      });
    }
    return [...nonSkus, ...skus];
  }
  function renderName(record: LicenseSku, i: number) {
    const disabled = !!record.code && record.code === license?.productSku?.code;
    return <SearchSelect
      searchCallback={name => queryV5SkuByName(name)}
      displayRender={skuDisplayRender}
      itemRender={skuDisplayItemWidthTooltipRender}
      updateValue={sku => handleSkuChange(sku, record)}
      value={record.sku}
      key={record.id + i}
      placeholder={t('请选择 SKU')}
      className={disabled ? styles['sku-table-disabled-select'] : styles['sku-table-name-select']}
      disabled={disabled}
    />
  }
  function renderSkuOperate(record: LicenseSku) {
    return <span className={styles['remove-item']} onClick={e => handleRemoveClick(record)}><CloseIcon className={styles['remove-item-icon']} /></span>
  }
  function renderPeriod(record: LicenseSku) {
    return <TextField
      disabled={true}
      variant="outlined"
      className={styles['sku-table-period-input']}
      value={record.period}
      onChange={e => handlePeriodChange(e, record)}
      InputProps={{
        endAdornment: renderInputSelectAdornment(record.periodUnit || '', value => handlePeriodUnitChange(value, record), true)
      }}
    />;
  }
  function getColumns() {
    const columns: TableColumn[] = [
      licenseSkuEditableNameColumn(renderName),
    ];
    if (skuType === 'base') {
      columns.push(licenseSkuEditableCountColumn(renderCount));
    }
    if (skuType === 'base') {
      columns.push(licenseSkuHostCountColumn);
      columns.push(licenseSkuHddColumn);
      columns.push(licenseSkuSsdColumn);
    }
    columns.push(licenseSkuEditablePeriodColumn(renderPeriod));
    columns.push(licenseSkuDisableSignedColumn);
    columns.push(licenseSkuExpiredColumn);
    columns.push(licenseSkuOperateColumn(renderSkuOperate));
    return columns;
  }
  function handleCancelBatchAddSkuModelClick() {
    setShowBatchAddSkuModel(false);
  }
  async function fetchAvailableAddskus(search?: string) {
    const skus = await queryV5SkuByName(search, 1000)
    const existSkuIds = license.skus?.map(sku => sku.skuId) || [];
    const availableAddskus = lodash.filter(lodash.cloneDeep(skus || []) as Sku[], sku => !existSkuIds.includes(sku.id));
    setAvailableSkus(availableAddskus);
    setCheckedData([]);
    setCheckAll(false);
  }
  async function handleOpenBatchAddSkuModelClick() {
    setSearch('')
    await fetchAvailableAddskus();
    setShowBatchAddSkuModel(true);
  }
  function handleBatchAddSkuClick() {
    for (const data of checkedData) {
      const sku = lodash.omit(data, 'checked');
      license.skus?.push({
        ...getDefaultSku(skuType, license),
        sku,
        code: sku.code,
        skuId: sku.id,
        id: -Math.random(),
      });
    }
    update({});
    clearError();
    setShowBatchAddSkuModel(false);
  }
  async function handleSearchInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    var value = e.target.value.trim();
    setSearch(value);
  };
  async function handleSearchInputKeydown(e: React.KeyboardEvent) {
    e.persist();
    if (e.keyCode === 13) {
      await fetchAvailableAddskus(search);
    }
  };
  async function handleSearchClick() {
    await fetchAvailableAddskus(search);
  };
  function renderSkuTableModel() {
    const columns: TableColumn[] = [
      checkBoxAllColumn(handleCheckItem, handleCheckAll, checkAll),
      licenseSkuTableModelNameColumn
    ];
    return <Table columns={columns} data={availableSkus} rowKey='code' loading={false} />
  }
  function handleCancelModelClick() {
    setShowHistoryModel(false);
  }
  function getHistoryColumns() {
    const columns: TableColumn[] = [
      licenseSkuNameColumn,
    ];
    if (skuType === 'base') {
      columns.push(licenseSkuCountColumn);
    }
    if (skuType === 'base') {
      columns.push(licenseSkuHostCountColumn);
      columns.push(licenseSkuHddColumn);
      columns.push(licenseSkuSsdColumn);
    }
    columns.push(licenseSkuPeriodColumn);
    columns.push(licenseSkuSignedColumn);
    columns.push(licenseSkuExpiredColumn);
    return columns;
  }
  async function handleHistoryModelClick() {
    if (license.clusterMd5) {
      const response = await getLicensesByMd5(license.clusterMd5, type, 'V5');
      const licenseSkuArr: LicenseSku[] = [];
      response.data[0].forEach((l: LicenseDigital) => {
        if (l.skus) {
          l.skus.forEach(i => { licenseSkuArr.push(i); });
        }
      });
      setHistoryLicenseSkus(licenseSkuArr.filter(l => l.type === skuType));
      setShowHistoryModel(true);
    }
  }

  return (
    <div className={styles['sku-container']}>
      {props.title}
      <div className={styles['part-content']}>
        <Table columns={getColumns()} data={skus} loading={false} />
        <ErrorText error={error} />
        {(license.productType === 'SDS' || skuType === 'extension') && <div
          onClick={handleAddClick}
          className={classnames('link-without-decoration', styles['add-link-button'])}
        >
          <span>+</span>
          {t('添加')}
        </div>}
        {skuType === 'extension' &&
          <div
            onClick={handleOpenBatchAddSkuModelClick}
            className={classnames('link-without-decoration', styles['add-link-button'])}
          >
            <span>+</span>
            {t('批量添加')}
          </div>
        }
        {!!license?.clusterMd5 && <span onClick={handleHistoryModelClick} className={classnames('link-without-decoration', styles['history-link-button'])} >
          {t('集群现有许可信息')}
        </span>}
        <Dialog open={showBatchAddSkuModel} scroll="paper">
          <div className={styles['modal-container']}>
            <DialogTitle id="scroll-dialog-title" hasClose onCloseClick={handleCancelBatchAddSkuModelClick}>{t('批量添加')}</DialogTitle>
            <DialogContent className={styles['modal-first-container']} >
              <TextField
                classes={{ root: styles.search }}
                id="input-with-icon-textfield"
                placeholder={t('请输入 SKU 名称')}
                variant="outlined"
                value={search}
                onChange={handleSearchInputChange}
                onKeyDown={handleSearchInputKeydown}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" onClick={handleSearchClick}>
                      <span className={`icon-search ${styles['search-icon']}`} />
                    </InputAdornment>
                  ),
                }}
              />
            </DialogContent>
            <DialogContent dividers={true} className={styles['modal-second-container']} >
              {renderSkuTableModel()}
            </DialogContent>
            <div className={styles.action}>
              <TextButton size="large" className={styles.cancel} onClick={handleCancelBatchAddSkuModelClick}>{t('取消')}</TextButton>
              <Button size="large" color="primary" onClick={handleBatchAddSkuClick}>{t('添加')}</Button>
            </div>
          </div>
        </Dialog>
        <Dialog open={showHistoryModel} scroll="paper">
          <div className={styles['modal-container']}>
            <DialogTitle id="scroll-dialog-title" hasClose onCloseClick={handleCancelModelClick}>{t('集群现有许可信息')}</DialogTitle>
            <DialogContent dividers={true}>
              <Table columns={getHistoryColumns()} data={historyLicenseSkus} rowKey='code' loading={false} />
            </DialogContent>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default SkuV5;
