import { FormItemLabel, FormItemLine } from 'components';
import { LICENSE_LIMITS } from 'constant';
import { LicenseFormLimitProps } from 'interfaces/props';
import t from 'locale';
import { LicenseDigitalLimit } from 'model';
import { TextField } from 'x-material-ui';
import styles from './index.module.scss';
import PartTitle from './part-title';

const Limit = (props: LicenseFormLimitProps) => {
  const { license, originalLicense, update } = props;
  const limitKeys: (keyof LicenseDigitalLimit)[] = [
    'max_capacity_per_host',
    'max_host_num',
    'max_capacity',
  ];
  function handleLimitChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: keyof LicenseDigitalLimit) {
    let value = e.target.value;
    value = value.replace(/[^\d.+]/g, '');
    const limits = license.limits || {};
    if (value) {
      limits[key] = value;
    } else {
      delete limits[key];
    }
    update({
      limits,
    });
  }
  function renderLimit(key: keyof LicenseDigitalLimit) {
    return (<FormItemLine key={key}>
      <FormItemLabel label={LICENSE_LIMITS[key]} />
      <TextField
        variant="outlined"
        onChange={e => handleLimitChange(e, key)}
        value={license.limits?.[key]}
        classes={{ root: styles['number-input'] }}
        placeholder={t('无限制')}
        disabled={originalLicense && license.isDelay}
      />
    </FormItemLine>);
  }
  return (<div className={styles['basic-container']}>
    <PartTitle title={t('限制')} />
    <div className={styles['part-content']}>
    {limitKeys.map(l => renderLimit(l))}
  </div>
  </div>);
}

export default Limit;