import { formatDateOnly } from "common";
import { getContractDeliveryStatus, getContractStatus } from "common/src/contract";
import { CLUSTER_DELIVERY_TYPE, CONTRACT_SUBJECT_MATTER_UNIT } from "constant";
import dayjs from 'dayjs';
import { TableColumn } from "interfaces/props";
import t from 'locale';
import lodash from 'lodash';
import { Cluster, ClusterDelivery, Contract, ContractProductDetail, ContractSubjectMatter, ScmShipmentApplyLogistic } from "model";
import { getContractTypes, getSkuName } from "utils";
import { Status } from "x-material-ui";
import styles from './index.module.scss';

export const contractOuterNoColumn: (onClick: (record: Contract) => void) => TableColumn<Contract> = onClick => ({
  title: t('合同编号'),
  dataKey: 'outerNo',
  template: record => {
    return <span className="link-without-decoration" onClick={() => onClick(record)}>{record?.outerNo || ''}</span>
  }
})

export const contractPartyANameColumn: TableColumn<Contract> = {
  title: t('甲方'),
  dataKey: 'partyAName',
};

export const contractCustomerColumn: TableColumn<Contract> = {
  title: t('客户名称'),
  dataKey: 'opportunity.customer.name',
  template: (record: Contract) => {
    return <span>{record.opportunity?.customer?.name}</span>;
  }
};

export const contractStatusColumn: TableColumn<Contract> = {
  title: t('合同状态'),
  dataKey: 'status',
  template: (record: Contract) => {
    return <Status {...getContractStatus(record.status, t)} />;
  }
};
export const contractTypeColumn: TableColumn<Contract> = {
  title: t('合同类型'),
  dataKey: 'contractType',
  template: getContractTypes

};
export const contractProductColumn: TableColumn<Contract> = {
  title: t('产品名称'),
  dataKey: 'product',
  template: (record: Contract) => {
    const products = record.subjectMatters?.map(c => c.matter?.name || '') || [];
    return (<div>
      {lodash.uniq(products).map(c => (<div key={c}>
        {c}
      </div>))}
    </div>);
  }
};
export const contractDeliveryStatusColumn: TableColumn<Contract> = {
  title: t('交付进度'),
  dataKey: 'deliveryStatus',
  template: record => {
    return <Status {...getContractDeliveryStatus(record.deliveryStatus, t)} />;
  }
};

export const scanContractSignDateColumn: TableColumn<Contract> = {
  title: t('合同签订日期'),
  dataKey: 'signingDate',
  template: record => formatDateOnly(record.signingDate)
};
export const contractSubjectMatterNameColumn: TableColumn<ContractSubjectMatter> = {
  title: t('产品名称'),
  dataKey: 'matterId',
  template: (record: ContractSubjectMatter) => {
    return record.matter?.name || record.name;
  }
};
export const contractSubjectMatterUnitColumn: TableColumn<ContractSubjectMatter> = {
  title: t('单位'),
  dataKey: 'unit',
  template: record => {
    return CONTRACT_SUBJECT_MATTER_UNIT[record.unit];
  }
};

export const contractSubjectMatterCountColumn: TableColumn<ContractSubjectMatter> = {
  title: t('数量'),
  dataKey: 'count',
};
export const contractSubjectMatterVersionColumn: TableColumn<ContractSubjectMatter> = {
  title: t('版本'),
  dataKey: 'version',
};

export const contractSkuNameColumn: TableColumn<ContractProductDetail> = {
  title: t('名称'),
  dataKey: 'name',
  template: record => {
    return record.sku ? getSkuName(record.sku) : '';
  }
};
export const contractSkuSaleTypeColumn: TableColumn<ContractProductDetail> = {
  title: t('单位'),
  dataKey: 'sale_type',
  template: record => {
    return record.sku?.sale_type;
  }
};
export const contractSkuCountColumn: TableColumn<ContractProductDetail> = {
  title: t('数量'),
  dataKey: 'count',
};

export const contractSkuAllDescColumn: TableColumn<ContractProductDetail> = {
  title: t('描述'),
  dataKey: 'desc',
  template: record => {
    const desc = record?.skuDesc || record.bidSku?.desc || record.sku?.desc || '';
    return <div title={desc} className={styles['sku-all-desc-cell-root']}>{desc}</div>;
  }
};
export const contractSubjectMatterTotalCountColumn: TableColumn<ContractSubjectMatter> = {
  title: t('合同购买数量'),
  dataKey: 'count',
};
export const contractSubjectMatterShipCountColumn: TableColumn<ContractSubjectMatter> = {
  title: t('已发货数量'),
  dataKey: 'shipCount',
  template: (record: ContractSubjectMatter) => {
    const shipCount = record.logistics?.reduce((p, c) => {
      return p + (c.matterCount || 0);
    }, 0)
    return shipCount;
  }
};
export const shipmentLogisticDeliveryDateColumn: TableColumn<ScmShipmentApplyLogistic> = {
  title: t('发货时间'),
  dataKey: 'deliveryDate',
  template: (record: ScmShipmentApplyLogistic) => {
    return formatDateOnly(record.deliveryDate);
  }
}
export const shipmentLogisticNoColumn: TableColumn<ScmShipmentApplyLogistic> = {
  title: t('物流单号'),
  dataKey: 'logisticNo',
}
export const shipmentLogisticMatterCountColumn: TableColumn<ScmShipmentApplyLogistic> = {
  title: t('发货数量'),
  dataKey: 'matterCount',
}
export const deliveryProductColumn: TableColumn<ClusterDelivery> = {
  title: t('交付产品'),
  dataKey: 'deliverProduct',
  template: (record: ClusterDelivery) => {
    return <div>
      {record.deliverProduct.map(c => (<div key={c}>{c}</div>))}
    </div>
  }
}
export const deliveryDateColumn: TableColumn<ClusterDelivery> = {
  title: t('交付时间'),
  dataKey: 'deliveryDate',
  template: (record: ClusterDelivery) => {
    return formatDateOnly(record.deploy?.end);
  }
}
export const deliveryCountColumn: TableColumn<ClusterDelivery> = {
  title: t('交付数量'),
  dataKey: 'deliveryCount',
  template: (record: ClusterDelivery) => {
    return `${record.hostCount}节点 ${record.capacityTbyte ? record.capacityTbyte + 'TB' : ''} ${record.cpuCount ? record.cpuCount + 'cpu' : ''}`;
  }
}
export const warrantyClusterNameColumn: TableColumn<Cluster> = {
  title: t('集群名称'),
  dataKey: 'name',
}
export const warrantyDeliveryTypeColumn: TableColumn<Cluster> = {
  title: t('交付类型'),
  dataKey: 'type',
  template: (record: Cluster) => {
    const clusterDeliveryies = record.clusterDeliveries || [];
    const types = clusterDeliveryies.map(c => CLUSTER_DELIVERY_TYPE[c.type]);
    return lodash.uniq(types).join(',');
  }
}
export const warrantyClusterServeLevelColumn = {
  title: t('服务级别'),
  dataKey: 'software_service_level',
};

export const warrantyClusterServePeriodColumn = {
  title: t('服务期限'),
  dataKey: 'warranty_service_period',
};
export const warrantyClusterWarrantyEndColumn = {
  title: t('质保结束时间'),
  dataKey: 'finalWarrantyTime',
  template: (record: Cluster) => {
    return record.finalWarrantyTime ? formatDateOnly(record.finalWarrantyTime) : '';
  }
};
export const warrantyClusterWarrantyStartColumn = {
  title: t('质保开始时间'),
  dataKey: 'warranty_service_start',
  template: (record: Cluster) => {
    return record.warranty_service_start ? formatDateOnly(record.warranty_service_start) : '';
  }
};
export const warrantyStatusColumn = {
  title: t('质保状态'),
  dataKey: 'warranty_status',
  template: (record: Cluster) => {
    let status = '';
    if (record.finalWarrantyTime) {
      const warrantyEnd = dayjs(record.finalWarrantyTime).valueOf();
      const now = dayjs().valueOf();
      if (now > warrantyEnd) {
        status = t('已过保');
      } else if (dayjs().add(30, 'day').valueOf() > warrantyEnd) {
        status = t('<30 天');
      } else if (dayjs().add(90, 'day').valueOf() >= warrantyEnd) {
        status = t('30-90 天');
      } else if (dayjs().add(90, 'day').valueOf() < warrantyEnd) {
        status = t('90+ 天');
      }
    }
    return status;
  }
};