interface CheckRequiredOptions {
  isZeroValid?: boolean;
}

export function checkRequired(payload: any, fields: string[] | string, options?: CheckRequiredOptions) {
  if (typeof fields === 'string') {
    fields = [fields];
  }
  for (const field of fields) {
    const value = payload[field];
    if (value === undefined || value === null || value === '' || (options?.isZeroValid === false && value === 0)) {
      return `Invalid schema: ${field} required`;
    } else if (value instanceof Array) {
      if (!value.length) {
        return `Invalid schema: ${field} required`;
      }
    }
  }
  return '';
}