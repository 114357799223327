import React, { useState } from 'react';
import styles from './index.module.scss';
import { RoleForm as RoleFormProps, DeleteRoleForm as DeleteRoleFormProps } from 'interfaces/props';
import { TextField, Button, TextButton } from 'x-material-ui';
import t from 'locale';

const RoleForm = (props: RoleFormProps) => {
  const role = props.role;
  const [rolename, setRolename] = useState(role ? role.name : '');
  const [roleDesc, setRoleDesc] = useState(role ? role.desc : '');

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRolename(e.target.value);
  };

  const handleDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoleDesc(e.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles['item-line']}>
        <div className={`${styles['form-text']} required`}>{t('名称：')}</div>
        <TextField
          required
          variant="outlined"
          className={styles['form-input']}
          value={rolename}
          onChange={handleNameChange}
          placeholder={t('请输入角色名称')}
          InputProps={{ classes: { root: styles['input-root'] } }}
          defaultValue={props.role ? props.role.name : ''}
        />
      </div>
      <div className={styles['item-line']}>
        <div className={`${styles['form-text']} required`}>{t('描述：')}</div>
        <TextField
          required
          variant="outlined"
          className={styles['form-input']}
          value={roleDesc}
          multiline
          onChange={handleDescChange}
          placeholder={t('请输入角色描述')}
          InputProps={{ classes: { root: styles['input-root'] } }}
          defaultValue={props.role ? props.role.desc : ''}
        />
      </div>
      <div className={styles['form-footer']}>
        <Button
          size="medium"
          color="primary"
          onClick={() => props.onSubmit({ name: rolename, desc: roleDesc })}
          className={`${styles['footer-button']}`}
          variant='contained'
        >
          {t('确定')}
        </Button>
        <TextButton
          className={`${styles['footer-button']}`}
          onClick={props.onCancel} size="large"
        >
          {t('取消')}
        </TextButton>
      </div>
    </div>
  );
};

export const DeleteRoleForm = (props: DeleteRoleFormProps) => {
  const { roles, onSubmit, onCancel } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles['item-line']}>
        <p>{t('是否删除：{{roles}}', { roles: roles.map(role => role.name).join(', ') })}</p>
      </div>
      <div className={styles['form-footer']}>
        <Button
          size='medium'
          color="secondary"
          onClick={() => onSubmit(roles.map(role => role.id))}
          className={`${styles['footer-button']}`}
          variant='contained'
        >
          {t('确定')}
        </Button>
        <TextButton
          className={`${styles['footer-button']}`}
          onClick={onCancel} size="large"
        >
          {t('取消')}
        </TextButton>
      </div>
    </div>
  );
};

export default RoleForm;
