import { useLocation } from 'react-router';
import styles from './index.module.scss';
import queryString from 'query-string';
import { ClusterPageTab } from 'interfaces/models';
import { useState } from 'react';
import { Tab, Tabs } from 'x-material-ui';
import t from 'locale';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { ClusterList, PermitRecordList } from 'components';
import { checkPermission } from 'common/src/permission';
import { GlobalContext } from 'context';

const Cluster = () => {
  const location = useLocation();
  const history = useNavigate();
  const query = queryString.parse(location.search);
  let defaultTab: ClusterPageTab = 'cluster';
  const defaultId = parseInt(query.defaultId as string, 10);
  const { token } = useContext(GlobalContext);
  if (['cluster', 'permit'].includes(query.tab as string)) {
    defaultTab = query.tab as ClusterPageTab;
  }
  const [tab, setTab] = useState(defaultTab);
  function handleTabChange(e: React.ChangeEvent<{}>, value: ClusterPageTab) {
    setTab(value);
    history({
      pathname: '/cluster',
      search: `tab=${value}`,
    });
  }
  return (<div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.tab}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
        >
          {checkPermission('ViewCluster', token) && <Tab value="cluster" label={t('我的集群')} />}
          {checkPermission('ViewOEMLicense', token) && <Tab value="permit" label={t('我的许可')} />}
        </Tabs>
      </div>
      <div className={styles['table-content']}>
        {tab === 'cluster' && <ClusterList />}
        {tab === 'permit' && <PermitRecordList defaultId={defaultId} />}
      </div>
    </div>
  </div>);
}

export default Cluster;