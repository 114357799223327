import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';
import { CommonMailErrorModel, LicenseFormAdditionalProps } from 'interfaces/props';
import t from 'locale';
import lodash from 'lodash';
import { GlobalContext } from 'context';
import { useContext, useState, useEffect } from 'react';
import styles from './index.module.scss';
import PartTitle from './part-title';
import { ErrorText, FormItemLabel, FormItemLine } from 'components';
import { getCommonMails, createCommonMails } from 'request';
import { Button, Dialog, DialogContent, DialogTitle, SearchSelect, TextareaAutosize, TextField, TextButton } from 'x-material-ui';
import { ReceivingMailBox } from 'model';
import { emailRegex } from 'common';

const Additional = (props: LicenseFormAdditionalProps) => {
  const { license, update, error } = props;
  const [showCommonMail, setShowCommonMail] = useState(false);
  const [operationLoading, setOperationLoading] = useState(false);
  const [mailError, setMailError] = useState<CommonMailErrorModel>({});
  const { user } = useContext(GlobalContext);
  const [commonMails, setCommonMails] = useState<Partial<ReceivingMailBox>[]>([]);
  function handleReasonChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    update({
      applyReason: e.target.value,
    });
  }
  const outerMails = license.receivingMailBox?.filter(c => !c.isApplicant) || [];
  const receivingMailBox = [{ id: 0, name: user?.name || '', type: 'inner', mail: user?.mail || '', isApplicant: true }, ...outerMails];
  license.receivingMailBox = receivingMailBox
  function handleAddOuterMail() {
    license.receivingMailBox = license.receivingMailBox || [];
    license.receivingMailBox.push({
      id: 0,
      name: '外部人员',
      mail: '',
      type: 'outer',
      isApplicant: false,
    });
    update({
      receivingMailBox: [...(license.receivingMailBox || [])],
    });
  }
  function handleAddCommonOuterMail() {
    commonMails.push({
      name: '外部人员',
      mail: '',
      type: 'outer',
      isApplicant: false,
    });
    setCommonMails([...commonMails]);
  }
  async function setCommomMail() {
    const response = await getCommonMails('');
    const mails = response.data;
    setCommonMails(mails);
    setShowCommonMail(true);
  }
  function handleDeleteMail(i: number) {
    license.receivingMailBox = license.receivingMailBox || [];
    license.receivingMailBox.splice(i, 1);
    update({
      receivingMailBox: [...(license.receivingMailBox || [])],
    });
  }
  function handleDeleteCommonMail(i: number) {
    commonMails.splice(i, 1);
    setCommonMails([...commonMails]);
  }
  function handleTextChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, m: Partial<ReceivingMailBox>) {
    m.mail = e.target.value;
    setCommonMails([...commonMails]);
  }
  function mailDisplayRender(s?: Partial<ReceivingMailBox>) {
    return s?.mail || '';
  }

  function mailDisplayItemRender(s: Partial<ReceivingMailBox>) {
    if (s.id === 0) {
      return <div className={styles['mails-tip']}>{s.mail}</div>;
    } else {
      return s.mail || '';
    }
  }
  function handleMailChange(mail: Partial<ReceivingMailBox>, m: ReceivingMailBox) {
    if (mail.id !== 0) {
      m.mail = mail.mail || '';
      update({
        receivingMailBox: [...(license.receivingMailBox || [])],
      });
    }
  }
  function handleMailInputChange(mail: string, m: ReceivingMailBox) {
    m.mail = mail;
    update({
      receivingMailBox: [...(license.receivingMailBox || [])],
    });
  }
  async function handleOkClick() {
    mailError.mail = undefined;
    if (commonMails.find(r => !r.mail?.match(emailRegex))) {
      mailError.mail = {
        error: true,
        text: t('请输入合法的邮箱'),
      };
    }
    setMailError({ ...mailError });
    const hasError = (Object.keys(mailError) as (keyof ReceivingMailBox)[]).filter(key => mailError[key]?.error).length > 0
    if (!hasError) {
      setOperationLoading(true);
      const param = commonMails.map(c => ({
        ...lodash.omit(c, 'id'),
      }));
      await createCommonMails(param);
      setShowCommonMail(false);
      setOperationLoading(false);
    }
  }
  function handleCancelClick() {
    setOperationLoading(false);
    setShowCommonMail(false);
  }
  async function searchCommonMail(mail: string) {
    const mails = await getCommonMails(mail);
    if (mails.data.length) {
      return [
        {
          id: 0,
          mail: t('以下为常用邮箱'),
          type: '',
        },
        ...mails.data
      ];
    }
    return mails.data;
  }
  return (<div className={styles['additional-container']}>
    <PartTitle title={t('其他')} />
    <div className={styles['part-content']}>
    <FormItemLine>
        <FormItemLabel label='' />
        {license.type === 'oem' &&
          <div>
            <span>{t('预计使用时长{{month}}个月', { month: license.expectedUsageTime })}</span>
          </div>
        }
      </FormItemLine>
      <FormItemLine>
        <FormItemLabel label={t('申请内容描述')} required />
        <div>
          <TextareaAutosize
            className={classnames('textarea', styles.textarea)}
            maxRows={15}
            onChange={handleReasonChange}
            placeholder={(license.expectedUsageTime || 0) > 3 ? t('POC license 预计使用时长大于等于 3 个月，请说明本次延期原因；') : t('请输入申请原因')}
            value={license.applyReason}
          />
          <ErrorText error={error?.applyReason} />
        </div>
      </FormItemLine>
      <FormItemLine>
        <FormItemLabel label={t('license 接收邮箱')} />
        <div>
          {
            license.receivingMailBox?.map((m, i) => {
              return <div className={styles['mail-container']}>
                <span className={styles['mail-index']}>{t('邮箱 {{index}}', { index: i + 1 })}</span>
                <div className={styles['mail-user']}>
                  {m.name}
                </div>
                <div className={styles['mail-name']}>
                  {m.isApplicant ? m.mail : <SearchSelect
                    searchCallback={searchCommonMail}
                    displayRender={mailDisplayRender}
                    itemRender={mailDisplayItemRender}
                    updateValue={(mail) => handleMailChange(mail, m)}
                    handleInputChange={(mail) => handleMailInputChange(mail, m)}
                    value={m}
                    placeholder={t('请选择常用邮箱或者填写邮箱')}
                    className={styles['sku-select']}
                  />}
                </div>
                {!m.isApplicant && <div className={`icon-delete ${styles.icon}`} onClick={() => handleDeleteMail(i)} ><CloseIcon className={styles['remove-item-icon']} /></div>}
              </div>
            })
          }
          <ErrorText error={props.error.receivingMailBox} />
          <div className={styles['action-container']}>
            <div className='link-without-decoration' onClick={handleAddOuterMail}>{t('+ 添加外部邮箱')}</div>
            <div className='link-without-decoration' onClick={setCommomMail}>{t('设置常用')}</div>
          </div>
        </div>
      </FormItemLine>
    </div>
    <Dialog open={showCommonMail} maxWidth='md'>
      <div>
        <DialogTitle id="scroll-dialog-title" hasClose onCloseClick={handleCancelClick}>{t('设置常用邮箱')}</DialogTitle>
        <DialogContent >
          <div className={styles['mail-diglog-container']}>
            {
              commonMails.map((m, i) => {
                return <div className={styles['mail-container']}>
                  <span className={styles['mail-index']}>{t('邮箱 {{index}}', { index: i + 1 })}</span>
                  <div className={styles['mail-user']}>
                    {m.name}
                  </div>
                  <div className={styles['mail-name']}>
                    <TextField
                      variant="outlined"
                      onChange={e => handleTextChange(e, m)}
                      value={m.mail || ''}
                      classes={{ root: styles['mail-input'] }}
                      placeholder={t('请输入邮箱')}
                    />
                  </div>
                  {!m.isApplicant && <div className={`icon-delete ${styles.icon}`} onClick={() => handleDeleteCommonMail(i)} ><CloseIcon className={styles['remove-item-icon']} /></div>}
                </div>
              })
            }
            <ErrorText error={mailError.mail} />
            <div className='link-without-decoration' onClick={handleAddCommonOuterMail}>{t('+ 添加外部邮箱')}</div>
          </div>
          <div className={styles.action}>
            <TextButton
              size="medium"
              className={styles.cancel}
              variant='text'
              color='primary'
              onClick={handleCancelClick}
            >
              {t('取消')}
            </TextButton>
            <Button
              size='medium'
              variant='contained'
              loading={operationLoading}
              disabled={operationLoading}
              color='primary'
              onClick={handleOkClick}
            >
              {t('确定')}
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  </div>);
}

export default Additional;