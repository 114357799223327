import { AxiosResponse } from 'axios';
import { UploadConfig } from 'model';
import { instance } from './instance';

export async function initUpload(name: string, type: string, size: number, resourceId: number | string) {
  return instance.post(`/file/init-upload`, {
    name,
    type,
    size,
    relative_id: resourceId,
  });
}

export async function uploadComplete(param: UploadConfig & { filename: string }) {
  return instance.post(`/file/upload-complete`, param);
}

export async function getAliOssConfig(): Promise<AxiosResponse<string>> {
  return instance.get(`/file/ali-oss-config`);
}