import React, { useState, useEffect } from 'react';
import { Loading, Table } from 'components';
import { permissionIdColumn, permissionNameColumn, permissionDescColumn } from 'components';
import { getPermissions } from 'request';
import { usePagination } from '../../hooks';
import styles from './index.module.scss';
import { TABLE_DEFAULT_PAGE_SIZE } from 'constant';
import Pagination from '../Pagination';
import Toolbar from '../Toolbar';
import { Permission as PermissionModel } from 'model';

const Permission = () => {
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');
  const [permissions, setPermissions] = useState<PermissionModel[]>([]);
  const [loading, setLoading] = useState(false);
  const { limit, page, setPage, handlePageChange, handleRowsPerPageChange } = usePagination(handlePagerChange);

  useEffect(() => {
    fetchPermissions(TABLE_DEFAULT_PAGE_SIZE, 0, search);
  }, []);

  const fetchPermissions = async (limit: number = TABLE_DEFAULT_PAGE_SIZE, offset: number = 0, search: string = '') => {
    setLoading(true);
    const response = await getPermissions(limit, offset * limit, search);
    setLoading(false);
    if (response.status === 200) {
      setCount(response.data[1]);
      setPermissions(response.data[0]);
    }
  };

  function handleSearchInputChange(e: React.ChangeEvent<{ value: string }>) {
    setSearch(e.target.value);
  };
  function handleSearchClick() {
    setPage(0);
    fetchPermissions(limit, 0, search);
  }
  function handlePagerChange(limit: number, page: number) {
    setPage(page);
    fetchPermissions(limit, page, search);
  }
  const toolbars: any[] = [];
  toolbars.push({
    type: 'search',
    children: search,
    onChange: handleSearchInputChange,
    onSearch: handleSearchClick,
    tableName: 'permission'
  });
  return (
    <div className={styles.content}>
      <Toolbar
        className={styles.toolbar}
        items={toolbars}
        
      />
      {!loading ? (
        <React.Fragment>
          <div className={styles.table}>
            <Table
              data={permissions}
              columns={[permissionIdColumn, permissionNameColumn, permissionDescColumn]}
              loading={loading}
            />
          </div>
          {count > 0 && (
            <Pagination
              count={count}
              rowsPerPage={limit}
              page={page}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          )}
        </React.Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Permission;
