import { EXPIRE_NAME, TOKEN_NAME, USER_ID } from 'constant';
import { Token } from 'model';
import { getCookie } from './cookie';

export function getToken(): Token {
  const token = getCookie(TOKEN_NAME) || '';
  const expires = getCookie(EXPIRE_NAME);
  const userId = parseInt(getCookie(USER_ID), 10) || 0;
  return {
    uuid: token,
    expires,
    permission: [],
    userId: userId,
    authingToken: '',
    authingTokenExpires: '',
  }
}