import { licenseV5BaseSkuFields, licenseV5ExtensionSkuFields } from 'common/src/license';
import { InputError } from 'interfaces/models';
import t from 'locale';
import lodash from 'lodash';
import { DiditalLicenseFormModel, LicenseSku } from 'model';
import { checkDigitalLicenseApplyUnfinished, checkDigitalLicenseNewContractTypeExits } from 'request';
import { checkRequired } from 'src/utils/validation';
import { ErrorModel, SkuV5TypeProps } from './interface';
import { emailRegex } from 'common';

export async function validate(license: DiditalLicenseFormModel) {
  const error: ErrorModel = {};
  if (!license.supportVersions?.length) {
    error.supportVersions = {
      error: true,
      text: t('请至少选择一个支持版本范围'),
    };
  }
  if (!license.clusterName && license.version !== 'V6') {
    error.clusterName = {
      error: true,
      text: t('请输入集群名称'),
    };
  }
  if (!license.clusterMd5) {
    error.clusterMd5 = {
      error: true,
      text: t('请生成集群 MD5'),
    };
  }
  if (license.receivingMailBox && license.receivingMailBox.find(r => !r.mail.match(emailRegex))) {
    error.receivingMailBox = {
      error: true,
      text: t('请输入合法的邮箱'),
    };
  }
  const md5Error = await validateLicenseMd5(license.clusterMd5, license.id);
  if (md5Error) {
    error.clusterMd5 = md5Error;
  }
  if ((!license.productInfo?.id && license.version === 'V4') || (!license.productSkuCode && license.version === 'V5')) {
    error.productInfo = {
      error: true,
      text: t('请选择软件产品'),
    };
  }
  if (license.services?.find(s => s.name !== '0' && (!s.activeDate || !s.period))) {
    error.services = {
      error: true,
      text: t('请填写正确的服务信息'),
    };
  }
  if (license.version === 'V4') {
    if (!license.activeDate) {
      error.activeDate = {
        error: true,
        text: t('请选择签发日期'),
      };
    }
  } else {
    if (license.productSkuCode && !license.activeDate) {
      error.activeDate = {
        error: true,
        text: t('请选择签发日期'),
      };
    }
  }
  if (license.getMd5Type === 'manual' && !license.keyFile) {
    error.keyFile = {
      error: true,
      text: t('请选择集群 license 文件'),
    }
  } else if (license.getMd5Type === 'qr-code' && !license.licenseKey) {
    error.licenseKey = {
      error: true,
      text: t('请输入集群密钥'),
    }
  }
  if (license.type === 'oem' && !license.vendorId) {
    error.vendorId = {
      error: true,
      text: t('请选择厂商'),
    }
  }
  if (license.version === 'V5' && license.skus?.length) {
    const baseSkusError = validateSkuV5(license.skus, 'base', licenseV5BaseSkuFields);
    if (baseSkusError) {
      error.baseSkus = baseSkusError;
    }
    const extensionSkusError = validateSkuV5(license.skus, 'extension', licenseV5ExtensionSkuFields);
    if (extensionSkusError) {
      error.extensionSkus = extensionSkusError;
    }
  }
  if (!license?.applyReason) {
    error.applyReason = {
      error: true,
      text: t('请输入申请内容描述'),
    }
  }
  return error;
}

export async function validateLicenseMd5(licenseMd5?: string, id?: number) {
  let error: InputError | undefined;
  if (licenseMd5) {
    const response = await checkDigitalLicenseApplyUnfinished(licenseMd5, id);
    let text = '';

    if (response.status === 200) {
      if (response.data) {
        text = t('有未完成的 license 申请，不可重复申请');
        error = {
          error: true,
          text,
        }
      }
    } else {
      text = t('密钥校验失败，请重新获取');
      error = {
        error: true,
        text,
      }
    }
  }
  return error;
}

export async function validateLicenseNewContractTypeExits(licenseMd5?: string) {
  let error: InputError | undefined;
  if (licenseMd5) {
    const response = await checkDigitalLicenseNewContractTypeExits(licenseMd5);
    if (response.status !== 200) {
      let text = ''
      if (response.data?.message === "ActvieLicenseExist") {
        text = t('已存在首次下单的 license 申请，不可继续申请合同类型为首次下单的 license');
      } else {
        text = t('密钥校验失败，请重新获取');
      }
      error = {
        error: true,
        text,
      }
    }
  }
  return error;
}
// 检查单种类别sku
function validateSkuV5(skus: Partial<LicenseSku>[], type: SkuV5TypeProps, fields: string[]) {
  let error: InputError | undefined;
  const singleClassSkus = skus?.filter(s => s.type === type) || [];
  for (const key in singleClassSkus) {
    const message = checkRequired(singleClassSkus[key], fields, { isZeroValid: false });
    if (message) {
      return {
        error: true,
        text: t('请输入完整 SKU 信息'),
      }
    }
  }
  return error;
}


export async function validateClusterMd5(licenseMd5?: string, clusterMd5?: string, id?: number) {
  let error: InputError | undefined;
  if (clusterMd5 && licenseMd5 && licenseMd5 !== clusterMd5) {
    error = {
      error: true,
      text: t('该集群与集群密钥不匹配，请选择其他集群。如果确定集群密钥属于该集群，请在集群管理中清除该集群已有密钥然后重新上传'),
    }
  } else if (licenseMd5 && (id && id > 0)) {
    const response = await checkDigitalLicenseApplyUnfinished(licenseMd5, id);
    if (response.status === 200 && response.data) {
      error = {
        error: true,
        text: t('有未完成的 license 申请，不可重复申请'),
      }
    }
  }
  return error;
}

export async function validateOspClusterMd5(licenseMd5?: string, ospMd5?: string) {
  let error: InputError | undefined;
  if (ospMd5 && licenseMd5 && licenseMd5 !== ospMd5) {
    error = {
      error: true,
      text: t('该对象服务平台与集群密钥不匹配，请选择其他对象服务平台。'),
    }
  }
  return error;
}
export async function validateV6Licenses(license: DiditalLicenseFormModel) {
  let hasError = false;
  const newV6License = lodash.cloneDeep(license);
  newV6License.error = {};
  const error = await validate(newV6License);
  if (Object.keys(error).length) {
    hasError = true;
  }
  if (newV6License.error) {
    newV6License.error.licenseError = error;
  }
  if (newV6License.licenseV6Cluster) {
    for (const license of newV6License.licenseV6Cluster) {
      const error = await validateDataServiceLicense(license);
      if (!license.error) {
        license.error = {};
      }
      license.error.licenseError = error;
      license.error.services = error.services;
      license.error.baseSkus = error.baseSkus;
      license.error.extensionSkus = error.extensionSkus;
      if (Object.keys(error).length) {
        hasError = true;
      }
    }
    return { hasError, newV6License };
  }
}
export async function validateDataServiceLicense(license: DiditalLicenseFormModel) {
  const error: ErrorModel = {};
  if (license.services?.find(s => s.name !== '0' && (!s.activeDate || !s.period))) {
    error.services = {
      error: true,
      text: t('请填写正确的服务信息'),
    };
  }
  if (license.productSkuCode && !license.activeDate) {
    error.activeDate = {
      error: true,
      text: t('请选择产品许可签发日期'),
    };
  }
  if (license.classify === 'XSOS-SERVE' && license.scene !== 'test') {
    const md5Error = await validateOspClusterMd5(license.clusterMd5, license.osp?.fsIdMd5);
    if (md5Error) {
      error.ospId = md5Error;
    }
  }
  if (license.receivingMailBox && license.receivingMailBox.find(r => !r.mail.match(emailRegex))) {
    error.receivingMailBox = {
      error: true,
      text: t('请输入合法的邮箱'),
    };
  }
  if (license.version === 'V6' && license.skus?.length) {
    const baseSkusError = await validateSkuV5(license.skus, 'base', licenseV5BaseSkuFields);
    if (baseSkusError) {
      error.baseSkus = baseSkusError;
    }
    const extensionSkusError = await validateSkuV5(license.skus, 'extension', licenseV5ExtensionSkuFields);
    if (extensionSkusError) {
      error.extensionSkus = extensionSkusError;
    }
  }
  return error;
}
