import { Permission, Role, Token, User } from "model";

export function getPermissionAndUserFromRole(role?: Role) {
  let permissions: Permission[] = [];
  let users: User[] = [];
  if (role && role.permissionToRoles) {
    permissions = role.permissionToRoles.map(c => c.permission) as Permission[];
  }
  if (role && role.userToRoles) {
    users = role.userToRoles.map(c => c.user) as User[];
  }
  return { permissions, users }
}

export function checkPermission(permission: string | string[], token?: Token) {
  let hasPermission = false;
  if (typeof permission === 'string') {
    hasPermission = !!token?.permission.includes(permission);
  } else {
    for (const p of permission) {
      if (token?.permission.includes(p)) {
        hasPermission = true;
        break;
      }
    }
  }
  return hasPermission;
}