import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { toChineseNum } from 'common/src/number';
import {
  licenseSkuUuidColumn,
  licenseSkuNameColumn,
  licenseSkuPeriodColumn,
  licenseSkuSignedColumn,
  licenseSkuExpiredColumn,
  licenseSkuCountColumn,
  licenseSkuLimitColumn
} from 'components'
import { TableColumn, InfoFieldProps } from 'interfaces/props';
import t from 'locale';
import lodash from 'lodash';
import React, { useState } from 'react';
import { dateDisplay, licenseProductPeriodDisplay } from 'utils';
import { IconButton } from 'x-material-ui';
import styles from './index.module.scss';
import Collapse from '../Collapse';
import DetailBasicInfo from '../DetailBasicInfo';
import { DiditalLicenseFormModel, LicenseDigital, LicenseDigitalService, LicenseDigitalVersion } from 'model';
import Table from '../Table';

type DigitalLicenseV6InfoProps = {
  license: DiditalLicenseFormModel;
  historyLicense?: LicenseDigital;
  opportunityFields: InfoFieldProps[];
  warrantyDateConditionFields: InfoFieldProps[];
  additionalFields: InfoFieldProps[];
  renderService: (service: LicenseDigitalService, index: number, version: LicenseDigitalVersion) => React.ReactNode
}

const DigitalLicenseV6Info = (props: DigitalLicenseV6InfoProps) => {
  const [foldIds, setFoldIds] = useState<number[]>([]);
  const { license, opportunityFields, renderService, historyLicense, additionalFields, warrantyDateConditionFields } = props;
  function getColumns(type: string | undefined) {
    const columns: TableColumn[] = [
      licenseSkuUuidColumn,
      licenseSkuNameColumn,
    ];
    if (type !== 'extension') {
      columns.push(licenseSkuCountColumn);
      columns.push(licenseSkuLimitColumn);
    }
    columns.push(licenseSkuPeriodColumn);
    columns.push(licenseSkuSignedColumn);
    columns.push(licenseSkuExpiredColumn);
    return columns;
  }
  function getProductFields(license: LicenseDigital) {
    const fields: InfoFieldProps[] = [];
    const activeDate = license.productSku ? dateDisplay(license.activeDate) : '';
    const expireDate = license.productSku ? dateDisplay(license.expireDate) : '';
    const period = licenseProductPeriodDisplay(license);
    const productUuid = license.productSku ? license.productUuid : '';
    fields.push(
      {
        label: t('电子 license 序列号'),
        value: productUuid,
      },
      {
        label: t('软件产品'),
        value: license.productSku?.name || '',
      },
      {
        label: t('有效期'),
        value: period,
      },
      {
        label: t('签发时间'),
        value: activeDate,
      },
      {
        label: t('到期时间'),
        value: expireDate,
      },
    );
    return fields;
  }

  function getClusterFields(license: DiditalLicenseFormModel) {
    const fields: InfoFieldProps[] = [];
    fields.push(
      {
        label: t('集群名称'),
        value: license.clusterName || license.clusterInfo?.name,
      });
    return fields;
  }

  function toggleOpen(licenseId: number | undefined) {
    if (licenseId) {
      if (foldIds?.includes(licenseId)) {
        setFoldIds([...lodash.pull(foldIds, licenseId)]);
      } else {
        setFoldIds([...foldIds, licenseId]);
      }
    }
  }

  function renderLicenseInfo(license: DiditalLicenseFormModel, i: number) {
    if (!license.id) {
      return <div>暂无数据</div>
    }
    return (
      <div key={i}>
        <div className={styles['collapse-header']}>
          <span className={styles['collapse-title']}>{t('{{classify}}{{index}}', { classify: license.classify === 'XSOS-DATA' ? '数据集群' : '对象服务平台', index: toChineseNum(i + 1) })}</span>
          <div className={styles['collapse-split-line']} />
          <IconButton classes={{ root: styles['fold-arrow'] }} onClick={() => toggleOpen(license.id)}>
            {!foldIds.includes(license.id) ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
          </IconButton>
        </div>
        <Collapse title={t('集群信息')} initialOpen>
          <DetailBasicInfo fields={getClusterFields(license)} className={styles['info-field-container']} />
        </Collapse>
        {!foldIds.includes(license.id) && <div>
          {license.classify === 'XSOS-DATA' && <Collapse title={t('软件产品许可')} initialOpen>
            <DetailBasicInfo fields={getProductFields(license as LicenseDigital)} className={styles['info-field-container']} />
          </Collapse>}
          <Collapse title={t('基础许可')} initialOpen>
            <div className={styles['table-content']}>
              <Table data={(license.skus || []).filter(i => i.type === 'base')} columns={getColumns(license?.classify)} loading={false} />
            </div>
          </Collapse>
          <Collapse title={t('扩展许可')} initialOpen>
            <div className={styles['table-content']}>
              <Table data={(license.skus || []).filter(i => i.type === 'extension')} columns={getColumns('extension')} loading={false} />
            </div>
          </Collapse>
          {
            (license.scene !== 'poc' && license.classify === 'XSOS-DATA') &&
            <Collapse title={t('服务')} initialOpen>
              <DetailBasicInfo
                fields={warrantyDateConditionFields}
                col={3}
                colWidth={6}
                className={styles['info-field-container']}
              />
              {(license.services?.filter(s => s.name !== '0') || []).map((s, i) => renderService(s, i, license?.version as LicenseDigitalVersion))}
            </Collapse>
          }
        </div>}
      </div>
    );
  }

  return (
    <div className={styles['info-content-v5']}>
      <Collapse title={t('销售机会')} initialOpen>
        <DetailBasicInfo fields={opportunityFields} className={styles['info-field-container']} />
      </Collapse>
      {license.enableMcm && <div>
        <div className={styles['title-text']}>{t('全局license 配置')}</div>
        <Collapse title={t('基础许可')} initialOpen>
          <div className={styles['table-content']}>
            <Table data={(license.skus || []).filter(i => i.type === 'base')} columns={getColumns('XSOS-GLOBAL')} loading={false} />
          </div>
        </Collapse>
      </div>}
      {license?.licenseV6Cluster?.map((license, index) => renderLicenseInfo(license, index))}
      <Collapse title={t('其他')} initialOpen>
        <DetailBasicInfo
          fields={additionalFields}
          col={1}
          colWidth={12}
          className={styles['info-field-container']} />
      </Collapse>
    </div>
  )
}

export default DigitalLicenseV6Info;