import WestIcon from '@mui/icons-material/West';
import { PASSWORD_SECRET } from 'common';
import { ErrorText, FormItemLabel } from 'components';
import { EMAILREGEX, PASSWORDREGEX } from 'constant';
import { GlobalContext } from 'context';
import t from 'locale';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { bindEmail, checkPassword, sendEmail, updateEmail, updatePassword } from 'request';
import { Button, Dialog, DialogContent, DialogTitle, Step, StepLabel, Stepper, TextButton, TextField } from 'x-material-ui';
import styles from './index.module.scss';


type DialogType = 'bindMail' | 'updateMail' | 'updatePassword';

const AccountForm = () => {
  const history = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [step, setStep] = useState(0);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [mail, setMail] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [oldCountdown, setOldCountdown] = useState(0);
  const [clock, setClock] = useState<NodeJS.Timer>();
  const [oldClock, setOldClock] = useState<NodeJS.Timer>();
  const [mailCode, setMailCode] = useState('');
  const [oldMailCode, setOldMailCode] = useState('');
  const [oldMail, setOldMail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [oldMailError, setOldMailError] = useState('');
  const [mailCodeError, setMailCodeError] = useState('');
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [oldMailCodeError, setOldMailCodeError] = useState('');
  const [dialogType, setDialogType] = useState<DialogType>('bindMail');
  const { user } = useContext(GlobalContext);
  function handleBackClick() {
    history(-1);
  }
  function handleBindMailClick() {
    setShowDialog(true);
    setDialogType('bindMail');
  }
  function handleUpdateMailClick() {
    setShowDialog(true);
    setDialogType('updateMail');
  }
  function handleUpdatePasswordClick() {
    setShowDialog(true);
    setDialogType('updatePassword');
  }
  function handleCancelDialogClick() {
    clearValue();
  }
  async function hadnleSendEmailClick(mail: string, setButtonValue: (v: number) => void, setClockValue: (v: NodeJS.Timer) => void) {
    if (!mail.match(EMAILREGEX)) {
      setError('请输入正确的邮箱');
    } else {
      setError('');
      let num = 120;
      const clock = setInterval(() => {
        num--;
        if (num > 0) {
          setButtonValue(num);
        } else {
          clearInterval(clock)
          setButtonValue(0);
        }
      }, 1000)
      setClockValue(clock);
      await sendEmail(mail, dialogType);
    }
  }
  async function handleTextFieldChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setValue: (v: string) => void) {
    const value = e.target.value;
    setValue(value);
  }
  function clearValue() {
    clearInterval(clock);
    clearInterval(oldClock);
    setCountdown(0);
    setOldCountdown(0);
    setLoading(false);
    setShowDialog(false);
    setError('');
    setStep(0);
    setPassword('');
    setMail('');
    setOldMail('');
    setMailCode('');
    setOldMailCode('');
    setMailCodeError('');
    setOldMailCodeError('');
    setOldMailError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    setNewPassword('');
    setConfirmPassword('');
  }
  function validate() {
    let hasError = false;
    setError('');
    setMailCodeError('');
    setOldMailCodeError('');
    setOldMailError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    if (step === 1) {
      if (!mail.match(EMAILREGEX)) {
        setError('请输入正确的邮箱');
        hasError = true;
      }
      if (!mailCode) {
        setMailCodeError('请输入验证码');
        hasError = true;
      }
      if (dialogType === 'updateMail') {
        if (!oldMail.match(EMAILREGEX)) {
          setOldMailError('请输入正确的原邮箱');
          hasError = true;
        }
        if (!oldMailCode) {
          setOldMailCodeError('请输入验证码');
          hasError = true;
        }
      }
    } else if (dialogType === 'updatePassword') {
      if (!password) {
        setError('请输入旧密码');
        hasError = true;
      }
      if (!newPassword) {
        setNewPasswordError('请输入新密码');
        hasError = true;
      } else {
        if (!newPassword.match(PASSWORDREGEX)) {
          setNewPasswordError('密码8-20位，必须包含字母和数字（不能包含空格），字母区分大小写');
          hasError = true;
        } else {
          if (newPassword !== confirmPassword) {
            setConfirmPasswordError('两次输入的密码不一致');
            hasError = true;
          }
        }
      }
      if (!confirmPassword) {
        setConfirmPasswordError('请输入确认新密码');
        hasError = true;
      }
    }
    return hasError;
  }
  async function hadnleOkClick() {
    setError('');
    setMailCodeError('');
    setOldMailCodeError('');
    setOldMailError('');
    let hasError = validate();
    if (!hasError) {
      if (step === 0 && ['bindMail', 'updateMail'].includes(dialogType)) {
        const response = await checkPassword({ name: user?.name || '', password });
        const result = response.data;
        if (response.status === 200) {
          const newCompleted = completed;
          newCompleted[step] = true;
          setStep(1);
          setCompleted(newCompleted);
        } else {
          const message = JSON.parse(result.message);
          if (message.code === 'INVALID_PASSWORD') {
            setError('请输入正确的密码');
          }
        }
      } else if (step === 1) {
        if (dialogType === 'bindMail') {
          if (!hasError) {
            setLoading(true);
            await bindEmail(mail, mailCode);
            if (user?.mail) {
              user.mail = mail;
            }
            clearValue();
          }
        } else {
          if (!hasError) {
            setLoading(true);
            await updateEmail(mail, mailCode, oldMail, oldMailCode);
            if (user?.mail) {
              user.mail = mail;
            }
            clearValue();
          }
        }
      } else if (dialogType === 'updatePassword') {
        const response = await checkPassword({ name: user?.name || '', password });
        const result = response.data;
        if (response.status === 200) {
          await updatePassword(password, newPassword);
          clearValue();
        } else {
          const message = JSON.parse(result.message);
          if (message.code === 'INVALID_PASSWORD') {
            setError('请输入正确的旧密码');
          }
        }
      }
    }
  }
  function getDialogTitle() {
    let title = '';
    if (step === 0 && ['bindMail', 'updateMail'].includes(dialogType)) {
      title = t('验证身份');
    } else if (step === 1 && dialogType === 'bindMail') {
      title = t('绑定邮箱');
    } else if (step === 1 && dialogType === 'updateMail') {
      title = t('更改邮箱');
    } else if (dialogType === 'updatePassword') {
      title = t('修改密码');
    }
    return title;
  }
  function renderPassword() {
    return (<div className={styles['password-content']}>
      <div className={styles['account-line-content']}>
        <div className={styles['account-line']}>
          <FormItemLabel label={t('账号')} width={90} />
          <span>{user?.name}</span>
        </div>
      </div>
      <div className={styles['account-line-content']}>
        <div className={styles['account-line']}>
          <FormItemLabel label={t('旧密码')} required={true} width={90} />
          <TextField
            value={password}
            variant="outlined"
            onChange={(e) => handleTextFieldChange(e, setPassword)}
            classes={{ root: styles['password-text-input'] }}
            placeholder={t('请输入旧密码')}
            error={!!error}
          />
        </div>
        <div className={styles['password-tip']}>{t('如果忘记密码，请联系XSKY销售代表重置密码')}</div>
        <div className={styles['password-text-error']}><ErrorText error={{ error: !!error, text: error }} /></div>
      </div>
      <div className={styles['account-line-content']}>
        <div className={styles['account-line']}>
          <FormItemLabel label={t('新密码')} required={true} width={90} />
          <TextField
            value={newPassword}
            variant="outlined"
            onChange={(e) => handleTextFieldChange(e, setNewPassword)}
            classes={{ root: styles['password-text-input'] }}
            placeholder={t('请输入新密码')}
            error={!!newPasswordError}
          />
        </div>
        <div className={styles['password-tip']}>{t('密码8-20位，必须包含字母和数字（不能包含空格），字母区分大小写')}</div>
        <div className={styles['password-text-error']}><ErrorText error={{ error: !!newPasswordError, text: newPasswordError }} /></div>
      </div>
      <div className={styles['account-line-content']}>
        <div className={styles['account-line']}>
          <FormItemLabel label={t('确认新密码')} required={true} width={90} />
          <TextField
            value={confirmPassword}
            variant="outlined"
            onChange={(e) => handleTextFieldChange(e, setConfirmPassword)}
            classes={{ root: styles['password-text-input'] }}
            placeholder={t('请输入新密码')}
            error={!!confirmPasswordError}
          />
        </div>
        <div className={styles['password-text-error']}><ErrorText error={{ error: !!confirmPasswordError, text: confirmPasswordError }} /></div>
      </div>
    </div>);
  }

  function renderMail() {
    let steps: string[] = [t('身份验证')];
    if (dialogType === 'bindMail') {
      steps.push(t('绑定邮箱'));
    } else {
      steps.push(t('更改邮箱'));
    }
    return (<div className={styles['mail-content']}>
      <div className={styles['step-content']}>
        <Stepper nonLinear activeStep={step}>
          {
            steps.map((c, index) => <Step key={c} completed={completed[index]}>
              <StepLabel >
                {c}
              </StepLabel>
            </Step>)
          }
        </Stepper>
      </div>
      {
        step === 0 && ['bindMail', 'updateMail'].includes(dialogType) && <div className={styles['account-column']}>
          <div className={styles['account-column']}>
            <div className={styles['account-line']}>
              <FormItemLabel label={t('密码')} required={true} width={40} />
              <TextField
                value={password}
                variant="outlined"
                onChange={(e) => handleTextFieldChange(e, setPassword)}
                classes={{ root: styles['password-text-input'] }}
                placeholder={t('请输入密码')}
                type='password'
                error={!!error}
              />
            </div>
            <div className={styles['check-password-password-tip']}>{t('如果忘记密码，请联系XSKY销售代表重置密码')}</div>
            <div className={styles['check-password-text-error']}> <ErrorText error={{ error: !!error, text: error }} /> </div>
          </div>
        </div>
      }
      {
        step === 1 && <div className={styles['account-column']}>
          <div className={styles.tip}>{t('{{type}}的邮箱将作为您的登陆账号', { type: dialogType === 'bindMail' ? '绑定' : '更改' })}</div>
          <div className={styles['account-line-content']}>
            <div className={styles['account-line']}>
              <FormItemLabel label={t('{{type}}邮箱', { type: dialogType === 'updateMail' ? '新' : '' })} required={true} width={60} />
              <TextField
                value={mail}
                variant="outlined"
                onChange={(e) => handleTextFieldChange(e, setMail)}
                classes={{ root: styles['mail-text-input'] }}
                placeholder={t('请输入{{type}}邮箱', { type: dialogType === 'updateMail' ? '新' : '' })}
                error={!!error}
              />
            </div>
            <div className={styles['text-error']}><ErrorText error={{ error: !!error, text: error }} /></div>
          </div>
          <div className={styles['account-line-content']}>
            <div className={styles['account-line']}>
              <FormItemLabel label={t('验证码')} width={60} />
              <TextField
                value={mailCode}
                variant="outlined"
                onChange={(e) => handleTextFieldChange(e, setMailCode)}
                classes={{ root: styles['text-input'] }}
                placeholder={t('请输入邮箱中的验证码')}
                error={!!mailCodeError}
              />
              <div className={styles['mail-code']}>
                <Button
                  variant="outlined"
                  onClick={() => hadnleSendEmailClick(mail, setCountdown, setClock)}
                  disabled={!!countdown}
                  // color='primary'
                  classes={{ root: styles['mail-button'] }}
                >
                  {countdown ? countdown : t('获取验证码')}
                </Button>
              </div>
            </div>
            <div className={styles['text-error']}><ErrorText error={{ error: !!mailCodeError, text: mailCodeError }} /></div>
          </div>
          {
            dialogType === 'updateMail' && <>
              <div className={styles['account-line-content']}>
                <div className={styles['account-line']}>
                  <FormItemLabel label={t('原邮箱')} required={true} width={60} />
                  <TextField
                    value={oldMail}
                    variant="outlined"
                    onChange={(e) => handleTextFieldChange(e, setOldMail)}
                    classes={{ root: styles['mail-text-input'] }}
                    placeholder={t('请输入原邮箱')}
                    error={!!oldMailError}
                  />
                </div>
                <div className={styles['text-error']}><ErrorText error={{ error: !!oldMailError, text: oldMailError }} /></div>
              </div>
              <div className={styles['account-line-content']}>
                <div className={styles['account-line']}>
                  <FormItemLabel label={t('验证码')} width={60} />
                  <TextField
                    value={oldMailCode}
                    variant="outlined"
                    onChange={(e) => handleTextFieldChange(e, setOldMailCode)}
                    classes={{ root: styles['text-input'] }}
                    placeholder={t('请输入邮箱中的验证码')}
                    error={!!oldMailCodeError}
                  />
                  <div className={styles['mail-code']}>
                    <Button
                      variant="outlined"
                      onClick={() => hadnleSendEmailClick(oldMail, setOldCountdown, setOldClock)}
                      disabled={!!oldCountdown}
                      classes={{ root: styles['mail-button'] }}
                    >
                      {oldCountdown ? oldCountdown : t('获取验证码')}
                    </Button>
                  </div>
                </div>
                <div className={styles['text-error']}><ErrorText error={{ error: !!oldMailCodeError, text: oldMailCodeError }} /></div>
              </div>
            </>
          }
        </div>
      }
    </div>)

  }
  return <div className={styles.container}>
    <div className={styles['account-title']}>
      <span className={styles.back} onClick={handleBackClick}>
        <WestIcon className={styles['back-icon']} />
        {t('账号管理')}
      </span>
    </div>
    <div className={styles.content}>
      <div className={styles['account-info-title']}>{t('我的账号')}</div>
      <div className={styles['account-name']}><span className={styles['account-label']}>{t('账号')}</span>{user?.name}</div>
      <div className={styles['account-mail']}>
        <div className={styles['account-mail-content']}>
          <span className={styles['account-label']}>{t('邮箱')}</span>
          {user?.mail}
        </div>
        {!user?.mail && <span onClick={handleBindMailClick} className={`${styles['account-mail-bind']} link-without-decoration`}>{t('绑定')}</span>}
        {user?.mail && <span onClick={handleUpdateMailClick} className={`${styles['account-mail-bind']} link-without-decoration`}>{t('更改')}</span>}
      </div>
      <div className={styles['account-type']}><span className={styles['account-label']}>{t('账号类型')}</span>{user?.type}</div>
      <div className={styles['account-password-title']}>{t('我的密码')}</div>
      <div className={styles['account-password']}>
        <div className={styles['account-password-content']}>
          <span className={styles['account-label']}>{t('登录密码')}</span>
          {PASSWORD_SECRET}
        </div>
        <span onClick={handleUpdatePasswordClick} className={`${styles['account-password-update']} link-without-decoration`}>{t('修改')}</span></div>
    </div>
    <Dialog open={showDialog} >
      <div className={styles['modal-container']}>
        <DialogTitle id="scroll-dialog-title" hasClose onCloseClick={handleCancelDialogClick}>{getDialogTitle()}</DialogTitle>
        <DialogContent >
          {['bindMail', 'updateMail'].includes(dialogType) && renderMail()}
          {dialogType === 'updatePassword' && renderPassword()}
          < div className={styles.action}>
            <div className={styles.cancel}>
              <TextButton
                size='medium'
                onClick={handleCancelDialogClick}
                variant="text"
                classes={{ root: styles['cancel-button'] }}
              >
                {t('取消')}
              </TextButton>
            </div>
            <Button
              variant='contained'
              onClick={hadnleOkClick}
              disabled={loading}
              loading={loading}
              classes={{ root: styles['action-button'] }}
            >
              {step === 0 && ['bindMail', 'updateMail'].includes(dialogType) ? t('下一步') : t('确认')}
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog >
  </div >
}

export default AccountForm;