import t from 'locale';

export const userNameColumn = {
  title: t('姓名'),
  dataKey: 'name',
}

export const userEmailColumn = {
  title: t('用户邮箱'),
  dataKey: 'email',
};

export const userGroupTypeColumn = {
  title: t('类型'),
  dataKey: 'type',
}

export const userGroupNameColumn = {
  title: t('用户(组)名称'),
  dataKey: 'name',
}
