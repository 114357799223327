import { LicenseFormPartTitleProps } from 'interfaces/props';
import styles from './index.module.scss';

const PartTitle = (props: LicenseFormPartTitleProps) => {
  return (<div className={styles['part-title']}>
    {props.title}
    <span className={styles['part-title-line']}></span>
  </div>);
}

export default PartTitle;