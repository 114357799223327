import { ClusterDelivery } from 'model';
import { 
  deliveryDateColumn,
  deliveryProductColumn,
  deliveryCountColumn,
  Table 
} from 'components';
import { useEffect, useState } from 'react';
import { getDeliveriesFromCustomerByContractId } from 'request';
import styles from './index.module.scss';

const DeliveryRecord = (props: { contractId: number }) => {
  const [deliveries, setDeliveries] = useState<ClusterDelivery[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDeliveries()
  }, []);
  async function getDeliveries() {
    setLoading(true);
    const response = await getDeliveriesFromCustomerByContractId(props.contractId);
    const result = response.data;
    setDeliveries(result);
    setLoading(false);
  }
  const columns = [
    deliveryDateColumn,
    deliveryProductColumn,
    deliveryCountColumn,
  ];
  return (<div className={styles.content}>
    <Table data={deliveries} columns={columns} loading={loading} />
  </div>);
}
export default DeliveryRecord;