import { DetailsBasicFieldProps } from 'interfaces/props';
import styles from './index.module.scss';

const DetailsBasicField = (props: DetailsBasicFieldProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{props.label}</div>
      <div className={styles.text}>{props.render ? props.render() : props.text}</div>
    </div>);
}

export default DetailsBasicField;