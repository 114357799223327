
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import classnames from 'classnames';
import { GlobalContext } from 'context';
import { getLicenseContractType, pocVersion } from 'common/src/license';
import {
  approvalApplyDateColumn,
  digitalLicenseProductColumn,
  digitalLicenseSerialNoColumn,
  FormItemLabel,
  FormItemLine,
  ErrorText,
  Table,
} from 'components';
import { LICENSE_CLUSTER_MD5_TYPE } from 'constant';
import { LicenseFormRadioKey, LicenseFormTextKey } from 'interfaces/models';
import { LicenseFormBasicProps, TableColumn } from 'interfaces/props';
import t from 'locale';
import lodash from 'lodash';
import { DigitalLicenseKeyAlgorithm, DigitalLicenseKeyVersion, LicenseDigital, LicenseDigitalContractType, LicenseDigitalVersion, ParseLicenseKeyResult, UploadLicenseFileResult } from 'model';
import { useRef, useContext, useState } from 'react';
import { analyzeLicenseKey, getLastDownloadedLicenseByMd5, getOemLicensesByMd5 } from 'request';
import { getHistoryApplyInfoByMd5 } from 'utils';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
  Tooltip
} from 'x-material-ui';
import styles from './index.module.scss';
import PartTitle from './part-title';
import { validateLicenseMd5, validateLicenseNewContractTypeExits } from './validation';
import { useUpload } from '../../hooks';
import { getKeyInfoFromKeyResult, getMd5FromKeyResult } from './utils';

const licenseKeyFileType = '.key';
const Basic = (props: LicenseFormBasicProps) => {
  const {
    license,
    update,
    clearError,
    error,
    contractTypes,
    isNewContractLicenseExist,
    getFeatures,
    updateError,
    setOriginalLicense,
    setCurrentFeatures,
    setIsNewContractLicenseExist,
    version,
  } = props;
  const [licenseKeyError, setLicenseKeyError] = useState(false);
  const [showOemHistoryModel, setShowOemHistoryModel] = useState<boolean>(false);
  const [oemHistoryLicenses, setOemHistoryLicenses] = useState<LicenseDigital[]>([]);
  const [currentLicense, setCurrentLicense] = useState<LicenseDigital>();
  const [showLicenseDetail, setShowLicenseDetail] = useState(false);
  const { token, user } = useContext(GlobalContext);
  const fileRef = useRef<HTMLInputElement>(null);
  const { uploadFile, error: licenseFileError } = useUpload(handleUploadSuccess, clearUploadInput);
  function handleRadioChange(value: string, key: LicenseFormRadioKey) {
    update({
      [key]: value,
    });
  }
  function handleTextChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: LicenseFormTextKey) {
    let clusterMd5 = license.clusterMd5;
    if (key === 'licenseKey') {
      clusterMd5 = '';
      setLicenseKeyError(false);
    }
    update({
      [key]: e.target.value,
      clusterMd5,
    });
    clearError(key);
  }
  function handleV6ClusterMd5(md5: ParseLicenseKeyResult[]) {
    let globalMd5, dataClusterMd5, objectServicePlatformMd5, v5UpgradeClusterUuid;
    const globalKeyInfo = getKeyInfoFromKeyResult(md5[0]);
    if (globalKeyInfo.md5.startsWith('region')) {
      globalMd5 = globalKeyInfo.md5.split('-')[1];
    }
    let dataPlatformMd5 = md5.slice(1);
    const v5UpgradeUuid = dataPlatformMd5.filter(s => getMd5FromKeyResult(s).indexOf('ceph-uuid') !== -1);
    if (v5UpgradeUuid.length) {
      v5UpgradeClusterUuid = getMd5FromKeyResult(v5UpgradeUuid[0]).split('ceph-uuid-')[1];
      lodash.remove(dataPlatformMd5, n => getMd5FromKeyResult(n).startsWith('ceph-uuid'));
    }
    if (dataPlatformMd5.filter(s => getMd5FromKeyResult(s).indexOf('osp-uuid') !== -1).length) {
      lodash.remove(dataPlatformMd5, n => getMd5FromKeyResult(n).startsWith('osp-uuid'));
    }
    dataClusterMd5 = dataPlatformMd5.filter(s => getMd5FromKeyResult(s).indexOf('ceph') !== -1);
    objectServicePlatformMd5 = dataPlatformMd5.filter(s => getMd5FromKeyResult(s).indexOf('ngos') !== -1);
    if ((license.pocVersion && !pocVersion.includes(license.pocVersion)) || !license.pocVersion) {
      objectServicePlatformMd5 = dataPlatformMd5.filter(s => getMd5FromKeyResult(s).indexOf('osp') !== -1);
    }
    return { globalMd5, dataClusterMd5, objectServicePlatformMd5, v5UpgradeClusterUuid };
  }
  async function handleUploadSuccess(result: UploadLicenseFileResult) {
    clearUploadInput();
    let clusterMd5;
    let dataClusterMd5;
    let objectServicePlatformMd5;
    let contractType = license.contractType;
    let v5UpgradeClusterUuid;
    let expectedUsageTime = 0;
    let keyAlgorithm: DigitalLicenseKeyAlgorithm | undefined = undefined;
    let keyVaultAgentKey: string | undefined = undefined;
    let keyVaultKey: string | undefined = undefined;
    let keyVersion: DigitalLicenseKeyVersion | undefined = undefined;
    if (version === 'V6') {
      const md5Info = result.md5 as unknown as ParseLicenseKeyResult[];
      const md5Data = handleV6ClusterMd5(md5Info);
      const keyInfo = getKeyInfoFromKeyResult(md5Info[0]);
      clusterMd5 = md5Data.globalMd5;
      dataClusterMd5 = md5Data.dataClusterMd5;
      objectServicePlatformMd5 = md5Data.objectServicePlatformMd5;
      keyAlgorithm = keyInfo.keyAlgorithm;
      keyVaultAgentKey = keyInfo.keyVaultAgentKey;
      keyVaultKey = keyInfo.keyVaultKey;
      keyVersion = keyInfo.keyVersion;
    } else {
      clusterMd5 = result.md5 as string;
      const { expectedUsageTime: licenseExpectedUsageTime } = await getHistoryApplyInfoByMd5(clusterMd5, license as Partial<LicenseDigital>);
      expectedUsageTime = licenseExpectedUsageTime;
    }
    update({
      keyFile: result,
      clusterMd5,
      keyFileId: result.id,
      expectedUsageTime,
      dataClusterMd5,
      objectServicePlatformMd5,
      contractType,
      v5UpgradeClusterUuid,
      md5Data: result.md5 as unknown as ParseLicenseKeyResult[],
      keyAlgorithm,
      keyVaultAgentKey,
      keyVaultKey,
      keyVersion,
    });
    if (clusterMd5) {
      await validateMd5(clusterMd5);
      await validateContractType(clusterMd5);
      await fetchOriginalLicense(clusterMd5);
    }
  }

  async function handleLicenseKeyBlur() {
    if (license.licenseKey && !license.clusterMd5) {
      const response = await analyzeLicenseKey(license.licenseKey, version);
      if (response.status === 200) {
        if (version === 'V6') {
          const result = response.data as unknown as ParseLicenseKeyResult[];
          const md5Data = handleV6ClusterMd5(result);
          const globalMd5 = md5Data.globalMd5;
          const dataClusterMd5 = md5Data.dataClusterMd5;
          const objectServicePlatformMd5 = md5Data.objectServicePlatformMd5;
          const v5UpgradeClusterUuid = md5Data.v5UpgradeClusterUuid;
          const keyInfo = getKeyInfoFromKeyResult(result[0]);
          update({
            clusterMd5: globalMd5,
            dataClusterMd5,
            objectServicePlatformMd5,
            v5UpgradeClusterUuid,
            md5Data: response.data as unknown as ParseLicenseKeyResult[],
            keyAlgorithm: keyInfo.keyAlgorithm,
            keyVaultAgentKey: keyInfo.keyVaultAgentKey,
            keyVaultKey: keyInfo.keyVaultKey,
            keyVersion: keyInfo.keyVersion,
          });
          if (globalMd5) {
            fetchOriginalLicense(globalMd5);
          }

        } else {
          const md5 = response.data as string;
          const { expectedUsageTime } = await getHistoryApplyInfoByMd5(md5, license as Partial<LicenseDigital>);
          update({
            clusterMd5: md5,
            expectedUsageTime,
          });
          fetchOriginalLicense(md5);
        }
      }
      if (response.data) {
        await validateMd5(response.data as string);
        await validateContractType(response.data as string);
        await fetchOriginalLicense(response.data as string);
      }
    } else {
      setLicenseKeyError(true);
    }

  }
  function handleSupportedVersionChange(version: LicenseDigitalVersion, checked: boolean) {
    const versions = license.supportVersions || [];
    if (checked) {
      versions.push(version);
    } else {
      const index = versions.indexOf(version);
      versions.splice(index, 1);
    }
    update({
      supportVersions: versions,
    });
    clearError('supportVersions');
  }
  async function validateContractType(md5: string) {
    const contractTypeError = await validateLicenseNewContractTypeExits(md5);
    let isDelay = false;
    if (contractTypeError) {
      isDelay = true;
      setIsNewContractLicenseExist(true);
      update({
        isDelay,
      });
    } else {
      setIsNewContractLicenseExist(false);
    }
  }
  async function fetchOriginalLicense(md5: string) {
    const response = await getLastDownloadedLicenseByMd5(md5);
    const result = response.data;
    if (result) {
      const seriesFeatures = getFeatures(result.productInfo);
      setCurrentFeatures(seriesFeatures);
      if (result.version === 'V4') {
        result.services = result?.services.filter(s => !['service_hardware_5_9', 'service_hardware_7_24'].includes(s.name)) || [];
      }
      setOriginalLicense(result);
      update({
        ...getOriginalLicenseInfo(result),
        contractNo: result.contractNo || license.contractNo || ''
      });
    } else {
      setOriginalLicense(undefined);
    }
  }
  function getOriginalLicenseInfo(l: LicenseDigital) {
    let pickKeys = ['limits', 'productInfo', 'features', 'services', 'contractNo'];
    if (version === 'V5') pickKeys = lodash.omit(pickKeys, ['services']) as string[];
    const info = lodash.pick(l, pickKeys);
    return lodash.cloneDeep(info);
  }
  function clearUploadInput() {
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  }
  async function validateMd5(md5: string) {
    error.clusterMd5 = await validateLicenseMd5(md5, license?.id);
    updateError({
      ...error,
    });
  }
  function handleClusterNameChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    update({
      clusterName: e.target.value,
    });
    clearError(['clusterInfo', 'clusterType', 'clusterMd5']);
  }
  function handleSerialNoClick(license: LicenseDigital) {
    setCurrentLicense(license);
    setShowLicenseDetail(true);
    setShowOemHistoryModel(false);
  }
  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target && event.target.files && event.target.files[0]) {
      const currentFile = event.target.files[0];
      const reader = new FileReader();
      reader.readAsText(currentFile);
      reader.onload = async () => {
        const result = reader.result;
        if (typeof result === 'string') {
          update({
            licenseKey: result,
          });
        }
      };
      clearError('keyFile');
      let type = 'digital-license';
      if (version === 'V6') {
        type = 'digital-license-v6';
      }
      await uploadFile(currentFile, 'digitalLicense', type);
    }
  }
  async function handleOemHistoryModelClick() {
    if (license.clusterMd5) {
      const response = await getOemLicensesByMd5(user?.companyId || 0, license.clusterMd5);
      const historyLicense = response.data[0];
      if (historyLicense) {
        setOemHistoryLicenses(historyLicense);
      }
      setShowOemHistoryModel(true);
    }
  }
  function handleCancelOemModelClick() {
    setShowOemHistoryModel(false);
  }
  function getSupportedVersionOptions() {
    if (license.contractType !== 'upgrade') {
      return [license.version || 'V4'];
    } else {
      if (license.version === 'V4') {
        return ['V4', 'V5'];
      } else if (license.version === 'V5') {
        return ['V5', 'V6'];
      } else if (license.version === 'V6') {
        return ['V6'];
      }
      return ['V3', 'V4', 'V5', 'V6'];
    }
  }
  function handleEnableMcmChange(checked: boolean) {
    update({
      enableMcm: checked,
      skus: [],
      productSku: undefined,
      licenseV6Cluster: [],
      objectServicePlatformMd5: [],
      licenseKey: undefined,
      clusterMd5: undefined,
      clusterAddress: undefined,
      keyFile: undefined,
      keyFileId: undefined,
      dataClusterMd5: [],
      mcmId: undefined,
      mcm: undefined,
    });
  }
  const columns: TableColumn[] = [
    digitalLicenseSerialNoColumn(handleSerialNoClick),
    digitalLicenseProductColumn,
    approvalApplyDateColumn,
  ];
  return (<div className={styles['part-container']}>
    <PartTitle title={t('基本信息')} />
    <div className={styles['part-content']}>
      <FormItemLine>
        <FormItemLabel label={t('使用场景')} />
        <span className={styles['scene-label']}>
          {t('POC')}
          <Tooltip title={t('用于 POC 测试')} placement="top" arrow>
            <InfoOutlinedIcon className={styles.icon} fontSize='small' />
          </Tooltip>
        </span>
      </FormItemLine>
      {license.version === 'V6' && <FormItemLine>
        <FormItemLabel label={t('多集群')} />
        <div>
          <div>
            <FormControlLabel
              control={<Checkbox
                checked={license.enableMcm || false}
                color="primary"
                onChange={(e, checked) => handleEnableMcmChange(checked)}
                name="enableMcm"
              />}
              label={t('启用')}
            />
          </div>
          <div>
            {license.enableMcm && <span className='warning-text'>{t('启用多集群仅针对部署对象服务平台，单集群场景无需启用。启用后无法更改回单集群，请谨慎操作。')}</span>}
          </div>
        </div>
      </FormItemLine>}
      <FormItemLine>
        <FormItemLabel label={t('密钥获取方式')} />
        <RadioGroup value={license.getMd5Type} onChange={(e, value) => handleRadioChange(value, 'getMd5Type')} row>
          {Object.keys(LICENSE_CLUSTER_MD5_TYPE).map(t => (
            <FormControlLabel
              value={t}
              control={<Radio color="primary" />}
              label={LICENSE_CLUSTER_MD5_TYPE[t]}
              labelPlacement="end"
              key={t}
            />))}
        </RadioGroup>
      </FormItemLine>
      {
        license.getMd5Type === 'manual' && <FormItemLine>
          <FormItemLabel label={t('密钥文件')} required />
          <div>
            <div className={styles['upload-value']}>
              <input
                ref={fileRef}
                className={styles['upload-input']}
                id="contained-button-file"
                accept={licenseKeyFileType}
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <span className={styles.upload}>
                  <FileUploadOutlinedIcon className={styles['upload-icon']} />
                  <span>{t('选择文件')}</span>
                </span>
              </label>
              <span className={styles['file-name']}>{license.keyFile?.name}</span>
            </div>
            <ErrorText error={{ error: licenseFileError, text: t('请选择合法的密钥文件') }} />
            <ErrorText error={error.keyFile} />
          </div>
        </FormItemLine>
      }
      {
        license.getMd5Type === 'qr-code' && <FormItemLine>
          <FormItemLabel label={t('集群密钥')} required />
          <div>
            <TextareaAutosize
              className={classnames('textarea', styles.textarea)}
              maxRows={15}
              onChange={e => handleTextChange(e, 'licenseKey')}
              placeholder={t('请输入集群密钥')}
              value={license.licenseKey || ''}
              onBlur={handleLicenseKeyBlur}
            />
            <ErrorText error={{ error: licenseKeyError, text: t('请输入合法的集群密钥') }} />
            <ErrorText error={error.licenseKey} />
          </div>
        </FormItemLine>
      }
      <FormItemLine>
        <FormItemLabel label={t('集群 MD5')} required />
        <div>
          {license.clusterMd5}
          <ErrorText error={error.clusterMd5} />
        </div>
      </FormItemLine>
      <FormItemLine>
        <FormItemLabel label={t('合同类型')} />
        <RadioGroup value={license.contractType} onChange={(e, value) => handleRadioChange(value as LicenseDigitalContractType, 'contractType')} row>
          {contractTypes.map(type => (
            <FormControlLabel
              value={type}
              control={<Radio color="primary" />}
              label={getLicenseContractType(type, t)}
              labelPlacement="end"
              key={type}
              disabled={(type === 'new' && isNewContractLicenseExist)}
            />))}
        </RadioGroup>
        <ErrorText error={error?.contractType} />
      </FormItemLine>
      <FormItemLine>
        <FormItemLabel label={t('是否为延期')} />
        <span>{license.isDelay ? t('是') : t('否')}</span>
      </FormItemLine>
      <FormItemLine>
        <FormItemLabel label={t('厂商')} required />
        {license?.vendor?.name || user?.nickname}
        <span onClick={handleOemHistoryModelClick} className={classnames('link-without-decoration', styles['history-link-button'])} >
          {t('历史信息')}
        </span>
      </FormItemLine>
      <FormItemLine>
        <FormItemLabel label={t('支持产品版本范围')} required />
        <div>
          <div className={styles['support-versions']}>
            {getSupportedVersionOptions().map(v => {
              const version = v as LicenseDigitalVersion;
              return (<div key={v}>
                <FormControlLabel
                  control={<Checkbox
                    checked={license.supportVersions?.includes(version) || false}
                    color="primary"
                    onChange={(e, checked) => handleSupportedVersionChange(version, checked)}
                    name={v}
                  />}
                  label={v}
                />
              </div>);
            })}
          </div>
          <ErrorText error={error.supportVersions} />
        </div>
      </FormItemLine>
      <FormItemLine>
        <FormItemLabel label={t('客户名称')} />
        <TextField
          variant="outlined"
          onChange={e => handleTextChange(e, 'customerName')}
          value={license.customerName || ''}
          classes={{ root: styles['text-input'] }}
          placeholder={t('请输入客户名称')}
        />
      </FormItemLine>
      <FormItemLine>
        <FormItemLabel label={t('客户联系人')} />
        <TextField
          variant="outlined"
          onChange={e => handleTextChange(e, 'customerContact')}
          value={license.customerContact || ''}
          classes={{ root: styles['text-input'] }}
          placeholder={t('请输入客户方的对接人姓名')}
        />
      </FormItemLine>
      {license.version !== 'V6' && <FormItemLine>
        <FormItemLabel label={t('集群名称')} required />
        <div>
          <TextField
            variant="outlined"
            onChange={handleClusterNameChange}
            value={license.clusterName}
            classes={{ root: styles['text-input'] }}
            placeholder={t('请输入集群名称')}
          />
          <ErrorText error={error.clusterName} />
        </div>
      </FormItemLine>}
    </div>
    {
      showOemHistoryModel && <Dialog open={showOemHistoryModel} scroll="paper">
        <div className={styles['modal-container']}>
          <DialogTitle id="scroll-dialog-title" hasClose onCloseClick={handleCancelOemModelClick}>{t('历史信息')}</DialogTitle>
          <DialogContent dividers={true}>
            <Table columns={columns} data={oemHistoryLicenses} rowKey='code' loading={false} />
          </DialogContent>
        </div>
      </Dialog>
    }
  </div>);
}

export default Basic;