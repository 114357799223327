import React, { useState, useEffect } from 'react';
import {
  Table,
  roleDescColumn,
  checkBoxWithTextColumn,
  deleteColumn,
} from 'components';
import t from 'locale';
import {
  getRoles,
  createRole,
  updateRole,
  removeRole,
  updateRolePermission,
  addUserToRole,
  removeUserFromRole,
} from 'request';
import { Role } from 'model';
import { ModalActions } from 'interfaces/props';
import { LinkButton, Dialog, DialogTitle } from 'x-material-ui';
import { UserGroupForm, RoleForm, PermissionForm, DeleteRoleForm } from 'forms';
import RoleInfo from './RoleInfo';
import styles from './index.module.scss';
import Toolbar from '../Toolbar';
import Pagination from '../Pagination';
import { TABLE_DEFAULT_PAGE_SIZE } from 'constant';
import { usePagination } from '../../hooks';

const Roles = (props: { onOpenDrawer: (show: boolean, children?: JSX.Element) => void }) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [search, setSearch] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showRoleDeleteModal, setShowRoleDeleteModal] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [showUserGroupModal, setShowUserGroupModal] = useState(false);
  const [tempDelete, setTempDelete] = useState<Role[]>([]);
  const [action, setAction] = useState<ModalActions>();
  const [currentOperateRole, setCurrentOperateRole] = useState<Role>();
  const { limit, page, setPage, handlePageChange, handleRowsPerPageChange } = usePagination(handlePagerChange);
  const actionMap = {
    add: '添加角色',
    edit: '编辑角色',
    'add-user-group': '添加用户组',
    'remove-user-group': '移除用户组',
    'add-permissions': '添加权限',
    'remove-permissions': '移除权限',
    delete: '删除角色',
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async (limit: number = TABLE_DEFAULT_PAGE_SIZE, page: number = 0, search: string = '') => {
    setLoading(true);
    const response = await getRoles(limit, page * limit, search);
    setLoading(false);
    setCount(response.data[1]);
    setRoles(response.data[0]);
    setSelectedRoles([]);
  };

  const handleRoleCheck = (e: any, checked: boolean, record: Role) => {
    if (checked) {
      selectedRoles.push(record.id);
      setSelectedRoles(selectedRoles.slice());
    } else {
      setSelectedRoles(selectedRoles.filter(id => id !== record.id));
    }
  };

  const handleAddClick = () => {
    setAction('add');
    setShowRoleModal(true);
  };

  const handleEditClick = () => {
    setAction('edit');
    setShowRoleModal(true);
  };

  const handleAddUserGroup = (current?: Role) => {
    setAction('add-user-group');
    setShowUserGroupModal(true);
    if (current) {
      setCurrentOperateRole(current);
    } else {
      setCurrentOperateRole(roles.find(r => r.id === selectedRoles[0]));
    }
  };

  const handleAddUserPermissions = (current?: Role) => {
    setAction('add-permissions');
    setShowPermissionModal(true);
    if (current) {
      setCurrentOperateRole(current);
    } else {
      setCurrentOperateRole(roles.find(r => r.id === selectedRoles[0]));
    }
  };

  const handleRemoveUserPermissions = () => {
    setAction('remove-permissions');
    setShowPermissionModal(true);
    setCurrentOperateRole(roles.find(r => r.id === selectedRoles[0]));
  };

  const handleRemoveUserGroup = () => {
    setAction('remove-user-group');
    setShowUserGroupModal(true);
    setCurrentOperateRole(roles.find(r => r.id === selectedRoles[0]));
  };

  const handleCloseRoleModalClick = () => {
    setShowRoleModal(false);
  };

  const handleClosePermissionModalClick = () => {
    setShowPermissionModal(false);
  };

  const handleRoleModalSubmit = async (role: Pick<Role, 'name' | 'desc'>) => {
    const param = { name: role.name, desc: role.desc };
    if (action === 'add') {
      await createRole(param);
    } else {
      await updateRole(selectedRoles[0], param);
    }
    setShowRoleModal(false);
    fetchRoles(limit, 0, search);
    setPage(0);
  };

  const handleUserGroupModalSubmit = async (roleId: number, users: any) => {
    if (action === 'add-user-group') {
      await addUserToRole(roleId, users);
    } else {
      await removeUserFromRole(roleId, users);
    }
    setShowUserGroupModal(false);
    props.onOpenDrawer(false);
    fetchRoles(limit, page, search);
  };

  const handleCloseUserGroupModalClick = () => {
    setShowUserGroupModal(false);
  };

  const handlePermissionModalSubmit = async (permissions: number[]) => {
    await updateRolePermission(currentOperateRole?.id || 0, permissions);
    setShowPermissionModal(false);
    props.onOpenDrawer(false);
    fetchRoles(limit, page, search);
  };
  const handleCloseDeleteRoleModalClick = () => {
    setShowRoleDeleteModal(false);
    setTempDelete([]);
  };

  const handleCloseDeleteRoleModalSubmit = async (selectedRoles: number[]) => {
    for (let i = 0; i < selectedRoles.length; i++) {
      await removeRole(selectedRoles[i]);
    }
    fetchRoles(limit, 0, search);
    setPage(0);
    setTempDelete([]);
    setShowRoleDeleteModal(false);
  };

  const handleRemoveClick = (selectedRole?: Role) => {
    if (selectedRole && selectedRole.name) {
      setTempDelete([selectedRole]);
    }
    setAction('delete');
    setShowRoleDeleteModal(true);
  };

  const handleToggleRoleDrawer = (isOpen: boolean, row: any) => {
    const Info = () => (
      <RoleInfo
        addUserToRole={handleAddUserGroup}
        addPermissionToRole={handleAddUserPermissions}
        role={row}
      />
    );
    props.onOpenDrawer(isOpen, Info());
    if (isOpen) {
      setCurrentOperateRole(row);
    }
  };
  function handlePagerChange(limit: number, page: number) {
    setPage(page);
    fetchRoles(limit, page, search);
  }
  function handleSearchClick() {
    setPage(0);
    fetchRoles(limit, page, search);
  }

  const handleSearchInputChange = (e: React.ChangeEvent<{ value: string }>) => {
    setSearch(e.target.value);
  };

  const generateName = (row: any) => {
    return (
      <LinkButton
        className={styles.name}
        value={row.name}
        onClick={handleToggleRoleDrawer.bind(null, true, row)}
      />
    );
  };

  return (
    <React.Fragment>
      <Toolbar
        className={styles.toolbar}
        items={[
          { type: 'button', onClick: handleAddClick, children: t('添加角色') },
          {
            type: 'dropdown',
            name: t('操作'),
            menus: [
              { name: t('编辑'), disabled: selectedRoles.length !== 1, callback: handleEditClick },
              {
                name: t('添加用户组'),
                disabled: selectedRoles.length !== 1,
                callback: handleAddUserGroup,
              },
              {
                name: t('移除用户组'),
                disabled: selectedRoles.length !== 1,
                callback: handleRemoveUserGroup,
              },
              {
                name: t('添加权限'),
                disabled: selectedRoles.length !== 1,
                callback: handleAddUserPermissions,
              },
              {
                name: t('移除权限'),
                disabled: selectedRoles.length !== 1,
                callback: handleRemoveUserPermissions,
              },
              { name: t('删除'), disabled: selectedRoles.length <= 0, callback: handleRemoveClick },
            ],
          },
          {
            type: 'search',
            children: search,
            onChange: handleSearchInputChange,
            onSearch: handleSearchClick,
            tableName: 'role',
          },
        ]}
      />
      <div className={styles['table-wrapper']}>
        <div className={styles.table}>
          <Table
            loading={loading}
            data={roles}
            columns={[
              checkBoxWithTextColumn(handleRoleCheck, 'name', t('角色名称'), generateName),
              roleDescColumn,
              deleteColumn(handleRemoveClick),
            ]}
          />
        </div>
        <Pagination
          count={count}
          rowsPerPage={limit}
          page={page}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          className={styles['table-pagination']}
        />
      </div>
      <Dialog open={showRoleModal}>
        <div className={styles['role-modal-container']}>
          <DialogTitle id="form-dialog-title" hasClose onCloseClick={handleCloseRoleModalClick}>
            {action && t(actionMap[action])}
          </DialogTitle>
          <RoleForm
            role={action === 'edit' ? roles.find(r => r.id === selectedRoles[0]) : undefined}
            onCancel={handleCloseRoleModalClick}
            onSubmit={handleRoleModalSubmit}
          />
        </div>
      </Dialog>
      <Dialog open={showRoleDeleteModal}>
        <div className={styles['role-modal-container']}>
          <DialogTitle
            id="form-dialog-title"
            hasClose
            onCloseClick={handleCloseDeleteRoleModalClick}
          >
            {action && t(actionMap[action])}
          </DialogTitle>
          <DeleteRoleForm
            roles={
              action === 'delete'
                ? tempDelete.length
                  ? tempDelete
                  : roles.filter(r => selectedRoles.indexOf(r.id) !== -1)
                : []
            }
            onCancel={handleCloseDeleteRoleModalClick}
            onSubmit={handleCloseDeleteRoleModalSubmit}
          />
        </div>
      </Dialog>
      <Dialog open={showUserGroupModal} maxWidth='md'>
        <div className={styles['user-modal-container']}>
          <DialogTitle
            id="form-dialog-title"
            hasClose
            onCloseClick={handleCloseUserGroupModalClick}
          >
            {action && t(actionMap[action])}
          </DialogTitle>
          <UserGroupForm
            action={action}
            roleName={currentOperateRole?.name || ''}
            role={currentOperateRole}
            onCancel={handleCloseUserGroupModalClick}
            onSubmit={handleUserGroupModalSubmit}
          />
        </div>
      </Dialog>
      <Dialog open={showPermissionModal} maxWidth='md'>
        <div className={styles['permission-modal-container']}>
          <DialogTitle
            id="form-dialog-title"
            hasClose
            onCloseClick={handleClosePermissionModalClick}
          >
            {action && t(actionMap[action])}
          </DialogTitle>
          <PermissionForm
            action={action}
            role={currentOperateRole}
            onCancel={handleClosePermissionModalClick}
            onSubmit={handlePermissionModalSubmit}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default Roles;
