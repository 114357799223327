import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import classNames from 'classnames';
import { DetailsContainerProps } from 'interfaces/props';
import ReactDOM from 'react-dom';
import { ClickAwayListener } from 'x-material-ui';
import styles from './index.module.scss';

const DetailsContainer = (props: DetailsContainerProps) => {
  function handleClickAway(e: MouseEvent | TouchEvent) {
    const root = document.getElementById('root');
    if (root?.contains(e.target as any)) {
      props.setOpen(false);
    }
  };
  function handleCloseClick() {
    props.setOpen(false);
  }
  const container = document.body;
  const node = (<ClickAwayListener onClickAway={handleClickAway}>
    <div className={classNames(styles.container, props.type === 'small' ? styles.small : styles.normal)}>
      <CloseOutlinedIcon className={styles.icon} onClick={handleCloseClick} />
      {props.children}
    </div>
  </ClickAwayListener>);
  if (!props.open) {
    return null;
  }
  return container ? ReactDOM.createPortal(node, container) : node;
}

export default DetailsContainer;