import { Sku } from "model";

export function getV5SkuQuota(sku?: Sku) {
  let hdd = 0;
  let ssd = 0;
  let host = 0;
  let cpu = 0;
  sku?.extensions?.forEach(e => {
    if (e.type === 'limit') {
      hdd += e.limits.maxHddCapacityTb || 0;
      ssd += e.limits.maxSsdCapacityTb || 0;
      host += e.limits.maxHostNum || 0;
      cpu += e.limits.maxCpuNum || 0;
    }
  });
  return { hdd, ssd, host, cpu };
}
export function getV5SkuSsdQuotaOmitCache(sku?: Sku) {
  let ssd = 0;
  sku?.extensions?.forEach(e => {
    if (e.type === 'limit' && e.name.toLowerCase().indexOf('cache') === -1 && e.name.indexOf('对象索引盘') === -1) {
      ssd += e.limits.maxSsdCapacityTb || 0;
    }
  });
  return { ssd };
}