import { TABLE_DEFAULT_PAGE_SIZE } from 'constant';
import { Cluster, TableFilterSetting } from 'model';
import { useEffect, useState } from 'react';
import { getClusters } from 'request';
import {
  clusterContractNosColumn,
  clusterCustomerNameColumn,
  clusterDeployStartColumn,
  clusterFsIdColumn,
  clusterNameColumn,
  clusterProductNameColumn,
  clusterSoftwareWarrantyPeriodColumn,
  clusterVersionColumn,
  clusterWarrantyEndColumn
} from '../Column';
import DefaultTable from '../DefaultTable';
import DetailsContainer from '../DetailsContainer';
import ClusterDetail from './detail';
import styles from './index.module.scss';

const ClusterList = () => {
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState<Cluster>();
  const [showDetail, setShowDetail] = useState(false);
  const [setting, setSetting] = useState<TableFilterSetting>({
    limit: TABLE_DEFAULT_PAGE_SIZE,
    page: 0,
    search: ''
  });
  useEffect(() => {
    fetchData(setting.limit, setting.page, setting.search);
  }, []);
  function handleClusterClick(record: Cluster) {
    setCurrent(record);
    setShowDetail(true);
  }
  const columns = [
    clusterNameColumn(handleClusterClick),
    clusterCustomerNameColumn,
    clusterProductNameColumn,
    clusterVersionColumn,
    clusterSoftwareWarrantyPeriodColumn,
    clusterWarrantyEndColumn,
    clusterContractNosColumn,
    clusterDeployStartColumn,
    clusterFsIdColumn,
  ];
  async function fetchData(limit: number, page: number, search: string) {
    setSetting({
      limit,
      page,
      search,
    });
    setLoading(true);
    const response = await getClusters(limit, page * limit, search);
    const result = response.data;
    setClusters(result[0]);
    setTotal(result[1]);
    setLoading(false);
  }
  return (<div className={styles.container}>
    <DefaultTable
      loading={loading}
      data={clusters}
      columns={columns}
      name="cluster"
      total={total}
      fetchData={fetchData}
    />
    <DetailsContainer open={showDetail} setOpen={setShowDetail}>
      <ClusterDetail id={current?.id} />
    </DetailsContainer>
  </div>);
}

export default ClusterList;