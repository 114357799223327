import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import classnames from 'classnames';
import { formatDateTime } from 'common';
import { checkPermission } from 'common/src/permission';
import { EXPIRE_NAME, TOKEN_NAME, USER_ID } from 'constant';
import { GlobalContext } from 'context';
import { TopBarProps } from 'interfaces/props';
import t from 'locale';
import { Message } from 'model';
import { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getMessages, logout, readAllMessages, readMessages } from 'request';
import { Popover } from 'x-material-ui';
import styles from './index.module.scss';

const TopBar = (props: TopBarProps) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [messageOffset, setMessageOffset] = useState(0);
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageAnchorEl, setMessageAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [accountAnchorEl, setAccountAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [settingAnchorEl, setSettingAnchorEl] = useState<HTMLSpanElement | null>(null);
  const { token } = useContext(GlobalContext);
  const history = useNavigate();
  const { user } = useContext(GlobalContext);
  useEffect(() => {
    fetchMessages();
  }, [])
  async function fetchMessages() {
    const response = await getMessages(messageOffset);
    const result = response.data;
    let count = result[1];
    if (count > 99) {
      count = 99;
    }
    setUnreadCount(count);
    setTotalCount(result[2]);
    setMessages([...messages, ...result[0]]);
    setMessageOffset(result[0].length + messageOffset);
  }
  function handleMessageClick(event: React.MouseEvent<HTMLSpanElement>) {
    setMessageAnchorEl(event.currentTarget);
  }
  function handleAccountClick(event: React.MouseEvent<HTMLSpanElement>) {
    setAccountAnchorEl(event.currentTarget);
  }
  function handleSettingClick(event: React.MouseEvent<HTMLSpanElement>) {
    setSettingAnchorEl(event.currentTarget);
  }
  function handleSettingClose() {
    setSettingAnchorEl(null);
  }
  function handleMessageClose() {
    setMessageAnchorEl(null);
  }
  function handleAccountClose() {
    setAccountAnchorEl(null);
  }
  async function handleReadAllClick() {
    await readAllMessages();
    messages.forEach(m => m.read = true);
    setMessages([...messages]);
  }
  async function handleMessageScroll() {
    const container = document.querySelector(`.${styles['message-list-content']}`);
    if (container) {
      if ((container.clientHeight + container.scrollTop) === container.scrollHeight && messages.length < totalCount) {
        fetchMessages();
      }
    }
  }
  function handleAccountManagementClick() {
    history('/account-form');
    setAccountAnchorEl(null);
  }
  async function handleLogoutClick() {
    const date = new Date();
    date.setTime(date.getTime() - 1);
    await logout();
    document.cookie = `${TOKEN_NAME}=; path=/; expires=${date.toUTCString()}`;
    document.cookie = `${EXPIRE_NAME}=; path=/; expires=${date.toUTCString()}`;
    document.cookie = `${USER_ID}=;path=/;expires=${date.toUTCString()}`;
    history('/login');
  }
  async function viewMessageDetail(message: Message) {
    await readMessages(message.id);
    message.read = true;
    setMessages([...messages]);
    fetchMessages();
  }
  function renderMessage(message: Message) {
    let statusClassName = styles.unread;
    if (message.read) {
      statusClassName = styles.read;
    }
    return (<div key={`${message.id}_${message.read}`} className={styles['message-item']}>
      <div className={styles['message-title']}><div className={classnames(styles['message-status-icon'], statusClassName)}></div>{message.title}</div>
      <div className={styles['message-content']}>{message.content}</div>
      <div className={styles['message-button-date-item']}>
        <div className={styles['message-date']}>{formatDateTime(message.createdAt)}</div>
        {message.link && <div onClick={() => viewMessageDetail(message)}>
          <a href={`${window.location.origin}/${message.link}`} rel="noopener noreferrer" target='_blank' className={styles.view}>{t('去查看')}</a>
        </div>}
      </div>
    </div>);
  }
  const messageOpen = Boolean(messageAnchorEl);
  const accountOpen = Boolean(accountAnchorEl);
  const settingOpen = Boolean(settingAnchorEl);
  const location = useLocation();
  const topBars: { title: string, url: string }[] = [];
  if (checkPermission('ManagePermission', token)) {
    topBars.push({ title: t('用户权限'), url: 'permission' });
  }
  return <div className={styles.container}>
    <div className={styles.left}>
      <img className={styles.logo} src={require('../../assets/logo.png')} alt='logo' />
      <span className={classnames(styles.menu, {
        [styles.active]: location.pathname === '/'
      })}>
        <Link to="/">
          {t('首页')}
        </Link>
      </span>
      {checkPermission('ViewContract', token) && <span className={classnames(styles.menu, {
        [styles.active]: location.pathname === '/order'
      })}>
        <Link to="/order">
          {t('订单')}
        </Link>
      </span>}
      {checkPermission(['ViewCluster', 'ViewOEMLicense'], token) && <span className={classnames(styles.menu, {
        [styles.active]: location.pathname === '/cluster'
      })}>
        <Link to="/cluster">
          {t('集群/许可')}
        </Link>
      </span>}
     {checkPermission('ViewTicket', token) && <span className={styles.menu}>
        <a href="https://support.xsky.com/api/acct/authing-login?next_path=/tickets" target='_blank' rel="noreferrer">
          {t('工单')}
        </a>
      </span>}
      {checkPermission(['ViewConfigTool', 'ManageConfigTool'], token) && <Link to="/sds-config">
        <span className={classnames(styles.menu, {
          [styles.active]: location.pathname === '/sds-config'
        })}>
          {t('配置工具')}
        </span>
      </Link>}
    </div>
    <div className={styles.right}>
      {/* <span className={styles.menu}>{t('文档中心')}</span> */}
      <span className={classnames(styles.menu, styles['notification-container'])} onClick={handleMessageClick}>
        <NotificationsIcon className={styles.notification} />
        {!!unreadCount && <span className={styles['unread-count']}>{unreadCount}</span>}
      </span>
      <Popover
        open={messageOpen}
        anchorEl={messageAnchorEl}
        onClose={handleMessageClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: -4, horizontal: 300 }}
      >
        <div className={styles['message-list-container']}>
          <div className={styles['message-list-title']}>
            <span>{t('全部消息')}</span><span onClick={handleReadAllClick} className={styles['read-all']}>{t('忽略全部')}</span>
          </div>
          <div className={styles['message-list-content']} onScroll={handleMessageScroll}>{messages.map(m => renderMessage(m))}</div>
        </div>
      </Popover>
      {topBars.length > 0 && <>
        <span className={styles.menu} onClick={handleSettingClick}>
          <span className={styles.account}>
            <SettingsIcon className={styles['account-arrow']} />
          </span>
        </span>
        <Popover
          open={settingOpen}
          anchorEl={settingAnchorEl}
          onClose={handleSettingClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{ vertical: -2, horizontal: 'right' }}
        >
          <div className={styles['pop-container']} onClick={handleSettingClose}>
            {topBars.map(topBar => (<NavLink className={styles['pop-item']} to={`/${topBar.url}`} key={topBar.url}>
              {topBar.title}
            </NavLink>))}
          </div>
        </Popover>
      </>}
      <span className={styles.menu} onClick={handleAccountClick}>
        <span className={styles.account}>
          {user?.name}
          <ArrowDropDownIcon className={styles['account-arrow']} />
        </span>
      </span>
      <Popover
        open={accountOpen}
        anchorEl={accountAnchorEl}
        onClose={handleAccountClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: -1, horizontal: 0 }}
      >
        <div className={styles['account-container']}>
          <div className={styles['account-item']}>{user?.nickname}</div>
          <div onClick={handleAccountManagementClick} className={classnames(styles['account-item'], styles['account-management'])}>{t('账号管理')}</div>
          <div onClick={handleLogoutClick} className={classnames(styles['account-item'], styles['logout'])}>{t('退出登录')}</div>
        </div>
      </Popover>
    </div>
  </div>
}
export default TopBar;