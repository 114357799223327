import { getProductName, productInfos } from 'common/src/license';
import { ErrorText, FormItemLabel, FormItemLine } from 'components';
import { GlobalContext } from 'context';
import { LicenseFormProductV4Props } from 'interfaces/props';
import t from 'locale';
import { useContext } from 'react';
import { LicenseDigitalFeature, LicenseDigitalProductInfo } from 'model';
import { Checkbox, FormControlLabel, MenuItem, Select, SelectChangeEvent } from 'x-material-ui';
import styles from './index.module.scss';
import PartTitle from './part-title';
import ProductPeriod from './product-period';

const ProductV4 = (props: LicenseFormProductV4Props) => {
  const {
    license,
    clearError,
    update,
    setCurrentFeatures,
    getFeatures,
    error,
    originalLicense,
    currentFeatures,
  } = props;  
  const { user } = useContext(GlobalContext);
  let supportProductInfos: LicenseDigitalProductInfo[] = [];
  let supportProductFeatures: LicenseDigitalFeature[] = [];
  if (user?.id) {
    supportProductInfos = productInfos;
    supportProductFeatures = currentFeatures.filter(p => p.is_standard || !p.is_standard);
  }
  function handleProductChange(e: SelectChangeEvent<number>) {
    const value = e.target.value as string;
    const id = parseInt(value, 10);
    const productInfo = productInfos.find(p => p.id === id);
    const seriesFeatures = getFeatures(productInfo);
    setCurrentFeatures(seriesFeatures);
    const features = seriesFeatures.filter(f => f.is_standard).map(f => f.name);
    update({
      productInfo,
      features,
    });
    clearError('productInfo');
  }
  function handleFeatureChange(name: string, checked: boolean) {
    const features = license.features || [];
    if (checked) {
      features.push(name);
    } else {
      const index = features.indexOf(name);
      features.splice(index, 1);
    }
    update({
      features,
    });
  }
  return (<div className={styles['part-container']}>
    <PartTitle title={t('产品信息')} />
    <div className={styles['part-content']}>
      <FormItemLine>
        <FormItemLabel label={t('软件产品')} required />
        <div>
          <Select
            variant="outlined"
            classes={{
              select: styles.select,
              icon: styles['select-icon'],
            }}
            value={license.productInfo?.id}
            onChange={handleProductChange}
            error={error.productInfo?.error}
            disabled={(originalLicense && license.contractType === 'expand') || license.isDelay}
          >
            <MenuItem disabled value="0">
              {t('请选择软件产品')}
            </MenuItem>
            {supportProductInfos.map(p => {
              const key = p.id;
              return (<MenuItem value={key} key={key}>
                {getProductName(p)}
              </MenuItem>)
            })}
          </Select>
          <ErrorText error={error.productInfo} />
        </div>
      </FormItemLine>
      <FormItemLine>
        <FormItemLabel label={t('特性')} required />
        <div className={styles['feature-container']}>
          {supportProductFeatures.map(f => {
            return (<div key={f.name} className={styles['product-feature']}>
              <FormControlLabel
                control={<Checkbox
                  checked={(license?.features || []).includes(f.name) || f.is_standard}
                  color="primary"
                  onChange={(e, checked) => handleFeatureChange(f.name, checked)}
                  name={f.name}
                  disabled={f.is_standard || ((license.contractType === 'expand') && originalLicense?.features.includes(f.name)) || license.isDelay}
                />}
                label={f.desc}
              />
            </div>);
          })}
        </div>
      </FormItemLine>
      <ProductPeriod
        license={license}
        update={update}
        error={error}
        getExpireDate={props.getExpireDate}
        version={props.version}
        renderInputSelectAdornment={props.renderInputSelectAdornment}
        renderActiveDate={props.renderActiveDate}
      />
    </div>
  </div>);
}

export default ProductV4;