import { calculateOmap, checkNodeNum, calculateXgfsFileNum, findIndexByCode, objectCpuLimit } from './calculation';
import { showTip } from './common';
import { FormItemLabel, Collapse, FormItemLine } from 'components';
import { ConfigOption, ConfigList, DictOption, DomFactorConfig, SdsConfigProps } from './interface';
import styles from './index.module.scss';
import t from 'locale';
import { useState, useEffect } from 'react';
import { validate } from './validation';
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  SearchSelect,
  Select,
  TextField,
} from 'x-material-ui';

const SdsConfigForm = (props: SdsConfigProps) => {
  let globalDisabled = false;
  const dataDict = props.dataDict;
  var options: ConfigList = {
    products: [{ name: 'XEBS', default: true }, { name: 'XEOS' }, { name: 'XGFS' }],
    pools: [{ name: 'XSpeed' }, { name: 'LocalCache' }, { name: '无cache盘数据池', code: 'data' }],
    versions: [
      { name: 'XSOS 5.1.000.*', code: 'v5_1' },
      { name: 'XSOS 5.2.000.*（非全闪）', code: 'v5_2' }, { name: 'XSOS 5.2.000.*（全闪）', code: 'v5_2_all_ssd' },
      { name: 'XSOS 5.2.100.*（非全闪）', code: 'v5_2_1' }, { name: 'XSOS 5.2.100.*（全闪）', code: 'v5_2_1_all_ssd' },
      { name: 'XSOS 6.1.000.*（非全闪）', code: 'v6_1', default: true }, { name: 'XSOS 6.1.000.*（全闪）', code: 'v6_1_all_ssd' },
    ],
    bondModes: [{ name: 'active-backup(mode=1)', code: 'mode1', default: true }, { name: '802.3ad(mode=4)', code: 'mode4' }],
    cacheReplicateds: [{ name: '3 副本', code: '3', default: true }, { name: '2 副本', code: '2' }],
    policyTypes: [{ name: 'EC', code: 'erasure', default: true }, { name: '副本', code: 'replicated' }],
    replicateds: [{ name: '3 副本', code: '3', default: true }, { name: '2 副本', code: '2' }],
    erasures: [{ name: '2+1' }, { name: '4+2:1', default: true }, { name: '4+2' }, { name: '8+2:1' }, { name: '8+3' }, { name: '16+2:1' }, { name: '自定义EC', code: 'other' }],
    objectMetaTypes: [{ name: 'HDD', code: 'hdd', default: true }, { name: 'SSD', code: 'ssd' }],
    objectCacheTypes: [{ name: 'HDD', code: 'hdd', default: true }, { name: 'SSD', code: 'ssd' }],
    gfsScenes: [{ name: '通用场景', code: 'general', default: true }, { name: 'large IO场景', code: 'largeIO' }],
  };
  const dataDictKeys = ['cpus', 'netCards', 'ssds', 'hdds'];
  for (let i = 0; i < dataDictKeys.length; i++) {
    const key = dataDictKeys[i];
    if (dataDict[key]) {
      options[key] = dataDict[key];
    } else {
      options[key] = [];
    }
  }
  options.dataOsds = options.hdds;
  options.dataSsdOsds = getDisksConfig('dataSsdDisks');
  options.cacheOsds = options.ssds;
  options.gfsMetaDisks = getDisksConfig('gfsMetaDisks');
  let defaultOpt: DictOption = {};
  for (let key in options) {
    let items = options[key];
    let flag = true;
    for (let i = 0; i < items.length; i++) {
      let item = items[i];
      if (item.default) {
        defaultOpt[key.slice(0, -1)] = item.code ? item.code : item.name; //products->product
        flag = false;
        break;
      }
    }
    if (flag) {
      defaultOpt[key.slice(0, -1)] = '';
    }
  }
  defaultOpt.pool = 'XSpeed';
  defaultOpt.nodeNum = defaultOpt.gfsGatewayNum = 3;
  defaultOpt.gfsMetaDiskNum = 3;
  defaultOpt.cpuNum = 2;
  defaultOpt.netCardNum = 2;
  defaultOpt.gfsFileNum = calculateXgfsFileNum(defaultOpt.gfsMetaDisk, dataDict);

  const [state, setState] = useState<DictOption>(defaultOpt);
  const [error, setError] = useState<DictOption>({});
  const [errorExist, setErrorExist] = useState(false);
  const [disabled, setDisabled] = useState<DictOption>({});
  const [xeosTurn, setXeosTurn] = useState(false);
  const [omapTips, setOmapTips] = useState('');

  function getDisksConfig(type: string) {
    const retList = [];
    const disksConfig = dataDict.disksConfig;
    const config = disksConfig[type];
    const codeMap = new Map<string, any>();
    const items = dataDict[config.diskType];
    for (let j = 0; j < items.length; j++) {
      let item = items[j];
      codeMap.set(item.code, item);
    }
    const disks = config.diskCodes;
    for (let i = 0; i < disks.length; i++) {
      const disk = disks[i];
      retList.push(codeMap.get(disk));
    }
    return retList;
  }

  function updateError(errorDict: DictOption) {
    const newError = JSON.parse(JSON.stringify(error));
    for (let key in errorDict) {
      newError[key] = errorDict[key];
    }
    setError(newError);
  }

  function updateDisabled(disableDict: DictOption) {
    const newDisabled = JSON.parse(JSON.stringify(disabled));
    for (let key in disableDict) {
      newDisabled[key] = disableDict[key];
    }
    setDisabled(newDisabled);
  }

  function updateState(config: DictOption) {
    const newState = JSON.parse(JSON.stringify(state));
    for (let key in config) {
      newState[key] = config[key];
    }
    const errorConfig = { omap: '', nodeNum: '', cpu: '', gfsGatewayNum: '', gfsFileNum: '' };
    const disabledConfig = { gfsGatewayNum: true, gfsFileNum: true, omap: true };
    if (newState.product === 'XEOS') {
      errorConfig.cpu = objectCpuLimit(newState, dataDict);
    } else if (newState.product === 'XGFS') {
      if (newState.gfsGatewayNumSet) {
        disabledConfig.gfsGatewayNum = false;
        if (newState.gfsGatewayNum > newState.nodeNum) {
          errorConfig.gfsGatewayNum = t('网关数超过节点数');
        }
      } else {
        newState.gfsGatewayNum = newState.nodeNum;
      }
      const gfsFileNum = calculateXgfsFileNum(newState.gfsMetaDisk, dataDict);
      if (newState.gfsFileNumSet) {
        disabledConfig.gfsFileNum = false;
        if (newState.gfsFileNum > gfsFileNum) {
          errorConfig.gfsFileNum = t('文件数超过计算值');
        }
      } else {
        newState.gfsFileNum = gfsFileNum;
      }
    }
    if (newState.pool === 'XSpeed') {
      if (newState.omapSet) {
        disabledConfig.omap = false;
      } else {
        const result = calculateOmap(newState, options, dataDict.capacity);
        newState.omap = result[0];
        errorConfig.omap = result[1] as string;
        setOmapTips(result[2] as string);
      }
    }
    errorConfig.nodeNum = checkNodeNum(newState);
    if (errorConfig.omap || errorConfig.nodeNum || errorConfig.cpu || errorConfig.gfsGatewayNum || errorConfig.gfsFileNum) {
      setErrorExist(true);
    } else {
      setErrorExist(false);
    }
    updateDisabled(disabledConfig);
    updateError(errorConfig);
    setState(newState);
  }

  function getPriorityValue(entity: ConfigOption) {
    if (entity.id) {
      return entity.id;
    } else if (entity.code) {
      return entity.code;
    } else {
      return entity.name;
    }
  }

  function getDisabledStatus(key: string) {
    return globalDisabled || disabled[key];
  }

  function domRow(params: DomFactorConfig[]) {
    const domList = [];
    for (let i = 0; i < params.length; i++) {
      const el = params[i];
      const key = el.key;
      const keys = el.keys ? el.keys : key + 's';
      const placeholder = el.placeholder ? t(el.placeholder) : '';
      switch (el.componType) {
        case 'label':
          domList.push(
            <FormItemLabel label={t(key)} required={el.required} key={t(key)} />
          );
          break;
        case 'radio':
          domList.push(
            <RadioGroup value={state[key]} onChange={e => updateState({ [key]: e.target.value })} key={key} row>
              {options[keys].map(v => (
                <FormControlLabel disabled={getDisabledStatus(key)} value={getPriorityValue(v)} control={<Radio color="primary" />}
                  label={t(v.name)} labelPlacement="end" key={t(v.name)} />
              ))}
            </RadioGroup>
          );
          break;
        case 'select':
          const selectOpt = [];
          for (let i = 0; i < options[keys].length; i++) {
            const item = options[keys][i];
            selectOpt.push(<MenuItem value={getPriorityValue(item)} key={getPriorityValue(item)}>{t(item.name)}</MenuItem>);
          }
          domList.push(
            <Select variant='outlined' SelectDisplayProps={{style: {width: '184px'} }} disabled={getDisabledStatus(key)}
              value={state[key]} onChange={e => updateState({ [key]: e.target.value })} key={key}>
              {selectOpt}
            </Select>
          );
          break;
        case 'checkbox':
          domList.push(
            <FormControlLabel value={state[key]} onChange={(e, checked) => updateState({ [key]: checked })} disabled={getDisabledStatus(key)}
              control={<Checkbox color="primary" checked={state[key]} />} label={el.label} key={key} />
          );
          break;
        case 'inputNumber':
          domList.push(
            <TextField type="number" variant='outlined' classes={{ root: `${styles['number-input']}` }} disabled={getDisabledStatus(key)} value={state[key]}
              onChange={e => updateState({ [key]: e.target.value ? parseInt(e.target.value) : '' })} placeholder={placeholder} key={key}
              error={!!error[key]} helperText={error[key]} />
          );
          break;
        case 'inputNumberPolish':
          domList.push(
            <div key={key + 'div'}>
              <span className={styles['input-font-span']} key={key + 'span'}>x</span>
              <TextField type="number" variant='outlined' classes={{ root: `${styles['number-input']}` }} disabled={getDisabledStatus(key)} value={state[key]}
                onChange={e => updateState({ [key]: e.target.value ? parseInt(e.target.value) : '' })} placeholder={placeholder} key={key}
                error={!!error[key]} helperText={error[key]} />
            </div>
          );
          break;
        case 'inputFloatPolish':
          domList.push(
            <div key={key + 'div'}>
              <span className={styles['input-font-span']} key={key + 'span'}>x</span>
              <TextField type="number" variant='outlined' classes={{ root: `${styles['number-input']}` }} disabled={getDisabledStatus(key)} value={state[key]}
                onChange={e => updateState({ [key]: e.target.value ? parseFloat(e.target.value) : '' })} placeholder={placeholder} key={key}
                error={!!error[key]} helperText={error[key]} />
            </div>
          );
          break;
        case 'inputText':
          domList.push(
            <TextField type="text" variant='outlined' value={state[key]} onChange={e => updateState({ [key]: e.target.value })} disabled={getDisabledStatus(key)}
              placeholder={placeholder} key={key}
              error={!!error[key]} helperText={error[key]} />
          );
          break;
        case 'inputSearch':
          domList.push(
            <SearchSelect
              searchCallback={searchSelectOpt(options[keys])}
              displayRender={option => option.name}
              itemRender={option => option.name}
              updateValue={option => updateState({ [key]: getPriorityValue(option) })}
              value={getDataDictByKey(key, keys)}
              placeholder={placeholder} className={styles['number-input']} key={key}
              disabled={getDisabledStatus(key)} error={!!error[key]} helperText={error[key]} />
          );
          break;
        case 'tips':
          domList.push(showTip(key, el.colorError ? true : false));
          break;
      }
    }
    return (
      <FormItemLine>
        {domList}
      </FormItemLine>
    );
  }
  function getDataDictByKey(key: string, keys: string) {
    let ret: any = '';
    const items = options[keys];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (state[key] === item.id) {
        ret = item;
        break;
      }
    }
    return ret;
  }
  function searchSelectOpt(option: ConfigOption[]) {
    async function handleSearch(search: string) {
      const newOpt: ConfigOption[] = [];
      if (!search) {
        return await option;
      }
      for (let i = 0; i < option.length; i++) {
        const item = option[i];
        if (item.name.toLowerCase().indexOf(search.toLowerCase()) != -1) {
          newOpt.push(item);
        }
      }
      return await newOpt;
    }
    return handleSearch;
  }
  function checkDictNotNull(dict: DictOption) {
    let result = true;
    for (let key in dict) {
      if (dict[key]) {
        result = false;
        break;
      }
    }
    return result;
  }
  function validateForm() {
    const retError = validate(state);
    if (!errorExist) {
      updateError(retError);
    }
    return !errorExist && checkDictNotNull(retError);
  }
  function handleOutput() {
    if (validateForm()) {
      props.openOutput(state);
    }
  }
  const [keydownList, setKeydownList] = useState<string[]>([]);
  const [openHiddenFun, setOpenHiddenFun] = useState(false);
  function handleOutputKeydown(e: KeyboardEvent) {
    if (!openHiddenFun) {
      let keys = keydownList;
      if (keys.length >= 3) {
        keys.shift();
      }
      keys.push(e.key);
      const hiddenFlag = keys.findIndex((key) => key === 'Alt') !== -1 &&
        keys.findIndex((key) => key === 'Shift') !== -1 &&
        (keys.findIndex((key) => key === 'S') !== -1 || keys.findIndex((key) => key === 's') !== -1);
      setOpenHiddenFun(hiddenFlag);
      setKeydownList(keys);
    }
    if (e.key === 'Enter') {
      handleOutput();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleOutputKeydown);
    return () => {
      document.removeEventListener("keydown", handleOutputKeydown);
    }
  }, [state, openHiddenFun]);

  let cpuSetFlag = false;
  const otherCpuIndex = findIndexByCode('other', dataDict.cpus);
  if (state.cpu && state.cpu === dataDict.cpus[otherCpuIndex].id) {
    cpuSetFlag = true;
    if (state.cpuFrequency && state.cpuCore) {
      dataDict.cpus[otherCpuIndex].value.baseFrequency = state.cpuFrequency;
      dataDict.cpus[otherCpuIndex].value.cores = state.cpuCore;
    }
  }
  const currPool = state.pool;
  const xspeedFlag = currPool === 'XSpeed';
  const xgfsFlag = state.product === 'XGFS';
  const xeosFlag = state.product === 'XEOS';
  const allSddFlag = state.version.endsWith('all_ssd');
  const detailPerformFlag = state.product === 'XEBS' && xspeedFlag && (allSddFlag || openHiddenFun);
  if (options.netCards.length > 0 && !state.netCard) {
    updateState({
      'gfsMetaDisk': getPriorityValue(options.gfsMetaDisks[0]),
      'netCard': getPriorityValue(options.netCards[0])
    });
  }
  const ssdObjectMeta = state.objectMetaType === 'ssd';
  const ssdObjectCache = state.objectCacheType === 'ssd';
  if (xeosFlag) {
    // XEOS不支持XSpeed
    options.pools.shift();
    if (!xeosTurn) {
      updateState({ pool: 'data' });
      updateDisabled({ objectCacheType: true });
      setXeosTurn(true);
    }
    if (state.pool === 'data') {
      if (ssdObjectMeta || ssdObjectCache) {
        updateState({ objectMetaType: 'hdd', objectCacheType: 'hdd' });
      }
      if (!disabled.objectMetaType) {
        updateDisabled({ objectMetaType: true });
      }
    } else if (state.pool === 'LocalCache') {
      if (!ssdObjectCache) {
        updateState({ objectCacheType: 'ssd' });
      }
      if (disabled.objectMetaType) {
        updateDisabled({ objectMetaType: false });
      }
    }
  } else if (xeosTurn) {
    updateDisabled({ objectCacheType: false });
    setXeosTurn(false);
  }
  const dataOsdKeys = allSddFlag ? 'dataSsdOsds' : '';
  const dataPolicy: DomFactorConfig[] = [{ componType: 'label', key: '安全策略', required: true }
    , { componType: 'radio', key: 'policyType' }
    , { componType: 'select', key: state.policyType, autoWidth: true }];
  if (state.policyType === 'erasure' && state.erasure === 'other') {
    dataPolicy.push({ componType: 'inputText', key: 'otherErasure', placeholder: '示例: 8+2:1' });
  }

  return (
    <div className={styles['left-container']}>
      <div className={styles['left-content']}>
        <Collapse title={t('集群基本配置')} initialOpen>
          {domRow([{ componType: 'label', key: '产品' }, { componType: 'radio', key: 'product' }])}
          {domRow([{ componType: 'label', key: '存储池' }, { componType: 'radio', key: 'pool' }])}
          {domRow([{ componType: 'label', key: '版本' }, { componType: 'select', key: 'version' }])}
          {domRow([{ componType: 'label', key: '节点数', required: true }
            , { componType: 'inputNumber', key: 'nodeNum', placeholder: '请输入节点数' }])}
          {xgfsFlag && domRow([{ componType: 'label', key: 'XGFS网关数', required: true }
            , { componType: 'inputNumber', key: 'gfsGatewayNum', placeholder: '请输入网关数' }
            , { componType: 'tips', key: '网关数影响性能瓶颈' }
            , { componType: 'checkbox', key: 'gfsGatewayNumSet', label: '自定义' }])}
          {xgfsFlag && <>
            {domRow([{ componType: 'label', key: '集群元数据盘', required: true }
              , { componType: 'select', key: 'gfsMetaDisk' }
              , { componType: 'inputNumberPolish', key: 'gfsMetaDiskNum', placeholder: '请输入集群元数据盘数' }])}
            {domRow([{ componType: 'label', key: '集群文件数(亿)', required: true }
              , { componType: 'inputNumber', key: 'gfsFileNum', placeholder: '请输入文件数' }
              , { componType: 'tips', key: '文件数影响索引容量的计算' }
              , { componType: 'checkbox', key: 'gfsFileNumSet', label: '自定义' }])}
          </>}
        </Collapse>

        <Collapse title={t('CPU/网络配置')} initialOpen>
          {domRow([{ componType: 'label', key: 'CPU', required: true }, { componType: 'inputSearch', key: 'cpu', placeholder: '请选择 CPU' }
            , { componType: 'inputFloatPolish', key: 'cpuNum', placeholder: '请输入节点 CPU 个数' }])}
          {cpuSetFlag && domRow([{ componType: 'label', key: 'CPU核数/主频', required: true }
            , { componType: 'inputNumber', key: 'cpuCore', placeholder: '请输入CPU核数' }
            , { componType: 'inputFloatPolish', key: 'cpuFrequency', placeholder: '请输入CPU主频(Ghz)' }
          ])}
          {domRow([{ componType: 'label', key: '网卡', required: true }, { componType: 'select', key: 'netCard', placeholder: '请选择网卡' }
            , { componType: 'inputFloatPolish', key: 'netCardNum', placeholder: '请输入节点网卡数量' }])}
          {domRow([{ componType: 'label', key: 'bond模式' }, { componType: 'radio', key: 'bondMode' }])}
        </Collapse>

        {xeosFlag && <Collapse title={t('对象存储池介质')} initialOpen>
          {domRow([{ componType: 'label', key: '对象索引池' }, { componType: 'radio', key: 'objectMetaType' }])}
          {domRow([{ componType: 'label', key: '对象缓存池' }, { componType: 'radio', key: 'objectCacheType' }])}
        </Collapse>}

        <Collapse title={t('数据层')} initialOpen>
          {domRow(dataPolicy)}
          {!allSddFlag && domRow([{ componType: 'label', key: 'data osd', required: true }
            , { componType: 'inputSearch', key: 'dataOsd', placeholder: '请选择硬盘' }
            , { componType: 'inputNumberPolish', key: 'dataOsdNum', placeholder: '请输入节点硬盘数量' }])}
          {allSddFlag && domRow([{ componType: 'label', key: 'data osd', required: true }
            , { componType: 'inputSearch', key: 'dataOsd', placeholder: '请选择硬盘', keys: dataOsdKeys }
            , { componType: 'inputNumberPolish', key: 'dataOsdNum', placeholder: '请输入节点硬盘数量' }])}
        </Collapse>

        {currPool !== 'data' && <Collapse title={t('缓存层')} initialOpen>
          {xspeedFlag && domRow([{ componType: 'label', key: '安全策略' }, { componType: 'select', key: 'cacheReplicated' }])}
          {domRow([{ componType: 'label', key: 'cache osd', required: true }
            , { componType: 'inputSearch', key: 'cacheOsd', placeholder: '请选择硬盘' }
            , { componType: 'inputNumberPolish', key: 'cacheOsdNum', placeholder: '请输入节点硬盘数量' }])}
          {xspeedFlag && domRow([{ componType: 'label', key: '索引容量', required: true }
            , { componType: 'inputNumber', key: 'omap' }, { componType: 'tips', key: omapTips, colorError: true }
            , { componType: 'checkbox', key: 'omapSet', label: '自定义' }])}
          {xspeedFlag && xgfsFlag && domRow([{ componType: 'label', key: 'XGFS场景' }, { componType: 'radio', key: 'gfsScene' }
            , { componType: 'tips', key: '通用场景：索引容量满足的情况下，SSD:HDD最大容量比为1:50。large IO场景：索引容量按0.6来折算，并且SSD:HDD最大容量比为1:80' }])}
        </Collapse>}
      </div>
      <div className={styles['menu-case']}>
        <Button size='large' color='primary' classes={{ root: styles.button }} onClick={() => handleOutput()}>
          {t('生成方案(Enter)')}
        </Button>
      </div>
    </div>
  );
}

export default SdsConfigForm;