import { formatDateOnly } from 'common';
import { TableColumn } from 'interfaces/props';
import t from 'locale';
import { Cluster, ClusterHardwareInfo } from 'model';

export const clusterNameColumn: (onClick: (record: Cluster) => void) => TableColumn<Cluster> = onClick => ({
  title: t('集群名称'),
  dataKey: 'name',
  template: record => {
    return <span className="link-without-decoration" onClick={() => onClick(record)}>{record?.name || ''}</span>
  }
})

export const clusterCustomerNameColumn: TableColumn<Cluster> = {
  title: t('客户名称'),
  dataKey: 'customerName',
}

export const clusterProductNameColumn: TableColumn<Cluster> = {
  title: t('产品名称'),
  dataKey: 'productName',
}

export const clusterVersionColumn: TableColumn<Cluster> = {
  title: t('版本'),
  dataKey: 'version',
}

export const clusterSoftwareWarrantyPeriodColumn: TableColumn<Cluster> = {
  title: t('软件质保期限'),
  dataKey: 'warrantyServicePeriod',
  template: record => {
    if (!record.warrantyServicePeriod) {
      return '';
    }
    return t('{{period}} 个月', { period: record.warrantyServicePeriod });
  }
}

export const clusterWarrantyEndColumn: TableColumn<Cluster> = {
  title: t('软件质保结束日期'),
  dataKey: 'warrantyEnd',
  template: record => {
    return formatDateOnly(record.warrantyEnd);
  }
}

export const clusterContractNosColumn: TableColumn<Cluster> = {
  title: t('合同编号'),
  dataKey: 'contractNos',
  template: record => {
    return record.contractNos?.join(',');
  }
}

export const clusterDeployStartColumn: TableColumn<Cluster> = {
  title: t('集群安装时间'),
  dataKey: 'deployStart',
  template: record => {
    return formatDateOnly(record.deployStart);
  }
}

export const clusterFsIdColumn: TableColumn<Cluster> = {
  title: t('集群 ID'),
  dataKey: 'fsId',
}

export const clusterHardwareProductColumn: TableColumn<ClusterHardwareInfo> = {
  title: t('硬件产品'),
  dataKey: 'product',
}

export const clusterHardwareSnColumn: TableColumn<ClusterHardwareInfo> = {
  title: t('SN号'),
  dataKey: 'sn',
}

export const clusterHardwareWarrantyServicePeriodColumn: TableColumn<ClusterHardwareInfo> = {
  title: t('硬件质保期限'),
  dataKey: 'warrantyServicePeriod',
}

export const clusterHardwareWarrantyServiceLevelColumn: TableColumn<ClusterHardwareInfo> = {
  title: t('售后服务级别'),
  dataKey: 'warrantyServiceLevel',
}
export const clusterHardwareWarrantyServiceEndColumn: TableColumn<ClusterHardwareInfo> = {
  title: t('硬件质保截止时间'),
  dataKey: 'end',
  template: record => {
    return formatDateOnly(record.end);
  }
}