import t from 'locale';
const tokenPrefix = 'xboc-guest-v2';
export const TOKEN_NAME = `${tokenPrefix}-token`;
export const EXPIRE_NAME = `${tokenPrefix}-expires`;
export const USER_ID = `${tokenPrefix}-user-id`;
export const CHECK_SESSION_INTERVAL = 1 * 60 * 1000;
export const TABLE_DEFAULT_PAGE_SIZE = 100;
export const NO = t('暂无');
export const EMAILREGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const PASSWORDREGEX = /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z*]{8,20}$/;
export const LICENSE_CLUSTER_MD5_TYPE: { [key: string]: string; } = {
  manual: t('手动上传'),
  'qr-code': t('来自二维码'),
};
export const LICENSE_LIMITS: { [key: string]: string; } = {
  max_capacity_per_host: t('TiB/节点'),
  max_host_num: t('节点数/集群'),
  max_capacity: t('TiB/集群'),
  max_file_num: t('文件数/集群'),
  max_osd_num: t('OSD 数/集群'),
  max_fc_host_num: t('FC 网关机头/集群'),
  max_nas_host_num: t('NAS 网关机头/集群'),
};
export const TABLE_SEARCH_PLACEHOLDER = {
  cluster: t('可查询的值：集群名称/ID、客户名称、合同编号'),
  permit: t('可查询的值：license 序列号、集群名称、客户名称、ID'),
  permission: t('可查询的值：权限名称'),
  'user-permission': t('可查询的值：用户邮箱'),
  role: t('可查询的值：角色名称'),
  order: t('可查询的值：合同编号，客户名称'),
}
export const LICENSE_STATUS: { [key: string]: string; } = {
  expiring: t('即将失效'),
  expired: t('已失效'),
  active: t('使用中'),
  lapsed: t('已失效'),
};
const LICENSE_SERVICE_COMMOM_NAMES: { [key: string]: string; } = {
  service_hardware_5_9: t('硬件支持服务 5x9'),
  service_hardware_7_24: t('硬件支持服务 7x24'),
};
export const LICENSE_V4_SERVICE_NAMES: { [key: string]: string; } = {
  service_support_online_5_9: t('软件支持服务 5x9 在线'),
  service_support_online_7_24: t('软件支持服务 7x24 在线'),
  ...LICENSE_SERVICE_COMMOM_NAMES,
};
export const LICENSE_V5_SERVICE_NAMES: { [key: string]: string; } = {
  service_support_online_5_9: t('软件金牌质保'),
  service_support_online_7_24: t('软件铂金质保'),
  ...LICENSE_SERVICE_COMMOM_NAMES,
};

export const BID_SCENES: { [key: string]: string; } = {
  new: t('新建集群'),
  expand: t('集群扩容'),
  renew: t('客户续保'),
  serve: t('服务与培训'),
  all: t('全部'),
  clusterUpgrade: t('集群升级')
};
export const CONTRACT_SUBJECT_MATTER_UNIT = {
  '1': t('TB'),
  '2': t('PB'),
  '3': t('节点'),
  '4': t('台'),
  '5': t('块'),
  '6': t('套'),
  '7': t('年'),
  '8': t('月'),
  '9': t('人/天'),
  '10': t('CPU'),
  '11': t('张'),
  '12': t('个'),
  '13': t('批'),
  '14': t('项'),
  '15': t('功能包'),
  '16': t('集群'),
  '17': t('基础包'),
  '18': t('人/次'),
  '19': t('人/年'),
  '20': t('系统'),
  '21': t('基础包'),
  '22': t('人/月'),
  '23': t('容量'),
  '24': t('项目'),
  '25': t('客户端'),
  '26': t('用户数'),
  '27': t('容量包'),
  '28': t('节点/年'),
  '29': t('件'),
  '30': t('人'),
  '31': t('次'),
  '32': t('条'),
  '33': t('根'),
  '34': t('TB/年'),
  '35': t('PB/年'),
};
export const CLUSTER_DELIVERY_TYPE: { [key: string]: string; } = {
  expand: t('扩容'),
  new: t('新建交付'),
  renew: t('续保'),
  upgrade: t('集群升级'),
};