import React, { useState, useRef } from 'react';
import _ from 'lodash';
import styles from './index.module.scss';
import {
  Table,
  checkboxColumn,
  userGroupNameColumn,
  userGroupTypeColumn,
  Pagination,
} from 'components';
import { Button, TextButton, Grid, Select, MenuItem, MultiSearchSelect, SelectChangeEvent } from 'x-material-ui';
import { queryUser } from 'request';
import t from 'locale';
import { UserGroup as UserProps } from 'interfaces/props';
import { useCheckboxColumn, usePagination } from '../../hooks';
import { getPermissionAndUserFromRole } from 'common/src/permission';

type UserType = 'user';
const types: UserType[] = ['user'];
const typeName = {
  user: t('用户'),
}
const UserGroupForm = (props: any) => {
  const { roleName, action, role } = props;
  const { users: roleUsers } = getPermissionAndUserFromRole(role);
  const [type, setType] = useState<UserType>('user');
  const [users, setUsers] = useState<UserProps[]>([]);
  const [effectSub, setEffectSub] = useState(false);
  const { checkedData, handleCheckItem, setCheckedData } = useCheckboxColumn(users, setUsers);
  const typeRef = useRef(type);
  typeRef.current = type;
  const { limit, page, setPage, handlePageChange, handleRowsPerPageChange } = usePagination(handlePagerChange);

  async function searchUser(value: string) {
    const response = await queryUser(value);
    const result = response.data.map(d => ({ id: d.id, name: d.name, type: 'user', email: d.mail, groupId: '' }));
    return result;
  }

  function handlePagerChange(limit: number, page: number) {
    setPage(page);
  }
  function handleTypeChange(event: SelectChangeEvent<string>) {
    const type = event.target.value as UserType;
    setType(type);
    setUsers([]);
  }

  function userRender(u?: UserProps) {
    let name = u?.name || '';
    if (u?.email) {
      name += `(${u.email})`;
    }
    return name;
  }
  function addUser(u: UserProps) {
    users.push(u);
    setUsers([...users]);
  }
  function removeUser(u: UserProps) {
    const index = users.findIndex(user => user.name === u.name);
    if (index !== -1) {
      users.splice(index, 1);
    }
    setUsers([...users]);
  }

  return (
    <Grid container spacing={3} className={styles.layout}>
      <Grid item xs={3} className={styles.label}>
        {t('角色名称：')}
      </Grid>
      <Grid item xs={8}>
        {roleName}
      </Grid>
      {action === 'add-user-group' && <>
        <Grid item xs={3} className={styles.label}>
          {t('角色类型：')}
        </Grid>
        <Grid item xs={8}>
          <Select
            variant="outlined"
            classes={{
              select: styles.select,
            }}
            value={type}
            onChange={handleTypeChange}
          >
            {types.map(type => {
              return (<MenuItem value={type} key={type}>
                {typeName[type]}
              </MenuItem>)
            })}
          </Select>
        </Grid>
      </>}
      <Grid item xs={3} className={`${styles['form-text']} ${styles.label} required`}>
        {t('选择用户：')}
      </Grid>
      {action === 'add-user-group' && (
        <Grid item xs={8} className={styles.search}>
          <div className={styles['multi-select-container']}>
            <MultiSearchSelect
              searchCallback={searchUser}
              value={users}
              displayRender={userRender}
              itemRender={userRender}
              addValue={addUser}
              removeValue={removeUser}
              placeholder={t('选择用户：')}
              className={styles['multi-select']}
            />
          </div>
        </Grid>
      )}
      {action === 'add-user-group' && (<Grid item xs={12} className={styles['form-footer']}>
        <Button
          size="medium"
          color="primary"
          onClick={() => props.onSubmit(role.id, users.map(u => ({ ...u, effectSub })))}
          className={`${styles['footer-button']}`}
          variant='contained'
        >
          {t('确定')}
        </Button>
        <TextButton
          className={`${styles['footer-button']}`}
          onClick={props.onCancel}
          size="medium"
        >
          {t('取消')}
        </TextButton>
      </Grid>)}
      {action === 'remove-user-group' && (
        <React.Fragment>
          <Grid item xs={8} className={styles['table-container']}>
            <div className={styles.table}>
              <Table
                data={roleUsers.slice(page * limit, page * limit + limit)}
                columns={[
                  checkboxColumn(handleCheckItem),
                  userGroupNameColumn,
                  userGroupTypeColumn,
                ]}
                loading={false}
              />
            </div>
            {!!roleUsers.length && (
              <Pagination
                rows={[10, 25, 50, 100]}
                count={roleUsers.length}
                rowsPerPage={limit}
                page={page}
                type="mini"
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
              />
            )}
          </Grid>
          <Grid item xs={12} className={styles['form-footer']}>
            <Button
              size="medium"
              color="secondary"
              onClick={() => {
                props.onSubmit(role.id, checkedData.map(i => i.id));
                setCheckedData([]);
              }}
              variant='contained'
              className={`${styles['footer-button']}`}
            >
              {t('移除')}
            </Button>
            <TextButton
              className={`${styles['footer-button']}`}
              onClick={() => {
                props.onCancel();
                setCheckedData([]);
              }}
              size="medium"
            >
              {t('取消')}
            </TextButton>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default UserGroupForm;
