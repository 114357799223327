import OSS, { Checkpoint } from 'ali-oss';
import { UploadConfig } from 'model';
import { ResponseType } from 'axios';
import { GlobalContext } from 'context';
import { useState, useRef, useContext } from 'react';
import { initUpload, uploadComplete } from 'request';

export function useUpload(successCallback?: (result: any) => void, errorCallback?: (error: any) => void) {
  const [current, setCurrent] = useState(0);
  const [total, setTotal] = useState(0);
  // 在上传的过程中通过 setCancel 更新 cancel 的值后，由于 upload 方法对 cancel
  // 的值的引用是在第一次调用 upload 的闭包中的，因此不能获取到最新的值，需要使用 ref
  // 来通过引用方式获取最新的 cancel 的值
  const cancelStatus = useRef(false);
  const [checkPoint, setCheckPoint] = useState<Checkpoint>();
  const checkPointRef = useRef<Checkpoint>();
  checkPointRef.current = checkPoint;
  const [cancel, setCancel] = useState(false);
  cancelStatus.current = cancel;
  const currentRef = useRef<number>();
  currentRef.current = current;
  const [error, setError] = useState(false);
  const { aliOssConfig } = useContext(GlobalContext);
  let client: OSS | undefined;
  if (aliOssConfig) {
    client = new OSS({
      // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
      region: aliOssConfig?.region || '',
      // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
      accessKeyId: aliOssConfig?.accessKeyId || '',
      accessKeySecret: aliOssConfig?.accessKeySecret || '',
      bucket: aliOssConfig?.bucket || '',
    });
  }

  async function complete(upload: UploadConfig, filename: string) {
    const response = await uploadComplete({
      ...upload,
      filename,
    });
    if (response.status === 200) {
      successCallback?.(response.data);
    }
  }
  function progress(p: number, checkpoint: Checkpoint) {
    setCurrent(p);
    setCheckPoint(checkpoint);
  };
  async function uploadFile(file: File, type: string, resourceId: number | string, responseType?: ResponseType) {
    const name = file.name;
    const size = file.size;
    const response = await initUpload(name, type, size, resourceId);
    if (response.status === 200) {
      const data = response.data;
      setTotal(1);
      multipartUpload(data, name, file);
    }
  }
  async function multipartUpload(upload: UploadConfig, filename: string, file: File) {
    try {
      // object-name可以定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至Bucket根目录或Bucket下的指定目录。
      await client?.multipartUpload(upload.temp_path, file, {
        progress,
      });
      complete(upload, filename);
    } catch (e) {
      // 捕获超时异常。
      if ((e as any).code === 'ConnectionTimeoutError') {
        console.error('TimeoutError');
        // do ConnectionTimeoutError operation
      }
      setError(true);
      errorCallback?.(e);
    }
  }
  function cancelUpload() {
    if (checkPointRef.current && currentRef.current !== 1) {
      client?.abortMultipartUpload(checkPointRef.current.name, checkPointRef.current.uploadId);
    }
    setCancel(true);
    setError(false);
    setTotal(0);
    setCurrent(0);
  }
  function clear() {
    setCurrent(0);
    setTotal(0);
    setCancel(false);
    setError(false);
  }
  return { uploadFile, current, total, cancel, cancelUpload, error, clear };
}
