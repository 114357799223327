import { DATE_FORMAT } from 'common';
import { SKU_CATEGORY_MAP } from 'common/src/license';
import { LICENSE_V4_SERVICE_NAMES, LICENSE_V5_SERVICE_NAMES } from 'constant';
import dayjs from 'dayjs';
import t from 'locale';
import lodash from 'lodash';
import { DiditalLicenseFormModel, LicenseDigital, LicenseDigitalPeriodUnit, LicenseDigitalService, LicenseDigitalVersion, Sku } from 'model';
import { getActiveLicensesByMd5 } from 'request';

export async function getHistoryApplyInfoByMd5(md5: string, payload: Partial<LicenseDigital>) {
  const response = await getActiveLicensesByMd5(md5);
  const usedLicenses = response.data;
  let usedDuration = 0;
  let expectedUsageTime = 0;
  let license;
  for (let i = 0; i < usedLicenses.length; i++) {
    if (['testToProd', 'permanent'].includes(usedLicenses[i].scene)) {
      break;
    } else {
      license = usedLicenses[i];
    }
  }
  if (license) {
    usedDuration = dayjs().diff(dayjs(license.activeDate), 'month');
    if (!payload.expireDate) {
      const expireDate = getExpireDate(dayjs().format(DATE_FORMAT), payload.period, 'month');
      expectedUsageTime = dayjs(expireDate).diff(dayjs(license.activeDate), 'month');
    } else {
      expectedUsageTime = dayjs(payload.expireDate).diff(dayjs(license.activeDate), 'month');
    }
  }
  return { usedDuration, expectedUsageTime };
}
export async function getExpectedUsageTime(masterLicense: DiditalLicenseFormModel, time?: number) {
  const expectedUsageTimes: number[] = [time || 0];
  const clusterLicenses = masterLicense?.licenseV6Cluster?.filter(m => m.classify === 'XSOS-DATA' && m.clusterMd5) || [];
  for (const clusterLicense of clusterLicenses) {
    const { expectedUsageTime } = await getHistoryApplyInfoByMd5(clusterLicense.clusterMd5 || '', { ...clusterLicense, expireDate: clusterLicense.expireDate } as Partial<LicenseDigital>);
    expectedUsageTimes.push(expectedUsageTime);
  }
  const maxExpectedUsageTime = lodash.max(expectedUsageTimes) || 0;
  return maxExpectedUsageTime;
}
export function getDetaultService() {
  const unit = 'month';
  const period = 1;
  let activeDate = '';
  let expireDate = '';
  const service: LicenseDigitalService = {
    name: '0',
    unit,
    period,
    activeDate,
    expireDate,
  }
  return service;
}

export function getExpireDate(start?: string, period: number = 0, unit?: LicenseDigitalPeriodUnit) {
  return dayjs(start).add(period, unit).format(DATE_FORMAT);
}
export function dateDisplay(date: string) {
  if (!date) {
    return t('永久');
  }
  return dayjs(date).format(DATE_FORMAT);
};
export function getWarrantySkuName(sku: Sku) {
  return t(`${sku.category}升级至${sku.warranty_period}${sku.warranty_unit === 1 ? t('年') : t('月')}${sku.warranty_level === 1 ? '5\*9' : '7\*24'}在线质保`);
}
export function getSkuName(sku: Sku) {
  if (sku.name) {
    return sku.name;
  }
  if (sku.type === '在线质保') {
    if (sku.category === 'expand') {
      return t(`${SKU_CATEGORY_MAP.get(sku.category)}${sku.warranty_period}${sku.warranty_unit === 1 ? t('年') : t('月')}${sku.warranty_level === 1 ? '5\*9' : '7\*24'}在线质保`);
    }
    return getWarrantySkuName(sku);
  }
  if (sku.type === '在线服务') {
    return t(`${sku.category}升级至${sku.warranty_period}${sku.warranty_unit === 1 ? t('年') : t('月')}${sku.warranty_level === 1 ? '5\*9' : '7\*24'}在线`);
  }
  return sku.name;
}
export function skuDisplayRender(s?: Sku) {
  return s?.name || '';
}

export function skuDisplayItemRender(s: Sku) {
  return s.name || '';
}

export function licenseProductPeriodDisplay(license: LicenseDigital) {
  if (!license.productSku) {
    return '';
  }
  if (license.scene === 'poc') {
    return t('{{period}}月', { period: license.period })
  }
  return '';
};
export function getLicenseServiceNames(version?: LicenseDigitalVersion) {
  if (version === 'V5') {
    return LICENSE_V5_SERVICE_NAMES;
  }
  return LICENSE_V4_SERVICE_NAMES;
}