import { slice } from 'lodash';
import { ComponentsOverrides, Theme } from 'x-material-ui';

const errorColor = '#f24542';
const focusBorderColor = '#6b58c4';
const fontFamily = 'PingFangSC-Regular';
const primaryRadioColor = '#6b58c4';
const radioUncheckColor = '#e6e6e6';
const contentBackground = '#fff';
const standardSelectedColor = '#8158f8';
const standardIndicatorColor = '#8158f8';
const secondaryTextColor = '#89888e';
const buttonPrimaryColor = '#7C6CCA';
const buttonSecondaryColor = '#ff512f';
const buttonTextColor = '#a2a3a9';

export const outlinedInputOverrides: ComponentsOverrides<Theme>['MuiOutlinedInput'] = {
  root: {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: focusBorderColor,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: focusBorderColor,
    },
  },
  input: {
    padding: '7px 14px',
    fontFamily: fontFamily,
    fontSize: '14px',
    lineHeight: '22px',
  },
  multiline: {
    paddingLeft: '0px',
    paddingTop: '0px',
  },
  notchedOutline: {
    borderRadius: '2px',
    '&$error': {
      borderColor: errorColor,
    },
  }
}
export const radioOverrides: ComponentsOverrides<Theme>['MuiRadio'] = {
  colorPrimary: {
    color: radioUncheckColor,
    '&.Mui-checked': {
      color: primaryRadioColor,
    },
  },
  root: {
    padding: 0,
  }
}
export const checkboxOverrides: ComponentsOverrides<Theme>['MuiCheckbox'] = {
  root: {
    padding: 0,
  }
}
export const formControlLabelOverrides: ComponentsOverrides<Theme>['MuiFormControlLabel'] = {
  label: {
    fontSize: '14px',
  },
  root: {
    marginLeft: 0,
  }
}
export const menuItemOverrides: ComponentsOverrides<Theme>['MuiMenuItem'] = {
  root: {
    fontSize: '14px',
  }
}
export const tabsOverrides: ComponentsOverrides<Theme>['MuiTabs'] = {
  root: {
    paddingLeft: '8px',
    backgroundColor: contentBackground,
    borderBottomWidth: 0,
    minHeight: '42px',
  },
  indicator: {
    backgroundColor: standardIndicatorColor,
  }
}
export const tabOverrides: ComponentsOverrides<Theme>['MuiTab'] = {
  root: {
    fontSize: '14px',
    lineHeight: '22px',
    padding: '8px 24px 6px',
    minHeight: '40px',
    color: secondaryTextColor,
    '&.Mui-selected': {
      color: standardSelectedColor,
    }
  },
}
export const buttonOverrides: ComponentsOverrides<Theme>['MuiButton'] = {

  textPrimary: {
    color: buttonTextColor,
  },
  textSecondary: {
    color: buttonSecondaryColor,
  },
  outlinedPrimary: {
    borderColor: buttonPrimaryColor,
    color: buttonPrimaryColor,
    ":hover": {
      borderColor: buttonPrimaryColor,
    }
  },
  outlinedSecondary: {
    borderColor: buttonSecondaryColor,
    color: buttonSecondaryColor,
    ":hover": {
      borderColor: buttonSecondaryColor,
    }
  },
  containedPrimary: {
    backgroundColor: buttonPrimaryColor,
    ":hover": {
      backgroundColor: buttonPrimaryColor,
    }
  },
  containedSecondary: {
    backgroundColor: buttonSecondaryColor,
    ":hover": {
      backgroundColor: buttonSecondaryColor,
    }
  }
}