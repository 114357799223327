import { UNAVAILABLE_SYMBOL } from './constant';

interface ByteOptions {
  baseUnit?: string;
  targetUnit?: string;
  needUnit?: boolean;
  needInt?: boolean;
}
function getCapacityScale(scale: number): { [key: string]: number; } {
  return {
    B: 1,
    KB: scale ** 1,
    MB: scale ** 2,
    GB: scale ** 3,
    TB: (scale ** 3) * (scale ** 1),
    PB: (scale ** 3) * (scale ** 2),
    EB: (scale ** 3) * (scale ** 3),
    ZB: (scale ** 3) * (scale ** 3) * (scale ** 1),
  }
}
export const capacityUnits = ['KB', 'MB', 'GB', 'TB', 'PB'];
export function convertBytes(value: number, options: ByteOptions = {}) {
  return converBytesByScale(value, 1024, options);
}

export function convertBytes1000(value: number, options: ByteOptions = {}) {
  return converBytesByScale(value, 1000, options);
}
function converBytesByScale(value: number, baseScale: number, options: ByteOptions = {}) {
  let result: number | string = value;
  const scale = getCapacityScale(baseScale);
  if (!options.baseUnit) {
    options.baseUnit = 'KB';
  }
  result = result * scale[options.baseUnit];
  if (!options.targetUnit) {
    if (!options.needUnit) {
      options.needUnit = true;
    }
    for (const s in scale) {
      const convertValue = result / scale[s];
      if (convertValue >= 1 && (convertValue < baseScale || s === 'PB')) {
        options.targetUnit = s;
        break;
      }
    }
    if (!options.targetUnit) {
      options.targetUnit = 'B';
    }
  }
  if (options.needInt) {
    result = parseInt((result / scale[options.targetUnit]).toString());
  } else {
    result = parseFloat((result / scale[options.targetUnit]).toFixed(2));
  }
  if (isNaN(result)) {
    result = UNAVAILABLE_SYMBOL;
  }
  if (options.needUnit) {
    return {
      value: result,
      unit: options.targetUnit,
    };
  }
  return result;
}