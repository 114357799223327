import { DATE_FORMAT, UNAVAILABLE_SYMBOL } from 'common';
import { ErrorText, FormItemLabel, FormItemLine } from 'components';
import dayjs from 'dayjs';
import { LicenseFormProductPeriodProps } from 'interfaces/props';
import t from 'locale';
import { MenuItem, Select, SelectChangeEvent } from 'x-material-ui';
import styles from './index.module.scss'
import { getExpectedUsageTime, getHistoryApplyInfoByMd5 } from 'utils';
import { DiditalLicenseFormModel, LicenseDigital } from 'model';

const ProductPeriod = (props: LicenseFormProductPeriodProps) => {
  const {
    license,
    matserLicense,
    error,
    getExpireDate,
    update,
    renderActiveDate,
  } = props;
  async function handleTemporaryPeriodChange(e: SelectChangeEvent<number>) {
    const period = parseInt(e.target.value as string, 10);
    const expireDate = getExpireDate(license.activeDate, period, 'month');
    license.skus?.forEach(element => {
      element.period = period;
      element.expiredDate = expireDate;
    });
    const { expectedUsageTime } = await getHistoryApplyInfoByMd5(license.clusterMd5 || '', { ...license, expireDate, period } as Partial<LicenseDigital>);
    if (license.version === 'V6') {
      license.period = period;
      license.expireDate = expireDate;
      license.expectedUsageTime = expectedUsageTime;
      update({});
      const licenseExpectedUsageTime = await getExpectedUsageTime(matserLicense as DiditalLicenseFormModel, expectedUsageTime);
      if (matserLicense) {
        matserLicense.expectedUsageTime = licenseExpectedUsageTime;
      }
      update({ expectedUsageTime: licenseExpectedUsageTime });
    } else {
      update({
        period,
        expireDate,
        skus: license.skus,
        expectedUsageTime
      });
    }
  }
  async function handleActiveDateChange(date: string | null) {
    let expireDate = license.expireDate;
    if (date) {
      if (license.periodUnit && license.period) {
        expireDate = getExpireDate(date, license.period, license.periodUnit);
      }
      license.skus?.forEach(element => {
        element.signedDate = dayjs(date).format(DATE_FORMAT);
        element.expiredDate = expireDate;
      });
    
      const { expectedUsageTime } = await getHistoryApplyInfoByMd5(license.clusterMd5 || '', { ...license, expireDate } as Partial<LicenseDigital>);
      license.expectedUsageTime = expectedUsageTime;
        update({});
      if (license.version === 'V6') {
        license.activeDate = date;
        license.expireDate = expireDate;
        const licenseExpectedUsageTime = await getExpectedUsageTime(matserLicense as DiditalLicenseFormModel, expectedUsageTime);
        update({ expectedUsageTime: licenseExpectedUsageTime });
      } else {
        update({
          activeDate: date,
          expireDate,
          skus: license.skus,
          expectedUsageTime
        });
      }

    }
  }
  return (<>
    <FormItemLine>
      <FormItemLabel label={t('有效期')} required={license.version === 'V4' || (license.version === 'V5' && !!license.productSkuCode)} />
      <Select
        variant="outlined"
        classes={{
          select: `${styles.select} ${styles['service-name-select']}`,
          icon: styles['select-icon'],
        }}
        value={license.period || 1}
        onChange={handleTemporaryPeriodChange}
        disabled={license.isDelay && license.scene !== 'poc'}
      >
        <MenuItem value={1}>
          {t('1月')}
        </MenuItem>
        <MenuItem value={2}>
          {t('2月')}
        </MenuItem>
      </Select>
    </FormItemLine>
    <FormItemLine>
      <FormItemLabel label={t('签发时间')} required={license.version === 'V4' || (license.version === 'V5' && !!license.productSkuCode)} />
      <div>
        {renderActiveDate(license.activeDate, handleActiveDateChange, false)}
        <ErrorText error={error.activeDate} />
      </div>
    </FormItemLine>
    <FormItemLine>
      <FormItemLabel label={t('到期时间')} required={license.version === 'V4' || (license.version === 'V5' && !!license.productSkuCode)} />
      {(license.activeDate && license.expireDate) ? dayjs(license.expireDate).format(DATE_FORMAT) : UNAVAILABLE_SYMBOL}
    </FormItemLine>
  </>);
}

export default ProductPeriod;