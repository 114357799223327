import styles from './index.module.scss';
import { InfoFieldProps } from 'interfaces/props';
import classnames from 'classnames';

const InfoField = (props: InfoFieldProps) => {
  const { label, value, className } = props;
  return (
    <div className={classnames(styles['info-item'], className)}>
      <p className={styles.label}>{label}{props.colon !== false && ':'}</p>
      <div className={styles.value}>{value}</div>
    </div>
  );
};

export default InfoField;
