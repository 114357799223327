import {  DetailBasicInfoProps, DetailBasicInfoDefaultProps } from 'interfaces/props';
import styles from './index.module.scss';
import lodash from 'lodash';
import { Grid } from 'x-material-ui';
import InfoField from '../InfoField';
import classnames from 'classnames';

const DetailBasicInfo = (props: DetailBasicInfoProps) => {
  const layouts = lodash.chunk(props.fields, props.col);
  return (<div className={classnames(styles.container, props.className)}>
    {layouts.map((line, i) => {
      return (<Grid key={`row-${i}`} container item xs={12}>
        {line.map((col, i) => {
          return <Grid item xs={props.colWidth} key={`col-${i}`}>
            <InfoField
              key={i}
              {...col}
            />
          </Grid>
        })}
      </Grid>);
    })}
    {props.separateFields?.map((field, index) => (<div key={index}>
      <InfoField
        {...field}
      /></div>))}
  </div>);
}

const defaultProps: DetailBasicInfoDefaultProps = {
  col: 3,
  colWidth: 4,
}
DetailBasicInfo.defaultProps = defaultProps;

export default DetailBasicInfo;