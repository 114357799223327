import { AuthenticationClient } from 'authing-js-sdk';
import { ErrorText } from 'components';
import { EXPIRE_NAME, TOKEN_NAME, USER_ID } from 'constant';
import { GlobalContext } from 'context';
import { Login as LoginProps } from 'interfaces/props';
import t from 'locale';
import { Token } from 'model';
import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { loginByToken } from 'request';
import { Checkbox, TextField, ThemeProvider, createTheme } from 'x-material-ui';
import { outlinedInputOverrides } from '../../styles/theme';
import styles from './index.module.scss';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        ...outlinedInputOverrides,
        notchedOutline: {
          borderWidth: 0,
        },
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            'border-width': '0',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            'border-width': '0',
          },
        },
      },
    }
  }
});
const authing = new AuthenticationClient({
  appId: '63a91767f1bb3e52734bc9f5',
  appHost: 'https://xsky-ext.authing.cn',
});
const Login = (props: LoginProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [accountError, setAccountError] = useState('');
  const [agree, setAgree] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  const context = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    init();
  }, []);
  async function init() {
    const user = await authing.getCurrentUser();
    if (user?.id) {
      if (user.token) {
        const response = await loginByToken(user.token);
        if (response.status === 200) {
          const result = response.data;
          loginSuccess(result);
        }
      }
    }
  }
  function loginSuccess(result: Token) {
    document.cookie = `${TOKEN_NAME}=${result.uuid}; path=/; expires=${result.expires}`;
    document.cookie = `${EXPIRE_NAME}=${result.expires}; path=/; expires=${result.expires}`;
    document.cookie = `${USER_ID}=${result.userId};path=/;expires=${result.expires}`;
    context.updateToken?.(result);
    let from = '/';
    if (location.search) {
      const query = queryString.parse(location.search);
      if (query.redirect) {
        from = query.redirect as string;
      }
    }
    navigate(from, { replace: true });
    props.updateUser(result.userId);
    props.updateAliOssConfig();
  }
  function handleAgreementChange(checked: boolean) {
    setAgree(checked);
    setAgreeError(false);
  }
  async function handleLoginClick() {
    if (!agree) {
      setAgreeError(true);
      return;
    }
    if (!username || !password) {
      setAccountError('请输入正确的账号和密码');
    }
    let user;
    try {
      if (username.indexOf('@') !== -1) {
        user = await authing.loginByEmail(username, password);
      } else {
        user = await authing.loginByUsername(username, password);
      }
      if (user.id) {
        if (user.token) {
          const response = await loginByToken(user.token);
          if (response.status === 200) {
            const result = response.data;
            loginSuccess(result);
          }
        }
      } else {
        setAccountError('请输入正确的账号和密码');
      }
    } catch (e: any) {
      if (e.message === '密码错误') {
        setAccountError('请输入正确的账号和密码');
      } else {
        setAccountError(e.message);
      }
    }
  }
  function handleUsernameChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
    setUsername(e.target.value);
    setAccountError('');
  }
  function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
    setPassword(e.target.value);
    setAccountError('');
  }
  function renderLogin() {
    return (<div className={styles.container}>
      <div className={styles.left}><img alt='' height={100} width={450} src={require('../../assets/login.png')} /></div>
      <div className={styles.right}>
        <div className={styles['login-form']}>
          <div className={styles.title}>{t('XSKY 客户中心')}</div>
          <div className={styles['user-login-title-container']}>
            <span className={styles['user-login-title-text']}>{t('用户登录')}</span>
          </div>
          <ThemeProvider theme={theme}>
            <TextField
              className={styles['login-text-field']}
              placeholder={t('请输入用户名')}
              value={username}
              onChange={handleUsernameChange}
            />
            <TextField
              className={styles['login-text-field']}
              placeholder={t('请输入登录密码')}
              value={password}
              type="password"
              onChange={handlePasswordChange}
            />
          </ThemeProvider>
          <div className={styles['error-text']}><ErrorText error={{ error: !!accountError, text: accountError }} /></div>
          <div className={styles['agreement-container']}>
            <Checkbox
              checked={agree}
              color="primary"
              onChange={(e, checked) => handleAgreementChange(checked)}
              name={'agreement'}
            />
            <span>{t('我已阅读并同意')}</span>
            <a className={styles['agreement-title']} href='/policy.html' target='_blank'>{t('服务协议')}</a>
            <span>{t('和')}</span>
            <a className={styles['agreement-title']} href='/agreement.html' target='_blank'>{t('隐私条款')}</a>
          </div>
          <div className={styles['error-text']}><ErrorText error={{ error: agreeError, text: t('请先阅读并同意协议') }} /> </div>
          <div className={styles['login-button']} onClick={handleLoginClick} color='primary'>{t('登录')}</div>
          <div className={styles['password-tips']}>{t('如果忘记密码，请联系XSKY销售代表重置密码')}</div>
        </div>
      </div>
    </div>);
  }
  return renderLogin();
}

export default Login;