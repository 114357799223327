import React from 'react';
import styles from './index.module.scss';
import t from 'locale';
import { LoadingProps } from 'interfaces/props';
import classnames from 'classnames';

const Loading = (props: LoadingProps) => {
  return (
    <div className={classnames(styles.container, props.className)}>
      <span className={`loading ${styles['loading-container']}`}>
        <span className={styles['left-part']}></span>
        <span className={styles['right-part']}></span>
      </span>
      <span className={styles.tips}>{props.message || t('页面正在加载...')}</span>
    </div>
  );
}

export default Loading;