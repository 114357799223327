import { formatDateOnly } from 'common';
import { ClusterDetailProps, ClusterDetailTab, DetailsBasicFieldProps } from 'interfaces/props';
import t from 'locale';
import lodash from 'lodash';
import { Cluster, ClusterExpandRecord, ClusterRenewRecord } from 'model';
import { SyntheticEvent, useEffect, useState } from 'react';
import { getCluster } from 'request';
import { Tab, Tabs } from 'x-material-ui';
import {
  clusterHardwareProductColumn,
  clusterHardwareSnColumn,
  clusterHardwareWarrantyServiceLevelColumn,
  clusterHardwareWarrantyServicePeriodColumn,
  clusterHardwareWarrantyServiceEndColumn,
} from '../Column';
import DefaultTable from '../DefaultTable';
import DetailBasicInfo from '../DetailBasicInfo';
import DetailHeaderInfo from '../DetailHeaderInfo';
import PartCollapseList from '../PartCollapseList';
import ClusterPermit from './cluster-permit';
import ClusterPolling from './cluster-polling';
import styles from './detail.module.scss';

const ClusterDetail = (props: ClusterDetailProps) => {
  const [cluster, setCluster] = useState<Cluster>();
  const [tab, setTab] = useState<ClusterDetailTab>('software');
  const hardwareProducts = ['X3000', 'X3200', 'X5000', 'X5100', 'X5200', 'Y3000'];
  useEffect(() => {
    fetchCluster();
  }, []);
  const expandHistory = cluster?.expandHistory || [];
  const renewHistory = cluster?.renewHistory || [];
  const pollings = cluster?.pollings || [];
  const hardwares = cluster?.hardwares || [];
  async function fetchCluster() {
    if (props.id) {
      const response = await getCluster(props.id);
      setCluster(response.data);
    }
  }
  function handleTabChange(event: SyntheticEvent<Element, Event>, value: any) {
    setTab(value);
  }
  function getSoftwareName(productName = '') {
    if (!productName) {
      return '';
    }
    const products = productName.split(',');
    return products.filter(p => !hardwareProducts.includes(p)).join(',');
  }
  function getExpandHistoryData(records: ClusterExpandRecord[]) {
    return records.map((c, i) => {
      const fields = [
        c.hostNum && { label: t('节点数'), value: c.hostNum },
        c.capacityTb && { label: t('容量'), value: `${c.capacityTb} TB` },
        c.cpuCount && { label: t('CPU 数量'), value: c.cpuCount },
      ];
      return {
        title: (<div>
          <span className={styles['record-date']}>{formatDateOnly(c.start)}</span>
        </div>),
        fields: lodash.compact(fields),
        separateFields: [],
        id: i,
      }
    });
  }
  function getRenewHistoryData(records: ClusterRenewRecord[]) {
    return records.map((c, i) => {
      const fields = [
        c.start && { label: t('续保开始日期'), value: formatDateOnly(c.start) },
        c.end && { label: t('续保结束日期'), value: formatDateOnly(c.end) },
      ];
      return {
        title: (<div>
          <span className={styles['record-date']}>{formatDateOnly(c.activeDate)}</span>
        </div>),
        fields: lodash.compact(fields),
        separateFields: [],
        id: i,
      }
    });
  }
  function renderBasic() {
    const headerFields: DetailsBasicFieldProps[] = [
      { label: t('集群 ID'), text: cluster?.fsId, },
      { label: t('客户名称'), text: cluster?.customerName, },
      { label: t('合同编号'), text: cluster?.contractNos, },
      { label: t('产品名称'), text: cluster?.productName, },
    ];
    return (
      <div className={styles['basic-container']}>
        <DetailHeaderInfo title={cluster?.name} fields={headerFields} />
      </div>
    );
  }
  function renderSoftware() {
    const softwareFields = [
      {
        label: t('软件产品'),
        value: getSoftwareName(cluster?.productName),
      },
      {
        label: t('版本'),
        value: cluster?.version,
      },
      {
        label: t('软件质保期限'),
        value: t('{{period}} 个月', { period: cluster?.warrantyServicePeriod }),
      },
      {
        label: t('售后服务级别'),
        value: cluster?.warrantyServiceLevel,
      },
      {
        label: t('许可授权节点数'),
        value: cluster?.hostNum,
      },
      {
        label: t('许可授权容量'),
        value: cluster?.capacityTb + ' TB',
      },
    ];
    return (<div>
      <DetailBasicInfo fields={softwareFields} col={2} colWidth={6} />
    </div>);
  }
  function renderHardware() {
    const columns = [
      clusterHardwareProductColumn,
      clusterHardwareSnColumn,
      clusterHardwareWarrantyServicePeriodColumn,
      clusterHardwareWarrantyServiceLevelColumn,
      clusterHardwareWarrantyServiceEndColumn,
    ];
    return (<div>
      <DefaultTable
        loading={false}
        data={hardwares}
        columns={columns}
        total={hardwares.length || 0}
        name='cluster-hardware'
        fetchData={() => { }}
        hasSearch={false}
      />
    </div>);
  }
  function renderExpandHistory() {
    return (<div className={styles['expand-history-container']}>
      <PartCollapseList data={getExpandHistoryData(expandHistory)} />
    </div>);
  }
  function renderRenewHistory() {
    return (<div className={styles['renew-history-container']}>
      <PartCollapseList data={getRenewHistoryData(renewHistory)} />
    </div>);
  }
  function renderDetail() {
    return (<div className={styles['detail-container']}>
      <div className={styles['tab-container']}>
        <Tabs value={tab} onChange={handleTabChange} >
          <Tab value="software" label={t('软件信息')} />
          {!!hardwares.length && <Tab value="hardware" label={t('硬件信息')} />}
          <Tab value="permit" label={t('许可信息')} />
          {!!expandHistory.length && <Tab value="expand" label={t('扩容记录')} />}
          {!!renewHistory.length && <Tab value="renew" label={t('续保记录')} />}
          {!!pollings.length && <Tab value="polling" label={t('巡检记录')} />}
        </Tabs>
      </div>
      <div className={styles['content-container']}>
        {tab === 'software' && renderSoftware()}
        {tab === 'hardware' && renderHardware()}
        {tab === 'permit' && <ClusterPermit xsosLicenses={cluster?.licenses?.xsosLicenses || []} v4Licenses={cluster?.licenses?.v4Licenses || []} />}
        {tab === 'expand' && renderExpandHistory()}
        {tab === 'renew' && renderRenewHistory()}
        {tab === 'polling' && <ClusterPolling pollings={pollings} />}
      </div>
    </div>);
  }
  return (<div className={styles.container}>
    {renderBasic()}
    {renderDetail()}
  </div>);
}

export default ClusterDetail;