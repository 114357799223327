import { ErrorTextProps } from 'interfaces/props';
import React from 'react';

const ErrorText = (props: ErrorTextProps) => {
  if (props.error && props.error.error) {
    return <div className="error-text">{props.error.text}</div>
  }
  return <></>;
}

export default ErrorText;