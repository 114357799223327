import { AxiosResponse } from 'axios';
import { TABLE_DEFAULT_PAGE_SIZE } from 'constant';
import { Cluster, DashboardSummary, Polling } from 'model';
import { instance } from './instance';

export async function getClusters(limit = TABLE_DEFAULT_PAGE_SIZE, offset = 0, search = ''): Promise<AxiosResponse<[Cluster[], number]>> {
  return instance.get(`/cluster`, { params: { offset, limit, search } });
}

export async function getCluster(id: number): Promise<AxiosResponse<Cluster>> {
  return instance.get(`/cluster/${id}`);
}

export async function getDashboardSummary(): Promise<AxiosResponse<DashboardSummary>> {
  return instance.get(`/cluster/summary`);
}

export async function getPollingDetail(pollingId: number): Promise<AxiosResponse<Polling>> {
  return instance.get(`/cluster/polling-detail`, { params: { pollingId } });
}