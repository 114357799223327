import { TableColumn } from 'interfaces/props';
import { Checkbox } from 'x-material-ui';
export const checkBoxAllColumn: (onChange: (checked: boolean, record: any) => void, onChangeAll: (checked: boolean) => void, checkedAll: boolean) => TableColumn = (onChange, onChangeAll, checkedAll) => ({
  title: '',
  titleTemplate: () => {
    return (
      <Checkbox
        checked={!!checkedAll}
        color="primary"
        onChange={(e, checked) => onChangeAll(checked)}
      />
    );
  },
  dataKey: 'checkboxAll',
  template: (record: any) => {
    return (
      <Checkbox
        checked={!!record.checked}
        color="primary"
        onChange={(e, checked) => onChange(checked, record)}
      />
    );
  }
})
export const checkboxColumn = (onChange: (checked: boolean, record: any) => void) => ({
  title: '',
  dataKey: 'checkbox',
  template: (record: any) => {
    return (
      <Checkbox
        checked={!!record.checked}
        color="primary"
        disabled={record.disabled}
        onChange={(e, checked) => onChange(checked, record)}
      />
    );
  }
});