import React, { Component } from 'react';
import styles from './index.module.scss';
import {  TableProps } from 'interfaces/props';
import t from 'locale';
import { Loading } from 'components';
import { Collapse, TableCell, TableRow, Table as MaterialTable, TableBody, TableHead } from 'x-material-ui';
import classnames from 'classnames';

export default class Table extends Component<TableProps> {

  componentDidUpdate() {
    const { left = 0, right = 0 } = this.props;
    if (left > 1) {
      this.setStickyColOffset(left, 'left');
    }
    if (right > 1) {
      this.setStickyColOffset(left, 'right');
    }
  }

  setStickyColOffset(colSpan: number, prop: 'left' | 'right') {
    let offset = 0;
    const tableHead = document.getElementsByClassName(styles['head-root'])[0];
    const tableBody = document.getElementsByClassName(styles['body-root'])[0];
    if (!tableHead || !tableBody) {
      return;
    }
    for (let i = 0; i < colSpan - 1; i++) {
      const current = tableHead.querySelector(`.sticky-${prop}-${i}`);
      offset += current?.clientWidth || 0;
      const nextHeaderCell = tableHead.querySelectorAll<HTMLElement>(`.sticky-${prop}-${i + 1}`)[0];
      if (nextHeaderCell) {
        nextHeaderCell.style[prop] = offset + 'px';
      }
      const nextBodyCells = tableBody.querySelectorAll<HTMLElement>(`.sticky-${prop}-${i + 1}`);
      nextBodyCells.forEach(item => {
        item.style[prop] = offset + 'px';
      });
    }
  }

  handleRowClick = (e: any, row: any) => {
    const { rowClick } = this.props;
    if (rowClick) {
      rowClick(e, row);
    }
  }

  getRowKey(row: any) {
    const { rowKey = 'id' } = this.props;
    const keys = rowKey.split(',');
    return keys.map(k => row[k]).join('_');
  }

  getRootClass(total: number, current: number) {
    const { left = 0, right = 0 } = this.props;
    let rootClass = styles['cell-root'];
    if (left !== 0) {
      if (current < left) {
        rootClass += ` ${styles['sticky-left']} sticky-left-${current}`
      }
    }
    if (right !== 0) {
      if (current > total - right - 1) {
        rootClass += ` ${styles['sticky-right']} sticky-right-${total - current - 1}`
      }
    }
    return rootClass;
  }

  getTableHead() {
    const { columns = [], } = this.props;
    const length = columns.length;
    return (
      <TableHead classes={{ root: styles['head-root'] }}>
        <TableRow>
          {columns.map((column, i) => (
            <TableCell
              key={column.dataKey || column.title}
              classes={{
                root: classnames(this.getRootClass(length, i), column.className),
                stickyHeader: styles['stick-header-cell'],
              }}
            >
              {column.titleTemplate ? column.titleTemplate() : column.title || ''}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  getTableBody() {
    const {
      columns = [],
      data = [],
      noDataMessage,
      className
    } = this.props;
    const length = columns.length;
    return (
      <TableBody classes={{ root: classnames(styles['body-root'], className) }}>
        {data.length ? (
          data.map((row, index) => (
            <React.Fragment key={this.getRowKey(row)}>
              <TableRow
                classes={{
                  root: classnames(styles['body-row-root'], {
                    [row.className]: row.className,
                  })
                }}
                onClick={(e: any) => this.handleRowClick(e, row)}
              >
                {columns.map((column, i) => {
                  return (
                    <TableCell
                      key={column.dataKey || column.title}
                      classes={{
                        root: classnames(this.getRootClass(length, i), column.className),
                      }}
                    >
                      {column.template ? column.template(row, index) : row[column.dataKey || '']}
                    </TableCell>
                  );
                })}
              </TableRow>
              {row.open && this.props.subTableCols && row.subs &&
                <>
                  <TableRow >
                    <TableCell style={{ padding: 0 }} className={styles['second-table']} colSpan={this.props.subTableCols.length}>
                      <Collapse in={true} timeout="auto" unmountOnExit>
                        <Table data={row.subs} columns={this.props.subTableCols} loading={false} />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </>
              }
            </React.Fragment>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <div>{noDataMessage || t('暂无数据')}</div>
            </TableCell>
          </TableRow>
        )
        }
      </TableBody>
    );
  }

  render() {
    const { className, loading, sticky = true } = this.props;
    return (
      <>
        <MaterialTable stickyHeader={sticky} className={className}>
          {this.getTableHead()}
          {!loading && this.getTableBody()}
        </MaterialTable>
        {loading && <Loading message={t('数据加载中')} />}
      </>
    );
  }
}
