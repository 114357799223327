import { useState, useEffect } from 'react';
import { queryAllDict } from 'request';
import PerformResult from './index-output';
import styles from './index.module.scss';
import { DictOption } from './interface';
import SdsConfigForm from './index-config';

const SdsConfig = () => {
  const [dataDict, setDataDict] = useState<DictOption>({});
  const [loading, setLoading] = useState(false);
  const [configDict, setConfigDict] = useState<DictOption>({});
  useEffect(() => {
    async function fetchAllDict() {
      const response = await queryAllDict();
      setDataDict(response.data);
      setLoading(true);
    }
    fetchAllDict();
  }, []);

  function openOutput(configData: DictOption) {
    setConfigDict(configData);
  }

  return (
    <div className={styles.container}>
      {loading && <>
        <div className={styles.content}>
          <SdsConfigForm dataDict={dataDict} openOutput={openOutput} />
          <PerformResult dataDict={dataDict} configDict={configDict} />
        </div>
      </>}
    </div>
  );
}

export default SdsConfig;