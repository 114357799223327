import { useEffect, useState } from 'react';
import {
  contractOuterNoColumn,
  contractPartyANameColumn,
  contractCustomerColumn,
  contractStatusColumn,
  contractTypeColumn,
  contractProductColumn,
  contractDeliveryStatusColumn,
  scanContractSignDateColumn,
  DefaultTable,
  OrderDetail,
  DetailsContainer
} from 'components';
import { Contract, TableFilterSetting } from 'model';
import { useLocation } from 'react-router';
import styles from './index.module.scss';
import queryString from 'query-string';
import { TABLE_DEFAULT_PAGE_SIZE } from 'constant';
import { getContracts } from 'request';

const Order = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const status = query.status as string;
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [loading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [current, setCurrent] = useState<Contract>();
  const [total, setTotal] = useState(0);
  const [setting, setSetting] = useState<TableFilterSetting>({
    limit: TABLE_DEFAULT_PAGE_SIZE,
    page: 0,
    search: ''
  });
  useEffect(() => {
    fetchData(setting.limit, setting.page, setting.search);
  }, []);
  async function fetchData(limit: number, page: number, search: string) {
    setSetting({
      limit,
      page,
      search,
    });
    setLoading(true);
    const response = await getContracts(limit, page * limit, search, status);
    const result = response.data;
    setContracts(result[0]);
    setTotal(result[1]);
    setLoading(false);
  }
  function handleOuterNoClick(record: Contract) {
    setCurrent(record);
    setShowDetail(true);
  }
  const columns = [
    contractOuterNoColumn(handleOuterNoClick),
    contractPartyANameColumn,
    contractCustomerColumn,
    contractStatusColumn,
    contractDeliveryStatusColumn,
    contractTypeColumn,
    contractProductColumn,
    scanContractSignDateColumn];
  return (<div className={styles.container}>
    <div className={styles.content}>
      <DefaultTable
        loading={loading}
        data={contracts}
        columns={columns}
        name="order"
        total={total}
        fetchData={fetchData}
      />
    </div>
    {/* <Dialog open={showLicenseDialog} >
        <div className={styles['modal-container']}>
          <DialogTitle id="scroll-dialog-title" hasClose onCloseClick={handleCancelLicenseDialogClick}>{t('{{action}} license', { action: confirmTitle[dialogType] })}</DialogTitle>
          <DialogContent >
            {dialogType === 'apply' ? renderVersion() : renderOperation()}
            <div className={styles.action}>
              <TextButton
                size="medium"
                className={styles.cancel}
                variant='text'
                color='primary'
                onClick={handleCancelLicenseDialogClick}
              >
                {t('取消')}
              </TextButton>
              <Button
                size='medium'
                variant='contained'
                loading={operationLoading}
                disabled={operationLoading}
                color={dialogType === 'cancel' ? 'secondary' : 'primary'}
                onClick={handleOkClick}
              >
                {confirmTitle[dialogType]}
              </Button>
            </div>
          </DialogContent>
        </div>
      </Dialog> */}
    {
      showDetail && (<DetailsContainer open={showDetail} setOpen={setShowDetail} >
        <OrderDetail contractId={current?.id || 0} />
      </DetailsContainer>)
    }
  </div>);
}

export default Order;