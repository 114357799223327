import classnames from 'classnames';
import { DefaultTableProps, DefaultTableDefaultProps } from 'interfaces/props';
import t from 'locale';
import React, { useState } from 'react';
import { ConfirmDialog } from 'x-material-ui';
import { usePagination } from '../../hooks';
import Pagination from '../Pagination';
import Table from '../Table';
import Toolbar from '../Toolbar';
import styles from './index.module.scss';


function DefaultTable<T, S>(props: DefaultTableProps<T, S>) {
  const [search, setSearch] = useState('');
  const [showDownloadTips, setShowDownloadTips] = useState(false);
  function handlePagerChange(limit: number, page: number) {
    props.fetchData(limit, page, search);
  }
  function handleSearchClick() {
    setPage(0);
    props.fetchData(limit, 0, search);
  }
  function handleSearchInputChange(e: React.ChangeEvent<{ value: string; }>) {
    setSearch(e.target.value);
  };
  function handleDownloadExcelClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>,) {
    if (props.downloadExcel) {
      props.downloadExcel(search);
      if (props.backgroundDownload) {
        setShowDownloadTips(true);
      }
    } else if (props.hadleSelectDownloadExcelChange) {
      props.hadleSelectDownloadExcelChange(e, search);
    }
  }
  function handleConfirmDownloadTipsClick() {
    setShowDownloadTips(false);
    props.setShowDownloadTips?.(false);
  }

  const { limit, page, setPage, handlePageChange, handleRowsPerPageChange } = usePagination(handlePagerChange);
  const toolbars: any[] = props.toolbars ? [...props.toolbars] : [];
  if (props.hasSearch) {
    toolbars.push({
      type: 'search',
      children: search,
      onChange: handleSearchInputChange,
      onSearch: handleSearchClick,
      tableName: props.name,
    });
  }

  let columns = props.columns;

  return (<div className={classnames(props.className, styles.container)}>
    <div className={styles.toolbar}>
      <div className={styles['toolbar-left']}>
        <Toolbar items={toolbars} />
        {(props.downloadExcel || props.hadleSelectDownloadExcelChange) && <div className={styles['download-container']} title={props.downloadExcelTitle}>
          <span className={styles['download-icon-container']} onClick={(e) => handleDownloadExcelClick(e)}>
            <span className={classnames('icon-download', styles['download-excel'])} />
          </span>
        </div>}
        {!!props.additionalToolbars?.length && props.additionalToolbars.map((toolbar, i) => {
          return (<span key={i}>{toolbar}</span>);
        })}
      </div>
    </div>
    <div className={styles['table-content']}>
      <Table
        data={props.data}
        columns={columns}
        subTableCols={props.subTableCols}
        left={props.left}
        right={props.right}
        rowClick={props.rowClick}
        rowKey={props.rowKey}
        loading={props.loading}
      />
    </div>
    <Pagination
      count={props.total}
      rowsPerPage={limit}
      page={page}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
      className={`${styles['table-pagination']}`}
      additionalButton={props.paginationAdditionalButton}
    />
    {
      (showDownloadTips || !!props.showDownloadTips) && <ConfirmDialog
        open={showDownloadTips || !!props.showDownloadTips}
        title={t('提示')}
        tips={t('已开始下载，请到下载任务中查看进度')}
        handleOk={handleConfirmDownloadTipsClick}
        handleCancel={handleConfirmDownloadTipsClick}
        color="primary"
        okText={t('确定')}
        hasCancel={false}
      />
    }
  </div>);
}

const defaultProps: DefaultTableDefaultProps = {
  hasSearch: true,
  hasFilter: true,
  hasColumnSetting: true,
  backgroundDownload: true,
};
DefaultTable.defaultProps = defaultProps;

export default DefaultTable;
