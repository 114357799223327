import { useState } from 'react';
import { CollapseProps } from 'interfaces/props';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import {
  IconButton,
  TextButton,
  Collapse as MaterialCollapse,
} from 'x-material-ui';
import styles from './index.module.scss';

const Collapse = (props: CollapseProps) => {
  const { initialOpen, children, title, additionalButton } = props;
  const [open, setOpen] = useState(initialOpen);
  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <div className={styles.collapse}>
      <div className={styles["split-line"]} onClick={toggleOpen}>
        <TextButton classes={{ root: styles["line-title"] }}>{title}</TextButton>
        <div className={styles["split-line-right"]}>
          {additionalButton && additionalButton()}
          <IconButton classes={{ root: styles["contract-arrow"] }} >
            {open ? <KeyboardArrowUpOutlinedIcon /> : <KeyboardArrowDownOutlinedIcon />}
          </IconButton>
        </div>
      </div>
      <MaterialCollapse
        in={open}
      >
        {children}
      </MaterialCollapse>
    </div>
  );
};

export default Collapse;