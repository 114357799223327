import { DATE_FORMAT } from 'common';
import dayjs from 'dayjs';
import { ClusterPollingProps } from 'interfaces/props';
import t from 'locale';
import { Polling } from 'model';
import { useState } from 'react';
import { LinkButton } from 'x-material-ui';
import DetailsContainer from '../DetailsContainer';
import PartCollapseList from '../PartCollapseList';
import styles from './detail.module.scss';
import PollingDetail from './polling-detail';

const ClusterPolling = (props: ClusterPollingProps) => {
  const [showPollingReport, setShowPollingReport] = useState(false);
  const [currentPollingId, setCurrentPollingId] = useState(0);
  function handlePollingReportClick(pollingId: number) {
    setCurrentPollingId(pollingId);
    setShowPollingReport(true);
  }
  function getPollingsData(pollings: Polling[]) {
    return pollings.map(polling => {
      return {
        title: (<div>
          <span className={styles['polling-date']}>{dayjs(polling.polling_date).format(DATE_FORMAT)}</span>
          <LinkButton value={t('查看报告')} size="large" className={styles['polling-report']} onClick={() => handlePollingReportClick(polling.id)} />
        </div>),
        fields: [],
        separateFields: [],
        id: polling.id,
      }
    });
  }
  return <div className={styles['polling-container']}>
    <PartCollapseList data={getPollingsData(props.pollings)} />
    <DetailsContainer open={showPollingReport} setOpen={setShowPollingReport}><PollingDetail pollingId={currentPollingId} /></DetailsContainer>
  </div>
}

export default ClusterPolling;