import { AliOssConfig, Token, User } from 'model';
import React from 'react';

interface GlobalContextValue {
  token?: Token;
  user?: User;
  updateToken?: (token: Token) => void;
  aliOssConfig?: AliOssConfig;
}

export const GlobalContext = React.createContext<GlobalContextValue>({
});
