import React, { useState, useEffect } from 'react';
import { getPermissions } from 'request';
import { Permission } from 'model';
import { Loading, TransferList, Pagination } from 'components';
import { Button, Grid, TextButton, TextField } from 'x-material-ui';
import { PermissionForm as PermissionFormProps } from 'interfaces/props';
import styles from './index.module.scss';
import t from 'locale';
import { NO } from 'constant';
import { getPermissionAndUserFromRole } from 'common/src/permission'
import { usePagination } from '../../hooks';

function not(a: Permission[], b: Permission[]) {
  return a.filter(value => b.findIndex(v => v.id === value.id) === -1);
}
const PermissionForm = (props: PermissionFormProps) => {
  const { role, action = 'add-permissions' } = props;
  const { permissions: rolePermissions } = getPermissionAndUserFromRole(role);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [loading, setLoading] = useState(true);
  const [checkedPermissions, setCheckedPermissions] = useState<number[]>([]);
  const [search, setSearch] = useState<string>('');
  const { limit, page, setPage, handlePageChange, handleRowsPerPageChange } = usePagination(handlePagerChange);
  useEffect(() => {
    fetchPermissions();
  }, []);

  async function fetchPermissions(search?: string) {
    if (action === 'add-permissions') {
      const response = await getPermissions(1000, 0, search);
      setLoading(false);
      if (response.status === 200) {
        const rows = response.data[0];
        setPermissions(not(rows, role ? rolePermissions : []));
      }
    } else {
      setLoading(false);
      setPermissions(role ? !!search ? rolePermissions.filter(p => p.name.indexOf(search) !== -1) : rolePermissions : []);
    }
    setPage(0);
  };

  const handlePermisionChange = (selected: any) => {
    setCheckedPermissions(selected.map((s: any) => s.id));
  };

  function handlePagerChange(limit: number, page: number) {
    setPage(page);
  }

  async function handleSearchInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    var value = e.target.value.trim();
    setSearch(value);
  };

  async function handleSearchInputKeydown(e: React.KeyboardEvent) {
    e.persist();
    if (e.keyCode === 13) {
      await fetchPermissions(search);
    }
  };

  const TransferListPagination = (checked: number, total: number) => {
    return (
      <Pagination
        count={total}
        page={page}
        rowsPerPage={limit}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        type={'mini'}
      />
    );
  };

  return (
    <Grid container spacing={3} className={styles.layout}>
      <Grid className={styles.label} item xs={3}>
        {t('角色名称：')}
      </Grid>
      <Grid item xs={9}>
        {role ? role.name : NO}
      </Grid>
      <Grid className={styles.label} item xs={3}>
        {t('搜索权限：')}
      </Grid>
      <Grid item xs={9}>
        <TextField
          classes={{ root: styles.search }}
          id="input-with-icon-textfield"
          placeholder={t('请输入权限名称搜索')}
          variant="outlined"
          value={search}
          onChange={handleSearchInputChange}
          onKeyDown={handleSearchInputKeydown}
        />
      </Grid>
      <Grid className={styles.label} item xs={3}>
        {t('选择权限：')}
      </Grid>
      <Grid item xs={9}>
        {!loading ? (
          <TransferList
            data={permissions}
            onSelected={handlePermisionChange}
            page={page}
            perPage={limit}
            leftFooter={TransferListPagination}
          />
        ) : (
          <Loading />
        )}
      </Grid>
      <Grid item xs={12} classes={{ root: styles['form-footer'] }}>
        <Button
          size="medium"
          color={action === 'add-permissions' ? 'primary' : 'secondary'}
          onClick={() =>
            props.onSubmit(
              action === 'add-permissions'
                ? (rolePermissions).map(p => p.id).concat(checkedPermissions)
                : (rolePermissions).map(p => p.id).filter(id => !checkedPermissions.includes(id))
            )
          }
          className={`${styles['footer-button']}`}
          variant='contained'
        >
          {t('确定')}
        </Button>
        <TextButton
          className={`${styles['footer-button']}`}
          onClick={props.onCancel}
          size="medium"
        >
          {t('取消')}
        </TextButton>
      </Grid>
    </Grid>
  );
};

export default PermissionForm;
