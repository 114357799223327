import {
  contractSubjectMatterNameColumn,
  contractSubjectMatterTotalCountColumn,
  contractSubjectMatterShipCountColumn,
  shipmentLogisticDeliveryDateColumn,
  shipmentLogisticNoColumn,
  shipmentLogisticMatterCountColumn,
  Table,
} from 'components';
import { ContractSubjectMatter } from 'model';
import { useEffect, useState } from 'react';
import { getScmProductByContractNo } from 'request';
import styles from './index.module.scss';
import { ContractScmSubjectMatterTableModel } from 'interfaces/models';
const ScmShipment = (props: { contractNo: string }) => {
  const [matters, setMatters] = useState<ContractSubjectMatter[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getHardwareProducts()
  }, []);
  async function getHardwareProducts() {
    setLoading(true);
    const response = await getScmProductByContractNo(props.contractNo);
    const result = response.data;
    setMatters(result);
    setLoading(false);
  }
  const columns = [
    contractSubjectMatterNameColumn,
    contractSubjectMatterTotalCountColumn,
    contractSubjectMatterShipCountColumn,
  ];
  const logisticColumns = [
    shipmentLogisticDeliveryDateColumn,
    shipmentLogisticNoColumn,
    shipmentLogisticMatterCountColumn,
  ];
  function getData() {
    const products: ContractScmSubjectMatterTableModel[] = [];
    matters.forEach(c => {
      products.push({
        open: true,
        ...c,
        subs: c.logistics
      });
    });
    return products;
  }
  return (<div className={styles.content}>
    <Table data={getData()} columns={columns} subTableCols={logisticColumns} loading={loading} />
  </div>);
}
export default ScmShipment;