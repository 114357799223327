export function focusToError() {
  const errorClasses = ['.Mui-error', '.error-text'];
  let errorElement: Element | null = null;
  for (let errorClass of errorClasses) {
    if (!errorElement) {
      errorElement = document.querySelector(errorClass);
    }
  }
  if (errorElement) {
    errorElement.scrollIntoView();
  }
}